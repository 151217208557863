import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import TableScroll from '../utils/TableScroll'
import { useQuery } from '@tanstack/react-query'
import { getMethodService } from '../services'
import { BET_HISTORY_NODE_API } from '../services/constants'
import moment from 'moment'
import TableLoading from '../utils/TableLoading'
import { HandleLogout, amountCommasSparated, decodeData, popupCenter } from '../utils/Helper'
import { stateObjType } from '../types'
import { useSelector } from 'react-redux'
const Cookies = require('js-cookie');

const StockMemberListDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data,setData] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const { temp_user_id } = useSelector((state:any) => state.userId);
  const {pathname} = useLocation();

  
  const start_date_v2 = params?.start_date && `${params.start_date}`;
  const end_date_v2 = params?.end_date && `${moment(params.end_date).add(1,'days').format('YYYY-MM-DD')}`;
  
  const fetchMemberListDetail = (id: number | string | undefined,status: string | undefined,startDate: string | undefined,endDate : string | undefined) => {
    const getAuth = Cookies.get('n_a_u');
    if(getAuth){
        let decode = decodeData(getAuth);
        return getMethodService(`${BET_HISTORY_NODE_API}?user_id=${id}${status !== 'all' ? `&status=${status}`: ''}&sortDirection=desc&sortColumn=id&start_date=${startDate} 10:29&end_date=${endDate} 10:28&page=1&limit=1000`,{},false,decode?.token);
    };
  };
  const memberListDetailQuery = useQuery({ queryKey: ['memberOutstand',params.id,params.status,start_date_v2,end_date_v2], queryFn: () => fetchMemberListDetail(params.id,params.status,start_date_v2,end_date_v2), refetchOnWindowFocus: false});
  useEffect(()=>{
     if(memberListDetailQuery){
        setIsLoading(memberListDetailQuery.isLoading);
        if(memberListDetailQuery?.data == 401){
            HandleLogout(navigate);
        };
        if(memberListDetailQuery?.data?.status === "success"){
            setData(memberListDetailQuery?.data?.data);
        };
     }
  },[memberListDetailQuery]);


  return (
       <Template title='Stock Management'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        {temp_user_id?.length > 1 ? (
                        <div className="col-sm-2">
                            <Link to={'/report/win-lose-detail'} className="btn btn-sm btn-dark" >
                                {isLoading ? "Loading ..." : "Back"}
                            </Link>
                        </div>
                        ):
                        (
                            <div className="col-sm-2">
                                <button onClick={() => navigate(-1)} className="btn btn-sm btn-dark" >
                                    {isLoading ? "Loading ..." : "Back"}
                                </button>
                            </div>
                        )
                        }
                        <div className="col-sm-5">
                            <h4 className="card-title">
                                User Bet List - {params.id}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <table className="table text-white border table-bordered table-striped">
                                    <thead>
                                        <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                            <th className="p-2 text-center text-light">No</th>
                                            <th className="p-2 text-center text-light">Member</th>
                                            <th className="p-2 text-center text-light">Date</th>
                                            <th className="p-2 text-center text-light">Event</th>
                                            <th className="p-2 text-center text-light">Detail</th>
                                            <th className="p-2 text-center text-light">Amount</th>
                                            <th className="p-2 text-center text-light">Status</th>
                                            <th className="p-2 text-center text-light">Bingo</th>
                                            <th className="p-2 text-center text-light">Payout</th>
                                            <th className="p-2 text-center text-light">Net Profit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading &&
                                            <TableLoading col={10} />
                                        }
                                        {
                                            data?.length > 0 ?
                                            data.map((list:stateObjType,i:number) =>
                                                <tr style={{verticalAlign: 'middle'}} key={i}>
                                                    <td className="p-2 text-center text-dark">{i+1}</td>
                                                    <td className="p-2 text-center text-dark">
                                                        {list?.user?.usercode}
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        <p className="mb-0">{list.created_at}</p>
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        <p className="mb-0">
                                                            {
                                                                list?.bet_fixtures[0]?.football_fixture?.league_data?.name
                                                            }
                                                        </p>
                                                        <p className="mb-0">
                                                            {
                                                                <span className={list?.bet_fixtures[0]?.football_fixture?.odds_team == 'home' ? "text-danger" : "text-primary"}>
                                                                    {
                                                                        list?.bet_fixtures[0]?.football_fixture?.home_team_data?.name
                                                                    }
                                                                </span>
                                                            }
                                                            {` `} vs {` `}
                                                            {
                                                                <span className={list?.bet_fixtures[0]?.football_fixture?.odds_team == 'away' ? "text-danger" : "text-primary"}>
                                                                    {
                                                                        list?.bet_fixtures[0]?.football_fixture?.away_team_data?.name
                                                                    }
                                                                </span>
                                                            }
                                                            {
                                                                list?.bet_fixtures[0]?.football_fixture?.goals ? `(${list?.bet_fixtures[0]?.football_fixture?.goals?.home} - ${list?.bet_fixtures[0]?.football_fixture.goals?.away})` : <></>
                                                            }
                                                        </p>
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        <div>
                                                            {
                                                                list?.bet_type == 'perlay' ?
                                                                <div style={{cursor: 'pointer'}} onClick={() =>{
                                                                    popupCenter({url: `/stock-manage/parlay-detail/${list?.id}`, title: 'xtf', w: 350, h: 400});
                                                                }}>
                                                                    <p className="mb-0 text-decoration-underline">
                                                                        <span style={{color: 'green'}}>Parlay</span>
                                                                        {` `}
                                                                        ({list?.stake} @ {Math.pow(2,list?.bet_fixtures?.length) * list?.stake}) <span>x {list?.bet_fixtures?.length}</span>
                                                                    </p>
                                                                </div>
                                                                :
                                                                <>
                                                                    <p className="mb-0 ">
                                                                        {
                                                                            list?.bet_fixtures[0]?.bet_team == 'home' &&
                                                                                <span className={list?.bet_fixtures[0]?.football_fixture?.odds_team == 'home' ? "text-danger" : "text-primary"}>
                                                                                    {list?.bet_fixtures[0]?.football_fixture?.home_team_data?.name}
                                                                                </span>
                                                                        }
                                                                        {
                                                                            list?.bet_fixtures[0]?.bet_team == 'away' &&
                                                                                <span className={list?.bet_fixtures[0]?.football_fixture?.odds_team == 'away' ? "text-danger" : "text-primary"}>
                                                                                    {list?.bet_fixtures[0]?.football_fixture?.away_team_data?.name}
                                                                                </span>
                                                                        }
                                                                        {
                                                                            list?.bet_fixtures[0]?.bet_team == 'over' && <span className='text-danger'>Over</span>
                                                                        }
                                                                        {
                                                                            list?.bet_fixtures[0]?.bet_team == 'under' && <span>Under</span>
                                                                        }
                                                                        {` `}
                                                                        ({list?.stake} @ {list?.bet_fixtures[0]?.bet_odd}
                                                                           {
                                                                            list?.bet_type != 'perlay' ?
                                                                            // `${list?.bet_fixtures[0]?.bet_symbol1}${list?.bet_fixtures[0]?.bet_symbol2}${list?.bet_fixtures[0]?.bet_symbol3} `
                                                                            // `${list?.bet_fixtures[0]?.bet_odd} `
                                                                            ''
                                                                            // (
                                                                            //     list?.bet_fixtures[0].football_fixture?.mm_football_category?.id == 1 ?
                                                                            //     "x 0.92" : "x 0.95"
                                                                            // )
                                                                            :
                                                                            <span>@ {list?.bet_fixtures?.length}</span>
                                                                           }
                                                                        )

                                                                    </p>
                                                                </>

                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="p-2 text-end text-dark">
                                                        <b>{amountCommasSparated(list?.stake ?? 0)}</b>
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        <b  className={`${
                                                            list?.total_bet_result?.bet_result=="win"
                                                                ? "text-success"
                                                                : "text-danger"
                                                        }`}>{list?.total_bet_result?.bet_result}</b>
                                                    </td>
                                                    <td className="p-2 text-end text-dark">
                                                        <b>
                                                            {
                                                                amountCommasSparated(list?.total_bet_result?.bingo ?? 0)
                                                            }
                                                        </b>
                                                    </td>
                                                    <td className="p-2 text-end text-dark">
                                                        <b>
                                                            {
                                                                amountCommasSparated(list?.total_bet_result?.payout_amount ?? 0)
                                                            }
                                                        </b>
                                                    </td>
                                                    <td className="p-2 text-end text-dark">
                                                        <b>
                                                            {
                                                                amountCommasSparated(list?.total_bet_result?.profit ?? 0)
                                                            }
                                                        </b>
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td colSpan={10} className='text-center'>No found data</td>
                                            </tr>
                                        }
                                    </tbody>
                                    {
                                        data.length > 0 &&
                                        
                                        <tfoot>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                <td colSpan={5} className='text-end text-white' style={{fontSize: '13px'}}>Total :</td>
                                                <td style={{fontSize: '13px'}} className="text-end text-white p-2"><b>{data.reduce((a:any,v:any) =>  a = a + v?.stake , 0 ).toLocaleString()}</b></td>
                                                <td colSpan={3}></td>
                                                <td style={{fontSize: '13px'}} className="text-end text-white p-2"><b>{data.reduce((a:any,v:any) =>  a = a + v?.total_bet_result?.profit , 0 ).toLocaleString()}</b></td>
                                            </tr>
                                        </tfoot>
                                        }
                                </table>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
       </Template> 
  )
}

export default StockMemberListDetail
