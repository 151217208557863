import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { getMethodService } from '../services';
import { HandleLogout, amountCommasSparated, decodeData } from '../utils/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MEMBER_LIST_NODE_API } from '../services/constants';
import Template from '../utils/Template';
import TableScroll from '../utils/TableScroll';
import TableLoading from '../utils/TableLoading';
import Pagi from '../utils/Pagi';
import { stateObjType } from '../types';
const Cookies = require('js-cookie');

const ReportMemberDetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [from, setFrom] = useState(1);
    const navigate = useNavigate();
    const params = useParams();

    const fetchMemberList = (id:string | number | undefined,page: number | undefined) => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            return getMethodService(`${MEMBER_LIST_NODE_API}?user_id=${id}&sortDirection=asc&sortColumn=usercode&page=${page}&limit=100`,{},false,decode?.token);
        };
    }
    const memberListQuery = useQuery({ queryKey: ['memberData', params.id, page], queryFn: () => fetchMemberList(params.id,page),keepPreviousData : true});
    useEffect(()=>{
        if(memberListQuery){
            setIsLoading(memberListQuery?.isLoading);
            if(memberListQuery?.data == 401){
                HandleLogout(navigate);
            };
            
            if(memberListQuery?.data?.status === "success"){
                if(memberListQuery?.data?.meta){
                    if(memberListQuery?.data?.meta.current_page > 0){
                       if(memberListQuery?.data?.meta.per_page && memberListQuery?.data?.meta.current_page){
                          setFrom((memberListQuery?.data?.meta.per_page * (memberListQuery?.data?.meta.current_page - 1)) + 1 );
                       };
                    };
                    setTotalPage(memberListQuery?.data?.meta.last_page);
                
                };
                setUsers(memberListQuery?.data?.data);
            };
        };
    },[memberListQuery]);
  return (
    <>
       <Template title='Report'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-3">
                            <h4 className="card-title">
                                Member list - {params?.id}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                 <div>
                                    <table className="table border table-bordered table-striped text-white">
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                <th className="text-center text-light p-2"  >No</th>
                                                <th className="text-center text-light p-2"  >Member</th>
                                                <th className="text-center text-light p-2"  >Contact</th>
                                                <th className="text-center text-light p-2"  >Role</th>
                                                <th className="text-center text-light p-2"  >Detail</th>
                                                {/* <th className="text-center text-light p-2"  >Cash Balance</th>
                                                <th className="text-center text-light p-2"  >Account Balance</th>
                                                <th className="text-center text-light p-2"  >Member Balance</th>
                                                <th className="text-center text-light p-2"  >Min Bet Limit</th>
                                                <th className="text-center text-light p-2"  >Max Single Bet Limit</th>
                                                <th className="text-center text-light p-2"  >Match Limit</th>
                                                <th className="text-center text-light p-2"  >Min Perlay Limit Per
                                                    Combination</th>
                                                <th className="text-center text-light p-2"  >Max Perlay Limit Per
                                                    Combination</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && (
                                                <TableLoading col={12} />
                                            )}
                                            {
                                                users?.length > 0 ?
                                                users.map((user:stateObjType,i:number) => 
                                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="text-center text-dark p-2">{i+from}</td>
                                                        <td className="text-center text-dark p-2">
                                                            {user?.role_name !==
                                                            "member" ? (
                                                                <Link
                                                                    to={`/report/member-list/${user?.user_id}`}
                                                                >
                                                                    <b>
                                                                        {`${user?.usercode} (${user?.role_name})`}
                                                                    </b>
                                                                </Link>
                                                            ) : (
                                                                <b>
                                                                    {`${user?.usercode} (${user?.role_name})`}
                                                                </b>
                                                            )}
                                                        </td>
                                                        <td className="text-center text-d p-2ark">
                                                            {user?.contact}
                                                        </td>
                                                        <td className="text-center text-d p-2ark">
                                                            {user?.role_name}
                                                        </td>
                                                        <td className="text-center p-2">
                                                            <button className='btn btn-sm btn-info py-1 px-2' onClick={() => navigate(`/report/member-percent/${user?.user_id}`)}>View</button>
                                                        </td>
                                                        {/* <td className="text-center text-d p-2ark">
                                                            <b>
                                                                {amountCommasSparated(user?.wallet?.cash_balance ?? 0)}
                                                            </b>
                                                        </td>
                                                        <td className="text-end text-dark p-2">
                                                            <b>
                                                                {amountCommasSparated(user?.wallet?.account_balance ?? 0)}
                                                            </b>
                                                        </td>
                                                        <td className="text-end text-dark p-2">
                                                            {user?.role_name !==
                                                            "member" ? (
                                                                <b>
                                                                    {amountCommasSparated(user?.wallet?.member_balance ?? 0)}
                                                                </b>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </td>
                                                        <td className="text-end text-dark p-2">
                                                            <b>
                                                                {amountCommasSparated(user?.bet_setting?.min_bet_limit ?? 0)}
                                                            </b>
                                                        </td>
                                                        <td className="text-end text-dark p-2">
                                                            <b>
                                                                {amountCommasSparated(user?.bet_setting?.max_single_bet_limit ?? 0)}
                                                            </b>
                                                        </td>
                                                        <td className="text-end text-dark p-2">
                                                            <b>
                                                                {amountCommasSparated(user?.bet_setting?.match_limit ?? 0)}
                                                            </b>
                                                        </td>
                                                        <td className="text-end text-dark p-2">
                                                            <b>
                                                                {amountCommasSparated(user?.bet_setting?.min_myanmar_perlay_limit_per_combination ?? 0)}
                                                            </b>
                                                        </td>
                                                        <td className="text-end text-dark p-2">
                                                            <b>
                                                                {amountCommasSparated(user?.bet_setting?.max_myanmar_perlay_limit_per_combination ?? 0)}
                                                            </b>
                                                        </td> */}
                                                    </tr>
                                                ):
                                                <tr>
                                                    <td colSpan={12} className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                 </div>
                            </TableScroll>
                            {
                                totalPage > 1 && <Pagi setPage={setPage} setIsLoading={setIsLoading} totalPage={totalPage}/>
                            }
                        </div>
                    </div>
                </div>
            </div>

       </Template>
    </>
  )
}

export default ReportMemberDetail
