import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { useNavigate, useParams } from 'react-router-dom'
import { stateObjType } from '../types';
import TableScroll from '../utils/TableScroll';
import { useQuery } from '@tanstack/react-query';
import { getMethodService, putMethodService } from '../services';
import { AGENT_LIST_API, USER_BANN_API, USER_PASSWORD_CHANGE_API, USER_UNBANN_API } from '../services/constants';
import { HandleLogout, amountCommasSparated, decodeData } from '../utils/Helper';
import { useSelector } from 'react-redux';
import ChangePasswordModal from '../utils/Modal/ChangePasswordModal';
import { ToastContainer } from 'react-toastify';
import BanUserModal from '../utils/Modal/BanUserModal';
import CreateAgent from './CreateAgent';
import Pagi from '../utils/Pagi';
import EditAgent from './EditAgent';
import TableLoading from '../utils/TableLoading';
const  SweetAlert = require('sweetalert2');
const Cookies = require('js-cookie');

const MemberList = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [users,setUsers] = useState([]);
  const params:stateObjType = useParams();
  const { user_info } = useSelector((state:any) => state.userInfo);
  const navigate = useNavigate();
  const [auth, setAuth] = useState(null);
  const [role, setRole] = useState(null);
  const [showChangePassModal, setShowChangePassModal] = useState(false);
  const [showBanModal, setShowBanModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(()=>{
    localStorage.removeItem('r_type');
  },[]);

  const fetchUsers = (id:string | undefined | number,page: number | undefined) => getMethodService(`${AGENT_LIST_API(id)}?page=${page}&sort_column=usercode&sort_direction=asc`);
  const usersQuery = useQuery({ queryKey: ['users', params.id,page,refreshKey], queryFn: () => fetchUsers(params.id,page),enabled: !!params.id, keepPreviousData : true, refetchOnWindowFocus: false});

  useEffect(()=>{
        setIsLoading(true);
        if(usersQuery?.data == 401){
            HandleLogout(navigate);
        };

        if(usersQuery?.data?.status === "success"){
            if(usersQuery?.data?.meta){
                setIsLoading(usersQuery?.data?.meta?.current_page === page ? false : true);
                if(usersQuery?.data?.meta.current_page > 0){
                   if(usersQuery?.data?.meta.per_page && usersQuery?.data?.meta.current_page){
                      setFrom((usersQuery?.data?.meta.per_page * (usersQuery?.data?.meta.current_page - 1)) + 1 );
                   };
                };
                setTotalPage(usersQuery?.data?.meta.last_page);
            };
            setUsers(usersQuery?.data?.data);
        };
  },[usersQuery]);

    const changePasswordSubmit = (data:React.FormEvent<HTMLFormElement>) => {
        if (auth) {
            setIsLoading(true);
            putMethodService(
                USER_PASSWORD_CHANGE_API,
                { user_id: auth, ...data },true
            )
            .then(res => {
                if(res == 401){
                    HandleLogout(navigate);
                }
                setIsLoading(false);
                if(res.status){
                    setShowChangePassModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
            });
        }
    };

    const handleBanClick = (userId: any) => {
        setAuth(userId);
        setShowBanModal(true);
    };

    const banUserOnSubmit = (data:any) => {
        if(auth) {
            // const getAuth = Cookies.get('n_a_u');
            // if(getAuth){
            //     let decode = decodeData(getAuth);
                setIsLoading(true);
                putMethodService(USER_BANN_API(auth),data,true)
                .then(res => {
                    setIsLoading(false);
                    if(res == 401){
                        HandleLogout(navigate);
                    };
                    if(res.status === "success"){
                        setShowBanModal(false);
                        setRefreshKey(prevKey => prevKey + 1);
                    };
                });
            // }
        }
    };

    const handleUnBanClick = (userId:any) => {
        SweetAlert.fire({
            title: "Are you sure you want to unban this user?",
            showCancelButton: true,
            confirmButtonText: "Yes, Unban it!",
            icon: "warning",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true
        }).then((result:any) => {
            if (result.isConfirmed) {
                if(userId){
                    // const getAuth = Cookies.get('n_a_u');
                    // if(getAuth){
                    //     let decode = decodeData(getAuth);
                        setIsLoading(true);
                        putMethodService(USER_UNBANN_API(userId),"",true)
                        .then(res => {
                            setIsLoading(false);
                            if(res == 401){
                                HandleLogout(navigate);
                            }
                            if(res.status === 'success'){
                                setRefreshKey(prevKey => prevKey + 1);
                            };
                        });
                    // }
                }
            }
        });
    };

    const handleEditClick = (userId:any,role:any) => {
        setAuth(userId);
        setRole(role);
        setShowEditModal(true);
        console.log(role)
    };
  return (
    <>
        <Template title="Member management">
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-6">
                            <h4 className="card-title">
                                {
                                    params.id == 2 && 'Super' ||
                                    params.id == 3 && 'Senior' ||
                                    params.id == 4 && 'Master' ||
                                    params.id == 5 && 'Agent' ||
                                    params.id == 6 && 'User' 
                                }
                            </h4>
                        </div>
                        <div className='col-sm-6'>
                            <div className='d-flex justify-content-end'>
                                <CreateAgent setRefreshKey={setRefreshKey} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className='col-sm-12'>
                            <TableScroll>
                                <table className="table text-white border table-bordered table-striped">
                                    <thead>
                                        <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                            <th className="p-2 text-center text-light">No</th>
                                            <th className="p-2 text-center text-light">Username</th>
                                            <th className="p-2 text-center text-light">Contact</th>
                                            <th className="p-2 text-center text-light">Role</th>
                                            <th className="p-2 text-center text-light">Cash balance</th>
                                            {/* <th className="p-2 text-center text-light">Account balance</th>
                                            <th className="p-2 text-center text-light">Member balance</th>
                                            <th className="p-2 text-center text-light">Total outstanding</th> */}
                                            <th className="p-2 text-center text-light">Status</th> 
                                            <th className="p-2 text-center text-light">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {isLoading && <TableLoading col={9} />}
                                    {users.map((user:stateObjType, $key:number) => {
                                            return (
                                                <tr
                                                    key={$key}
                                                    style={{ verticalAlign: "middle" }}
                                                >
                                                    <td className="p-2 text-center text-dark">
                                                        {$key + from}
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {user.usercode}
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {user.contact ?? "--"}
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {user.role ?? "--"}
                                                    </td>
                                                    <td className="p-2 text-end text-dark">
                                                        {amountCommasSparated(user?.wallet?.amount ?? '--')}
                                                    </td>
                                                    {/* <td className="p-2 text-end text-dark">
                                                        {amountCommasSparated(user?.wallet?.account_balance ?? '??')}
                                                    </td>
                                                    {user_info?.role?.toLowerCase() !=
                                                        "member" && (
                                                        <>
                                                            <td className="p-2 text-end text-dark">
                                                                {amountCommasSparated(user?.wallet?.member_balance ?? 0)}
                                                            </td>
                                                            <td className="p-2 text-end text-dark">
                                                                {amountCommasSparated(user?.wallet?.outstanding_balance ?? 0)}
                                                            </td>
                                                        </>
                                                    )} */}

                                                    <td className="p-2 text-center text-dark">
                                                        {user.ban_till ? (
                                                            <span
                                                                className={`badge bg-danger`}
                                                            >
                                                                banned
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className={`badge bg-${
                                                                    user.status ===
                                                                    "active"
                                                                        ? "success"
                                                                        : "danger"
                                                                }`}
                                                            >
                                                                {user.status}
                                                            </span>
                                                        )}
                                                    </td>

                                                    <td className="p-2 text-center text-dark">
                                                        <button className='btn btn-sm btn-outline-info py-1 px-2' onClick={() => navigate(`/report/member-management/detail/${user?.id}`)}>View</button>
                                                        <button
                                                            className="btn btn-outline-primary btn-sm m-1 py-1"
                                                            onClick={() => {
                                                                setShowChangePassModal(
                                                                    true
                                                                );
                                                                setAuth(user.id);
                                                            }}
                                                        >
                                                            <i className="bi bi-unlock-fill"></i>{" "}
                                                            Change Password
                                                        </button>
                                                        {user.ban_till ? (
                                                            <button
                                                                className="btn btn-outline-dark btn-sm m-1 py-1"
                                                                onClick={() =>
                                                                    handleUnBanClick(
                                                                        user.id
                                                                    )
                                                                }
                                                            >
                                                                <i className="bi bi-slash-circle"></i>{" "}
                                                                Unban
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btn-outline-danger btn-sm m-1 py-1"
                                                                onClick={() =>
                                                                    handleBanClick(
                                                                        user.id
                                                                    )
                                                                }
                                                            >
                                                                <i className="bi bi-slash-circle"></i>{" "}
                                                                Ban
                                                            </button>
                                                        )}
                                                        <button
                                                            className="btn btn-outline-success btn-sm m-1 py-1"
                                                            onClick={() =>
                                                                handleEditClick(
                                                                    user.id,
                                                                    user.role
                                                                )
                                                            }
                                                        >
                                                            <i className="bi-pencil-square"></i>{" "}
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </TableScroll>
                        </div>
                        {totalPage > 1 && (
                            <div className="card-footer">
                                <div className="row justify-content-end">
                                    <div>
                                        <Pagi
                                            setIsLoading={setIsLoading}
                                            setPage={setPage}
                                            totalPage={totalPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Template>
        <ToastContainer />
        {showChangePassModal && (
            <ChangePasswordModal
                onClose={() => setShowChangePassModal(false)}
                onSubmit={changePasswordSubmit}
                isLoading={isLoading}
            />
        )}
        {showBanModal && (
            <BanUserModal
                onClose={() => setShowBanModal(false)}
                onSubmit={banUserOnSubmit}
                isLoading={isLoading}
            />
        )}

        {showEditModal && (
            <EditAgent
                // roleId={role_id}
                auth={auth}
                role={role}
                // authRoleId={auth_role_id}
                onClose={() => setShowEditModal(false)}
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
            />
        )}
    </>
  )
}

export default MemberList
