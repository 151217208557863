import { useEffect, useState } from "react";
import Template from "../utils/Template";
import { ToastContainer } from "react-toastify";
import TableScroll from "../utils/TableScroll";
import TableLoading from "../utils/TableLoading";
import { HandleLogout, ISOdateFormatter, amountCommasSparated, decodeData } from "../utils/Helper";
import { getMethodService } from "../services";
import { THREE_D_OUTSTANDING_DETAIL_API } from "../services/constants";
import { Link, useNavigate, useParams } from "react-router-dom";
const Cookies = require('js-cookie');

const MMThreedOutstandingDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [betLists, setBetLists] = useState([]);
  const param = useParams();
  const navigate = useNavigate();

  const fetchBetList = async () => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await getMethodService(
        `${THREE_D_OUTSTANDING_DETAIL_API}?user_id=${param.user_id}&role_id=${param.role_id}`, [], false, decode.token
      );
      if (res == 401) {
        HandleLogout(navigate);
      }
      res && setIsLoading(false);
      if (res?.status === "success") {
        setBetLists(res?.data?.stock);
      }
    }
  };

  const calculateTotal = (data, type) => {
    return data?.reduce((a, b) => {
      if (type === "amount") {
        return a + b?.amount;
      }
      if (type === "payout_amount") {
        return a + b?.payout_amount;
      }
    }, 0);
  };

  useEffect(() => {
    fetchBetList();
  }, []);

  return (
    <>
      <Template title={`MM 3D Outstanding Detail -  ${param.user_id}`}>
        <div className="card mb-3 mb-lg-5">
          <div className="card-header">
            <button
              className="btn btn-primary py-2"
              onClick={() => navigate(-1)}
            >
              <i className="bi bi-arrow-return-left me-1"></i>
              Back
            </button>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table
                      className="table w-100 table-bordered"
                      style={{ border: ".5px solid #ddd" }}
                    >
                      <thead>
                        <tr>
                          <th className=" text-center text-light">#</th>
                          <th className=" text-center text-light">User Code</th>
                          <th className=" text-center text-light">3D Number</th>
                          <th className=" text-center text-light">Amount</th>
                          <th className=" text-center text-light">
                            Date & Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && <TableLoading col={4} />}
                        {betLists?.length > 0 &&
                          betLists
                            ?.sort((a, b) => b.amount - a.amount)
                            ?.map((num, i) => (
                              <tr style={{ verticalAlign: "middle" }} key={i}>
                                <td className="text-center bg-white p-2">
                                  {i + 1}.
                                </td>
                                <td className="text-center bg-white p-2">
                                  {num.usercode}
                                </td>
                                <td className="text-center bg-white p-2">
                                  {(num?.three_digit_id-1).toString().length ===
                                    1 && `00${num?.three_digit_id - 1}`}
                                  {(num?.three_digit_id-1).toString().length ===
                                    2 && `0${num?.three_digit_id - 1}`}
                                  {(num?.three_digit_id-1).toString().length ===
                                    3 && num?.three_digit_id - 1}
                                </td>
                                <td className="text-end bg-white">
                                  {amountCommasSparated(num?.amount)}
                                </td>
                                <td className="text-center bg-white p-2">
                                  {ISOdateFormatter(num.created_at)}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                      <thead>
                        <tr
                          className="text-center fw-bold text-white"
                          style={{ verticalAlign: "middle" }}
                        >
                          <td colSpan={3} className="text-end">
                            Total :{" "}
                          </td>
                          <td className="text-end">
                            {amountCommasSparated(calculateTotal(betLists, "amount"))}
                          </td>
                          <td></td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
      </Template>
      <ToastContainer />
    </>
  );
};

export default MMThreedOutstandingDetail;
