import React from 'react'
import { SubmitBtnType } from '../types'

const SubmitBtn = ({isLoading,type,name}:SubmitBtnType) => {
  return (
    <button type={type} disabled={isLoading} className="btn btn-dark btn-lg">
        {
            isLoading?
            <>
                <div
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                >
                    <span className="visually-hidden">
                        Loading...
                    </span>
                </div>
                Loading...
            </> :
            name
        }
    </button>
  )
}

export default SubmitBtn
