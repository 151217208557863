import { useEffect, useState } from "react";
import Template from "../utils/Template";
import { ToastContainer } from "react-toastify";
import TableScroll from "../utils/TableScroll";
import TableLoading from "../utils/TableLoading";
import { Link, useNavigate } from "react-router-dom";
import { HandleLogout, amountCommasSparated, decodeData } from "../utils/Helper";
import { getMethodService } from "../services";
import {
  NODE_USER_INFO_API,
  THREE_D_OUTSTANDING_API,
} from "../services/constants";
const Cookies = require('js-cookie')

const MMThreedOutstanding = () => {
  const abortController = new AbortController();
  const [isLoading, setIsLoading] = useState(false);
  const [threedNums, setThreedNums] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  const fetchUserInfo = async () => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await getMethodService(
        `${NODE_USER_INFO_API}`,
        [],
        false,
        decode.token
      );
      setUserInfo(res?.user);
    }
  };
  const fetchOutstanding = async () => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await getMethodService(
        `${THREE_D_OUTSTANDING_API}?user_id=${userInfo.userId}&role_id=${userInfo.role_id}`,
        [],
        false,
        decode.token,
        abortController
      );
      if (res == 401) {
        HandleLogout(navigate);
      }
      res && setIsLoading(false);
      if (res?.status === "success") {
        setThreedNums(res?.data?.filter(el => el.outstanding > 0));
      }
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo?.userId && userInfo?.role_id) {
      fetchOutstanding();
    }
  }, [userInfo]);

  const calculateTotal = (data, type) => {
    return data?.reduce((a, b) => {
      if (type === "outstanding") {
        return a + b?.outstanding;
      }
    }, 0);
  };

  useEffect(()=>{
      return () => {
          abortController.abort();
      }
  },[]);
  return (
    <>
      <Template title="MM 3D Outstanding">
        <div className="card mb-3 mb-lg-5">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12" style={{ maxWidth: "500px" }}>
                <TableScroll>
                  <div>
                    <table
                      className="table w-100 table-bordered"
                      style={{ border: ".5px solid #ddd" }}
                    >
                      <thead>
                        <tr>
                          <th className=" text-center text-light">#</th>
                          <th className=" text-center text-light">User Code</th>
                          <th className=" text-center text-light">
                            Outstanding
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && <TableLoading col={4} />}
                        {threedNums?.length > 0 &&
                          threedNums
                            ?.sort((a, b) => b.outstanding - a.outstanding)
                            ?.map((num, i) => (
                              <tr style={{ verticalAlign: "middle" }} key={i}>
                                <td className="text-center bg-white p-2">
                                  {i + 1}.
                                </td>
                                <td className="text-center bg-white p-2">
                                  {
                                    num?.outstanding > 0 ?
                                    <Link
                                    to={`/stock-manage/mm3d-outstanding/${num?.id}/${num?.role_id}`}
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {num.usercode}
                                  </Link>
                                  :
                                  num.usercode
                                  }
                                </td>
                                <td className="text-end bg-white">
                                  {amountCommasSparated(num?.outstanding)}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                      <thead>
                        <tr
                          className="text-center fw-bold text-white"
                          style={{ verticalAlign: "middle" }}
                        >
                          <td colSpan={2} className="text-end">
                            Total :{" "}
                          </td>
                          <td className="text-end">
                            {amountCommasSparated(
                              calculateTotal(threedNums, "outstanding")
                            )}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
      </Template>
      <ToastContainer />
    </>
  );
};

export default MMThreedOutstanding;
