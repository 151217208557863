import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePassword } from "../Helper";
const {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    Input,
  } = require("reactstrap");

function SubUserCreateModal(props: any) {
    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");
    const [contact,setContact] = useState("");
    const [roleId,setRoleId] = useState<any>(null);
    const { user_info } = useSelector((state:any) => state.userInfo);

    useEffect(()=>{
        switch(user_info?.role){
            case "admin":
                setRoleId(1);
            break;
            case "super":
                setRoleId(2);
            break;
            case "senior":
                setRoleId(3);
            break;
            case "master":
                setRoleId(4);
            break;
            case "agent":
                setRoleId(5);
            break;
        }
    },[user_info]);

    useEffect(()=>{
      let generatePass = generatePassword();
      setPassword(generatePass);
    },[roleId]);

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if(roleId){
            const data = {
                role_id: roleId,
                usercode:username,
                password: password,
                contact: contact,
            }
            props.onSubmit(data);
        };
    };
  return (
    <Modal isOpen={true} toggle={props.onClose}>
      <ModalHeader toggle={props.onClose}>Create Sub</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="username">Username</Label>
            <Input type="text" id="username" value={username} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setUsername(e.target.value)}/>
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input type="text" id="password" value={password} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setPassword(e.target.value)}/>
          </FormGroup>
          <FormGroup>
            <Label for="contact">Contact</Label>
            <Input type="textarea" rows="3" id="contact" onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setContact(e.target.value)}/>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={props.onClose}>
          No
        </button>
        {
          props.isLoading ?
          <button className="btn btn-primary" disabled={true}>
            Loading...
          </button>:
          <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
            Save
          </button>
        }
      </ModalFooter>
    </Modal>
  );
}

export default SubUserCreateModal;
