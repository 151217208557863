import React, { useEffect, useState } from 'react'
import classes from '../assets/styles/AutoBetToggle.module.css'
const AutoBetToggle = ({id,status,event}) => {
    const [checkStatus,setCheckStatus] = useState(status === 0? true: false);
    useEffect(()=>{
        event && event(!checkStatus);
    },[checkStatus]);
    return (
        <div className={classes['switch-container']}>
            <input type="checkbox" className={classes.checkbox} id={id} checked={checkStatus} onChange={() => setCheckStatus(!checkStatus)}/>
            <label className={classes.switch} htmlFor={id}>
                <span className={classes.slider}></span>
            </label>
        </div>
      )
    }

export default AutoBetToggle