import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import { decodeData } from '../utils/Helper';
import { getMethodService } from '../services';
import { stateObjType } from '../types';
import { TRANSFER_RECORD_NODE_API } from '../services/constants';
import DateRangeFormatForTransaction from '../utils/DateRangeFormatForTransaction';
import TableLoading from '../utils/TableLoading';
import Pagi from '../utils/Pagi';
import ScreenLoader from '../utils/ScreenLoader';
const Cookies = require('js-cookie');


const PaymentTransactionHistory = ({userId}:any) => {
    const previous_2month = new Date();
    previous_2month.setMonth(previous_2month.getMonth() - 2);
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    // const [startDate, setStartDate] = useState(formatDate(previous_2month));
    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(nextWeek));
    const [transHistory, setTransHistory] = useState([]);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [page, setPage] = useState<any>(1);
    const [from, setFrom] = useState<any>(1);
    const [totalPage, setTotalPage] = useState<any>(1);

    const fetchTransferRecord = (id:string | undefined | number, startDate:string | undefined, endDate:string | undefined) => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            if (id) {
               return getMethodService(`${TRANSFER_RECORD_NODE_API}?sortColumn=id&sortDirection=desc&user_id=${id}&transaction_type_id[]=1&transaction_type_id[]=2&start_date=${startDate}&end_date=${endDate}&perPage=10&page=${page}`,{},false,decode?.token);
            }
            return;
        };
    }
    // const transferRecordQuery = useQuery({ queryKey: ['recordData', user?.id, startDate, endDate,refreshKey,page], queryFn: () => fetchTransferRecord(user?.id,startDate,endDate), refetchOnWindowFocus: false,});
    const transferRecordQuery = useQuery({ queryKey: ['recordData', userId,refreshKey,page], queryFn: () => fetchTransferRecord(userId,startDate,endDate), refetchOnWindowFocus: false,});
    function formatDate(date:any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    };


    useEffect(() => {
        if(transferRecordQuery){
            setHistoryLoading(transferRecordQuery.isLoading);
            if(transferRecordQuery?.data?.meta){
                let totalPages = Number(Math.ceil((transferRecordQuery?.data?.meta?.total) / (transferRecordQuery?.data?.meta?.perPage)));
                let fromPages = Number(((transferRecordQuery?.data?.meta?.perPage)) * Number((transferRecordQuery?.data?.meta?.page)) -  Number(transferRecordQuery?.data?.meta?.perPage));
                setFrom(fromPages + 1);
                setTotalPage(totalPages);
            };
            setTransHistory(transferRecordQuery?.data?.data);
            
        };
    }, [transferRecordQuery, userId, startDate, endDate]);

    const submitHandler = () => {
        setRefreshKey(prev => prev + 1);
    };

  return (
    <>
        <div className="card-body">
            <div className="row justify-content-end align-items-end">
                <div className="mb-3 col-lg-12">
                    <DateRangeFormatForTransaction startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                </div>
                <div className="mb-3 col-lg-3">
                    <button
                        className="btn btn-primary w-100"
                        onClick={submitHandler}
                        disabled={historyLoading}
                        >
                        {historyLoading ? "Loading ..." : "Submit"}
                    </button>
                </div>
                <div className="col-12">
                    <table
                        className="table table-bordered table-striped"
                        style={{ fontSize: "12px" }}
                    >
                        <thead>
                            <tr
                                style={{
                                    backgroundColor:
                                        "#25282a",
                                    verticalAlign: 'middle'
                                }}
                            >
                                <th>#</th>
                                <th
                                    className="p-2 text-center text-light"
                                    style={{
                                        fontSize:
                                            "12px",
                                    }}
                                >
                                    Pay Date
                                </th>
                                <th
                                    className="p-2 text-center text-light"
                                    style={{
                                        fontSize:
                                            "12px",
                                    }}
                                >
                                    Type
                                </th>
                                <th
                                    className="p-2 text-center text-light"
                                    style={{
                                        fontSize:
                                            "12px",
                                    }}
                                >
                                    Amount
                                </th>
                                <th
                                    className="p-2 text-center text-light"
                                    style={{
                                        fontSize:
                                            "12px",
                                    }}
                                >
                                    Remark
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyLoading && (
                                <TableLoading col={5} />
                            )}
                            {transHistory?.length > 0 ? (
                                transHistory?.map(
                                    (hist:stateObjType, i:number) => (
                                        <tr key={i} style={{verticalAlign: 'middle'}}>
                                            <td>{i+from}</td>
                                            <td className="p-2 text-center text-dark">
                                                {
                                                    hist.created_at
                                                }
                                            </td>
                                            <td className="p-2 text-center text-dark">
                                                {
                                                    hist
                                                        .transaction_type
                                                        ?.name
                                                }
                                            </td>
                                            {/* hist.type=="increase"?"text-success":"text-danger" */}
                                            <td className={`p-2 text-end ${hist.transaction_type
                                                    ?.name=="Deposit"?"text-success":"text-danger"}`}>
                                                {
                                                    hist.transaction_type
                                                    ?.name=="Deposit"?'+':'-'
                                                }
                                                {hist.transfer_amount?.toLocaleString(
                                                    "en-US"
                                                )}
                                            </td>
                                            <td className="p-2 text-center text-dark">
                                                {
                                                    hist
                                                        .transaction_type
                                                        ?.remark ?? '-'
                                                }
                                            </td>
                                        </tr>
                                    )
                                )
                            ) : (
                                <tr>
                                    <td
                                        colSpan={5}
                                        className="p-2 text-center text-dark"
                                    >
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        {
                            totalPage > 1 && 
                            <tfoot>
                                <tr>
                                    <td colSpan={5}>
                                        <div className="row justify-content-end">
                                            <div>
                                                <Pagi
                                                    setIsLoading={setHistoryLoading}
                                                    setPage={setPage}
                                                    totalPage={totalPage}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        }
                    </table>
                </div>
            </div>
        </div>
        <ScreenLoader isLoading={historyLoading} />
    </>
  )
}

export default PaymentTransactionHistory
