import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Routers from './routes';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import {io} from "socket.io-client";

const queryClient = new QueryClient();

function App() {
  
  return (
    <QueryClientProvider client={queryClient}>
      <Router basename="/">
        <Routers />
      </Router>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
    // <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
    //   <Routers />
    //   {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    // </PersistQueryClientProvider>
  );
}

export default App;
