import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AutoBetToggle from "../AutoBetToggle";
import moment from "moment";
import { CONVERT_MM_ODDS_API, TRANSLATE } from "../../services/constants";
import { getMethodService } from "../../services";
import { useQuery } from "@tanstack/react-query";
import { errorToaster } from "../Helper";

function CreateFixtureModal(props:any) {
    const data = props?.data;

    const [leagueMy, setLeagueMy] = useState("");
    const [homeMy, setHomeMy] = useState("");
    const [awayMy, setAwayMy] = useState("");
    const [oddsTeam, setOddsTeam] = useState("");
    const [isLoading,setIsLoading] = useState(false);

    const fixture = data?.home?.name + " Vs " + data?.away?.name;
    const date = moment(data?.date).format('YYYY:MM:DD HH:mm:ss a');
    const league = data?.league?.name;
    const home = data?.home;
    const away = data?.away;
    data.league["name_mm"] = leagueMy;
    data.home["name_mm"] = homeMy;
    data.away["name_mm"] = awayMy;

    const [bodySymbol1, setBodySymbol1] = useState<any>("");
    const [bodySymbol2, setBodySymbol2] = useState<any>("");
    const [bodySymbol3, setBodySymbol3] = useState<any>("");
    const [totalSymbol1, setTotalSymbol1] = useState<any>("");
    const [totalSymbol2, setTotalSymbol2] = useState<any>("");
    const [totalSymbol3, setTotalSymbol3] = useState<any>("");

    // const [bodySymbolFirstHalf1, setBodySymbolFirstHalf1] = useState<any>("");
    // const [bodySymbolFirstHalf2, setBodySymbolFirstHalf2] = useState<any>("");
    // const [bodySymbolFirstHalf3, setBodySymbolFirstHalf3] = useState<any>("");
    // const [totalSymbolFirstHalf1, setTotalSymbolFirstHalf1] = useState<any>("");
    // const [totalSymbolFirstHalf2, setTotalSymbolFirstHalf2] = useState<any>("");
    // const [totalSymbolFirstHalf3, setTotalSymbolFirstHalf3] = useState<any>("");

    const [footballCategoriesFilter, setFootballCategoriesFilter] = useState<any>([]);
    const [footballCategories, setFootballCategories] = useState<any>([]);
    const [footballCategory, setFootballCategory] = useState<any>("");
    const [statusToggle,setStatusToggle] = useState(0);
    // const [firstHalfStatusToggle,setFirstHalfStatusToggle] = useState(0);
    const submitLoading = props?.createOddsLoading;

    const fetchMMOdds = () => getMethodService(CONVERT_MM_ODDS_API(data?.id));
    const mmOddsQuery = useQuery({ queryKey: ['mmodds'], queryFn: fetchMMOdds, refetchOnWindowFocus: false});
    useEffect(() => {
        if(mmOddsQuery?.data?.status === "success"){
            let data = mmOddsQuery?.data?.data;
            setBodySymbol1(data?.full_time?.body[0]);
            setBodySymbol2(data?.full_time?.body[1]);
            setBodySymbol3(data?.full_time?.body[2]);
            setTotalSymbol1(data?.full_time?.over_under[0]);
            setTotalSymbol2(data?.full_time?.over_under[1]);
            setTotalSymbol3(data?.full_time?.over_under[2]);
            setOddsTeam(data?.full_time?.odds_team);

            // if(firstHalfStatusToggle){
            //     setBodySymbolFirstHalf1(null);
            //     setBodySymbolFirstHalf2(null);
            //     setBodySymbolFirstHalf3(null);
            //     setTotalSymbolFirstHalf1(null);
            //     setTotalSymbolFirstHalf2(null);
            //     setTotalSymbolFirstHalf3(null);
            // }else{
            //     setBodySymbolFirstHalf1(data?.first_half?.body[0]);
            //     setBodySymbolFirstHalf2(data?.first_half?.body[1]);
            //     setBodySymbolFirstHalf3(data?.first_half?.body[2]);
            //     setTotalSymbolFirstHalf1(data?.first_half?.over_under[0]);
            //     setTotalSymbolFirstHalf2(data?.first_half?.over_under[1]);
            //     setTotalSymbolFirstHalf3(data?.first_half?.over_under[2]);
            // };
        };
    }, [mmOddsQuery]); //firstHalfStatusToggle

    const createFootballFixture = () => {
        // if(!firstHalfStatusToggle){
        //     if(!bodySymbolFirstHalf1 && !bodySymbolFirstHalf2 && !bodySymbolFirstHalf3){
        //         return errorToaster("First half odds is required!");
        //     };
        //     if(!totalSymbolFirstHalf1 && !totalSymbolFirstHalf2 && !totalSymbolFirstHalf3){
        //         return errorToaster("First half total odds is required!"); 
        //     }
        // }
        const request_json = {
            fixture_id: data.id,
            home_team_data: home,
            away_team_data: away,
            odds_team: oddsTeam,
            // first_half_body_symbol1: bodySymbolFirstHalf1,
            // first_half_body_symbol2: bodySymbolFirstHalf2,
            // first_half_body_symbol3: bodySymbolFirstHalf3,
            // first_half_total_symbol1: totalSymbolFirstHalf1,
            // first_half_total_symbol2: totalSymbolFirstHalf2,
            // first_half_total_symbol3: totalSymbolFirstHalf3,
            body_symbol1: bodySymbol1,
            body_symbol2: bodySymbol2,
            body_symbol3: bodySymbol3,
            total_symbol1: totalSymbol1,
            total_symbol2: totalSymbol2,
            total_symbol3: totalSymbol3,
            fixture_timestamp: data.time,
            league_id: data.league.id,
            league_data: data.league,
            mm_football_categories: footballCategories,
            is_manual: statusToggle
        };

        props.onSubmit(request_json);
    };
    const handleFootballCategriesChange = (id: number | string) => {
        if(footballCategoriesFilter.includes(id)){
            setFootballCategoriesFilter(
                footballCategoriesFilter?.filter((item:number | string) => item != id)
            );
        }else {
            setFootballCategoriesFilter([id]);
        }
    };

    useEffect(()=>{
        if(footballCategory){
            setFootballCategories([...footballCategoriesFilter,footballCategory])
        }else{
            setFootballCategories(footballCategoriesFilter);
        };
    },[footballCategoriesFilter,footballCategory]);

    useEffect(()=>{
            setIsLoading(true);
            (async()=>{
                await getMethodService(TRANSLATE, {
                    names: [league,home.name,away.name]
                })
                .then((data) => {
                    if(data.status === "success"){
                        let findLeague = data.data.find((en:any) => en.name_en === league);
                        let findHome = data.data.find((en:any) => en.name_en === home.name);
                        let findAway = data.data.find((en:any) => en.name_en === away.name);
                        findLeague? setLeagueMy(findLeague.name_mm): setLeagueMy("");
                        findHome? setHomeMy(findHome.name_mm): setHomeMy("");
                        findAway? setAwayMy(findAway.name_mm): setAwayMy("");
                    };
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("Error:", error);
                });
            })()
    },[league,home,away]);

    const fixtureToggleHandler = (status:any) => {
        setStatusToggle(status? 1: 0);
    };

    // const firstHalfToggleHandler = (status:any) => {
    //     setFirstHalfStatusToggle(status? 1: 0)
    // };

    return (
        <>
            <Modal isOpen={true} toggle={props.onClose}>
                <ModalHeader toggle={props.onClose}>
                    ပွဲစဉ်ဖန်တီးရန်{" "}
                </ModalHeader>
                <ModalBody>
                    <div className="row mb-4">
                        <label
                            htmlFor="firstNameLabel"
                            className="col-sm-4 col-form-label form-label"
                        >
                            လိဂ်
                        </label>

                        <div className="col-sm-8">
                            <div className="input-group input-group-sm-vertical position-relative">
                                <input
                                    type="text"
                                    className="form-control mb-1"
                                    value={leagueMy}
                                    placeholder="လိဂ် နာမည်ထည့်ပါ"
                                    disabled={isLoading}
                                    onChange={(e) =>
                                        setLeagueMy(e.target.value)
                                    }
                                />
                                {
                                    isLoading &&
                                    <div className="spinner-border text-secondary m-0 rounded-circle" role="status"
                                        style={{
                                            position: 'absolute',
                                            width: '25px',
                                            height: '25px',
                                            right: '10px',
                                            top: '10px'
                                        }}
                                    >
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </div>

                            <div className="input-group input-group-sm-vertical">
                                <input
                                    type="text"
                                    disabled
                                    className="form-control"
                                    value={league}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label
                            htmlFor="firstNameLabel"
                            className="col-sm-4 col-form-label form-label"
                        >
                            အိမ်ကွင်း
                        </label>
                        <div className="col-sm-8">
                            <div className="input-group input-group-sm-vertical position-relative">
                                <input
                                    type="text"
                                    className="form-control mb-1"
                                    value={homeMy}
                                    disabled={isLoading}
                                    placeholder="အိမ်ကွင်း နာမည်ထည့်ပါ"
                                    onChange={(e) =>
                                        setHomeMy(e.target.value)
                                    }
                                />
                                {
                                    isLoading &&
                                    <div className="spinner-border text-secondary m-0 rounded-circle" role="status"
                                        style={{
                                            position: 'absolute',
                                            width: '25px',
                                            height: '25px',
                                            right: '10px',
                                            top: '10px'
                                        }}
                                    >
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </div>
                            <div className="input-group input-group-sm-vertical">
                                <input
                                    type="text"
                                    disabled
                                    className="form-control"
                                    value={home.name}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label
                            htmlFor="firstNameLabel"
                            className="col-sm-4 col-form-label form-label"
                        >
                            အဝေးကွင်း
                        </label>
                        <div className="col-sm-8">
                            <div className="input-group input-group-sm-vertical position-relative">
                                <input
                                    type="text"
                                    className="form-control mb-1"
                                    value={awayMy}
                                    disabled={isLoading}
                                    placeholder="အဝေးကွင်း နာမည်ထည့်ပါ"
                                    onChange={(e) =>
                                        setAwayMy(e.target.value)
                                    }
                                />
                                {
                                    isLoading &&
                                    <div className="spinner-border text-secondary m-0 rounded-circle" role="status"
                                        style={{
                                            position: 'absolute',
                                            width: '25px',
                                            height: '25px',
                                            right: '10px',
                                            top: '10px'
                                        }}
                                    >
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </div>
                            <div className="input-group input-group-sm-vertical">
                                <input
                                    type="text"
                                    disabled
                                    className="form-control"
                                    value={away.name}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    {/* <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                        <AutoBetToggle id="first_half_status_create" status={firstHalfStatusToggle} event={firstHalfToggleHandler}  />
                    </div> */}
                    {/* {
                        !firstHalfStatusToggle &&
                        <>
                            <div className="row mb-4">
                            <label
                                htmlFor="firstNameLabel"
                                className="col-sm-4 col-form-label form-label"
                            >
                                ဘော်ဒီ ( ပပိုင်း ) <span className={`badge bg-${oddsTeam==='home'?'dark':'warning'}`}>{ oddsTeam}</span>
                            </label>

                            <div className="col-sm-8">
                                <div className="input-group input-group-warning input-group-sm-vertical">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="1"
                                        value={bodySymbolFirstHalf1}
                                        onChange={(event) => {
                                            event.target.value = event.target.value.replace('-', '');
                                            setBodySymbolFirstHalf1(event.target.value);
                                        }}
                                    />
                                    <select
                                        className="form-control"
                                        value={bodySymbolFirstHalf2}
                                        onChange={(e) => {
                                            setBodySymbolFirstHalf2(e.target.value);
                                        }}
                                    >
                                        <option>Choose</option>
                                        <option value="+">+</option>
                                        <option value="=">=</option>
                                        <option value="-">-</option>
                                    </select>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="30"
                                        value={bodySymbolFirstHalf3}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace('-', '');
                                            setBodySymbolFirstHalf3(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="row mb-4">
                                <label
                                    htmlFor="firstNameLabel"
                                    className="col-sm-4 col-form-label form-label"
                                >
                                    ဂိုးပေါင်း ( ပပိုင်း )
                                </label>

                                <div className="col-sm-8">
                                    <div className="input-group input-group-warning input-group-sm-vertical">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="2"
                                            value={totalSymbolFirstHalf1}
                                            onChange={(e) => {
                                                e.target.value = e.target.value.replace('-', '');
                                                setTotalSymbolFirstHalf1(e.target.value);
                                            }}
                                        />
                                        <select
                                            className="form-control"
                                            value={totalSymbolFirstHalf2}
                                            onChange={(e) => {
                                                setTotalSymbolFirstHalf2(e.target.value);
                                            }}
                                        >
                                            <option>Choose</option>
                                            <option value="+">+</option>
                                            <option value="=">=</option>
                                            <option value="-">-</option>
                                        </select>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="30"
                                            value={totalSymbolFirstHalf3}
                                            onChange={(e) => {
                                                e.target.value = e.target.value.replace('-', '');
                                                setTotalSymbolFirstHalf3(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <hr /> */}
                    <div className="row mb-4">
                        <label
                            htmlFor="firstNameLabel"
                            className="col-sm-4 col-form-label form-label"
                        >
                            ဘော်ဒီ ( ပွဲပြီး ) <span className={`badge bg-${oddsTeam==='home'?'dark':'warning'}`}>{ oddsTeam}</span>
                        </label>

                        <div className="col-sm-8">
                            <div className="input-group input-group-warning input-group-sm-vertical">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="2"
                                    value={bodySymbol1}
                                    onChange={(event) => {
                                        event.target.value = event.target.value.replace('-', '');
                                        setBodySymbol1(event.target.value);
                                    }}
                                />
                                <select
                                    className="form-control"
                                    value={bodySymbol2}
                                    onChange={(e) => {
                                        setBodySymbol2(e.target.value);
                                    }}
                                >
                                    <option>Choose</option>
                                    <option value="+">+</option>
                                    <option value="=">=</option>
                                    <option value="-">-</option>
                                </select>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="60"
                                    value={bodySymbol3}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace('-', '');
                                        setBodySymbol3(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label
                            htmlFor="firstNameLabel"
                            className="col-sm-4 col-form-label form-label"
                        >
                            ဂိုးပေါင်း ( ပွဲပြီး )
                        </label>

                        <div className="col-sm-8">
                            <div className="input-group input-group-warning input-group-sm-vertical">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="4"
                                    value={totalSymbol1}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace('-', '');
                                        setTotalSymbol1(e.target.value);
                                    }}
                                />
                                <select
                                    className="form-control"
                                    value={totalSymbol2}
                                    onChange={(e) => {
                                        setTotalSymbol2(e.target.value);
                                    }}
                                >
                                    <option>Choose</option>
                                    <option value="+">+</option>
                                    <option value="=">=</option>
                                    <option value="-">-</option>
                                </select>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="60"
                                    value={totalSymbol3}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace('-', '');
                                        setTotalSymbol3(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="mb-4 row align-items-center">
                        <label
                            htmlFor="firstNameLabel"
                            className="col-sm-4 col-form-label form-label"
                        >
                            Odds team
                        </label>
                        <div className="col-sm-8">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label className="form-control" htmlFor="home">
                                        <span className="form-check">
                                            <input type="radio" checked={oddsTeam === "home"} className="form-check-input" name="formControlRadioEg" id="home" onChange={() => setOddsTeam("home")} />
                                            <span className="form-check-label">Home</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-control" htmlFor="away">
                                        <span className="form-check">
                                            <input type="radio" checked={oddsTeam === "away"} className="form-check-input" name="formControlRadioEg" id="away" onChange={() => setOddsTeam("away")} />
                                            <span className="form-check-label">Away</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 row align-items-center">
                        <label
                            htmlFor="firstNameLabel"
                            className="col-sm-4 col-form-label form-label"
                        >
                            ကြေးပြောင်း (Manual / Auto)
                        </label>
                        <div className="col-sm-8">
                            <div>
                                <AutoBetToggle id={data.id} status={statusToggle} event={fixtureToggleHandler}  />
                            </div>
                        </div>
                    </div>
                    <hr className="mb-2" />
                    <div className="row">
                        <label
                            htmlFor="firstNameLabel"
                            className="col-sm-12 col-form-label form-label"
                        >
                            ရွေးပါ
                        </label>

                        <div className="col-sm-12">
                            <div className="input-group">
                                <label
                                    className="form-control"
                                    htmlFor="cb_body_small"
                                >
                                    <span className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="mmfootball_category"
                                            id="cb_body_small"
                                            onClick={() => {
                                                setFootballCategory(1);
                                            }}
                                        />
                                        <span className="form-check-label">
                                            ဘော်ဒီ (ပွဲသေး)
                                        </span>
                                    </span>
                                </label>

                                <label
                                    className="form-control"
                                    htmlFor="cb_body_large"
                                >
                                    <span className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="mmfootball_category"
                                            id="cb_body_large"
                                            onClick={() => {
                                                setFootballCategory(2);
                                            }}
                                        />
                                        <span className="form-check-label">
                                            ဘော်ဒီ (ပွဲကြီး)
                                        </span>
                                    </span>
                                </label>

                                <label className="form-control" htmlFor="maung">
                                    <span className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="mmfootball_category"
                                            id="maung"
                                            onClick={() => {
                                                handleFootballCategriesChange(
                                                    3
                                                );
                                            }}
                                        />
                                        <span className="form-check-label">
                                            မောင်း
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {
                    !submitLoading ? (
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-hs-step-form-next-options='{
                                "targetSelector": "#addUserStepBillingAddress"
                              }'
                            onClick={createFootballFixture}
                            disabled={submitLoading}
                        >
                            ကြေးဖွင့်မည် <i className="bi-chevron-right"></i>
                        </button>
                    ) : (
                        <button type="button" className="btn btn-primary">
                            <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </button>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default CreateFixtureModal;
