import React, { useEffect, useState } from 'react'
import { decodeData } from '../utils/Helper';
import NotFound from '../utils/NotFound';
import TempoCLose from '../utils/TempoClose';
import { useParams } from 'react-router-dom';
const Cookies = require('js-cookie');

const BlackListRoute = ({children,route}:any) => {
    const [userInfo,setUserInfo] = useState<any>({});
    const blackListRoles:any = ['']; //admin, super, senior, master, agent
    const blackListPages:any = [''];
    const blackListType:any = [''];  //main,sub

    useEffect(()=>{
        const getAuth = Cookies.get('a_u');
        let decode = getAuth && decodeData(getAuth);
        if(decode){
            setUserInfo(decode)
        };
    },[]);
   
    if(userInfo?.user_id){
        if(blackListRoles?.includes(userInfo?.role_name) && blackListPages?.includes(route) && blackListType?.includes(userInfo?.type) ){
            return <TempoCLose />;
        }else{
            return children;
        };
    };
}

export default BlackListRoute
