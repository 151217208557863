import React, { useEffect, useState } from 'react'
import Template from '../../utils/Template'
import moment from 'moment';
import EasyEdit from 'react-easy-edit';
import '../../assets/styles/EasyEdit.css';
import { useQuery } from '@tanstack/react-query';
import { getMethodService } from '../../services';
import { FIXTURES_API } from '../../services/constants';
import { HandleLogout } from '../../utils/Helper';
import { useNavigate } from 'react-router-dom';
import FootballResultCard from './FootballResultCard';
import { ToastContainer } from 'react-toastify';
import { debounce } from 'lodash';


const FootballResultFullTime = () => {
    const [fixtures, setFixtures] = useState([]);
    const [sameFixtures,setSameFixtures] = useState([]);
    const [type,setType] = useState("ready");
    const [delayTime,setDelayTime] = useState(1000*60); //default 1 min
    const [delayStatus,setDelayStatus] = useState(localStorage.getItem('delay_status') || false);
    const [isLoading,setIsLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState(Number(delayTime)/1000);
    const formattedTime = moment.utc(remainingTime * 1000).format('mm:ss');
    const [refreshKey, setRefreshKey] = useState(0);
    const navigate = useNavigate();
    const [refr,setRefr] = useState(false);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [search,setSearch] = useState("");

    const save = (value) => {
        if(value >= 1){
            setDelayTime(parseInt(value) * 60000 );
            localStorage.setItem("delay_reload", `${parseInt(value) * 60000}` )
        }else{
            setDelayTime(parseInt(delayTime) / 60000)
        };
    };

    useEffect(()=>{
        const getMin = localStorage.getItem('delay_reload');
        localStorage.setItem("delay_status", delayStatus);
        if(!getMin){
            localStorage.setItem("delay_reload", delayTime);
        };
        setDelayTime(getMin);
        setRemainingTime(Number(getMin)/1000);
    },[delayTime,delayStatus,refr]);

    useEffect(()=>{
        const getStatus = localStorage.getItem('delay_status');
        if(getStatus){
            setDelayStatus(JSON.parse(getStatus));
        };
    },[delayStatus])

    useEffect(()=>{
        if(delayStatus){
            const interval = setInterval(() => {
                setRemainingTime((prevTime) => Math.max(prevTime - 1, 0));
              }, 1000);
              return () => clearInterval(interval);
        };
    },[delayStatus,refr]);

    useEffect(() => {
        if(delayStatus){
            if (remainingTime === 0) {
                setRefreshKey(prevKey => prevKey + 1);
                setRefr(!refr);
            };
        }
    }, [remainingTime,delayStatus]);

    const fetchReadyResult = () => getMethodService(`${FIXTURES_API}?limit=400&status=active&source=backend&sortColumn=fixture_timestamp&sortDirection=asc&date=${date}`);
    const fetchInplayResult = () => getMethodService(`${FIXTURES_API}?limit=400&status=active&source=inplay&sortColumn=fixture_timestamp&sortDirection=asc&date=${date}`);
    const fetchCompleteResult = () => getMethodService(`${FIXTURES_API}?limit=500&status=completed&sortColumn=fixture_timestamp&sortDirection=desc&date=${date}`);
    const resultQuery = useQuery({ queryKey: ['result',refreshKey,type,date], queryFn: () => {
        switch(type){
            case "ready" :
                return fetchReadyResult();
            case "inplay" :
                return fetchInplayResult();
            case "complete" :
                return fetchCompleteResult();
            default: 
            return fetchReadyResult();
        };
    }, refetchOnWindowFocus: false});

    const typeHandler = (type) => {
        setType(type);
    };

    useEffect(()=>{
        if(resultQuery){
            setIsLoading(resultQuery?.isLoading);
            if(resultQuery?.data == 401){
                HandleLogout(navigate);
            };
            if(resultQuery?.data?.status === "success"){
                setFixtures(resultQuery?.data?.data);
            };
        };
    },[resultQuery]);

    useEffect(()=>{
        let temp = [];
        for(let i = 0; i < fixtures?.length; i++){
            let findFixture = temp.find(fix => fix.fixture_id === fixtures[i].fixture_id);
            if(findFixture){
                findFixture['mm_football_category2'] = fixtures[i].mm_football_category;
                findFixture['id2'] = fixtures[i].id;
            }else{
                temp.push(fixtures[i]);
            };
        };
        setSameFixtures(temp);
    },[fixtures]);

    // ------ for search handler -----
    const fetchSearchData = async ()=> {
        console.log(search)
    };

    useEffect(()=>{
        const searchTeamDebounce = debounce(fetchSearchData, 1500);
        searchTeamDebounce(search); // Initial fetch
        return () => {
            searchTeamDebounce.cancel(); // Cleanup debounced function on unmount
        };
        
    },[search])

  return (
    <>
      <Template title='Football Create Result'>
        <div className="card mb-3 mb-lg-5">
            <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                    <div className="col-sm-3">
                        <h4 className="card-title">
                            Full Time
                        </h4>
                    </div>
                    <div className="col-sm-9 text-end">
                        <ul className="mb-2 bg-transparent nav nav-segment align-items-center">
                            <li className="mx-1">Auto refresh: {formattedTime} {formattedTime.split(":")[0] === "00"?"sec":"min"}</li>
                            <li className="mx-1">
                                <i className="bi bi-arrow-clockwise" style={{fontSize: '20px',cursor: 'pointer'}} onClick={()=> setRefreshKey(prevKey => prevKey + 1)}/>
                            </li>
                            <li className="mx-1">
                                <div className="dropdown">
                                    <button className="px-1 bg-transparent btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        {""}
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" onClick={e => e.stopPropagation()}>
                                        <div className="px-0 bg-transparent dropdown-item">
                                            <div className="form-check form-switch form-switch-between">
                                                <input className=" ms-0 js-toggle-switch form-check-input" type="checkbox"
                                                    data-hs-toggle-switch-options='{
                                                        "targetSelector": "#pricingCount1, #pricingCount2, #pricingCount3"
                                                    }' checked={delayStatus} onChange={e => setDelayStatus(!delayStatus)}/>
                                                <label className="form-check-label">Auto Refresh</label>
                                            </div>
                                        </div>
                                        <div className="px-0 pt-0 bg-transparent dropdown-item">
                                            <label className="form-check-label d-flex align-items-center">Refresh reate:
                                                &nbsp;
                                                <EasyEdit
                                                    type="number"
                                                    onSave={ (value) => save(value)}
                                                    saveButtonLabel={<i className="p-2 py-1 bi bi-check-lg btn btn-success" style={{fontSize: '10px'}}></i>}
                                                    cancelButtonLabel={<i className="p-2 py-1 bi bi-x-lg btn btn-danger" style={{fontSize: '10px'}}></i>}
                                                    value={parseInt(delayTime) / 60000}
                                                    attributes={{ required: true}}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-3 card-header-title mb-md-0">
                            All Matchs
                        </h4>
                    </div>
                    <div className='col-12 col-md-3'>
                        <input type="text" className="form-control mb-2 mb-md-0" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)}/>
                    </div>
                    <div className="col-12 col-md-3">
                        <input className='form-control mb-2 mb-md-0' type="date" value={date} onChange={e => setDate(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-3 text-end">
                        <ul className="nav nav-segment nav-pills" role="tablist">
                            <li className="nav-item">
                                <a className={`nav-link ${type === "ready" && 'bg-primary active text-white'}`} id="nav-one-eg1-tab" href="#nav-one-eg1" data-bs-toggle="pill" data-bs-target="#nav-one-eg1" role="tab" aria-controls="nav-one-eg1" aria-selected="true" onClick={() => typeHandler("ready")}>Ready</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${type === "inplay"  && 'bg-primary active text-white'}`} id="nav-two-eg1-tab" href="#nav-two-eg1" data-bs-toggle="pill" data-bs-target="#nav-two-eg1" role="tab" aria-controls="nav-two-eg1" aria-selected="false" onClick={() => typeHandler("inplay")}>Inplay</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${type === "complete"  && 'bg-primary active text-white'}`} id="nav-two-eg1-tab" href="#nav-two-eg1" data-bs-toggle="pill" data-bs-target="#nav-two-eg1" role="tab" aria-controls="nav-two-eg1" aria-selected="false" onClick={() => typeHandler("complete")}>Complete</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='row'>
                    {
                        isLoading ?
                        <div className="text-center col-12">
                            <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                        </div>:
                        sameFixtures?.length > 0 ? (
                        sameFixtures?.map((fixture, index) => 
                            <div className="col-md-6 col-lg-4 p-2" key={index}>
                                <FootballResultCard  data={fixture} type={type} setRefreshKey={setRefreshKey} key={fixture.id} setDelayStatus={setDelayStatus} market="full-time" />
                            </div>
                        )
                        ) : (
                            <div className="text-center col-12">No data</div>
                        )

                    }
                </div>
            </div>
        </div>
      </Template>
      <ToastContainer />
    </>
  )
}

export default FootballResultFullTime
