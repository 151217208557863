import { useEffect, useState } from "react";
import Template from "../utils/Template";
import { ToastContainer } from "react-toastify";
import TableScroll from "../utils/TableScroll";
import TableLoading from "../utils/TableLoading";
import { HandleLogout, ISOdateFormatter, decodeData, errorToaster } from "../utils/Helper";
import {
  getMethodService,
  postMethodService,
  putMethodService,
} from "../services";
import {
  THREE_D_LUCKY_NUMS_API,
  THREE_D_SLIP_STATUS_API,
} from "../services/constants";
import MMThreedResultModal from "../utils/Modal/MMThreedResultModal";
import EditNumToast from "../utils/EditNumToast";
import MMThreedResetConfirm from "../utils/Modal/MMThreedResetConfirm";
import MMThreedResultProgress from "../utils/Modal/MMThreedResultProgress";
import MMThreedNoWinner from "../utils/Modal/MMThreedNoWinner";
import MMThreedResultFinished from "../utils/Modal/MMThreedResultFinish";
import { useNavigate } from "react-router-dom";
const Cookies = require('js-cookie');

const MMThreedResults = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [luckyNums, setLuckyNums] = useState([]);
  const [luckyNum, setLuckyNum] = useState("");
  const [lotteryTime, setLotteryTime] = useState("");
  const [popupCreateModal, setPopupCreateModal] = useState(false);
  const [state, setState] = useState(null);
  const [refs, setRefs] = useState(false);
  const [editId, setEditId] = useState("");
  const [editValue, setEditValue] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [popupResultConfirm, setPopupResultConfirm] = useState(false);
  const [authKey, setAuthKey] = useState("");
  const [closeTime, setCloseTime] = useState(0);
  const [authKeyId, setAuthKeyId] = useState("");
  const [progress, setProgress] = useState(0);
  const [closeNoWinner, setCloseNoWinner] = useState(false);
  const [finish, setFinish] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getAuth = Cookies.get("a_u");
    let decode = getAuth && decodeData(getAuth);
    if (decode) {
      setUserInfo(decode);
    }
  }, []);

  const fetchThreedNums = async () => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await getMethodService(`${THREE_D_LUCKY_NUMS_API}`, [], false, decode.token);
      if (res == 401) {
        HandleLogout(navigate);
      }
      if (res?.status === "success") {
        setLuckyNums(res.data);
        setIsLoading(false);
      }
    }
  };

  const createLuckyNum = async () => {
    if (lotteryTime) {
      setIsLoading(true);
      const getAuth = Cookies.get("n_a_u");
      const data = {
        three_digit_id: +luckyNum + 1,
        lottery_time: lotteryTime,
      };
      if (getAuth) {
        let decode = decodeData(getAuth);
        const res = await postMethodService(
          `${THREE_D_LUCKY_NUMS_API}`,
          data,
          true,
          decode.token
        );
        if (res) {
          if(res == 401){
            HandleLogout(navigate);
          };
          setIsLoading(false);
          setLuckyNum("");
          setLotteryTime("");
          setRefs(!refs);
          setPopupCreateModal(false);
        }
      }
    } else {
      errorToaster("Lottery Time Field is required!");
    }
  };

  const resultConfirm = async () => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    const data = {
      password: authKey,
    };
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await putMethodService(
        `${THREE_D_SLIP_STATUS_API}/${authKeyId}`,
        data,
        true,
        decode.token
      );
      if (res) {
        if (res == 401) {
          HandleLogout(navigate);
        }
        if (res.status === "success") {
          setIsLoading(false);
          setAuthKey("");
          setAuthKeyId("");
          setPopupResultConfirm(false)
          if (res.data.duration_time > 0) {
            setCloseTime(res.data.duration_time);
            setProgress(res.data.duration_time);
          } else {
            setCloseNoWinner(true);
          }
        } else {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (closeTime > 0) {
      const timerId = setInterval(() => {
        setCloseTime((prevCountdown) => prevCountdown - 500);
      }, 500);
      return () => {
        clearInterval(timerId);
      };
    } else {
      progress > 0 && setFinish(true);
    }
  }, [closeTime]);

  const updateLuckyNum = async (id, status) => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await putMethodService(
        `${THREE_D_LUCKY_NUMS_API}/${id}?status=${status}`,
        [],
        true,
        decode.token
      );
      if (res) {
        if (res == 401) {
          HandleLogout(navigate);
        }
        setIsLoading(false);
        setLuckyNum("");
        setLotteryTime("");
        setState(null);
        setRefs(!refs);
      }
    }
  };

  const saveState = async () => {
    if (editValue.length === 3) {
      setIsLoading(true);
      const getAuth = Cookies.get("n_a_u");
      if (getAuth) {
        let decode = decodeData(getAuth);
        const res = await putMethodService(
          `${THREE_D_LUCKY_NUMS_API}/${editId}?three_digit_id=${
            +editValue + 1
          }`,
          [],
          true
        );
        if (res) {
          if (res == 401) {
            HandleLogout(navigate);
          }
          setIsLoading(false);
          setEditValue("");
          setEditId("");
          setRefs(!refs);
        }
      }
    } else {
      errorToaster("3D Number must be 3 digits!");
    }
  };

  const approveState = () => {
    setState(null);
    updateLuckyNum(state, "Approved");
  };

  const rejectState = () => {
    setState(null);
    updateLuckyNum(state, "Rejected");
  };
  useEffect(() => {
    fetchThreedNums();
  }, [refs]);

  return (
    <>
      <Template title="MM 3D Results">
        <div
          className="card mb-3 mb-lg-5"
          style={{ cursor: isLoading ? "progress" : "" }}
        >
          <div className="card-header">
            <div className="d-md-flex justify-content-between">
              {userInfo.role_name === "admin" && (
                <button
                  disabled={isLoading}
                  className="btn btn-primary me-1"
                  onClick={() => setPopupCreateModal(true)}
                >
                  <i className="bi bi-plus-lg me-1"></i>Add Result
                </button>
              )}
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table className="table w-100 table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className=" text-center text-light">#</th>
                          <th className=" text-center text-light">3D Number</th>
                          <th className=" text-center text-light">Twit</th>
                          <th className=" text-center text-light">
                            Lottery Time
                          </th>
                          <th className=" text-center text-light">Status</th>
                          <th className=" text-center text-light">Action</th>
                          <th className=" text-center text-light">
                            Date & Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && <TableLoading col={7} />}
                        {luckyNums.length > 0 &&
                          luckyNums.map((num, i) => (
                            <tr style={{ verticalAlign: "middle" }} key={i}>
                              <td className="text-center bg-white p-2">
                                {i + 1}.
                              </td>
                              <td className="text-center bg-white p-2 position-relative">
                                <span
                                  className={`fs-5 ${
                                    num.status === "Pending"
                                      ? "text-decoration-underline"
                                      : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    num.status === "Pending" &&
                                      setEditId(num.id);
                                    setEditValue(num.three_digits.number);
                                  }}
                                >
                                  {" "}
                                  {num.three_digits.number}
                                </span>
                                <EditNumToast
                                  editValue={editValue}
                                  setEditValue={setEditValue}
                                  state={
                                    num.id === editId &&
                                    userInfo.role_name === "admin"
                                  }
                                  saveState={saveState}
                                  cancelState={() => setEditId("")}
                                  placeholder={"3D Number"}
                                  isLoading={isLoading}
                                />
                              </td>
                              <td className="text-center bg-white p-2">
                                {num.votes
                                  ? JSON.parse(num?.votes).join(", ")
                                  : "- -"}
                              </td>
                              <td className="text-center bg-white p-2">
                                <span
                                  className={`p-1 px-2 text-white rounded-1 ${
                                    num.lottery_time === "Two"
                                      ? "bg-info"
                                      : "bg-secondary"
                                  }`}
                                  style={{ userSelect: "none" }}
                                >
                                  {num.lottery_time === "Two"
                                    ? "၁ ရက်နေ့"
                                    : "၁၆ ရက်နေ့"}
                                </span>
                              </td>
                              <td className="text-center bg-white p-2 d-flex align-items-center justify-content-center">
                                <div style={{ width: "100px" }}>
                                  {num.status !== "Pending" && (
                                    <span
                                      className={`p-1 px-2 text-white rounded-1 ${
                                        num.status === "Pending"
                                          ? "bg-warning"
                                          : num.status === "Rejected"
                                          ? "bg-danger"
                                          : "bg-success"
                                      }`}
                                      style={{
                                        cursor:
                                          num.status === "Pending"
                                            ? "pointer"
                                            : "",
                                        userSelect: "none",
                                      }}
                                    >
                                      {num.status}
                                    </span>
                                  )}
                                  {userInfo.role_name === "admin" &&
                                  num.id === state ? (
                                    <div
                                      className="d-flex justify-content-center"
                                      onMouseLeave={() => setState(null)}
                                    >
                                      <button
                                        disabled={isLoading}
                                        className="btn py-1 btn-success me-1"
                                        onClick={approveState}
                                      >
                                        Approve
                                      </button>
                                      <button
                                        disabled={isLoading}
                                        className="btn py-1 btn-danger me-1"
                                        onClick={rejectState}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  ) : (
                                    num.status === "Pending" && (
                                      <span
                                        disabled={isLoading}
                                        className="d-flex align-items-center"
                                        onClick={() =>
                                          !isLoading && setState(num.id)
                                        }
                                        onMouseEnter={() =>
                                          !isLoading && setState(num.id)
                                        }
                                      >
                                        <span className="bg-warning p-1 px-2 rounded-1">
                                          {num.status}
                                        </span>
                                        <i
                                          className="ms-2 bi bi-pencil-square fs-2 text-primary"
                                          style={{
                                            cursor: isLoading
                                              ? "progress"
                                              : "pointer",
                                          }}
                                          disabled={isLoading}
                                        ></i>
                                      </span>
                                    )
                                  )}
                                </div>
                              </td>
                              <td className="text-center bg-white p-2">
                                {num.status === "Approved" ? (
                                  userInfo.role_name === "admin" ? (
                                    <span
                                      disabled={isLoading}
                                      className="p-1 px-2 rounded-1 bg-warning text-white"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setPopupResultConfirm(true);
                                        setAuthKeyId(num.id);
                                      }}
                                    >
                                      လျော်မည်
                                    </span>
                                  ) : (
                                    "--"
                                  )
                                ) : (
                                  "- -"
                                )}
                              </td>
                              <td className="text-center bg-white p-2">
                                {ISOdateFormatter(num.updated_at)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
      </Template>
      {popupCreateModal && (
        <MMThreedResultModal
          onClose={() => setPopupCreateModal(false)}
          name="Create MM 3D Lucky Number"
          onSubmit={createLuckyNum}
          isLoading={isLoading}
          luckyNum={luckyNum}
          setLuckyNum={setLuckyNum}
          lotteryTime={lotteryTime}
          setLotteryTime={setLotteryTime}
        />
      )}
      {popupResultConfirm && (
        <MMThreedResetConfirm
          onClose={() => !isLoading && setPopupResultConfirm(false)}
          title="Confirm Lucky Number"
          authKey={authKey}
          setAuthKey={setAuthKey}
          des="အလျော်အစားလုပ်မည်မှာ သေချာပြီလား?"
          onSubmit={resultConfirm}
          isLoading={isLoading}
        />
      )}
      {closeTime > 0 && (
        <MMThreedResultProgress
          closeTime={closeTime}
          setCloseTime={setCloseTime}
          progress={progress}
        />
      )}
      {finish && (
        <MMThreedResultFinished setFinish={setFinish} progress={progress} />
      )}
      {closeNoWinner && (
        <MMThreedNoWinner setCloseNoWinner={setCloseNoWinner} />
      )}
      <ToastContainer />
    </>
  );
};

export default MMThreedResults;
