 import React from 'react'
export default function ScreenLoader({isLoading}:any) {
  return (
    isLoading ? <div className='opacity-25' style={{position: 'fixed', top: '0', left: '0', height: '100vh', background: '#000', width: '100%', zIndex: '99', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div
        className="spinner-border spinner-border-sm" style={{height: '35px', width: '35px'}}
        role="status"
    >
        <span className="visually-hidden">
            Loading...
        </span>
    </div>
  </div> : <div></div>
  )
}
