import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import DateRangeFormat from "../utils/DateRangeFormat";
import { getMethodService } from "../services";
import { DOWNLINE_BETTING_USER_NODE_API, WIN_LOSE_REPORT_NODE_API, WIN_LOSE_REPORT_FOR_A_USER_API, AUTH_USER_NODE_API } from "../services/constants";
import moment from "moment";
import Template from "../utils/Template";
import TableScroll from "../utils/TableScroll";
import { HandleLogout, decodeData } from "../utils/Helper";
import { useNavigate } from "react-router-dom";
const Cookies = require('js-cookie');

function WinLoseDetailPerUser() {
    const actualDate = new Date();
    const footballDate = new Date();
    var checkTime = new Date();
    checkTime.setHours(10); checkTime.setMinutes(29);

    if(actualDate.getTime() < checkTime.getTime()){
        footballDate.setDate(footballDate.getDate() - 1);
    }

    const [userId, setUserId] = useState(null);
    const [userRoleID, setUserRoleID] = useState(null);
    const [parentUserId, setParentUserId] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [startDate, setStartDate] = useState(formatDate(footballDate));
    const [endDate, setEndDate] = useState(formatDate(footballDate));
    const [parentIDList, setParentIDList] = useState([]);

    const [reports, setReports] = useState([]);
    const [downlineUsers, setDownlineUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const start_date = startDate && `${startDate} 10:29`;
    const end_date = endDate && `${moment(endDate).add(1,'days').format('YYYY-MM-DD')} 10:28`;
    let show_data = {};

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month?.length < 2) month = "0" + month;
        if (day?.length < 2) day = "0" + day;
        return [year, month, day].join("-");
    };

    useEffect(()=>{
        const getAuth = Cookies.get('n_a_u');
        let decode = getAuth && decodeData(getAuth);
        if(decode){
            setUserId(decode?.user_id);
            setParentIDList([decode?.user_id])
        };
    },[]);

    const fetchReportData = () => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            if(userId){
                let decode = decodeData(getAuth);
                setIsLoading(true);
                getMethodService(`${WIN_LOSE_REPORT_NODE_API}?user_id=${userId}&start_date=${start_date}&end_date=${end_date}`,{},false,decode?.token)
                .then((res) => {
                    if(res == 401){
                        HandleLogout(navigate);
                    };
                    if(res?.status === "success"){
                        setReports(res?.data)
                    };
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
            };
        };
    };

    const fetchUserData = () => {
        if (userId) {
          const getAuth = Cookies.get('n_a_u');
          if(getAuth){
            let decode = decodeData(getAuth);
            getMethodService(`${AUTH_USER_NODE_API}`,{},false,decode?.token)
            .then((res) => {
              if (res == 401) {
                HandleLogout(navigate);
              };
              if (res?.status === "success") {
                setCurrentUser(res?.data);
              };
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
            });
          };
        }
    };

    switch (currentUser?.role) {
        case "admin":
            show_data = {
                self: "super",
                upline: "company",
            };
            break;
        case "super":
            show_data = {
                member: "member",
                self: "super",
                upline: "company",
            };
            break;
        case "senior":
            show_data = {
                member: "member",
                self: "senior",
                upline: "company",
            };
            break;
        case "master":
            show_data = {
                member: "member",
                self: "master",
                upline: "company",
            };
            break;
        case "agent":
            show_data = {
                member: "member",
                self: "agent",
                upline: "company",
            };
            break;
    }

    const handleEyeClick = (id) =>{
        fetchDataForAUser(id);
    };

    function fetchDownlineUsers(abortController){
        setIsLoading(true);
        if(userId){
            const getAuth = Cookies.get('n_a_u');
            let decode = getAuth && decodeData(getAuth);
            if(decode){
                getMethodService(`${DOWNLINE_BETTING_USER_NODE_API}?user_id=${userId}&limit=10000&start_date=${start_date}&end_date=${end_date}`,{},false,decode?.token,abortController)
                .then((res) => {
                    if(res == 401){
                        HandleLogout(navigate);
                    };
                    console.log('fetch............. downlines')
                    setReports(res.data)
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
            };
        }
    };

    function fetchDataForAUser(user_id) {
        setIsLoading(true);
        getMethodService(`${WIN_LOSE_REPORT_FOR_A_USER_API}?user_id=${user_id}&start_date=${start_date}&end_date=${end_date}`)
            .then((res) => {
                if(res == 401){
                    HandleLogout(navigate);
                };
                console.log('fetchDataForAUser')
                console.log(res.data);
                console.log('reports',reports)
                let users = reports.map((value)=> {
                    if(value.id === user_id){
                        value['data'] = res.data;
                    }
                    return value;
                });
                console.log('foundd fetchDataForAUser')
                setReports(users);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    useEffect(()=>{
        const abortController = new AbortController();
        fetchUserData();
        fetchDownlineUsers(abortController);
        return () => {
            abortController.abort();
        }
    },[userId]);

    const submitHandler = () => {
        fetchDownlineUsers();
    };

    return (
        <>
            <Template title="Report">
                <div className="mb-3 card mb-lg-5">
                    <div className="card-header" style={{ paddingTop: "0.25rem" }}>
                        <div className="row align-items-end">
                            <div className="col-lg-3">
                                {parentIDList?.length > 1 && (
                                    <button
                                        className="btn btn-sm btn-dark"
                                        disabled={isLoading}
                                        onClick={() => {
                                            setUserId(
                                                parentIDList[
                                                    parentIDList?.length - 2
                                                ]
                                            );
                                            setParentIDList(
                                                parentIDList.slice(0, -1)
                                            );
                                        }}
                                    >
                                        {isLoading ? "Loading ..." : "Back"}
                                    </button>
                                )}
                            </div>
                            <div className="col-lg-7">
                                <DateRangeFormat
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                />
                            </div>
                            <div className="col-lg-2">
                                <button
                                    className="btn btn-primary w-100 mt-4"
                                    onClick={submitHandler}
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Loading ..." : "Submit"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <TableScroll>
                                    <div>
                                        <table className="table border table-bordered table-striped text-white"  >
                                            <thead>
                                                <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                    <th className="text-center text-light p-2" rowSpan={2}>No</th>
                                                    <th className="text-center text-light p-2" rowSpan={2} >Account</th>
                                                    <th className="text-center text-light p-2" rowSpan={2} >Contact</th>
                                                    <th className="text-center text-light p-2" rowSpan={2} >Amount</th>
                                                    <th className="text-center text-light p-2" rowSpan={2} >Valid Amount</th>
                                                    {show_data.hasOwnProperty(
                                                        "member"
                                                    ) && (
                                                        <th
                                                            className="p-2 text-center text-light"
                                                            style={{ fontSize: "12px" }}
                                                            colSpan={3}
                                                        >
                                                            Member
                                                        </th>
                                                    )}

                                                    {show_data.hasOwnProperty(
                                                        "self"
                                                    ) && (
                                                        <th
                                                            className="p-2 text-center text-light"
                                                            style={{ fontSize: "12px" }}
                                                            colSpan={3}
                                                        >
                                                            {show_data.self}
                                                        </th>
                                                    )}

                                                    {show_data.hasOwnProperty(
                                                        "upline"
                                                    ) && (
                                                        <th
                                                            className="p-2 text-center text-light"
                                                            style={{ fontSize: "12px" }}
                                                            colSpan={3}
                                                        >
                                                            {show_data.upline}
                                                        </th>
                                                    )}
                                                    <th></th>
                                                </tr>
                                                <tr
                                                    style={{
                                                        backgroundColor: "#25282a",
                                                    }}
                                                >
                                                    {/* member  */}
                                                    {show_data.hasOwnProperty(
                                                        "member"
                                                    ) && (
                                                        <>
                                                            <th
                                                                className="p-2 text-center text-light"
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                W/L
                                                            </th>
                                                            <th
                                                                className="p-2 text-center text-light"
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                Comm:
                                                            </th>
                                                            <th
                                                                className="p-2 text-center text-light"
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                Total
                                                            </th>
                                                        </>
                                                    )}
                                                    {/* self  */}
                                                    {show_data.hasOwnProperty(
                                                        "self"
                                                    ) && (
                                                        <>
                                                            <th
                                                                className="p-2 text-center text-light"
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                W/L
                                                            </th>
                                                            <th
                                                                className="p-2 text-center text-light"
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                Comm:
                                                            </th>
                                                            <th
                                                                className="p-2 text-center text-light"
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                Total
                                                            </th>
                                                        </>
                                                    )}

                                                    {/* upline  */}
                                                    {show_data.hasOwnProperty(
                                                        "upline"
                                                    ) && (
                                                        <>
                                                            <th
                                                                className="p-2 text-center text-light"
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                W/L
                                                            </th>
                                                            <th
                                                                className="p-2 text-center text-light"
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                Comm:
                                                            </th>
                                                            <th
                                                                className="p-2 text-center text-light"
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                Total
                                                            </th>
                                                        </>
                                                    )}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading && (
                                                    <tr>
                                                        <td colSpan={23}>
                                                            <div className="d-flex justify-content-center">
                                                                <div
                                                                    className="spinner-border text-primary"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">
                                                                        Loading...
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                                {!isLoading && reports?.length > 0 &&
                                                    reports.map((item, index) => {
                                                        return (
                                                            <tr
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                }}
                                                            >
                                                                <td className="p-2 text-center text-dark">
                                                                    {index + 1}
                                                                </td>
                                                                <td
                                                                    className="p-2 text-center text-primary"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    {item?.role_name !=
                                                                    "member" ? (
                                                                        item.data === undefined ?
                                                                                <span className="text-danger">{`${item?.usercode} (${item?.role_name})`}</span>
                                                                            : 
                                                                        <span
                                                                            onClick={() => {
                                                                                // setParentUserId(
                                                                                //     userId
                                                                                // );
                                                                                setParentIDList(
                                                                                    (
                                                                                        parentIDList
                                                                                    ) => [
                                                                                        ...parentIDList,
                                                                                        item?.user_id,
                                                                                    ]
                                                                                );
                                                                                setUserId(
                                                                                    item?.user_id
                                                                                );
                                                                            }}
                                                                        >
                                                                            {`${item?.usercode} (${item?.role_name})`}
                                                                        </span>
                                                                    ) : (
                                                                        item.data === undefined ?
                                                                                <span className="text-danger">{`${item?.usercode} (${item?.role_name})`}</span>
                                                                            :
                                                                            <a
                                                                                href={`../football/member/betting-list/${item.user_id}/all?start_date=${startDate}&end_date=${endDate}`}
                                                                            >
                                                                                {`${item?.usercode} (${item?.role_name})`}
                                                                            </a>
                                                                    )}
                                                                </td>
                                                                <td className="p-2 text-center text-dark">
                                                                    {item?.contact}
                                                                </td>
                                                                <td className="p-2 text-end text-dark">
                                                                    <b>
                                                                        {" "}
                                                                        {(item?.data?.total_bet_amount)?.toLocaleString()}
                                                                    </b>
                                                                </td>
                                                                <td className="p-2 text-end text-dark">
                                                                    <b>
                                                                        {(item?.data?.total_valid_amount)?.toLocaleString()}
                                                                    </b>
                                                                </td>

                                                                {/* member  */}
                                                                {show_data.hasOwnProperty(
                                                                    "member"
                                                                ) && (
                                                                    <>
                                                                        <td className="p-2 text-end text-dark">
                                                                            <b
                                                                                className={`${
                                                                                    item?.data?.member_win_lose >=
                                                                                    0
                                                                                        ? "text-dark"
                                                                                        : "text-danger"
                                                                                }`}
                                                                            >
                                                                                {(item?.data?.member_win_lose)?.toLocaleString()}
                                                                            </b>
                                                                        </td>
                                                                        <td className="p-2 text-end text-dark">
                                                                            <b>
                                                                                {(item?.data?.member_commission)?.toLocaleString()}
                                                                            </b>
                                                                        </td>
                                                                        <td className="p-0 text-end text-dark">
                                                                            <div className="p-2 bg-warning">
                                                                                <b
                                                                                    className={`${
                                                                                        item?.data?.member_total >=
                                                                                        0
                                                                                            ? "text-dark"
                                                                                            : "text-danger"
                                                                                    }`}
                                                                                >
                                                                                    {(item?.data?.member_total)?.toLocaleString()}
                                                                                </b>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                {/* self  */}
                                                                {show_data.hasOwnProperty(
                                                                    "self"
                                                                ) && (
                                                                    <>
                                                                        <td className="p-2 text-end text-dark">
                                                                            <b
                                                                                className={`${
                                                                                    item?.data?.self_win_lose >=
                                                                                    0
                                                                                        ? "text-dark"
                                                                                        : "text-danger"
                                                                                }`}
                                                                            >
                                                                                {(item?.data?.self_win_lose)?.toLocaleString()}
                                                                            </b>
                                                                        </td>
                                                                        <td className="p-2 text-end text-dark">
                                                                            <b>
                                                                                {(item?.data?.self_commission)?.toLocaleString()}
                                                                            </b>
                                                                        </td>
                                                                        <td className="p-0 text-end text-dark">
                                                                            <div className="p-2 bg-warning">
                                                                                <b
                                                                                    className={`${
                                                                                        item?.data?.self_total >=
                                                                                        0
                                                                                            ? "text-dark"
                                                                                            : "text-danger"
                                                                                    }`}
                                                                                >
                                                                                    {(item?.data?.self_total)?.toLocaleString()}
                                                                                </b>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                {/* upline  */}
                                                                {show_data.hasOwnProperty(
                                                                    "upline"
                                                                ) && (
                                                                    <>
                                                                        <td className="p-2 text-end text-dark">
                                                                            <b
                                                                                className={`${
                                                                                    item?.data?.upline_win_lose >=
                                                                                    0
                                                                                        ? "text-dark"
                                                                                        : "text-danger"
                                                                                }`}
                                                                            >
                                                                                {(item?.data?.upline_win_lose)?.toLocaleString()}
                                                                            </b>
                                                                        </td>
                                                                        <td className="p-2 text-end text-dark">
                                                                            <b
                                                                                className={`${
                                                                                    item?.data?.upline_commission >=
                                                                                    0
                                                                                        ? "text-dark"
                                                                                        : "text-danger"
                                                                                }`}
                                                                            >
                                                                                {(item?.data?.upline_commission)?.toLocaleString()}
                                                                            </b>
                                                                        </td>
                                                                        <td className="p-0 text-end text-dark">
                                                                            <div className="p-2 bg-warning">
                                                                                <b
                                                                                    className={`${
                                                                                        item?.data?.upline_total >=
                                                                                        0
                                                                                            ? "text-dark"
                                                                                            : "text-danger"
                                                                                    }`}
                                                                                >
                                                                                    {(item?.data?.upline_total)?.toLocaleString()}
                                                                                </b>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}
                                                                <td>
                                                                    <i class="bi bi-eye" onClick={() => handleEyeClick(item.id)} style={{cursor:'pointer'}}></i>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                            {!isLoading && reports?.length > 0 && (
                                                <tfoot>
                                                    <tr
                                                        style={{
                                                            verticalAlign: "middle",
                                                        }}
                                                    >
                                                        <td className="p-2 text-center text-dark"></td>
                                                        <td></td>
                                                        <td className="p-2 text-center text-dark"></td>
                                                        <td className="p-2 text-end text-dark">
                                                            <b>
                                                                {" "}
                                                                {reports
                                                                    .reduce(
                                                                        (a, v) =>
                                                                            (a =
                                                                                a +
                                                                                (v.data == undefined ? 0 :
                                                                                parseInt(
                                                                                    v.data?.total_bet_amount
                                                                                ))
                                                                            ),
                                                                        0
                                                                    )
                                                                    ?.toLocaleString()}
                                                            </b>
                                                        </td>
                                                        <td className="p-2 text-end text-dark">
                                                            <b>
                                                                {reports
                                                                    .reduce(
                                                                        (a, v) =>
                                                                            (a =
                                                                                a +
                                                                                (v.data == undefined ? 0 :
                                                                                parseInt(
                                                                                    v.data?.total_valid_amount
                                                                                )
                                                                                )
                                                                            ),
                                                                        0
                                                                    )
                                                                    ?.toLocaleString()}
                                                            </b>
                                                        </td>

                                                        {/* member  */}
                                                        {show_data.hasOwnProperty(
                                                            "member"
                                                        ) && (
                                                            <>
                                                                <td className="p-2 text-end text-dark">
                                                                    <b
                                                                        className={`${
                                                                            reports.reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.member_win_lose
                                                                                        )
                                                                                        )
                                                                                    ),
                                                                                0
                                                                            ) >= 0
                                                                                ? "text-dark"
                                                                                : "text-danger"
                                                                        }`}
                                                                    >
                                                                        {reports
                                                                            .reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.member_win_lose
                                                                                        ))

                                                                                    ),
                                                                                0
                                                                            )
                                                                            ?.toLocaleString()}
                                                                    </b>
                                                                </td>
                                                                <td className="p-2 text-end text-dark">
                                                                    <b>
                                                                        {reports
                                                                            .reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.member_commission
                                                                                        ))
                                                                                    ),
                                                                                0
                                                                            )
                                                                            ?.toLocaleString()}
                                                                    </b>
                                                                </td>
                                                                <td className="p-0 text-end text-dark">
                                                                    <div className="p-2 bg-warning">
                                                                        <b
                                                                            className={`${
                                                                                reports.reduce(
                                                                                    (
                                                                                        a,
                                                                                        v
                                                                                    ) =>
                                                                                        (a =
                                                                                            a +
                                                                                            (v.data == undefined ? 0 :
                                                                                            parseInt(
                                                                                                v.data?.member_total
                                                                                            ))
                                                                                        ),
                                                                                    0
                                                                                ) >= 0
                                                                                    ? "text-dark"
                                                                                    : "text-danger"
                                                                            }`}
                                                                        >
                                                                            {reports
                                                                                .reduce(
                                                                                    (
                                                                                        a,
                                                                                        v
                                                                                    ) =>
                                                                                        (a =
                                                                                            a +
                                                                                            (v.data == undefined ? 0 :
                                                                                            parseInt(
                                                                                                v.data?.member_total
                                                                                            ))
                                                                                        ),
                                                                                    0
                                                                                )
                                                                                ?.toLocaleString()}
                                                                        </b>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )}

                                                        {/* self  */}
                                                        {show_data.hasOwnProperty(
                                                            "self"
                                                        ) && (
                                                            <>
                                                                <td className="p-2 text-end text-dark">
                                                                    <b
                                                                        className={`${
                                                                            reports.reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.self_win_lose
                                                                                        ))
                                                                                    ),
                                                                                0
                                                                            ) >= 0
                                                                                ? "text-dark"
                                                                                : "text-danger"
                                                                        }`}
                                                                    >
                                                                        {reports
                                                                            .reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.self_win_lose
                                                                                        ))
                                                                                    ),
                                                                                0
                                                                            )
                                                                            ?.toLocaleString()}
                                                                    </b>
                                                                </td>
                                                                <td className="p-2 text-end text-dark">
                                                                    <b>
                                                                        {reports
                                                                            .reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.self_commission
                                                                                        ))),
                                                                                0
                                                                            )
                                                                            ?.toLocaleString()}
                                                                    </b>
                                                                </td>
                                                                <td className="p-0 text-end text-dark">
                                                                    <div className="p-2 bg-warning">
                                                                        <b
                                                                            className={`${
                                                                                reports.reduce(
                                                                                    (
                                                                                        a,
                                                                                        v
                                                                                    ) =>
                                                                                        (a =
                                                                                            a +
                                                                                            (v.data == undefined ? 0 :
                                                                                            parseInt(
                                                                                                v.data?.self_total
                                                                                            ))),
                                                                                    0
                                                                                ) >= 0
                                                                                    ? "text-dark"
                                                                                    : "text-danger"
                                                                            }`}
                                                                        >
                                                                            {reports
                                                                                .reduce(
                                                                                    (
                                                                                        a,
                                                                                        v
                                                                                    ) =>
                                                                                        (a =
                                                                                            a +
                                                                                            (v.data == undefined ? 0 :
                                                                                            parseInt(
                                                                                                v.data?.self_total
                                                                                            ))),
                                                                                    0
                                                                                )
                                                                                ?.toLocaleString()}
                                                                        </b>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )}

                                                        {/* upline  */}
                                                        {show_data.hasOwnProperty(
                                                            "upline"
                                                        ) && (
                                                            <>
                                                                <td className="p-2 text-end text-dark">
                                                                    <b
                                                                        className={`${
                                                                            reports.reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.upline_win_lose
                                                                                        ))),
                                                                                0
                                                                            ) >= 0
                                                                                ? "text-dark"
                                                                                : "text-danger"
                                                                        }`}
                                                                    >
                                                                        {reports
                                                                            .reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.upline_win_lose
                                                                                        ))),
                                                                                0
                                                                            )
                                                                            ?.toLocaleString()}
                                                                    </b>
                                                                </td>
                                                                <td className="p-2 text-end text-dark">
                                                                    <b
                                                                        className={`${
                                                                            reports.reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.upline_commission
                                                                                        ))),
                                                                                0
                                                                            ) >= 0
                                                                                ? "text-dark"
                                                                                : "text-danger"
                                                                        }`}
                                                                    >
                                                                        {reports
                                                                            .reduce(
                                                                                (
                                                                                    a,
                                                                                    v
                                                                                ) =>
                                                                                    (a =
                                                                                        a +
                                                                                        (v.data == undefined ? 0 :
                                                                                        parseInt(
                                                                                            v.data?.upline_commission
                                                                                        ))),
                                                                                0
                                                                            )
                                                                            ?.toLocaleString()}
                                                                    </b>
                                                                </td>
                                                                <td className="p-0 text-end text-dark">
                                                                    <div className="p-2 bg-warning">
                                                                        <b
                                                                            className={`${
                                                                                reports.reduce(
                                                                                    (
                                                                                        a,
                                                                                        v
                                                                                    ) =>
                                                                                        (a =
                                                                                            a +
                                                                                            (v.data == undefined ? 0 :
                                                                                            parseInt(
                                                                                                v.data?.upline_total
                                                                                            ))),
                                                                                    0
                                                                                ) >= 0
                                                                                    ? "text-dark"
                                                                                    : "text-danger"
                                                                            }`}
                                                                        >
                                                                            {reports
                                                                                .reduce(
                                                                                    (
                                                                                        a,
                                                                                        v
                                                                                    ) =>
                                                                                        (a =
                                                                                            a +
                                                                                            (v.data == undefined ? 0 :
                                                                                            parseInt(
                                                                                                v.data?.upline_total
                                                                                            ))),
                                                                                    0
                                                                                )
                                                                                ?.toLocaleString()}
                                                                        </b>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>
                                                </tfoot>
                                            )}
                                        </table>
                                    </div>
                                </TableScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </Template>

            
            <ToastContainer></ToastContainer>
        </>
    );
}

export default WinLoseDetailPerUser;
