import moment from 'moment'
import React from 'react'

const Testing = () => {
  const date = moment().format('MMMM DD, hh:mm:ss A')
  return (
    <h1 style={{color: '#000', padding: '50px 0', textAlign: 'center'}}>
        Test CI/CD {date}
        {/* test CI/CD may 13, 10:04 pm */}
    </h1>
  )
}

export default Testing
