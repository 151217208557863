import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FOOTBALL_REJECT_API, GOALS_CONFIRM, PROXY_API } from "../../services/constants";
import { getMethodService, postMethodService } from "../../services";
import classes from '../../assets/styles/FootballResultCard.module.css';
import SweetAlert from 'sweetalert2';
import { HandleLogout, errorToaster, successToaster } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";
function FootballResultCard(props) {
    const fixture = props.data;
    const relodData = props.setRefreshKey;
    const market = props.market;
    const [showStatus,setShowStatus] = useState(true);
    const [isShowGoal, setIsShowGoal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [homeGoal, setHomeGoal] = useState(0);
    const [awayGoal, setAwayGoal] = useState(0);
    const navigate = useNavigate();

    const time_status_obj = {
        0: "Not Started",
        1: "InPlay",
        2: "TO BE FIXED",
        3: "Ended",
        4: "Postponed",
        5: "Cancelled",
        6: "Walkover",
        7: "Interrupted",
        8: "Abandoned",
        9: "Retired",
        10: "Suspended",
        11: "Decided by FA",
        99: "Removed",
    }


    const showBtnShowClickHandler = () => {
        props.setDelayStatus(false);
        setShowStatus(false);
        setIsLoading(true);
        getMethodService(PROXY_API, {
            request_url: "https://api.b365api.com/v1/event/view",
            request_body: {
                event_id: fixture.fixture_id,
            },
        })
        .then((response) => {
            if(response == 401){
                HandleLogout(navigate);
            };
            // response.success && response.results.length > 0
            console.log(response)
            if(response.success){
                setIsLoading(false);
                if(response.results.length > 0){
                    let result = response.results[0];
                    let time_status = result.time_status;
                    if(time_status == 1 || time_status == 2 || time_status==3 ){
                        if(time_status == 1 || time_status == 2){
                            errorToaster(`${time_status_obj[time_status]}`);
                        }
                        else{
                            successToaster(`${time_status_obj[time_status]}`);
                        }
                        if(result.scores){
                            let goals = market === "full-time"? result.scores[2]: result.scores[1];
                            if(goals){
                                if (goals.away == null && goals.home == null) {
                                    errorToaster("No goals result found");
                                } else {
                                    setHomeGoal(goals.home);
                                    setAwayGoal(goals.away);
                                };
                                setIsLoading(false);
                                setIsShowGoal(true);
                            }else{
                                setIsLoading(false);
                                errorToaster("ပွဲရလဒ် မရှိသေးပါ");
                                setHomeGoal("--")
                                setAwayGoal("--")
                            };
                        }else{
                            setIsLoading(false);
                            errorToaster("ပွဲရလဒ် မရှိသေးပါ");
                            setHomeGoal("--")
                            setAwayGoal("--")
                        };
                    }
                    else{
                        setIsLoading(false);
                        errorToaster(`${time_status_obj[time_status]}`);
                        setHomeGoal("--")
                        setAwayGoal("--")
                    }
                }else{
                    setIsLoading(false);
                    errorToaster("ပွဲရလဒ် မရှိသေးပါ");
                    setHomeGoal("--")
                    setAwayGoal("--")
                }
            }
            //to delete
            else{
                setHomeGoal("--")
                setAwayGoal("--")
                setIsLoading(false);
            };
            //to delete
        })
        .catch((err) => {
            console.error(err)
            setIsLoading(false);

        });
    };

    const fetchGoalsConfirm = (id,is_show_noti) => {
        props.setDelayStatus(false);
        postMethodService(GOALS_CONFIRM(id), {
            home: homeGoal,
            away: awayGoal,
            market
        },is_show_noti)
        .then((response) => {
            if(response == 401){
                HandleLogout(navigate);
            };
            if(response?.status === 'success'){
                setIsShowGoal(false);
                relodData(prevKey => prevKey + 1);
                setIsLoading(false);
            };
        })
        .catch((err) => {
            console.log("err", err);
            errorToaster(err.data?.message);
            setIsLoading(false);
        });
    }

    const fetchGoalReject = (id,is_show_noti) => {
        props.setDelayStatus(false);
        postMethodService(FOOTBALL_REJECT_API(id), null, is_show_noti)
        .then((response) => {
            if(response == 401){
                HandleLogout(navigate);
              };
            if(response?.status === 'success'){
                relodData(prevKey => prevKey + 1);
            };
        })
        .catch((err) => {
            console.error(err);
        });
    }

    const handleConfirmBtn = () => {
        setIsLoading(true);
        if(fixture.id2){
            fetchGoalsConfirm(fixture.id2,false);
        };
        fetchGoalsConfirm(fixture.id,true);
    };

    const rejectMatchHandler = () => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm!',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                if(fixture.id2){
                    fetchGoalReject(fixture.id2,false);
                };
                fetchGoalReject(fixture.id, true);
            };
          });
    };
    return (
        <>
            <div className={`card position-relative ${classes['football-card']}`}>
                 <div className={classes['content-position']}>
                    {
                        fixture.mm_football_category2?.id === 1 &&
                        <div className={classes['match-type-icon-left']} title={fixture.mm_football_category.name}>
                            {/* <i className="bi bi-star-fill" style={{color: '#E4A11B'}}></i> */}
                            <span style={{color: '#E4A11B',fontSize: '10px'}}>ပွဲသေး</span>
                        </div>
                    }
                    {
                        fixture.mm_football_category2?.id === 2 &&
                        <div className={classes['match-type-icon-left']} title={fixture.mm_football_category.name}>
                            {/* <i className="bi bi-star-fill" style={{color: '#E4A11B'}}></i> */}
                            <span style={{color: '#E4A11B',fontSize: '10px'}}>ပွဲကြီး</span>
                        </div>
                    }
                    {
                        fixture.mm_football_category?.id === 1 &&
                        <div className={classes['match-type-icon-left']} title={fixture.mm_football_category.name}>
                            {/* <i className="bi bi-star-fill" style={{color: '#E4A11B'}}></i> */}
                            <span style={{color: '#E4A11B',fontSize: '10px'}}>ပွဲသေး</span>
                        </div>
                    }
                    {
                        fixture.mm_football_category?.id === 2 &&
                        <div className={classes['match-type-icon-left']} title={fixture.mm_football_category.name}>
                            {/* <i className="bi bi-star-fill" style={{color: '#E4A11B'}}></i> */}
                            <span style={{color: '#E4A11B',fontSize: '10px'}}>ပွဲကြီး</span>
                        </div>
                    }
                    {
                       fixture.mm_football_category2?.id === 3 &&
                       <div className={classes['match-type-icon-right']} title={fixture.mm_football_category.name}>
                           <span style={{color: '#E4A11B',fontSize: '10px'}}>မောင်း</span>
                       </div>
                    }
                    {
                       fixture.mm_football_category?.id === 3 &&
                       <div className={classes['match-type-icon-right']} title={fixture.mm_football_category.name}>
                           <span style={{color: '#E4A11B',fontSize: '10px'}}>မောင်း</span>
                       </div>
                    }
                     <h4 className={classes.time}>{fixture.fixture_start_time}</h4>
                     <div className="row align-items-start justify-content-center" style={{height: '100px'}}>
                         <div className="col-4">
                            <div className="text-center">
                                <img src={
                                    fixture.home_team_data.image_id
                                    ? `https://assets.b365api.com/images/team/b/${fixture.home_team_data.image_id}.png`
                                    : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                                } className={classes.teamImage}/>
                                <p className={classes['team-name']}>{
                                    props.lang === 'mm'?
                                    fixture.home_team_data.name_mm ?? fixture.home_team_data.name:
                                    fixture.home_team_data.name
                                }</p>
                            </div>
                         </div>
                         <div className="col-3">
                            {
                                fixture.status === "completed"?
                                    <div className="d-flex align-items-center w-100">
                                        <input type="number" className={classes['goal-result']} value={fixture?.goals?.home} onChange={e => setHomeGoal(e.target.value)}/>
                                        <div>
                                            <p className={classes['goal-result']}>
                                                -
                                            </p>
                                        </div>
                                        <input type="number" className={classes['goal-result']} value={fixture?.goals?.away} onChange={e => setAwayGoal(e.target.value)}/>
                                    </div>:

                                (
                                    showStatus?
                                    <p className={classes['goal-result']}>
                                        VS
                                    </p>:
                                    <div className="d-flex align-items-center w-100">
                                        <input type="number" className={classes['goal-result']} value={homeGoal} onChange={e => setHomeGoal(e.target.value)} autoFocus/>
                                        <div>
                                            <p className={classes['goal-result']}>
                                                -
                                            </p>
                                        </div>
                                        <input type="number" className={classes['goal-result']} value={awayGoal} onChange={e => setAwayGoal(e.target.value)}/>
                                    </div>
                                )
                            }
                         </div>
                         <div className="col-4">
                            <div className="text-center">
                                <img src={
                                    fixture.away_team_data.image_id
                                    ? `https://assets.b365api.com/images/team/b/${fixture.away_team_data.image_id}.png`
                                    : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                                } className={classes.teamImage}/>
                                <p className={classes['team-name']}>{
                                    props.lang === 'mm'?
                                    fixture.away_team_data.name_mm ?? fixture.away_team_data.name:
                                    fixture.away_team_data.name
                                }</p>
                            </div>
                         </div>
                     </div>
                     <div className="row">
                        <div className="col-12">
                            <div className="mt-2 text-center">
                                {
                                    props.type === "ready" &&
                                    <div className="btn-group" role="group">
                                        {
                                            showStatus?
                                            <button type="button" className={`btn py-1 ${classes.actionBtnShow}`} onClick={showBtnShowClickHandler} disabled={isLoading}>{isLoading?'Loading...':'Show'}</button>:
                                            <button type="button" className={`btn py-1 ${classes.actionBtnConfirm}`} onClick={handleConfirmBtn} disabled={isLoading}>{isLoading?'Loading...':'Confirm'}</button>
                                        }
                                        {
                                            market === 'full-time' &&
                                            <button type="button" className={`btn py-1 ${classes.actionBtnReject}`} onClick={rejectMatchHandler} disabled={isLoading}>{isLoading?'Loading...':'Reject'}</button>
                                        }
                                    </div>
                                }
                                {
                                    props.type === "inplay" &&
                                    <div className="btn-group" role="group">
                                         <button type="button" className={`btn py-1 ${classes.actionBtnShow}`} onClick={showBtnShowClickHandler} disabled={isLoading}>{isLoading?'Loading...':'Show'}</button>
                                        {/* <button type="button" className={`btn py-1 ${classes.actionBtnReject}`} onClick={rejectMatchHandler}>Reject</button> */}

                                    </div>
                                }
                                {
                                    props.type === "complete" &&
                                    <div className="btn-group" role="group">
                                        {/* <button type="button" className={`btn py-1 ${classes.actionBtnConfirm}`} onClick={rollbackMatchHandler} disabled={isLoading}>{isLoading?'Loading...':'Rollback'}</button> */}
                                    </div>
                                }
                            </div>
                        </div>
                     </div>
                 </div>
                 <div className={classes['card-backdrop']}></div>
                 {
                    isLoading &&
                    <div className={classes['card-loading']}>
                        <div className="spinner-border text-white m-0 rounded-circle" role="status"
                            style={{
                                width: '25px',
                                height: '25px',
                            }}
                        >
                            <span className="visually-hidden">Loading...</span>
                        </div>

                    </div>
                 }
            </div>
        </>
    );
}

export default FootballResultCard;
