import { useEffect, useState } from "react";
import Template from "../utils/Template";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import { getMethodService } from "../services";
import {
  WIN_LOSE_REPORT_NODE_API,
  DOWNLINE_COUNT_NODE_API,
  AUTH_USER_NODE_API,
  CHECKED_CALL_API,
} from "../services/constants";
import {
  HandleLogout,
  amountCommasSparated,
  decodeData,
  exportExcelFormat,
} from "../utils/Helper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { stateObjType } from "../types";
import TableLoading from "../utils/TableLoading";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { userIdHandler } from "../store/slices/userIdSlice";
import useDebounce from "../hooks/useDebounce";
import ScreenLoader from "../utils/ScreenLoader";
import { dateHandler } from "../store/slices/dateSlice";
import { serviceStatusHandler } from "../store/slices/refreshStatusService";
import { ToastContainer } from "react-toastify";
const Cookies = require('js-cookie');

let totalFetch = 0;
let totalFetchPage = 0;

interface Report {
  user_id: number;
  role_id: number;
  role: object;
  usercode: string;
  contact: string;
  total_bet_amount: number;
  total_valid_amount: number;
  member_win_lose: number;
  member_commission: number;
  member_total: number;
  self_win_lose: number;
  self_commission: number;
  self_total: number;
  upline_win_lose: number;
  upline_commission: number;
  upline_total: number;
}

const WinLoseDetail = () => {
  // const abortController = new AbortController();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownlineLoading, setIsDownlineLoading] = useState(false);
  const actualDate = new Date();
  const footballDate = new Date();
  var checkTime = new Date();
  checkTime.setHours(10);
  checkTime.setMinutes(29);
  const params = useParams();

  if (actualDate.getTime() < checkTime.getTime()) {
    footballDate.setDate(footballDate.getDate() - 1);
  };
  const { store_date } = useSelector((state: any) => state.dateSlice);
  const [userId, setUserId] = useState<any>(null);
  const [checkedAdminId,setCheckedAdminId] = useState<any>(null)
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(formatDate(footballDate,"start_date"));
  const [endDate, setEndDate] = useState<any>(formatDate(footballDate,'end_date'));
  const [reports, setReports] = useState<Report[]>([]);
  const navigate = useNavigate();
  const [formatExcel, setFormatExcel] = useState<any>([]);
  const [downlineCount, setDownlineCount] = useState<any>(null);
  const { temp_user_id } = useSelector((state: any) => state.userId);
  const dispatch = useDispatch();
  const {serviceStatus} = useSelector((state:any) => state.refreshStatusService);
  const [role,setRole] = useState("");
  const [reportCallStatus,setReportCallStatus] = useState(false);
  let roles = ['admin','super','senior'];
  const {pathname} = useLocation();

  const checkedReportEvent = async () => {
    const getAuth = Cookies.get("n_a_u");
    let decode = getAuth && decodeData(getAuth);
    if(decode){
      let findRole = roles.includes(decode?.role_name);
      if(findRole){
        getMethodService(`${CHECKED_CALL_API}?message=${decode?.role_name}(${decode?.type}) view win-lose report from ${startDate} to ${endDate}`,{},false,decode?.token)
        .then((res) => {
          if (res == 401) {
            HandleLogout(navigate);
          };
          if (res?.status === "success") {
            
          };
        });
      };
    };
  }

  function formatDate(date: any,type: any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  useEffect(() => {
    const getAuth = Cookies.get("a_u");
    let decode = getAuth && decodeData(getAuth);
    if (decode) {
      setRole(decode?.role_name)
      if (temp_user_id?.length > 0) {
        setUserId(temp_user_id[temp_user_id.length - 1]);
        setCheckedAdminId(temp_user_id[temp_user_id.length - 1]);
      } else {
        dispatch(userIdHandler([decode?.user_id]));
        // setUserId(decode?.user_id);
        setCheckedAdminId(decode?.user_id);
      }
    };
  }, []);

  const userIdAddHandler = (user_id: string) => {
    dispatch(dateHandler({start_date: startDate, end_date: endDate}));
    setReportCallStatus(true);
    setUserId(user_id);
    setCheckedAdminId(user_id);
    const data = [...temp_user_id];
    data.push(user_id);
    dispatch(userIdHandler(data));
    totalFetchPage = 0;
  };

  const userIdRemoveHandler = () => {
    const data = [...temp_user_id];
    data.pop();
    dispatch(userIdHandler(data));
    setReportCallStatus(true);
    if(data.length === 1){
      setUserId(null);
      setCheckedAdminId(data[data.length - 1]);
      setReports([]);
    }else{
      setUserId(data[data.length - 1]);
      setCheckedAdminId(data[data.length - 1]);
    }
    totalFetchPage = 0;
  };

  const start_date = startDate && `${startDate} 10:29`;
  const end_date =
    endDate && `${moment(endDate).add(1, "days").format("YYYY-MM-DD")} 10:28`;
    const store_start_date = store_date?.start_date ? `${store_date?.start_date} 10:29` : null;
    const store_end_date = store_date?.end_date ? `${moment(store_date?.end_date).add(1, "days").format("YYYY-MM-DD")} 10:28` : null;

  const fetchReportData = async (page: number, limit: number) => {
    // console.log("abortController",abortController.abort());
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      // if (userId) {
        let decode = decodeData(getAuth);
        setIsLoading(true);
        // authId=${decode?.user_id}
        return getMethodService(
          `${WIN_LOSE_REPORT_NODE_API}?start_date=${store_start_date ?? start_date}&end_date=${store_end_date ?? end_date}${userId ? `&user_id=${userId}`: ''}&page=${page}&limit=${limit}`,{},false,decode?.token
        )
          .then((res) => {
            if(res == 455){
                return 455;
            };
            if (res == 401) {
                HandleLogout(navigate);
            };
            if (res?.status === "success") {
              // setReports(res?.data)
              totalFetch = +totalFetch+1;
              let result = res?.data;
              const filterZero = result.filter(
                (item: any) => item.total_bet_amount !== 0
              );
                // console.log(totalFetch, totalFetchPage)
                if (totalFetchPage == totalFetch) {
                    setIsLoading(false)
                }

              setReports((prevReports) => {
                const updatedReports = [...prevReports, ...filterZero];

                // Sort the updatedReports array by usercode
                updatedReports.sort((a, b) =>
                  a.usercode.localeCompare(b.usercode)
                );

                return updatedReports;
              });
            } else {
              // console.log("res",res)
              // console.log("report api  error response..");
              fetchReportData(page, limit);
            }

            // setIsLoading(false);
          })
          .catch((err) => {
              // console.log("report api requet fail ..", err);
              fetchReportData(page, limit);
            // setIsLoading(false);
          });
      // }
    };
  };

  const fetAllReportData = async() => {
    setIsLoading(true);
    setReports([]);
    if (downlineCount?.count) {
      const concurrencyLimit = 1;
      let promises: any = [];
      // let limit = 1000;
      // let page =1;
      let limit = 20;
      let page = Math.ceil(downlineCount?.count / limit);

      // agent master

      if(checkedAdminId == 1){
         limit =  2;
         page = Math.ceil(downlineCount?.count / limit);
      };
      for (let i = 1; i <= page; i++) {
        if(window.location.pathname !== '/report/win-lose-detail'){
          break;
        }
        promises.push(fetchReportData(i, limit));
        if (promises.length >= concurrencyLimit) {
          let res = await Promise.all(promises);
          if(res.includes(455)){
            break;
          };
          promises.length = 0; // Clear the array for the next batch
        }
      }
      setIsLoading(false);
    };
  };

  const fetchUserData = (abortController:any) => {
    // if (userId) {
      const getAuth = Cookies.get('n_a_u');
      if(getAuth){
        let decode = decodeData(getAuth);
        getMethodService(`${AUTH_USER_NODE_API}`,{},false,decode?.token)
        .then((res) => {
          if (res == 401) {
            HandleLogout(navigate);
          };
          if (res?.status === "success") {
            setCurrentUser(res?.data);
          };
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
      };
    // }
  };

  const fetchDownlinesCount = (abortController:any) => {
    const getAuth = Cookies.get("n_a_u");
    setIsDownlineLoading(true);
    let decode = decodeData(getAuth);
    getMethodService(`${DOWNLINE_COUNT_NODE_API}?user_id=${userId ?? decode?.user_id}`,{},false,decode?.token,abortController)
      .then((res) => {
        setIsDownlineLoading(false);
        if (res == 401) {
          HandleLogout(navigate);
        }
        if (res?.status === "success") {
          setDownlineCount(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsDownlineLoading(false);
      });
  };

  useEffect(() => {
      setIsLoading(true);
      if(reportCallStatus){
        if(downlineCount?.count){
          fetAllReportData();
        }else{
          setIsLoading(false);
        }
      }else{
        const getAuth = Cookies.get("n_a_u");
        let decode = decodeData(getAuth);
        if(decode?.user_id != downlineCount?.user_id){
          fetAllReportData();
        }else{
          setIsLoading(false);
        };
      };
  }, [downlineCount]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchUserData(abortController);
    fetchDownlinesCount(abortController);

    return () => {
        abortController.abort();
    };
  }, [userId]);

  let show_data: any = {};

  switch (currentUser?.role) {
    case "admin":
      show_data = {
        self: "super",
        upline: "company",
      };
      break;
    case "super":
      show_data = {
        member: "member",
        self: "super",
        upline: "company",
      };
      break;
    case "senior":
      show_data = {
        member: "member",
        self: "senior",
        upline: "company",
      };
      break;
    case "master":
      show_data = {
        member: "member",
        self: "master",
        upline: "company",
      };
      break;
    case "agent":
      show_data = {
        member: "member",
        self: "agent",
        upline: "company",
      };
      break;
  }

  const submitHandler = () => {
    const abortController = new AbortController();
    setIsLoading(true);
    fetAllReportData();
    // fetchReportData(1,100000);
    fetchUserData(abortController);
    dispatch(serviceStatusHandler(!serviceStatus));
    checkedReportEvent();
  };

  const calculateTotal = (data: any, type: string) => {
    return Number(
      data.reduce((a: any, v: stateObjType) => (a = a + parseInt(v[type])), 0)
    );
  };

  useEffect(() => {
    let temp: any = [];
    if (reports.length > 0) {
      reports.forEach((report: any, i) => {
        let obj = {
          No: ++i,
          Account: report?.usercode,
          Contact: report?.contact,
          Amount: report?.total_bet_amount,
          MemberWinLose: Number(Math.round(report?.member_win_lose)),
          MemberComm: Number(Math.round(report?.member_commission)),
          MemberTotal: Number(Math.round(report?.member_total)),
          [show_data?.self + " WinLose"]: Number(
            Math.round(report?.self_win_lose)
          ),
          [show_data?.self + " Comm"]: Number(
            Math.round(report?.self_commission)
          ),
          [show_data?.self + " Total"]: Number(Math.round(report?.self_total)),
          [show_data?.upline + " WinLose"]: Number(
            Math.round(report?.upline_win_lose)
          ),
          [show_data?.upline + " Comm"]: Number(
            Math.round(report?.upline_commission)
          ),
          [show_data?.upline + " Total"]: Number(
            Math.round(report?.upline_total)
          ),
        };
        temp.push(obj);
      });
      const total = {
        No: "",
        Account: "",
        Contact: "Total",
        Amount: calculateTotal(reports, "total_bet_amount"),
        MemberWinLose: calculateTotal(reports, "member_win_lose"),
        MemberComm: calculateTotal(reports, "member_commission"),
        MemberTotal: calculateTotal(reports, "member_total"),
        [show_data?.self + " WinLose"]: calculateTotal(
          reports,
          "self_win_lose"
        ),
        [show_data?.self + " Comm"]: calculateTotal(reports, "self_commission"),
        [show_data?.self + " Total"]: calculateTotal(reports, "self_total"),
        [show_data?.upline + " WinLose"]: calculateTotal(
          reports,
          "upline_win_lose"
        ),
        [show_data?.upline + " Comm"]: calculateTotal(
          reports,
          "upline_commission"
        ),
        [show_data?.upline + " Total"]: calculateTotal(reports, "upline_total"),
      };
      temp.push(total);
      setFormatExcel(temp);
    }
  }, [reports]);
  const exportHandler = () => {
    return exportExcelFormat(formatExcel, "Win-Lose");
  };

  return (
    <>
      <Template title="Report">
        <div className="card mb-3 mb-lg-5">
          <div className="card-header">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-3">
                {temp_user_id?.length > 1 && (
                  <button
                    className="btn btn-sm btn-dark"
                    disabled={isLoading}
                    onClick={() => {
                      // setUserId(
                      //     parentIDList[
                      //         parentIDList.length - 2
                      //     ]
                      // );
                      // setParentIDList(
                      //     parentIDList.slice(0, -1)
                      // );
                      userIdRemoveHandler();
                    }}
                  >
                    {isLoading ? "Loading ..." : "Back"}
                  </button>
                )}
              </div>
              <div className="col-lg-7">
                <DateRangeFormat
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
              <div className="col-lg-2">
                <button
                  className="btn btn-primary w-100 mt-4"
                  onClick={submitHandler}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading ..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              {/* <h1>{downlineCount}</h1> */}
              <div className="col-sm-12">
                {formatExcel?.length > 0 && (
                  <button
                    className="btn btn-success mb-3"
                    type="button"
                    onClick={exportHandler}
                  >
                    Export Excel{" "}
                  </button>
                )}
              </div>
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table className="table border table-bordered table-striped text-white">
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "#25282a",
                            verticalAlign: "middle",
                          }}
                        >
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            No
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Account
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Contact
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Amount
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Valid Amount
                          </th>

                          {show_data.hasOwnProperty("member") && (
                            <th
                              className="text-center text-light p-2"
                              colSpan={3}
                            >
                              Member
                            </th>
                          )}
                          {show_data.hasOwnProperty("self") && (
                            <th
                              className="text-center text-light p-2"
                              colSpan={3}
                            >
                              {show_data?.self}
                            </th>
                          )}
                          {show_data.hasOwnProperty("upline") && (
                            <th
                              className="text-center text-light p-2"
                              colSpan={3}
                            >
                              {show_data.upline}
                            </th>
                          )}
                        </tr>
                        <tr
                          style={{
                            backgroundColor: "#25282a",
                            verticalAlign: "middle",
                          }}
                        >
                          {show_data.hasOwnProperty("member") && (
                            <>
                              <th className="text-center text-light p-2">
                                W/L
                              </th>
                              <th className="text-center text-light p-2">
                                Comm:
                              </th>
                              <th className="p-2 text-center text-light p-2">
                                Total
                              </th>
                            </>
                          )}
                          {show_data.hasOwnProperty("self") && (
                            <>
                              <th className="text-center text-light p-2">
                                W/L
                              </th>
                              <th className="text-center text-light p-2">
                                Comm:
                              </th>
                              <th className="text-center text-light p-2">
                                Total
                              </th>
                            </>
                          )}
                          {show_data.hasOwnProperty("upline") && (
                            <>
                              <th className="text-center text-light p-2">
                                W/L
                              </th>
                              <th className="text-center text-light p-2">
                                Comm:
                              </th>
                              <th className="text-center text-light p-2">
                                Total
                              </th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {isLoading && <TableLoading col={23} />} */}
                        {reports?.length > 0 ? (
                          reports?.map((item: stateObjType, i: number) => (
                            <tr style={{ verticalAlign: "middle" }} key={i}>
                              <td className="text-center text-dark p-2">
                                {i + 1}
                              </td>
                              <td
                                className="p-2 text-center text-primary"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                {item?.role.name != "member" ? (
                                  <span
                                    onClick={() => {
                                      // setParentIDList(
                                      //     (
                                      //         parentIDList:any
                                      //     ) => [
                                      //         ...parentIDList,
                                      //         item?.user_id,
                                      //     ]
                                      // );
                                      // setUserId(
                                      //     item?.user_id
                                      // );
                                      userIdAddHandler(`${item?.user_id}`);
                                    }}
                                  >
                                    {`${item?.usercode} (${item?.role?.name})`}
                                  </span>
                                ) : (
                                  <Link
                                    to={`/report/win-lose-detail/${item.user_id}/all/${startDate}/${endDate}`}
                                  >
                                    {`${item?.usercode} (${item?.role?.name})`}
                                  </Link>
                                )}
                              </td>
                              <td className="p-2 text-center text-dark">
                                {item?.contact}
                              </td>
                              <td className="p-2 text-end text-dark">
                                <b>
                                  {" "}
                                  {amountCommasSparated(
                                    Math.round(item?.total_bet_amount)
                                  )}
                                </b>
                              </td>
                              <td className="p-2 text-end text-dark">
                                <b>
                                  {amountCommasSparated(
                                    Math.round(item?.total_valid_amount)
                                  )}
                                </b>
                              </td>
                              {show_data.hasOwnProperty("member") && (
                                <>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.member_win_lose >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.member_win_lose)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-2 text-end text-dark">
                                    <b>
                                      {amountCommasSparated(
                                        Math.round(item?.member_commission)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-0 text-end text-dark">
                                    <div className="p-2 bg-warning">
                                      <b
                                        className={`${
                                          item?.member_total >= 0
                                            ? "text-dark"
                                            : "text-danger"
                                        }`}
                                      >
                                        {amountCommasSparated(
                                          Math.round(item?.member_total)
                                        )}
                                      </b>
                                    </div>
                                  </td>
                                </>
                              )}
                              {show_data.hasOwnProperty("self") && (
                                <>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.self_win_lose >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.self_win_lose)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-2 text-end text-dark">
                                    <b>
                                      {amountCommasSparated(
                                        Math.round(item?.self_commission)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-0 text-end text-dark">
                                    <div className="p-2 bg-warning">
                                      <b
                                        className={`${
                                          item?.self_total >= 0
                                            ? "text-dark"
                                            : "text-danger"
                                        }`}
                                      >
                                        {amountCommasSparated(
                                          Math.round(item?.self_total)
                                        )}
                                      </b>
                                    </div>
                                  </td>
                                </>
                              )}
                              {show_data.hasOwnProperty("upline") && (
                                <>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.upline_win_lose >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.upline_win_lose)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.upline_commission >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.upline_commission)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-0 text-end text-dark">
                                    <div className="p-2 bg-warning">
                                      <b
                                        className={`${
                                          item?.upline_total >= 0
                                            ? "text-dark"
                                            : "text-danger"
                                        }`}
                                      >
                                        {amountCommasSparated(
                                          Math.round(item?.upline_total)
                                        )}
                                      </b>
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            {
                              userId ?
                              <td colSpan={23} className="text-center">
                                No found data
                              </td>:
                              <td colSpan={23} className="text-center">
                                ရက်စွဲ ရွေးပြီး Submit ခလုပ်နှိပ်ပေးပါရှင့်
                              </td>
                            }
                          </tr>
                        )}
                      </tbody>
                      {reports?.length > 0 && (
                        <tfoot>
                          <tr
                            style={{
                              verticalAlign: "middle",
                            }}
                          >
                            <td
                              colSpan={3}
                              className="text-end text-white"
                              style={{ backgroundColor: "#25282a" }}
                            >
                              Total :
                            </td>
                            <td className="p-2 text-end text-dark">
                              <b>
                                {" "}
                                {reports
                                  ?.reduce(
                                    (a, v: stateObjType) =>
                                      (a = a + parseInt(v.total_bet_amount)),
                                    0
                                  )
                                  .toLocaleString()}
                              </b>
                            </td>
                            <td className="p-2 text-end text-dark">
                              <b>
                                {reports
                                  ?.reduce(
                                    (a, v: stateObjType) =>
                                      (a = a + parseInt(v.total_valid_amount)),
                                    0
                                  )
                                  .toLocaleString()}
                              </b>
                            </td>

                            {show_data.hasOwnProperty("member") && (
                              <>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.member_win_lose)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.member_win_lose)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-2 text-end text-dark">
                                  <b>
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a =
                                            a + parseInt(v.member_commission)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-0 text-end text-dark">
                                  <div className="p-2">
                                    <b
                                      className={`${
                                        reports?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.member_total)),
                                          0
                                        ) >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {reports
                                        ?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.member_total)),
                                          0
                                        )
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                </td>
                              </>
                            )}
                            {show_data.hasOwnProperty("self") && (
                              <>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.self_win_lose)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.self_win_lose)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-2 text-end text-dark">
                                  <b>
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.self_commission)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-0 text-end text-dark">
                                  <div className="p-2">
                                    <b
                                      className={`${
                                        reports?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.self_total)),
                                          0
                                        ) >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {reports
                                        ?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.self_total)),
                                          0
                                        )
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                </td>
                              </>
                            )}

                            {show_data.hasOwnProperty("upline") && (
                              <>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.upline_win_lose)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.upline_win_lose)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a, v: stateObjType) =>
                                          (a =
                                            a + parseInt(v.upline_commission)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a =
                                            a + parseInt(v.upline_commission)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-0 text-end text-dark">
                                  <div className="p-2">
                                    <b
                                      className={`${
                                        reports?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.upline_total)),
                                          0
                                        ) >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {reports
                                        ?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.upline_total)),
                                          0
                                        )
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
        <ScreenLoader isLoading={isLoading || isDownlineLoading} />
      </Template>
      <ToastContainer />
    </>
  );
};

export default WinLoseDetail;
