import classes from '../assets/styles/StatusToggle.module.css'

const StatusToggle = ({status,event}) => {
    const submitHandler = () => {
        event();
    };
    return (
        <div className={`${classes['switch-container']} mx-auto`}>
            <input type="checkbox" className={classes.checkbox} id='statusHandler' checked={status} onChange={submitHandler}/>
            <label className={classes.switch} htmlFor={'statusHandler'}>
                <span className={classes.slider}></span>
            </label>
        </div>
      )
    }

export default StatusToggle