import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Template from '../../utils/Template'
import TableScroll from '../../utils/TableScroll'
import TableLoading from '../../utils/TableLoading'
import FixtureToggle from '../../utils/FixtureToggle'
import { stateObjType } from '../../types'
import { HandleLogout } from '../../utils/Helper'
import { CONFIG_SETTING_API, CONFIG_SETTING_EDIT_API } from '../../services/constants'
import { getMethodService, putMethodService } from '../../services'
import { useNavigate } from 'react-router-dom'
import EditAppSettingModal from '../../utils/Modal/EditAppSettingModal'
import Swal from "sweetalert2";

const AppSetting = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [data,setData] = useState<stateObjType>({});
  const [appStatus, setAppStatus] = useState<any>(null);
  const navigate = useNavigate();
  const [versionEditModal, setVersionEditModal] = useState(false);
  const [playStoreEditModal,setPlayStoreEditModal] = useState(false);
  const [announcementEditModal, setAnnouncementEditModal] = useState(false);
  const [popupEditModal, setPopupEditModal] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const fetchAppSetting = () => {
      getMethodService(CONFIG_SETTING_API)
      .then((response) => {
          setIsLoading(false);
          if(response == 401){
              HandleLogout(navigate);
          };
          if(response?.status === 'success'){
              setData(response?.data);
              setAppStatus(response?.data?.is_enable_mobile)
          };
      })
      .catch((err) => console.error(err));
  };

  useEffect(()=>{
    fetchAppSetting();
  },[]);

  const appStatusHandler = (id:any,status:any) => {
    setAppStatus(0);
    if(!status){
        Swal.fire({
            input: 'textarea',
            inputLabel: 'အသိပေးချက်',
            width: '400px',
            inputPlaceholder: 'Remark',
            inputValidator: (value) => {
                if (!value) {
                  return 'You need to write something!'
                }
            },
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
                let data = {
                    is_enable_mobile: status? 1: 0,
                    disable_remark: result.value
                };

                setIsLoading(true);
                putMethodService(CONFIG_SETTING_EDIT_API, data, true)
                .then((response) => {
                    setIsLoading(false);
                    if(response == 401){
                        HandleLogout(navigate);
                    };
                    if (response?.status === "success") {
                        fetchAppSetting();
                        setVersionEditModal(false);
                    };
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.error(err);
                });

            }else if (result.dismiss === Swal.DismissReason.cancel) {
                setAppStatus(data?.is_enable_mobile);
            };
        });
    }else{
        let data = {
            is_enable_mobile: status? 1: 0,
            disable_remark: null
        };
        setIsLoading(true);
        putMethodService(CONFIG_SETTING_EDIT_API, data, true)
        .then((response) => {
            setIsLoading(false);
            if(response == 401){
              HandleLogout(navigate);
            };
            if (response?.status === "success") {
                fetchAppSetting();
                setVersionEditModal(false);
            };
        })
        .catch((err) => {
            setIsLoading(false);
            console.error(err);
        });
    };
  };

  const versionEditSubmit = (data:any) => {
        setIsLoading(true);
        putMethodService(CONFIG_SETTING_EDIT_API, {version: data},true)
        .then((response) => {
            setIsLoading(false);
            if(response == 401){
              HandleLogout(navigate);
            };
            if (response?.status === "success") {
                fetchAppSetting();
                setVersionEditModal(false);
            };
        })
        .catch((err) => {
            setIsLoading(false);
            console.error(err);
        });
  };

  const playStoreLinkEditSubmit = (data:any) => {
      setIsLoading(true);
      putMethodService(CONFIG_SETTING_EDIT_API, {playstore_link: data},true)
      .then((response) => {
          setIsLoading(false);
          if(response == 401){
            HandleLogout(navigate);
          };
          if (response?.status === "success") {
              fetchAppSetting();
              setPlayStoreEditModal(false);
          };
      })
      .catch((err) => {
          setIsLoading(false);
          console.error(err);
      });
  };

  const updateAnnouncement = (data:any) => {
      setIsLoading(true);
      putMethodService(CONFIG_SETTING_EDIT_API, {announcement: data},true)
      .then((response) => {
          setIsLoading(false);
          if(response == 401){
            HandleLogout(navigate);
          };
          if (response?.status === "success") {
              fetchAppSetting();
              setAnnouncementEditModal(false);
          };
      })
      .catch((err) => {
          setIsLoading(false);
          console.error(err);
      });
  };

  const updatePopup = (data:any) => {
      setIsLoading(true);
      putMethodService(CONFIG_SETTING_EDIT_API, {popup_announcement: data},true)
      .then((response) => {
          setIsLoading(false);
          if(response == 401){
            HandleLogout(navigate);
          };
          if (response?.status === "success") {
              fetchAppSetting();
              setPopupEditModal(false);
          };
      })
      .catch((err) => {
          setIsLoading(false);
          console.error(err);
      });
  };

  return (
    <>
      <Template title='Setting'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <h4 className="card-title">App Setting</h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                               <div>
                                  <table className="table text-white border table-bordered table-striped">
                                      <thead>
                                          <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                              <th className="p-2 text-center text-light">No</th>
                                              <th className="p-2 text-center text-light">Name</th>
                                              <th className="p-2 text-center text-light">Content</th>
                                              <th className="p-2 text-center text-light">Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              isLoading &&
                                              <TableLoading col={4} />
                                          }
                                          <tr style={{verticalAlign: 'middle'}}>
                                              <td className="p-2 text-center text-dark">1</td>
                                              <td className="p-2 text-center text-dark">Sports899</td>
                                              <td className="p-2 text-center text-dark">{data?.disable_remark ?? '--'}</td>
                                              <td className="p-2 text-center">
                                                  <div className='d-flex justify-content-center'>
                                                    <FixtureToggle id={data?.id} status={appStatus} event={appStatusHandler} data={null}/>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr style={{verticalAlign: 'middle'}}>
                                              <td className="p-2 text-center text-dark">2</td>
                                              <td className="p-2 text-center text-dark">Version</td>
                                              <td className="p-2 text-center text-dark">{data?.version}</td>
                                              <td className="p-2 text-center">
                                                  <button className="py-1 btn btn-success" style={{fontSize: '13px'}} onClick={()=>{
                                                        setVersionEditModal(true);
                                                        setInputValue(data?.version)
                                                    }}>Edit</button>
                                              </td>
                                          </tr>
                                          <tr style={{verticalAlign: 'middle'}}>
                                              <td className="p-2 text-center text-dark">3</td>
                                              <td className="p-2 text-center text-dark">PlayStore Link</td>
                                              <td className="p-2 text-center text-dark" style={{whiteSpace: 'normal'}}>{data?.playstore_link}</td>
                                              <td className="p-2 text-center">
                                                  <button className="py-1 btn btn-success" style={{fontSize: '13px'}} onClick={()=>{
                                                        setPlayStoreEditModal(true);
                                                        setInputValue(data?.playstore_link);
                                                    }}>Edit</button>
                                              </td>
                                          </tr>
                                          <tr style={{verticalAlign: 'middle'}}>
                                              <td className="p-2 text-center text-dark">5</td>
                                              <td className="p-2 text-center text-dark">Announcement</td>
                                              <td className="p-2 text-center text-dark" style={{whiteSpace: 'normal'}}>{data?.announcement}</td>
                                              <td className="p-2 text-center">
                                                  <button className="py-1 btn btn-success" style={{fontSize: '13px'}} onClick={()=>{
                                                        setAnnouncementEditModal(true);
                                                        setInputValue(data?.announcement);
                                                    }}>Edit</button>
                                              </td>
                                          </tr>
                                          <tr style={{verticalAlign: 'middle'}}>
                                              <td className="p-2 text-center text-dark">6</td>
                                              <td className="p-2 text-center text-dark">Popup</td>
                                              <td className="p-2 text-center text-dark" style={{whiteSpace: 'normal'}} dangerouslySetInnerHTML={{ __html: data?.popup_announcement }}/>
                                              <td className="p-2 text-center">
                                                  <button className="py-1 btn btn-success" style={{fontSize: '13px'}} onClick={()=>{
                                                        setPopupEditModal(true);
                                                        setInputValue(data?.popup_announcement);
                                                    }}>Edit</button>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                               </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
      </Template>
      <ToastContainer />
      {versionEditModal && (
          <EditAppSettingModal
              onClose={() => setVersionEditModal(false)}
              name="App Version"
              onSubmit={versionEditSubmit}
              isLoading={isLoading}
              state={inputValue}
              setState={setInputValue}
          />
      )}
      {playStoreEditModal && (
          <EditAppSettingModal
              onClose={() => setPlayStoreEditModal(false)}
              name="Edit PlayStore Link"
              onSubmit={playStoreLinkEditSubmit}
              isLoading={isLoading}
              state={inputValue}
              setState={setInputValue}
          />
      )}
      {announcementEditModal && (
          <EditAppSettingModal
              onClose={() => setAnnouncementEditModal(false)}
              name="Edit Announcement"
              onSubmit={updateAnnouncement}
              isLoading={isLoading}
              state={inputValue}
              setState={setInputValue}
              area={true}
          />
      )}
      {popupEditModal && (
          <EditAppSettingModal
              onClose={() => setPopupEditModal(false)}
              name="Edit Popup"
              onSubmit={updatePopup}
              isLoading={isLoading}
              state={inputValue}
              setState={setInputValue}
              quill={true}
          />
      )}
    </>
  )
}

export default AppSetting
