import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { stateObjType } from "../../types";

const EditAppSettingModal = (props:stateObjType) => {

    const handleSubmit = (e:any) => {
        e.preventDefault();
        props.onSubmit(props.state);
    }

    const inputHandleChange = (e:any) => {
        props.setState(e.target.value);
    }

    const [value, setValue] = useState('');

    const modules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', { 'list': 'ordered' }, { 'list': 'bullet' },'link','clean'],
      ],
    };
  return (
    <Modal isOpen={true} toggle={props.onClose}>
      <ModalHeader toggle={props.onClose}>Edit</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="amount">{props.name}</Label>
            {
              props.quill?
              <div className="quill-custom">
                <ReactQuill theme="snow" value={props.state} onChange={props.setState} style={{height:'10rem'}} modules={modules}/>
              </div> :
              (
                props.area?
                <Input type="textarea" rows={5} name="amount" value={props.state} id="amount" onChange={inputHandleChange}/>:
                <Input type="text" name="amount" value={props.state} id="amount" onChange={inputHandleChange}/>
              )
            }
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={props.onClose}>
          No
        </button>
        <button className="btn btn-primary" onClick={handleSubmit} disabled={props.isLoading}>
            {
                props.isLoading ? 'Uploading...': 'Save'
            }
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default EditAppSettingModal
