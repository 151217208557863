import React, { useEffect, useState } from "react";
import Template from "../utils/Template";
import DateWithStartTime from "../utils/DateWithStartTime";
import TableScroll from "../utils/TableScroll";
import TableLoading from "../utils/TableLoading";
import { stateObjType } from "../types";
import {
  HandleLogout,
  ISOdateFormatter,
  amountCommasSparated,
  decodeData,
  errorToaster,
} from "../utils/Helper";
import {
  THREE_D_TRANSACTIONS_API,
} from "../services/constants";
import { getMethodService } from "../services";
import { useNavigate } from "react-router-dom";
import Pagi from "../utils/Pagi";
const Cookies = require('js-cookie');

const WalletTransaction = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);

  const fetchData = async () => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
        const res = await getMethodService(
          `${THREE_D_TRANSACTIONS_API}?limit=50&page=${page}&start_date=${startDate}&end_date=${endDate}&usercode=${search}`, [], false, decode?.token
        );
        res && setIsLoading(false);
        if (res == 401) {
          HandleLogout(navigate);
        }
        if (res?.status === "success") {
          setData(res?.data);
          setTotalPage(res?.meta.pageCount);
          setFrom(page > 1 ? (page - 1) * 50 : 0);
        }
    }
  };

  useEffect(() => {
    search && fetchData();
  }, [page])

  const submitHandler = () => {
    setPage(1);
    if (search) {
      fetchData();
    } else {
      errorToaster("Please enter usercode to search");
    }
  }

  return (
    <>
      <Template title="MM 3D Wallet Transactions">
        <div className="card mb-3 mb-lg-5">
          <div className="card-header">
            <div className="row">
              <div className="col-lg-12">
                <div className="row align-items-sm-center justify-content-end">
                  <div className="col-lg-3">
                    <div className="mb-3 mb-lg-0">
                      <label className="form-label mb-1">Search</label>
                      <input
                        type="text"
                        className="border form-control"
                        style={{ padding: "10px" }}
                        placeholder="search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div>
                      <DateWithStartTime
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <button
                      className="btn btn-primary w-100 mt-4"
                      onClick={submitHandler}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading ..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table className="table text-white border table-bordered table-striped">
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "#25282a",
                            verticalAlign: "middle",
                          }}
                        >
                          <th className="p-2 text-center text-light">No</th>
                          <th className="p-2 text-center text-light">Before</th>
                          <th className="p-2 text-center text-light">Amount</th>
                          <th className="p-2 text-center text-light">After</th>
                          <th className="p-2 text-center text-light">Type</th>
                          <th className="p-2 text-center text-light">Remark</th>
                          <th className="p-2 text-center text-light">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && <TableLoading col={8} />}
                        {data?.length > 0 ? (
                          data?.map((el, i) => (
                            <tr key={i}>
                              <td className="p-2 text-center text-dark">
                                {i + from + 1}
                              </td>
                              <td className="p-2 text-end text-dark">
                                {amountCommasSparated(el?.before ?? 0)}
                              </td>
                              <td className="p-2 text-end text-dark">
                                {amountCommasSparated(el?.amount ?? 0)}
                              </td>
                              <td className="p-2 text-end text-dark">
                                {amountCommasSparated(el?.after ?? 0)}
                              </td>
                              <td className="p-2 text-center text-dark">
                                <span
                                  className={`badge bg-${
                                    el?.type === "increase"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {el?.type}
                                </span>
                              </td>
                              <td className="p-2 text-center text-dark">
                                <span className={`${el?.transaction_type_id === 8 ? 'text-danger' : el.transaction_type_id === 9 ? 'text-success' : ''}`}>
                                  {el?.transaction_type_id === 8 && '3D ထိုးကြေး'}
                                  {el?.transaction_type_id === 9 && '3D လျော်ငွေ'}
                                </span>
                              </td>
                              <td className="p-2 text-center text-dark">
                                {ISOdateFormatter(el?.created_at)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </TableScroll>
              </div>
              <div className="col-sm-12">
                {totalPage > 1 && (
                  <Pagi
                    setPage={setPage}
                    setIsLoading={setIsLoading}
                    totalPage={totalPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Template>
    </>
  );
};

export default WalletTransaction;
