import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Template from '../../utils/Template'
import TableScroll from '../../utils/TableScroll'
import TableLoading from '../../utils/TableLoading'
import FixtureToggle from '../../utils/FixtureToggle'
import { stateObjType } from '../../types'
import { HandleLogout, decodeData } from '../../utils/Helper'
import { CONFIG_SETTING_API, CONFIG_SETTING_EDIT_API, POSTS, POSTS_DETAIL } from '../../services/constants'
import { deleteMethodService, getMethodService, postMethodService, putMethodService } from '../../services'
import { useNavigate } from 'react-router-dom'
import EditAppSettingModal from '../../utils/Modal/EditAppSettingModal'
import Swal from "sweetalert2";
import { useQuery } from '@tanstack/react-query'
import Pagi from '../../utils/Pagi'
const Cookies = require('js-cookie');
const SweetAlert = require('sweetalert2');

const News = () => {
  const abortController = new AbortController();
  const [isLoading,setIsLoading] = useState(false);
  const [posts,setPosts] = useState<stateObjType>([]);
  const navigate = useNavigate();
  const [refreshKey,setRefreshKey] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<any>(1);
  const [page, setPage] = useState<any>(1);
  const [from, setFrom] = useState<any>(1);
  

  const fetchPosts = () => {
    const getAuth = Cookies.get('n_a_u');
    if(getAuth){
        let decode = decodeData(getAuth);
        return getMethodService(`${POSTS}?source=dashboard&perPage=20&page=${page}`,{},false,decode?.token,abortController);
    };
  }
    const postsQuery = useQuery({ queryKey: ['posts',page,refreshKey], queryFn: fetchPosts,refetchOnWindowFocus: false});

    useEffect(() => {
        if(postsQuery){
            setIsLoading(postsQuery.isLoading);
            if(postsQuery?.data == 401){
                HandleLogout(navigate);
            };
            if(postsQuery?.data?.status === "success"){
                let totalPages = Number(Math.ceil((postsQuery?.data?.meta?.total) / (postsQuery?.data?.meta?.perPage)));
                let fromPages = Number(((postsQuery?.data?.meta?.perPage)) * Number((postsQuery?.data?.meta?.page)) -  Number(postsQuery?.data?.meta?.perPage));
                setPosts(postsQuery?.data?.data);
                setFrom(fromPages + 1);
                setTotalPage(totalPages);
            };
        };
    }, [postsQuery]);

    const deletePostHandler = (id:any) => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            SweetAlert.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm!',
                reverseButtons: true
            }).then((result: any) => {
                if (result.isConfirmed) {
                    deleteMethodService(POSTS_DETAIL(id), true,decode?.token)
                    .then((res) => {
                        if(res == 401){
                            HandleLogout(navigate);
                        };
                        if(res.status || (res.status === "success")){
                            setRefreshKey(prevKey => prevKey + 1);
                        };
                    })
                };
            });
        };
    };

  return (
    <>
      <Template title='Social'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-6">
                            <h4 className="card-title">News</h4>
                        </div>
                        <div className='col-sm-6'>
                            <div className='d-flex justify-content-end'>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => navigate('/community/create-news')}
                                >
                                    Create News
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                               <div>
                                  <table className="table text-white border table-bordered table-striped">
                                      <thead>
                                          <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                              <th className="p-2 text-center text-light">No</th>
                                              <th className="p-2 text-center text-light">Title</th>
                                              <th className="p-2 text-center text-light">Image</th>
                                              <th className="p-2 text-center text-light">Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              isLoading &&
                                              <TableLoading col={4} />
                                          }
                                          {
                                            posts?.length > 0?
                                            posts?.map((post:any, i:number) => 
                                                <tr key={post?.id} style={{verticalAlign: 'middle'}}>
                                                    <td className="p-2 text-center text-dark">{from + i}</td>
                                                    <td className="p-2 text-center text-dark">{
                                                        post?.title?
                                                        post?.title:
                                                        '--'
                                                    }</td>
                                                     <td className="p-2 text-center text-dark">
                                                        <img src={post?.image} style={{width: '50px', height: '35px', borderRadius: '3px'}} />
                                                     </td>
                                                    <td className="p-2 text-center">
                                                        <button className="py-1 me-2 btn btn-success" style={{fontSize: '13px'}} onClick={() => navigate(`/community/edit-news/${post?.id}`)}>Edit</button>
                                                        <button className="py-1 btn btn-danger" style={{fontSize: '13px'}} onClick={() => deletePostHandler(post?.id)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td colSpan={4} className='text-center'>No found data</td>
                                            </tr>
                                          }
                                      </tbody>
                                  </table>
                               </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
                {totalPage > 1 && (
                    <div className="card-footer">
                        <div className="row justify-content-end">
                            <div>
                                <Pagi
                                    setIsLoading={setIsLoading}
                                    setPage={setPage}
                                    totalPage={totalPage}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
      </Template>
      <ToastContainer />
      
    </>
  )
}

export default News
