import { createSlice } from "@reduxjs/toolkit";

const dateSlice = createSlice({
    name: 'store_date',
    initialState: {
        store_date: {},
    },
    reducers: {
        dateHandler: (state,action) => {
            state.store_date = action.payload
        }
    }
});

export const {dateHandler} = dateSlice.actions;
export default dateSlice.reducer;