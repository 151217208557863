import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangeType } from '../types';

const DateRangeFormatForTransaction = ({startDate,endDate,setStartDate, setEndDate}:DateRangeType) => {
    const [dateType,setDateType] = useState("");

    function currentDate() {
      const currentTime = moment();
      const targetTime = moment().set({ hour: 10, minute: 29, second: 0, millisecond: 0 });
      if (currentTime.isBefore(targetTime)) {
        let start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        let end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
      }else{
        let start_date = moment().format('YYYY-MM-DD');
        let end_date = moment().format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
      };
    };

    function yesterdayDate() {
        const yesterdayTime = moment();
        const targetTime = moment().set({ hour: 10, minute: 29, second: 0, millisecond: 0 });
        if(yesterdayTime.isBefore(targetTime)){
          let start_date = moment().subtract(2, 'days').format('YYYY-MM-DD');
          let end_date = moment().subtract(2, 'days').format('YYYY-MM-DD');
          setStartDate(start_date);
          setEndDate(end_date);
        }else{
          let start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
          let end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
          setStartDate(start_date);
          setEndDate(end_date);
        };
    };

    function thisWeek() {
        let start_date = moment().startOf('isoWeek').format('YYYY-MM-DD');
        let end_date = moment().endOf('isoWeek').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
    };

    function lastWeek() {
        let start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
        let end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
        // let curr = new Date;
        // let first = curr.getDate() - curr.getDay() - 7;
        // let last = first + 6;

        // let firstday = new Date(curr.setDate(first)).toUTCString();
        // let lastday = new Date(curr.setDate(last)).toUTCString();
        // setStartDate(formatDate(firstday));
        // setEndDate(formatDate(lastday));
        // console.log(`Last Week first ${formatDate(firstday)} second ${formatDate(lastday)}`);
    };

    function thisMonth() {
        let start_date = moment().startOf('month').format('YYYY-MM-DD');
        let end_date = moment().endOf('month').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
    };

    function lastMonth() {
      let start_date = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      let end_date = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(start_date);
      setEndDate(end_date);
    }



    useEffect(()=>{
       switch(dateType){
          case '1':
             return currentDate();
          case '2':
            return yesterdayDate();
          case '3':
            return thisWeek();
          case '4':
            return lastWeek();
          case '5':
            return thisMonth();
          case '6':
            return lastMonth();
          default:
            return ;
       }
    },[dateType])

  return (
      <div className="row">
         <div className="mb-3 col-lg-4 mb-lg-0">
              <label className="form-label mb-1">Start date</label>
              <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
         </div>
         <div className="mb-3 col-lg-4 mb-lg-0">
              <label className="form-label mb-1">End date</label>
              <input type="date" className='form-control' value={endDate} onChange={e => setEndDate(e.target.value)}/>
         </div>
         <div className="mb-3 col-lg-4 mb-lg-0">
            <label className="form-label mb-1">Find</label>
            <select className="form-select form-select-lg w-100" onChange={e => setDateType(e.target.value)}>
                  <option value="">Select</option>
                  <option value="1">Today</option>
                  <option value="2">Yesterday</option>
                  <option value="3" selected>This Week</option>
                  <option value="4">Last Week</option>
                  <option value="5">This Month</option>
                  <option value="6">Last Month</option>
              </select>
         </div>
      </div>
  )
}

export default DateRangeFormatForTransaction;
