import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import TableScroll from '../utils/TableScroll'
import { useQuery } from '@tanstack/react-query'
import { getMethodService } from '../services'
import { THREE_D_BET_SLIPS_NODE_API } from '../services/constants'
import TableLoading from '../utils/TableLoading'
import { HandleLogout, amountCommasSparated, decodeData } from '../utils/Helper'
import { stateObjType } from '../types';
const Cookies = require('js-cookie');

const ThreedStockMemberListDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data,setData] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const start_date_v2 = params?.start_date && `${params.start_date}`;
  const end_date_v2 = params?.end_date && `${params.end_date}`;
  
  const fetchMemberListDetail = (id: number | string | undefined,status: string | undefined,startDate: string | undefined,endDate : string | undefined) => {
    const getAuth = Cookies.get('n_a_u');
    if(getAuth){
        let decode = decodeData(getAuth);
        return getMethodService(`${THREE_D_BET_SLIPS_NODE_API}?user_id=${id}${status !== 'all' ? `&status=${status}`: ''}&sortDirection=desc&sortColumn=id&start_date=${startDate}&end_date=${endDate}&page=1&limit=1000`,{},false,decode?.token);
    };
    
  };
  const memberListDetailQuery = useQuery({ queryKey: ['memberOutstand',params.id,params.status,start_date_v2,end_date_v2], queryFn: () => fetchMemberListDetail(params.id,params.status,start_date_v2,end_date_v2), refetchOnWindowFocus: false});
  useEffect(()=>{
     if(memberListDetailQuery){
        console.log(memberListDetailQuery?.data?.data)
        setIsLoading(memberListDetailQuery.isLoading);
        if(memberListDetailQuery?.data == 401){
            HandleLogout(navigate);
        };
        if(memberListDetailQuery?.data?.status === "success"){
            setData(memberListDetailQuery?.data?.data);
        };
     }
  },[memberListDetailQuery]);

  return (
       <Template title='Stock Management'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <h4 className="card-title">
                                User Bet List - {params.id}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <table className="table text-white border table-bordered table-striped">
                                    <thead>
                                        <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                            <th className="p-2 text-center text-light">No</th>
                                            <th className="p-2 text-center text-light">Member</th>
                                            <th className="p-2 text-center text-light">Date</th>
                                            <th className="p-2 text-center text-light">Digits</th>
                                            <th className="p-2 text-center text-light">Amount</th>
                                            <th className="p-2 text-center text-light">Status</th>
                                            <th className="p-2 text-center text-light">Payout</th>
                                            <th className="p-2 text-center text-light">Net Profit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading &&
                                            <TableLoading col={10} />
                                        }
                                        {
                                            data?.length > 0 ?
                                            data?.map((list:stateObjType,i:number) =>
                                                <tr style={{verticalAlign: 'middle'}} key={i}>
                                                    <td className="p-2 text-center text-dark">{i+1}</td>
                                                    <td className="p-2 text-center text-dark">
                                                        {list?.users?.usercode}
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        <p className="mb-0">{list.created_at}</p>
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {list?.three_digits?.number}
                                                    </td>
                                                    <td className="p-2 text-end text-dark">
                                                        <b>
                                                            {
                                                                amountCommasSparated(list?.amount ?? 0)
                                                            }
                                                        </b>
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        <span className={`badge bg-${list?.bet_result === 'unsettled'? 'warning': (list?.bet_result === 'lose')? 'danger': 'success'}`}>
                                                            {list?.bet_result}
                                                        </span>
                                                    </td>
                                                    <td className="p-2 text-end text-dark">
                                                        <b>
                                                            {
                                                                list?.bet_result === "unsettled"? '-' :
                                                                amountCommasSparated(list?.payout_amount)
                                                            }
                                                        </b>
                                                    </td>
                                                    <td className="p-2 text-end text-dark">
                                                        <b>
                                                            {
                                                                list?.bet_result === "unsettled"? '-' :
                                                                amountCommasSparated(list?.payout_amount - list?.amount)
                                                            }
                                                        </b>
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td colSpan={10} className='text-center'>No found data</td>
                                            </tr>
                                        }
                                    </tbody>
                                    {
                                        data.length > 0 &&
                                        <tfoot>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                <td colSpan={4} className='text-end text-white' style={{fontSize: '13px'}}>Total :</td>
                                                <td style={{fontSize: '13px'}} className="text-end text-white p-2"><b>{data.reduce((a:any,v:any) =>  a = a + v?.amount , 0 ).toLocaleString()}</b></td>
                                                <td></td>
                                                <td style={{fontSize: '13px'}} className="text-end text-white p-2"><b>{data.reduce((a:any,v:any) =>  a = a + v?.payout_amount , 0 ).toLocaleString()}</b></td>
                                                <td style={{fontSize: '13px'}} className="text-end text-white p-2"><b>{data.reduce((a:any,v:any) =>  a = a + v?.payout_amount - v?.amount , 0 ).toLocaleString()}</b></td>
                                            </tr>
                                        </tfoot>
                                        }
                                </table>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
       </Template> 
  )
}

export default ThreedStockMemberListDetail