import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { useQuery } from '@tanstack/react-query';
import { getMethodService } from '../services';
import { AUTH_USER_WITH_COMM_AND_SETTINGS } from '../services/constants';
import { HandleLogout } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
const profileBgImg = require('../assets/images/background/card-6.svg');
const profileImg = require('../assets/images/icons/img6.jpg');

const Profile = () => {
  const [userInfo, setUserInfo] = useState<any>([]);
  const navigate = useNavigate();
  const fetchInfo = () => getMethodService(AUTH_USER_WITH_COMM_AND_SETTINGS);
  const infoQuery = useQuery({ queryKey: ['info'], queryFn: fetchInfo});

    useEffect(()=>{
        if(infoQuery?.data == 401){
            HandleLogout(navigate);
        };
        if(infoQuery?.data?.status){
            setUserInfo(infoQuery?.data?.data);
        };
    },[infoQuery]);

    const filterCommissions = (current_user:any,key:any) =>{
        let category_id:any = null;
        let game_type_id:any = null;
        let min_count:any = null;
        let max_count:any = null;
        switch (key) {
            case 'commissionSM':
                category_id = 1;
                game_type_id = 1;
                min_count = 1;
                max_count = 1;
                break;
            case 'commissionLG':
                category_id = 2;
                game_type_id = 1;
                min_count = 1;
                max_count = 1;
                break;
            case 'perlay2':
                category_id = 3;
                game_type_id = 1;
                min_count = 2;
                max_count = 2;
                break;
            case 'perlay38':
                category_id = 3;
                game_type_id = 1;
                min_count = 3;
                max_count = 8;
                break;
            case 'perlay911':
                category_id = 3;
                game_type_id = 1;
                min_count = 9;
                max_count = 11;
                break;
            case 'twoDCommission':
                category_id = null;
                game_type_id = 2;
                min_count = 1;
                max_count = 1;
                break;
            case 'threeDCommission':
                category_id = null;
                game_type_id = 3;
                min_count = 1;
                max_count = 1;
                break;
        }
        return current_user ? current_user?.agent_commissions?.find((element:any)=>{ return (element.game_type_id == game_type_id && element.mm_football_category_id == category_id && element.min_perlay_count == min_count && element.max_perlay_count == max_count)}) : null

    };
  return (
    <>
       <Template title="Account">
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <h4 className="card-title">
                                Profile
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className='col-sm-12'>
                            <div className="row justify-content-center">
                                <div className="col-lg-8 text-center">
                                    <div style={{
                                        backgroundImage: `url(${profileBgImg.default})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        borderRadius: '10px',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}>
                                    </div>
                                    <div style={{
                                        marginTop: '-120px'
                                    }}>
                                        <img className="avatar-img rounded-circle shadow-lg mb-2" style={{
                                            width: '150px',
                                            border: '4px solid rgb(235 235 235)'
                                        }} src={profileImg} alt="Image Description" />
                                        <h4 className="text-dark mb-1" style={{fontSize: '18px'}}>{userInfo?.usercode} {userInfo?.name && `( ${userInfo?.name} )`}</h4>
                                        <p className="text-dark mb-3" style={{fontSize: '16px'}}>{userInfo?.wallet?.amount?.toLocaleString('en-US') + ` Unit`}</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 p-1">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title text-start">Information</h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">2D Min/Max Per Bet</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{userInfo?.bet_setting?.two_d_min_per_bet?.toLocaleString('en-US')} / {userInfo?.bet_setting?.two_d_max_per_bet?.toLocaleString('en-US')}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">2D Limit</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{userInfo?.bet_setting?.two_d_limit?.toLocaleString('en-US')}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">3D Min/Max Per Bet</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{userInfo?.bet_setting?.three_d_min_per_bet?.toLocaleString('en-US')} / {userInfo?.bet_setting?.three_d_max_per_bet?.toLocaleString('en-US')}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">3D Limit</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{userInfo?.bet_setting?.three_d_limit?.toLocaleString('en-US')}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">Min/Max Body Bet Limit</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{userInfo?.bet_setting?.min_bet_limit?.toLocaleString('en-US')} / {userInfo?.bet_setting?.max_single_bet_limit?.toLocaleString('en-US')}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">Match Limit</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{userInfo?.bet_setting?.match_limit?.toLocaleString('en-US')}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">Min/Max Perlay Bet Limit</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{userInfo?.bet_setting?.min_myanmar_perlay_limit_per_combination?.toLocaleString('en-US')} / {userInfo?.bet_setting?.max_myanmar_perlay_limit_per_combination?.toLocaleString('en-US')}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">2D Commission</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{filterCommissions(userInfo,'twoDCommission')?.percent } %</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">3D Commission</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{filterCommissions(userInfo,'threeDCommission')?.percent } %</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">Commission ( ပွဲသေး )</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{filterCommissions(userInfo,'commissionSM')?.percent } %</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">Commission ( ပွဲကြီး )</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{filterCommissions(userInfo,'commissionLG')?.percent } %</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">Commission Perlay ( 2 )</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{filterCommissions(userInfo,'perlay2')?.percent } %</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">Commission Perlay ( 3-8 )</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{filterCommissions(userInfo,'perlay38')?.percent } %</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label htmlFor="" className="text-start col-sm-4 col-form-label form-label">Commission Perlay ( 9-11 )</label>
                                                        <div className="col-sm-8">
                                                            <div className="text-end text-dark">
                                                                <span>
                                                                    <b>{filterCommissions(userInfo,'perlay911')?.percent } %</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </Template>
    </>
  )
}

export default Profile
