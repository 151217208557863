import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AutoBetToggle from "../AutoBetToggle";
import '../../assets/styles/TimePicker.css';
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { errorToaster } from "../Helper";
import { CONVERT_MM_ODDS_API } from "../../services/constants";
import { getMethodService } from "../../services";
import { useQuery } from "@tanstack/react-query";

function EditFixtureModal(props:any) {
    const data = props.data;
    const [loading, setLoading] = useState<any>(props.loading);
    const [leagueMy, setLeagueMy] = useState<any>("");
    const [homeMy, setHomeMy] = useState<any>("");
    const [awayMy, setAwayMy] = useState<any>("");
    const [manual, setManual] = useState<any>(null);

    useEffect(()=>{
        if(data){
            data.league_data? setLeagueMy(data.league_data.name_mm): setLeagueMy("")
            data.home_team_data? setHomeMy(data.home_team_data.name_mm): setHomeMy("")
            data.away_team_data? setAwayMy(data.away_team_data.name_mm): setAwayMy("")
            data.is_manual && setManual(data.is_manual);
        }
    },[data]);

    const league = data.league_data.name;
    const home = data.home_team_data
    const away = data.away_team_data

    const first_half_body_arr = splitOdds(data?.first_half_body);
    const first_half_total_arr = splitOdds(data?.first_half_total);
    const body_arr = splitOdds(data?.body);
    const total_arr = splitOdds(data?.total);

    const [bodySymbol1, setBodySymbol1] = useState<any>(body_arr[0]);
    const [bodySymbol2, setBodySymbol2] = useState<any>(body_arr[2]);
    const [bodySymbol3, setBodySymbol3] = useState<any>(body_arr[1]);
    const [bodySymbolFirstHalf1, setBodySymbolFirstHalf1] = useState<any>(first_half_body_arr[0]);
    const [bodySymbolFirstHalf2, setBodySymbolFirstHalf2] = useState<any>(first_half_body_arr[2]);
    const [bodySymbolFirstHalf3, setBodySymbolFirstHalf3] = useState<any>(first_half_body_arr[1]);

    const [totalSymbol1, setTotalSymbol1] = useState<any>(total_arr[0]);
    const [totalSymbol2, setTotalSymbol2] = useState<any>(total_arr[2]);
    const [totalSymbol3, setTotalSymbol3] = useState<any>(total_arr[1]);
    const [totalSymbolFirstHalf1, setTotalSymbolFirstHalf1] = useState<any>(first_half_total_arr[0]);
    const [totalSymbolFirstHalf2, setTotalSymbolFirstHalf2] = useState<any>(first_half_total_arr[2]);
    const [totalSymbolFirstHalf3, setTotalSymbolFirstHalf3] = useState<any>(first_half_total_arr[1]);

    const dateString = moment.unix(data.fixture_timestamp).format("MM-DD-YYYY");
    const timeString = moment.unix(data.fixture_timestamp).format("hh:mm:ss A");
    const convertedTime = moment(timeString, 'hh:mm A').format('HH:mm:ss');
    const [dateTime,setDateTime] = useState<any>(`${dateString} ${convertedTime}`);
    const formatTimeStemp = moment(dateTime).format("X");
    const [footballCategory, setFootballCategory] = useState(data?.mm_football_category?.id?? null);
    const [firstHalfStatusToggle,setFirstHalfStatusToggle] = useState<any>(data?.is_disable_first_half ? 1: 0);


    const fetchMMOdds = () => getMethodService(CONVERT_MM_ODDS_API(data?.fixture_id));
    const mmOddsQuery = useQuery({ queryKey: ['mmodds'], queryFn: fetchMMOdds, refetchOnWindowFocus: false});
    useEffect(() => {
        if(mmOddsQuery?.data?.status === "success"){
            let data = mmOddsQuery?.data?.data;
            if(firstHalfStatusToggle){
                setBodySymbolFirstHalf1(null);
                setBodySymbolFirstHalf2(null);
                setBodySymbolFirstHalf3(null);
                setTotalSymbolFirstHalf1(null);
                setTotalSymbolFirstHalf2(null);
                setTotalSymbolFirstHalf3(null);
            }else{
                setBodySymbolFirstHalf1(first_half_body_arr[0]);
                setBodySymbolFirstHalf2(first_half_body_arr[2]);
                setBodySymbolFirstHalf3(first_half_body_arr[1]);
                setTotalSymbolFirstHalf1(first_half_total_arr[0]);
                setTotalSymbolFirstHalf2(first_half_total_arr[2]);
                setTotalSymbolFirstHalf3(first_half_total_arr[1]);
            };

            if(firstHalfStatusToggle){
                setBodySymbolFirstHalf1(null);
                setBodySymbolFirstHalf2(null);
                setBodySymbolFirstHalf3(null);
                setTotalSymbolFirstHalf1(null);
                setTotalSymbolFirstHalf2(null);
                setTotalSymbolFirstHalf3(null);
            }else{
                setBodySymbolFirstHalf1(data?.first_half?.body[0]);
                setBodySymbolFirstHalf2(data?.first_half?.body[1]);
                setBodySymbolFirstHalf3(data?.first_half?.body[2]);
                setTotalSymbolFirstHalf1(data?.first_half?.over_under[0]);
                setTotalSymbolFirstHalf2(data?.first_half?.over_under[1]);
                setTotalSymbolFirstHalf3(data?.first_half?.over_under[2]);
            };
        };
    }, [mmOddsQuery,firstHalfStatusToggle]);


    const updateFootballFixture = () => {
        if(!firstHalfStatusToggle){
            if(!bodySymbolFirstHalf1 && !bodySymbolFirstHalf2 && !bodySymbolFirstHalf3){
                return errorToaster("First half odds is required!");
            };
            if(!totalSymbolFirstHalf1 && !totalSymbolFirstHalf2 && !totalSymbolFirstHalf3){
                return errorToaster("First half total odds is required!"); 
            }
        }
        const request_json = {
            fixture_id: data.fixture_id,
            home_team_data:{
                id: data.home_team_data.id,
                name: home.name,
                name_mm: homeMy? homeMy: home.name,
                cc: home.cc,
                image_id: home.image_id
            },
            away_team_data:{
                id: data.away_team_data.id,
                name: away.name,
                name_mm: awayMy? awayMy: away.name,
                cc: away.cc,
                image_id: away.image_id
            },
            league_data:{
                id: data.league_data.id,
                name: league,
                name_mm:leagueMy? leagueMy: league,
            },
            first_half_body_symbol1: bodySymbolFirstHalf1,
            first_half_body_symbol2: bodySymbolFirstHalf2,
            first_half_body_symbol3: bodySymbolFirstHalf3,
            first_half_total_symbol1: totalSymbolFirstHalf1,
            first_half_total_symbol2: totalSymbolFirstHalf2,
            first_half_total_symbol3: totalSymbolFirstHalf3,
            body_symbol1: bodySymbol1,
            body_symbol2: bodySymbol2,
            body_symbol3: bodySymbol3,
            total_symbol1: totalSymbol1,
            total_symbol2: totalSymbol2,
            total_symbol3: totalSymbol3,
            is_manual: manual,
            is_published: data.is_published,
            mm_football_category_id: footballCategory,
            fixture_timestamp: Number(formatTimeStemp),
        };
        props.onSubmit(request_json, data.id);
    };

    function splitOdds(odds:any) {
        let oddsArray = [];
        if (odds.includes("+")) {
            oddsArray = odds.split("+");
            oddsArray.push("+");
        } else if (odds.includes("-")) {
            oddsArray = odds.split("-");
            oddsArray.push("-");
        } else if (odds.includes("=")) {
            oddsArray = odds.split("=");
            oddsArray.push("=");
        }
        return oddsArray;
    };

    const fixtureToggleHandler = (status:number) => {
        setManual(status? 1: 0)
    };

    const firstHalfToggleHandler = (status:any) => {
        setFirstHalfStatusToggle(status? 1: 0)
    };

    return (
        <>
            <Modal isOpen={true} toggle={props.onClose}>
                <ModalHeader toggle={props.onClose}>ကြေးပြင်ရန် {" "}</ModalHeader>
                <ModalBody>
                    <div>
                        <div className="row mb-4">
                            <label
                                htmlFor="firstNameLabel"
                                className="col-sm-4 col-form-label form-label"
                            >
                                လိဂ်
                            </label>

                            <div className="col-sm-8">
                                <div className="input-group input-group-sm-vertical position-relative">
                                    <input
                                        type="text"
                                        className="form-control mb-1"
                                        value={leagueMy}
                                        placeholder="လိဂ် နာမည်ထည့်ပါ"
                                        disabled={loading}
                                        onChange={(e) =>
                                            setLeagueMy(e.target.value)
                                        }
                                    />
                                    {
                                        loading &&
                                        <div className="spinner-border text-secondary m-0 rounded-circle" role="status"
                                            style={{
                                                position: 'absolute',
                                                width: '25px',
                                                height: '25px',
                                                right: '10px',
                                                top: '10px'
                                            }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                </div>
                                <div className="input-group input-group-sm-vertical">
                                    <input
                                        type="text"
                                        disabled
                                        className="form-control"
                                        value={league}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label
                                htmlFor="firstNameLabel"
                                className="col-sm-4 col-form-label form-label"
                            >
                                အိမ်ကွင်း
                            </label>

                            <div className="col-sm-8">
                                <div className="input-group input-group-sm-vertical position-relative">
                                    <input
                                        type="text"
                                        className="form-control mb-1"
                                        value={homeMy}
                                        disabled={loading}
                                        placeholder="အိမ်ကွင်း နာမည်ထည့်ပါ"
                                        onChange={(e) =>
                                            setHomeMy(e.target.value)
                                        }
                                    />
                                    {
                                        loading &&
                                        <div className="spinner-border text-secondary m-0 rounded-circle" role="status"
                                            style={{
                                                position: 'absolute',
                                                width: '25px',
                                                height: '25px',
                                                right: '10px',
                                                top: '10px'
                                            }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                </div>
                                <div className="input-group input-group-sm-vertical">
                                    <input
                                        type="text"
                                        disabled
                                        className="form-control"
                                        value={home.name}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label
                                htmlFor="firstNameLabel"
                                className="col-sm-4 col-form-label form-label"
                            >
                                အဝေးကွင်း
                            </label>

                            <div className="col-sm-8">
                                <div className="input-group input-group-sm-vertical position-relative">
                                    <input
                                        type="text"
                                        className="form-control mb-1"
                                        value={awayMy}
                                        disabled={loading}
                                        placeholder="အဝေးကွင်း နာမည်ထည့်ပါ"
                                        onChange={(e) =>
                                            setAwayMy(e.target.value)
                                        }
                                    />
                                    {
                                        loading &&
                                        <div className="spinner-border text-secondary m-0 rounded-circle" role="status"
                                            style={{
                                                position: 'absolute',
                                                width: '25px',
                                                height: '25px',
                                                right: '10px',
                                                top: '10px'
                                            }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                </div>
                                <div className="input-group input-group-sm-vertical">
                                    <input
                                        type="text"
                                        disabled
                                        className="form-control"
                                        value={away.name}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                            <AutoBetToggle id="first_half_status_edit" status={firstHalfStatusToggle} event={firstHalfToggleHandler}  />
                        </div>
                        {
                            !firstHalfStatusToggle &&
                            <>
                                <div className="row mb-4">
                                    <label
                                        htmlFor="firstNameLabel"
                                        className="col-sm-4 col-form-label form-label"
                                    >
                                        ဘော်ဒီ ( ပပိုင်း ) <span className={`badge bg-${data.odds_team === "home"?'dark':'warning'}`}>{data.odds_team}</span>
                                    </label>

                                    <div className="col-sm-8">
                                        <div className="input-group input-group-warning input-group-sm-vertical">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="1"
                                                onChange={(event) => {
                                                    setBodySymbolFirstHalf1(event.target.value);
                                                }}
                                                value={bodySymbolFirstHalf1}
                                            />
                                            <select
                                                className="form-control"
                                                onChange={(e) => {
                                                    setBodySymbolFirstHalf2(e.target.value);
                                                }}
                                                value={bodySymbolFirstHalf2}
                                            >
                                                <option>Choose</option>
                                                <option value="+">+</option>
                                                <option value="=">=</option>
                                                <option value="-">-</option>
                                            </select>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="30"
                                                value={bodySymbolFirstHalf3}
                                                onChange={(e) => {
                                                    setBodySymbolFirstHalf3(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label
                                        htmlFor="firstNameLabel"
                                        className="col-sm-4 col-form-label form-label"
                                    >
                                        ဂိုးပေါင်း ( ပပိုင်း )
                                    </label>

                                    <div className="col-sm-8">
                                        <div className="input-group input-group-warning input-group-sm-vertical">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="2"
                                                value={totalSymbolFirstHalf1}
                                                onChange={(e) => {
                                                    setTotalSymbolFirstHalf1(e.target.value);
                                                }}
                                            />
                                            <select
                                                className="form-control"
                                                onChange={(e) => {
                                                    setTotalSymbolFirstHalf2(e.target.value);
                                                }}
                                                value={totalSymbolFirstHalf2}
                                            >
                                                <option>Choose</option>
                                                <option value="+">+</option>
                                                <option value="=">=</option>
                                                <option value="-">-</option>
                                            </select>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="30"
                                                value={totalSymbolFirstHalf3}
                                                onChange={(e) => {
                                                    setTotalSymbolFirstHalf3(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <hr />
                        <div className="row mb-4">
                            <label
                                htmlFor="firstNameLabel"
                                className="col-sm-4 col-form-label form-label"
                            >
                                ဘော်ဒီ ( ပွဲပြီး ) <span className={`badge bg-${data.odds_team === "home"?'dark':'warning'}`}>{data.odds_team}</span>
                            </label>

                            <div className="col-sm-8">
                                <div className="input-group input-group-warning input-group-sm-vertical">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="2"
                                        onChange={(event) => {
                                            setBodySymbol1(event.target.value);
                                        }}
                                        value={bodySymbol1}
                                    />
                                    <select
                                        className="form-control"
                                        onChange={(e) => {
                                            setBodySymbol2(e.target.value);
                                        }}
                                        value={bodySymbol2}
                                    >
                                        <option>Choose</option>
                                        <option value="+">+</option>
                                        <option value="=">=</option>
                                        <option value="-">-</option>
                                    </select>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="60"
                                        value={bodySymbol3}
                                        onChange={(e) => {
                                            setBodySymbol3(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label
                                htmlFor="firstNameLabel"
                                className="col-sm-4 col-form-label form-label"
                            >
                                ဂိုးပေါင်း ( ပွဲပြီး )
                            </label>

                            <div className="col-sm-8">
                                <div className="input-group input-group-warning input-group-sm-vertical">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="4"
                                        value={totalSymbol1}
                                        onChange={(e) => {
                                            setTotalSymbol1(e.target.value);
                                        }}
                                    />
                                    <select
                                        className="form-control"
                                        onChange={(e) => {
                                            setTotalSymbol2(e.target.value);
                                        }}
                                        value={totalSymbol2}
                                    >
                                        <option>Choose</option>
                                        <option value="+">+</option>
                                        <option value="=">=</option>
                                        <option value="-">-</option>
                                    </select>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="60"
                                        value={totalSymbol3}
                                        onChange={(e) => {
                                            setTotalSymbol3(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mb-4 row align-items-center">
                            <label
                                htmlFor="firstNameLabel"
                                className="col-sm-4 col-form-label form-label"
                            >
                                အချိန်
                            </label>
                            <div className="col-sm-8">
                                <div>
                                    {/* <TimePicker clearIcon={false} openClockOnFocus={false} format="hh:mm a" className="form-control" onChange={setTime} value={time} /> */}
                                    <Datetime value={dateTime} dateFormat="MM-DD-YYYY" timeFormat="HH:mm:ss" onChange={date => setDateTime(moment(date).format("MM-DD-YYYY HH:mm:ss"))}/>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4 row align-items-center">
                            <label
                                htmlFor="firstNameLabel"
                                className="col-sm-5 col-form-label form-label"
                            >
                                ကြေးပြောင်း (Manual / Auto)
                            </label>
                            <div className="col-sm-7">
                                <div>
                                    <AutoBetToggle id={data.fixture_id} status={data.is_manual} event={fixtureToggleHandler}  />
                                </div>
                            </div>
                        </div>
                        <hr className="mb-2" />
                        <div className="row">
                            <label
                                htmlFor="firstNameLabel"
                                className="col-sm-12 col-form-label form-label"
                            >
                                ရွေးပါ
                            </label>

                            <div className="col-sm-12">
                                <div className="input-group">
                                    <label
                                        className="form-control"
                                        htmlFor="cb_body_small"
                                    >
                                        <span className="form-check">
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                name="mmfootball_category"
                                                id="cb_body_small"
                                                checked={footballCategory === 1? true: false}
                                                onChange={() => {
                                                    setFootballCategory(1);
                                                }}
                                            />
                                            <span className="form-check-label">
                                                ဘော်ဒီ (ပွဲသေး)
                                            </span>
                                        </span>
                                    </label>

                                    <label
                                        className="form-control"
                                        htmlFor="cb_body_large"
                                    >
                                        <span className="form-check">
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                name="mmfootball_category"
                                                id="cb_body_large"
                                                checked={footballCategory === 2? true: false}
                                                onChange={() => {
                                                    setFootballCategory(2);
                                                }}
                                            />
                                            <span className="form-check-label">
                                                ဘော်ဒီ (ပွဲကြီး)
                                            </span>
                                        </span>
                                    </label>

                                    <label
                                        className="form-control"
                                        htmlFor="cb_parlay"
                                    >
                                        <span className="form-check">
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                name="mmfootball_category"
                                                id="cb_parlay"
                                                checked={footballCategory === 3? true: false}
                                                onChange={() => {
                                                    setFootballCategory(3);
                                                }}
                                            />
                                            <span className="form-check-label">
                                                မောင်း
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {!props.loading ? (
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled={props.loading}
                            data-hs-step-form-next-options='{
                                "targetSelector": "#addUserStepBillingAddress"
                              }'
                            onClick={updateFootballFixture}
                        >
                            Save <i className="bi-chevron-right"></i>
                        </button>
                    ) : (
                        <button type="button" className="btn btn-primary">
                            <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </button>
                    )}
                </ModalFooter>
            </Modal>
            <ToastContainer/>
        </>
    );
}

export default EditFixtureModal;
