import React, { useState } from 'react';
import Input from '../utils/Input';
import { postMethodService } from '../services';
import { LOGIN_API, LOGIN_NODE_API } from '../services/constants';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { HandleLogout, convertRole, decodeData, encodeData } from '../utils/Helper';
import SubmitBtn from '../utils/SubmitBtn';
const logo = require("../assets/images/logo/logo.png");
const bgImg = require('../assets/images/background/card-6.svg')
const Cookies = require('js-cookie');

const Login = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [loginCode,setLoginCode] = useState("");
  const [loginPassword,setLoginPassword] = useState("");
  const [loginCodeError,setLoginCodeError] = useState("");
  const [loginPasswordError,setLoginPasswordError] = useState("");
  const navigate = useNavigate();

  const loginSubmitHandler = async (e:React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      const data = {
         usercode: loginCode,
         password: loginPassword
      };
      postMethodService(LOGIN_API, data, false)
      .then(res => {
        if(res == 401){
          HandleLogout(navigate);
        };

        if(res?.status){
            postMethodService(LOGIN_NODE_API, data, true)
            .then(nodeRes => {
              setIsLoading(false);
              if(nodeRes == 401){
                HandleLogout(navigate);
              };
              if(nodeRes?.status){
                  const saveInfo = {
                    user_id: res?.data?.user_id,
                    is_password_changed: res?.data?.is_password_changed,
                    role_name: res?.data?.role_name,
                    type: res?.data?.type,
                    permissions: res?.data.permissions,
                    token: res.token
                  };
                  const saveInfoNode = {
                    user_id: nodeRes?.data?.user_id,
                    role_name: convertRole(nodeRes?.data?.role_id),
                    type: nodeRes?.data?.type,
                    token: nodeRes.token
                  };
                  const bcode = encodeData(saveInfo);
                  const bcodeNode = encodeData(saveInfoNode);
                  Cookies.set("a_u", bcode);
                  Cookies.set("n_a_u", bcodeNode);
                  if(res?.data?.is_password_changed){
                      const delay = setTimeout(()=>{
                        setLoginCode("");
                        setLoginPassword("");
                        navigate('/');
                        window.location.reload();
                      },1000);
          
                      return ()=> {
                          clearTimeout(delay);
                      }
                  }else{
                        setLoginCode("");
                        setLoginPassword("");
                        navigate('/change-password');
                  };
              };
            })
        }else {
          setIsLoading(false);
        }
       
        
        // if(res?.status){
            // const saveInfo = {
            //   user_id: res?.data?.user_id,
            //   is_password_changed: res?.data?.is_password_changed,
            //   role_name: res?.data?.role_name,
            //   type: res?.data?.type,
            //   token: res.token
            // };
            // const bcode = encodeData(saveInfo);
            // Cookies.set("a_u", bcode);
            // if(res?.data?.is_password_changed){
            //     const delay = setTimeout(()=>{
            //       setLoginCode("");
            //       setLoginPassword("");
            //       navigate('/');
            //       window.location.reload();
            //     },1000);

            //     return ()=> {
            //         clearTimeout(delay);
            //     }
            // }else{
            //       setLoginCode("");
            //       setLoginPassword("");
            //       navigate('/change-password');
            // };
        // };
      })
     
  };

  const loginCodeHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
      setLoginCode(e.target.value);
      setLoginCodeError("");
  };

  const loginPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
      setLoginPassword(e.target.value);
      setLoginPasswordError("");
  };
  

  return (
    <>
      <main id="content" role="main" className="">
        <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{height: '32rem', backgroundImage: `url(${bgImg.default})`}}>
            <div className="shape shape-bottom zi-1">
              <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
                <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
              </svg>
            </div>
        </div>
        <div className="container py-5 py-sm-7">
          <div className="d-flex justify-content-center mb-5">
            <img className="zi-2" src={logo} alt="Image Description" style={{width: '15rem'}}/>
          </div>

          <div className="mx-auto" style={{maxWidth: '30rem'}}>
          
            <div className="card card-lg mb-5">
              <div className="card-body">
            
                <form onSubmit={loginSubmitHandler}>
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-5">Sign in</h1>
                    </div>
                  </div>
                  <Input title="User code" type="text" error={loginCodeError} tabIndex={1} placeholder='Code' name="code" value={loginCode} event={loginCodeHandler}/>
                  <Input title="Password" type="password" error={loginPasswordError} tabIndex={0} placeholder='8+ characters required' name="password" value={loginPassword} event={loginPasswordHandler}/>
                  <div className="form-check mb-4">
                    <input className="form-check-input" type="checkbox" value="" id="termsCheckbox" />
                    <label className="form-check-label" htmlFor="termsCheckbox">
                      Remember me
                    </label>
                  </div>

                  <div className="d-grid">
                      <SubmitBtn type="submit" isLoading={isLoading} name='Sign in'/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  )
}

export default Login
