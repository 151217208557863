import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Template from '../../utils/Template'
import TableScroll from '../../utils/TableScroll'
import TableLoading from '../../utils/TableLoading'
import { stateObjType } from '../../types'
import { HandleLogout, decodeData } from '../../utils/Helper'
import { FEEDBACK_API} from '../../services/constants'
import { getMethodService } from '../../services'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import Pagi from '../../utils/Pagi'
import moment from 'moment'
const Cookies = require('js-cookie');

const Recommend = () => {
  const abortController = new AbortController();
  const [isLoading,setIsLoading] = useState(false);
  const [feedbacks,setFeedbacks] = useState<stateObjType>([]);
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState<any>(1);
  const [page, setPage] = useState<any>(1);
  const [from, setFrom] = useState<any>(1);

  const fetchFeedbacks = () => {
    const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            return getMethodService(`${FEEDBACK_API}?type=feedback&perPage=20&page=${page}`,{},false,decode?.token,abortController);
        };
  };
  const feedbackQuery = useQuery({ queryKey: ['feedback',page], queryFn: fetchFeedbacks});
  
    useEffect(()=>{
        if(feedbackQuery){
            if(feedbackQuery.data === "Unauthenticated"){
                return HandleLogout(navigate);
            };
            setIsLoading(feedbackQuery.isLoading);
            if(feedbackQuery?.data){
                let totalPages = Number(Math.ceil((feedbackQuery?.data?.meta?.total) / (feedbackQuery?.data?.meta?.perPage)));
                let fromPages = Number(((feedbackQuery?.data?.meta?.perPage)) * Number((feedbackQuery?.data?.meta?.page)) -  Number(feedbackQuery?.data?.meta?.perPage));
                setFrom(fromPages + 1);
                setTotalPage(totalPages);
                setFeedbacks(feedbackQuery?.data?.data);
            };
        };
    },[feedbackQuery]);
  
  return (
    <>
      <Template title='Complain & Feedback'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-6">
                            <h4 className="card-title">Feedback</h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                               <div>
                                  <table className="table text-white border table-bordered table-striped">
                                      <thead>
                                          <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                              <th className="p-2 text-center text-light">No</th>
                                              <th className="p-2 text-center text-light">Usercode</th>
                                              <th className="p-2 text-center text-light">Phone</th>
                                              <th className="p-2 text-center text-light">Description</th>
                                              <th className="p-2 text-center text-light">Image</th>
                                              <th className="p-2 text-center text-light">Status</th>
                                              <th className="p-2 text-center text-light">Date</th>
                                              <th className="p-2 text-center text-light">Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              isLoading &&
                                              <TableLoading col={8} />
                                          }
                                          {
                                            feedbacks?.length > 0?
                                            feedbacks?.map((feedback:any, i:number) => 
                                                <tr key={feedback?.id} style={{verticalAlign: 'middle'}}>
                                                    <td className="p-2 text-center text-dark">{from + i}</td>
                                                    <td className="p-2 text-center text-dark">{feedback?.user_data?.usercode || '--'}</td>
                                                    <td className="p-2 text-center text-dark">
                                                        {feedback?.phone || '--'}
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {
                                                            feedback?.content?.length > 50 ?
                                                            feedback?.content?.slice(0, 50) + '. . .':
                                                            feedback?.content
                                                        }
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {
                                                           feedback?.attach_files?.length > 0 ?
                                                           <img src={feedback?.attach_files[0]} style={{width: '35px', height: '35px', borderRadius: '3px'}} />:
                                                            '--'
                                                        }
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        <span className='badge' style={{color: '#FFF', backgroundColor:  (feedback?.status === "accepted") ? '#00c9a7': (feedback?.status === "rejected" ? '#ed4c78':'#ff8800')}}>{feedback?.status}</span>
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {moment(feedback?.createdAt)?.format('YYYY-MM-DD, hh:mm:ss A')}
                                                    </td>
                                                    <td className="p-2 text-center">
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <button className="py-1 ms-2 btn btn-success" style={{fontSize: '13px'}} onClick={() => navigate(`${feedback?.id}`)}>View</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td colSpan={8} className='text-center'>No found data</td>
                                            </tr>
                                          }
                                      </tbody>
                                  </table>
                               </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
                {totalPage > 1 && (
                    <div className="card-footer">
                        <div className="row justify-content-end">
                            <div>
                                <Pagi
                                    setIsLoading={setIsLoading}
                                    setPage={setPage}
                                    totalPage={totalPage}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
      </Template>
      <ToastContainer />
      
    </>
  )
}

export default Recommend