import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { useQuery } from '@tanstack/react-query';
import { HandleLogout, amountCommasSparated, decodeData } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import { getMethodService, postMethodService } from '../services';
import useDebounce from '../hooks/useDebounce';
import { stateObjType } from '../types';
import { DEPOSIT_WITYDRAW_API, PAYMENT_TRANSACTION_NODE_API, USER_DETAIL_NODE_API } from '../services/constants';
import { useDispatch, useSelector } from 'react-redux';
import { controlHandler } from '../store/slices/unitControlSlite';
import { ToastContainer } from 'react-toastify';
import ScreenLoader from '../utils/ScreenLoader';
import PaymentTransactionHistory from './PaymentTransactionHistory';
const SweetAlert = require('sweetalert2');
const Cookies = require('js-cookie');

const PaymentTransaction = () => {
    const [isLoading,setIsLoading] = useState(true);
    const [isWalletLoading,setIsWalletLoading] = useState(false);
    const [isDepositWithdralLoading,setIsDepositWithdralLoading] = useState(false);
    const [users,setUsers] = useState<stateObjType>([]);
    const [search,setSearch] = useState("");
    const navigate = useNavigate();
    const [user, setUser] = useState<stateObjType | null>(null);
    const [accountWallet, setAccountWallet] = useState<string| number>(0);
    const [wallet, setWallet] = useState<string| number>(0);
    const [currentBalance, setCurrentBalance] = useState<string| number>(0);
    const [currentAccountBalance, setCurrentAccountBalance] = useState<string| number>(0);
    const debounceSearch = useDebounce(search,1000);
    const previous_2month = new Date();
    previous_2month.setMonth(previous_2month.getMonth() - 2);
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    const [remark, setRemark] = useState("");
    const { value } = useSelector((state:any) => state.unitController);
    const dispatch = useDispatch();
    const [historyStatus,setHistoryStatus] = useState(false);

    useEffect(()=>{
        localStorage.removeItem('r_type');
    },[]);
    const fetchUsers = (search:string | undefined) => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            return getMethodService(`${PAYMENT_TRANSACTION_NODE_API}?search=${search}&sortDirection=asc&sortColumn=usercode&limit=9999999`,{},false,decode?.token);
        };
    };
    const usersQuery = useQuery({ queryKey: ['paymentTransData', debounceSearch], queryFn: () => fetchUsers(debounceSearch),refetchOnWindowFocus: false});

    const currentDate = (date:any) => {
        const year = date?.getFullYear();
        const month = date?.getMonth() + 1;
        date?.setMonth(month - 1);
        const monthName = date?.toLocaleString("default", { month: "long" });
        const day = date?.getDate();
        return `${day}-${monthName}-${year}`;
    };

      useEffect(() => {
          if(usersQuery){
              setIsLoading(usersQuery.isLoading);
              if(usersQuery?.data == 401){
                  HandleLogout(navigate);
              };
              setUsers(usersQuery?.data?.data);
          };
      }, [usersQuery]);

      const handleSelectUser = (value: React.ChangeEvent<HTMLSelectElement>) => {
        setIsWalletLoading(true);
        const found:any = users.find((user:stateObjType) => user.id == value?.target?.value);
        if (found) {
            const getAuth = Cookies.get('n_a_u');
            if(getAuth){
                let decode = decodeData(getAuth);
                getMethodService(`${USER_DETAIL_NODE_API(found?.id)}?is_show_wallet_detail=${true}`,{},false,decode?.token)
                .then((res) => {
                    setIsWalletLoading(false);
                    if (res == 401) {
                        HandleLogout(navigate);
                    }
                    if (res?.status === "success") {
                        let walletObj = res?.data;
                        setWallet(
                            walletObj.wallet?.cash_balance > 0 ? `-${walletObj.wallet?.cash_balance}` : 0
                        );
                        setAccountWallet(
                            walletObj.wallet?.account_balance > 0 ? `-${walletObj.wallet?.account_balance}` : 0
                        );
                        setCurrentBalance(walletObj?.wallet?.cash_balance);
                        setCurrentAccountBalance(walletObj?.wallet?.account_balance);
                    }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsWalletLoading(false);
                    });
            };
            setUser(found);
        }
    };

    const handleSubmitDepositWithdraw = () => {
        const data = {
            amount: Math.abs(Number(accountWallet)),
            type: Number(accountWallet) > 0 ? "deposit" : "withdraw",
            user_id: user?.id,
        };
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm!',
            reverseButtons: true
          }).then((result: any) => {
            if (result.isConfirmed) {
                setIsDepositWithdralLoading(true);
                postMethodService(DEPOSIT_WITYDRAW_API, data, true)
                .then((res) => {
                    setIsDepositWithdralLoading(false);
                    if(res == 401){
                        HandleLogout(navigate);
                    };
                    if(res.status === "success"){
                        dispatch(controlHandler(!value));
                        setRemark("");
                        setCurrentBalance(Number(currentBalance) + Number(accountWallet));
                        setCurrentAccountBalance(Number(currentAccountBalance) + Number(accountWallet));
                        setWallet(
                            Number(currentBalance) + Number(accountWallet) > 0
                                ? `-${Number(currentBalance) + Number(accountWallet)}`
                                : 0
                        );
                        setAccountWallet(
                            Number(currentAccountBalance) + Number(accountWallet) > 0
                                ? `-${Number(currentAccountBalance) + Number(accountWallet)}`
                                : 0
                        );
                    };
                })
            };
          });
    };

  return (
    <>
        <Template title="Payment">
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <h4 className="card-title">
                                Deposit & Withdraw
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="mb-2 row align-items-center">
                                <div className="col-sm-12 text-dark">
                                    <input
                                        type="text"
                                        className="form-control p-2"
                                        placeholder="Search..."
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mb-3 mb-sm-0 position-relative">
                                {
                                    isLoading &&
                                    <div className="position-absolute d-flex align-items-center justify-content-center" style={{
                                        top: '0',
                                        right: '0',
                                        bottom: '0',
                                        left: '0',
                                        backgroundColor: 'rgb(0 0 0 / 26%)',
                                        borderRadius: '10px',
                                        zIndex: '90'
                                    }}>
                                        <div
                                            className="spinner-border spinner-border"
                                            role="status"
                                            style={{
                                                borderColor: '#FFF',
                                                borderRightColor: 'transparent',
                                                width: '50px',
                                                height: '50px',
                                                borderWidth: '5px',
                                            }}
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                }
                                <select
                                    className="p-2 rounded w-100"
                                    style={{
                                        border: "1px solid rgb(207 207 207)",
                                        outline: "none",
                                        fontSize: "17px",
                                    }}
                                    name=""
                                    id=""
                                    size={17}
                                    // ref={scrollRef}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleSelectUser(e)}
                                    // onScroll={gamesScrollHandler}
                                >
                                    {users?.map((user:stateObjType, $key:number) => {
                                        return (
                                            <option
                                                key={$key}
                                                value={user?.id}
                                                style={{ fontSize: "18px", padding: '3px 5px' }}
                                            >
                                                {user?.usercode}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            {user && (
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <h3 className="mb-0">
                                                            {historyStatus? 'မှတ်တမ်း': 'ငွေသွင်း/ငွေထုတ်'}
                                                        </h3>
                                                        <div>
                                                            <ul className="nav nav-segment nav-pills" role="tablist">
                                                                <li className="nav-item">
                                                                    <a className={`nav-link ${!historyStatus && 'bg-primary text-white'}`} id="nav-one-eg1-tab" href="#nav-one-eg1" data-bs-toggle="pill" data-bs-target="#nav-one-eg1" role="tab" aria-controls="nav-one-eg1" aria-selected="true" onClick={() => setHistoryStatus(false)}>ငွေသွင်း/ငွေထုတ်</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className={`nav-link ${historyStatus && 'bg-primary text-white'}`} id="nav-two-eg1-tab" href="#nav-two-eg1" data-bs-toggle="pill" data-bs-target="#nav-two-eg1" role="tab" aria-controls="nav-two-eg1" aria-selected="false" onClick={() => setHistoryStatus(true)}>မှတ်တမ်း</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {/* <button className='btn btn-primary' onClick={() => setHistoryStatus(!historyStatus)}>{historyStatus? 'ငွေသွင်း/ငွေထုတ်': 'မှတ်တမ်း'}</button> */}
                                                    </div>
                                                </div>
                                                {
                                                    historyStatus? 
                                                    <PaymentTransactionHistory userId={user?.id}/>:
                                                    <>
                                                        <div className="card-body">
                                                        <div className="row mb-2">
                                                            <label className="col-4 text-dark">
                                                                <b>User</b>
                                                            </label>
                                                            <div className="col-8 text-dark">
                                                                <span>
                                                                    <b> :&nbsp;
                                                                        {user?.usercode}
                                                                    </b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <label className="col-4 text-dark">
                                                                <b>Contact</b>
                                                            </label>
                                                            <div className="col-8 text-dark">
                                                                <span>
                                                                    <b> :&nbsp;
                                                                        {user?.contact}
                                                                    </b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <label className="col-4 text-dark">
                                                                <b>Cash Balance</b>
                                                            </label>
                                                            <div className="col-8 text-dark">
                                                                <span>
                                                                    <b> :&nbsp;
                                                                        {amountCommasSparated(currentBalance ?? 0)}
                                                                    </b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <label className="col-4 text-dark">
                                                                <b>Account Balance</b>
                                                            </label>
                                                            <div className="col-8 text-dark">
                                                                <span>
                                                                    <b> :&nbsp;
                                                                        {amountCommasSparated(currentAccountBalance ?? 0)}
                                                                    </b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <label className="col-4 text-dark">
                                                                <b>Type</b>
                                                            </label>
                                                            <div className="col-8 text-dark">
                                                                <span>
                                                                    <b>:&nbsp; Pay</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <label className="col-4 text-dark">
                                                                <b>Pay Date</b>
                                                            </label>
                                                            <div className="col-8 text-dark">
                                                                <span>
                                                                    <b> :&nbsp;
                                                                        {currentDate(
                                                                            new Date()
                                                                        )}
                                                                    </b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-4 text-dark">
                                                                <b>Amount</b>
                                                            </label>
                                                            <div className="col-8 text-dark">
                                                                <input
                                                                    type="text"
                                                                    value={accountWallet}
                                                                    className="form-control mb-2 p-2"
                                                                    onChange={(e) =>
                                                                        setAccountWallet(
                                                                            e.target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-4 text-dark">
                                                                <b>Remark</b>
                                                            </label>
                                                            <div className="col-8 text-dark">
                                                                <textarea
                                                                    rows={3}
                                                                    typeof='text'
                                                                    value={remark}
                                                                    className="form-control mb-2 p-2"
                                                                    onChange={(e) =>
                                                                        setRemark(
                                                                            e.target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="card-footer">
                                                            <div className="row justify-content-end">
                                                                <div className="col-sm-3">
                                                                    <button
                                                                        type="button"
                                                                        className="py-2 btn btn-primary w-100"
                                                                        disabled={isDepositWithdralLoading}
                                                                        onClick={
                                                                            handleSubmitDepositWithdraw
                                                                        }
                                                                    >
                                                                        {
                                                                            isDepositWithdralLoading? 'Loading...':'Submit'
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Template>
        <ToastContainer />
        <ScreenLoader isLoading={isWalletLoading || isDepositWithdralLoading} />
    </>
  )
}

export default PaymentTransaction
