import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
const SelectFilter = ({data,callback,attValue,attName,state}) => {
    const [result,setResult] = useState([]);
    const [clearVal,setClearVal] = useState("");
    const clearRef = useRef(null);
    let temp = [{ value: '', label: 'All' }];
    useEffect(()=>{
        if(data.length > 0){
            data.map(el => {
                return temp.push({value: el[`${attValue}`], label: el[`${attName}`]})
            });
            setResult(temp);
        }
        setClearVal(temp[0]);
    },[data]);

    useEffect(()=>{
        if(state === ""){
          setClearVal(temp[0])
        };
    },[state]);

  return (
    <div>
      <Select
        options={result}
        value={clearVal}
        ref={clearRef}
        onChange={e => {callback(e.value);setClearVal(e)}}
      />
    </div>
  )
};

export default SelectFilter