import React, { useEffect, useRef, useState } from 'react'
import Template from '../../utils/Template'
import { ToastContainer } from 'react-toastify'
import TableScroll from '../../utils/TableScroll'
import Pagi from '../../utils/Pagi'
import TableLoading from '../../utils/TableLoading'
import { getMethodService, postMethodService } from '../../services'
import { ALREADY_FIXTURES_API, LEAGUE_API, PROXY_API, SAVE_FIXTURES_API } from '../../services/constants'
import { useQuery } from '@tanstack/react-query'
import { HandleLogout, errorToaster } from '../../utils/Helper'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { stateObjType } from '../../types'
import SelectFilter from '../../utils/SelectFilter'
import CreateFixtureModal from '../../utils/Modal/CreateFixtureModal'
// import { debounce } from 'lodash'
const {debounce} = require('lodash');

const FootballCreateOdds = () => {
    const [isLoading,setIsLoading] = useState(true);
    const [createOddsLoading,setCreateOddsLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [leagueId, setLeagueId] = useState();
    const [country, setCountry] = useState();
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const day_format = `${year}${month}${day}`;
    const [fixtureDay, setFixtureDay] = useState(day_format);
    const [fixtures, setFixtures] = useState([]);
    const navigate = useNavigate();
    const [createdFixtures, setCreatedFixtures] = useState([]);
    const [filteredFixtures, setFilteredFixtures] = useState([]);
    const currentTimeStamp = moment.utc().unix();
    const formattedDate = `${year}-${month}-${day}`;
    const [date, setDate] = useState(formattedDate);
    const [leagues, setLeagues] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isShowCreateModal, setIsShowCreateModal] = useState(false);
    const [data,setData] = useState();
    const [searchBoxs, setSeachBoxs] = useState(true);
    const [homeSearch, setHomeSearch] = useState('');
    const [awaySearch, setAwaySearch] = useState('');
    const [searchFixtures, setSearchFixtures] = useState([]);

    function disablePreviousDate(date:any) {
        date.setDate(date.getDate() - 1);
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        let day = currentDate.getDate();
        let prevDate = year + "-" + month.toString().padStart(2, '0') + "-" + day.toString().padStart(2, '0');
        return prevDate;
    };

    const filteredMatch = (apiFixtures:any, oldFixtures:any) => {
        return apiFixtures.filter((apifixture:any) => {
            return (
                !oldFixtures.some(
                    (oldfixture:any) => apifixture?.id == oldfixture?.fixture_id
                ) &&
                Number(apifixture.time) > currentTimeStamp
            );
        });
    };

    const fetchMatchLists = (leagueid: string | number | undefined, country: string | undefined, day: string | undefined,page: number | undefined) => getMethodService(PROXY_API,{
        request_url: "https://api.b365api.com/v3/events/upcoming",
        request_body: {
            sport_id: 1,
            league_id: leagueid,
            cc: country,
            day: day,
            skip_esports: false,
            page: page,
        },
    });
    const fetchAlreadyMatch = () => getMethodService(ALREADY_FIXTURES_API);
    const fetchCountries = () => getMethodService(PROXY_API,{
        request_url: "https://betsapi.com/docs/samples/countries.json",
        request_body: {
            sport_id: 1,
            day: fixtureDay,
        },
    });
    const fetchLeagues = () => getMethodService(LEAGUE_API,{
        cc: country
    });

    const matchListQuery = useQuery({ queryKey: ['matchs',page,refreshKey], queryFn: () => fetchMatchLists(leagueId,country,fixtureDay,page), refetchOnWindowFocus: false});
    const alreadyMatchQuery = useQuery({ queryKey: ['alreadyMatch',refreshKey], queryFn: fetchAlreadyMatch, refetchOnWindowFocus: false});
    const countriesQuery = useQuery({queryKey: ['countries', fixtureDay], queryFn: fetchCountries, refetchOnWindowFocus: false})
    const leaguesQuery = useQuery({queryKey: ['leagues', country], queryFn: fetchLeagues, refetchOnWindowFocus: false})

    const fetchData = async () => {
        console.log("fetching data...");
        setIsLoading(true);
        getMethodService(PROXY_API, {
            request_url: "https://api.b365api.com/v3/events/upcoming",
            request_body: {
                sport_id: 1,
                league_id: leagueId,
                cc: country,
                day: fixtureDay,
                skip_esports: false,
                page: page,
            },
        })
        .then((response) => {
            console.log("fetchData*****", response);
            if(response.success){
                if(response.pager){
                    if(response.pager.page > 0){
                        if(response.pager.per_page && response.pager.page){
                            setFrom((response.pager.per_page * (response.pager.page - 1)) + 1 );
                        };
                        };
                    setTotalPage(Math.ceil(response.pager.total / response.pager.per_page));
                }
                setFixtures(response.results);
            }
            else{
                errorToaster(response.error_detail)
            }
            setIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    };

    const fetchSearchData = async (homeValue:any,awayValue:any,searchDay:any) => {
        setIsLoading(true);
        getMethodService(PROXY_API, {
            request_url: "https://api.b365api.com/v1/events/search",
            request_body: {
                sport_id: 1,
                home: homeValue,
                away: awayValue,
                time: searchDay
            },
        })
        .then((response) => {
            console.log("fetchData*****", response);
            if(response.success){
                if(response.pager){
                    if(response.pager.page > 0){
                        if(response.pager.per_page && response.pager.page){
                            setFrom((response.pager.per_page * (response.pager.page - 1)) + 1 );
                        };
                        };
                    setTotalPage(Math.ceil(response.pager.total / response.pager.per_page));
                }else{
                    setTotalPage(1)
                }
                console.log(".........................", response)
                setSearchFixtures(response.results);
            }
            else{
                errorToaster(response.error_detail)
            }
            setIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    };

    useEffect(()=>{
        setIsLoading(matchListQuery?.isLoading);
        if(matchListQuery?.data == 401){
            HandleLogout(navigate);
        };
        if(matchListQuery?.data?.success){
            if(matchListQuery?.data?.pager){
                if(matchListQuery?.data?.pager.page > 0){
                    if(matchListQuery?.data?.pager.per_page && matchListQuery?.data?.pager.page){
                        setFrom((matchListQuery?.data?.pager.per_page * (matchListQuery?.data?.pager.page - 1)) + 1 );
                    };
                };
                setTotalPage(Math.ceil(matchListQuery?.data?.pager.total / matchListQuery?.data?.pager.per_page));
            };
            setFixtures(matchListQuery?.data?.results);
        };
    },[matchListQuery]);
    
    useEffect(()=>{
        if(alreadyMatchQuery?.data == 401){
            HandleLogout(navigate);
        };
        if(alreadyMatchQuery?.data?.status === "success"){
            setCreatedFixtures(alreadyMatchQuery?.data?.data);
        };
    },[alreadyMatchQuery]);

    useEffect(()=>{
        if(countriesQuery?.data == 401){
            HandleLogout(navigate);
        };
        if(countriesQuery?.data?.success){
            setCountries(countriesQuery?.data?.results);
        };
    },[countriesQuery]);

    useEffect(()=>{
        if(leaguesQuery?.data == 401){
            HandleLogout(navigate);
        };
        if(leaguesQuery?.data?.status === "success"){
            setLeagues(leaguesQuery?.data?.data);
        };
    },[leaguesQuery]);

    useEffect(() => {
        if(createdFixtures != undefined && fixtureDay != undefined){
            if (createdFixtures.length > 0 || fixtures.length > 0) {
                setFilteredFixtures(filteredMatch(fixtures, createdFixtures));
            } else {
                setFilteredFixtures(fixtures);
            };
        };
    }, [createdFixtures, fixtures]);

    useEffect(() => {
        if (createdFixtures.length > 0 || searchFixtures.length > 0) {
            console.log("start filtering...");
            setIsLoading(true);
            setFilteredFixtures(filteredMatch(searchFixtures, createdFixtures));
            setIsLoading(false);
        } else {
            setFilteredFixtures(searchFixtures);
        }
    }, [searchFixtures]);

    useEffect(()=>{
        if(homeSearch && awaySearch){
            const debouncedFetchUsers = debounce(fetchSearchData, 1500);
            debouncedFetchUsers(homeSearch,awaySearch,fixtureDay); // Initial fetch
            return () => {
                debouncedFetchUsers.cancel(); // Cleanup debounced function on unmount
            };
        };
        if(!homeSearch && !awaySearch){
            fetchData();
        };
    },[fixtureDay,homeSearch,awaySearch])
    
    const submitHandler = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };

    const dateChangeHandler = (currentDay:any) => {
        if (currentDay) {
            let dateFormat = currentDay.replace(/-/g, "");
            setDate(currentDay);
            setFixtureDay(dateFormat);
        }
    };
    const leagueChangeHandler = (league_id:any) => {
        setLeagueId(league_id);
    };
    const countryChangeHandler = (country:any) => {
        setCountry(country);
    };
    const showCreateModal = (data:any) => {
        setData(data);
        setIsShowCreateModal(true);
    };
    const hideCreateModal = () => {
        setIsShowCreateModal(false);
    };
    const submitFixture = (data:any) => {
      setCreateOddsLoading(true);
      postMethodService(SAVE_FIXTURES_API, data, true)
      .then(res => {
        setCreateOddsLoading(false);
        if(res == 401){
            HandleLogout(navigate);
        };
        if(res?.status === "success"){
            hideCreateModal();
            setRefreshKey(prevKey => prevKey + 1);
            setCreateOddsLoading(false);
        };
      });
    };
  return (
    <>
        <Template title='Football'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="card-title mb-2">
                                Total - ( {filteredFixtures?.length} )
                            </h4>
                        </div>
                        <div className="col d-flex justify-content-end">
                            <button className={`btn ${!searchBoxs? 'btn-primary' : 'btn-outline-primary'} py-1`} onClick={()=> setSeachBoxs(!searchBoxs)}>
                                <i className="bi bi-arrow-left-right"></i>
                            </button>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-3 mb-3 mb-lg-0">
                            <label className="form-label mb-1">Search</label>
                            <input
                                type="date"
                                className="form-control"
                                value={date}
                                min={disablePreviousDate(
                                    currentDate
                                )}
                                onChange={(event) => {
                                    dateChangeHandler(
                                        event.target.value
                                    );
                                }}
                            />
                        </div>
                        <div className="col-lg-3 mb-3 mb-lg-0">
                            <label className="form-label mb-1">
                            {
                                searchBoxs ? "Select Country" : "Home Team"
                            }:
                            </label>
                            {
                                searchBoxs ?
                                    <SelectFilter
                                        data={countries}
                                        callback={countryChangeHandler}
                                        attValue="cc"
                                        attName="name"
                                        state={country}
                                    />
                                :
                                <input type="text" style={{fontSize: '12px'}} value={homeSearch} className="form-control" placeholder="Search" onChange={e => setHomeSearch(e.target.value)}/>
                            }
                            
                        </div>
                        <div className="col-lg-3 mb-3 mb-lg-0">
                            <label className="form-label mb-1">
                            {
                                searchBoxs ? "Select League" : "Away Team"
                            }:
                            </label>
                            {
                                searchBoxs ?
                                    <SelectFilter
                                        data={leagues}
                                        callback={leagueChangeHandler}
                                        attValue="league_id"
                                        attName="name"
                                        state={leagueId}
                                    />
                                :
                                <input type="text" style={{fontSize: '12px'}} value={awaySearch} className="form-control" placeholder="Search" onChange={e => setAwaySearch(e.target.value)}/>
                            }
                            
                        </div>
                        
                        <div className="col-lg-3">
                            <button
                                className="btn btn-primary w-100 mt-4"
                                onClick={submitHandler}
                                disabled={isLoading}
                            >
                                {isLoading ? "Loading ..." : "Submit"}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white border table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                {/* <th className="p-2 text-center text-light">No</th> */}
                                                <th className="p-2 text-center text-light">လိဂ်</th>
                                                <th className="p-2 text-center text-light">အိမ်ကွင်း</th>
                                                <th className="p-2 text-center text-light">အဝေးကွင်း</th>
                                                <th className="p-2 text-center text-light">ပွဲချိန်</th>
                                                <th className="p-2 text-center text-light">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading &&
                                                <TableLoading col={15} />
                                            }
                                            {
                                                filteredFixtures?.length > 0 ?
                                                filteredFixtures.map((fixture:stateObjType, index:number) => 
                                                    <tr style={{verticalAlign: 'middle'}} key={index}>
                                                        {/* <td className="p-2 text-center text-dark">{index + from}</td> */}
                                                        <td className="p-2 text-center text-dark">
                                                            {fixture?.league?.name}
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <div className='d-flex align-items-center text-dark'>
                                                                <div className="flex-shrink-0">
                                                                    <div className="avatar avatar-sm avatar-circle">
                                                                        <img
                                                                            className="avatar-img"
                                                                            src={
                                                                                fixture?.home?.image_id
                                                                                    ? `https://assets.b365api.com/images/team/b/${fixture?.home?.image_id}.png`
                                                                                    : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                                                                            }
                                                                            alt="Home Team Logo"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3">
                                                                    <b>
                                                                        {
                                                                            fixture?.home?.name
                                                                        }
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <div className='d-flex align-items-center text-dark'>
                                                                <div className="flex-shrink-0">
                                                                    <div className="avatar avatar-sm avatar-circle">
                                                                        <img
                                                                            className="avatar-img"
                                                                            src={
                                                                                fixture?.away?.image_id
                                                                                    ? `https://assets.b365api.com/images/team/b/${fixture?.away?.image_id}.png`
                                                                                    : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                                                                            }
                                                                            alt="away Team Logo"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3">
                                                                    <b>
                                                                        {
                                                                            fixture?.away?.name
                                                                        }
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            {new Date(
                                                                fixture?.time * 1000
                                                            ).toLocaleString(
                                                                "en-US",
                                                                {
                                                                    timeZone:
                                                                        "Asia/Yangon",
                                                                }
                                                            )}
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <input
                                                                type="button"
                                                                className="btn btn-primary py-1"
                                                                value="ကြေးဖွင့်မည်"
                                                                style={{fontSize:'13px'}}
                                                                onClick={(e) => {
                                                                    showCreateModal(
                                                                        fixture
                                                                    );
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr className="p-2 text-center text-dark">
                                                    <td colSpan={7} className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>

                                            }
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                        {totalPage > 1 && (
                            <div className="card-footer">
                                <div className="row justify-content-end">
                                    <div>
                                        <Pagi
                                            setIsLoading={setIsLoading}
                                            setPage={setPage}
                                            totalPage={totalPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Template>
        {isShowCreateModal && (
            <CreateFixtureModal
                createOddsLoading={createOddsLoading}
                data={data}
                onSubmit={submitFixture}
                onClose={hideCreateModal}
            />
        )}
        <ToastContainer />
    </>
  )
}

export default FootballCreateOdds
