import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import 'react-quill/dist/quill.snow.css';
import { errorToaster } from "../Helper";

const MMThreedResetConfirm = (props) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.authKey.length > 0) {
      props.onSubmit();
    } else {
      errorToaster('Enter your secret key!')
    }
  }

  const closeHandler = () => {
    props.onClose()
  }

  return (
    <Modal isOpen={true} toggle={closeHandler} style={{maxWidth: '400px'}}>
      <ModalHeader toggle={closeHandler}>{props.title}</ModalHeader>
      <ModalBody className="pb-3">
          <p className="text-center fs-3" style={{lineHeight: '30px'}}>{props.des}</p>
          <input disabled={props.isLoading} className="form-control" value={props.authKey} onChange={e => props.setAuthKey(e.target.value)} placeholder="Enter your secret key" />
      </ModalBody>
      <ModalFooter className="py-3 d-flex justify-content-center">
        <button className="btn py-2 btn-danger" onClick={closeHandler} disabled={props.isLoading}>
          ပယ်ဖျက်မည်
        </button>
        <button className="btn py-2 btn-primary" onClick={handleSubmit} disabled={props.isLoading}>
          {
           props.isLoading ? 'ခဏစောင့်ပါ ...' : 'သေချာသည်'
          }
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default MMThreedResetConfirm
