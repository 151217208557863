import React, { useEffect, useState } from 'react';
import Input from '../utils/Input';
import { postMethodService } from '../services';
import { ADMIN_PASSWORD_CHANGE_API, LOGIN_API } from '../services/constants';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { HandleLogout, decodeData } from '../utils/Helper';
import SubmitBtn from '../utils/SubmitBtn';
const logo = require("../assets/images/logo/logo.png");
const bgImg = require('../assets/images/background/card-6.svg');
const Cookies = require('js-cookie');

const ChangePasswordAuth = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [oldPass,setOldPass] = useState("");
  const [newPass,setNewPass] = useState("");
  const [conPass,setConPass] = useState("");
  const [oldPasswordError,setOldPasswordError] = useState("");
  const [newPasswordError,setNewPasswordError] = useState("");
  const [conPasswordError,setConPasswordError] = useState("");
  const navigate = useNavigate();

  useEffect(()=>{
      const token = Cookies.get('a_u');
      if(token){
          let decode = decodeData(token);
          if(decode.is_password_changed){
              return navigate('/');
          };
      }else{
          return navigate('/login');
      };
  },[]);

  const changePassHandler = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userData = {
        old_password: oldPass,
        new_password: newPass,
        confirm_password: conPass
    };
    postMethodService(ADMIN_PASSWORD_CHANGE_API, userData, true)
    .then(res => {
        setIsLoading(false);
        if(res == 401){
          HandleLogout(navigate);
        };
        if(res?.status){
            const getToken = Cookies.get("a_u");
            const delay = setTimeout(()=>{
                if(getToken){
                    Cookies.get("a_u");
                    setOldPass("");
                    setNewPass("");
                    setConPass("");
                    navigate('/login');
                };
              },1000);
  
              return ()=> {
                  clearTimeout(delay);
              };
        };
      });
  };

  const oldPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
      setOldPass(e.target.value);
      setOldPasswordError("");
  };

  const newPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
      setNewPass(e.target.value);
      setNewPasswordError("");
  };

  const conPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
      setConPass(e.target.value);
      setConPasswordError("");
  };
  

  return (
    <>
      <main id="content" role="main" className="">
        <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{height: '32rem', backgroundImage: `url(${bgImg.default})`}}>
            <div className="shape shape-bottom zi-1">
              <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
                <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
              </svg>
            </div>
        </div>
        <div className="container py-5 py-sm-7">
          <div className="d-flex justify-content-center mb-5">
            <img className="zi-2" src={logo} alt="Image Description" style={{width: '8rem'}}/>
          </div>

          <div className="mx-auto" style={{maxWidth: '30rem'}}>
          
            <div className="card card-lg mb-5">
              <div className="card-body">
            
                <form onSubmit={changePassHandler}>
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-5">Change password</h1>
                    </div>
                  </div>
                  <Input title="Old password" type="password" error={oldPasswordError} tabIndex={0} placeholder="********" name="oldPassowrd" value={oldPass} event={oldPasswordHandler}/>
                  <Input title="New password" type="password" error={newPasswordError} tabIndex={1} placeholder="********" name="newPassowrd" value={newPass} event={newPasswordHandler}/>
                  <Input title="Confirm password" type="password" error={conPasswordError} tabIndex={2} placeholder="********" name="conPassowrd" value={conPass} event={conPasswordHandler}/>

                  <div className="d-grid">
                      <SubmitBtn type="submit" isLoading={isLoading} name='Submit'/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  )
}

export default ChangePasswordAuth
