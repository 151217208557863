import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import TableScroll from '../../utils/TableScroll'
import Template from '../../utils/Template'
import { stateObjType } from '../../types'
import { BET_HISTORY_API } from '../../services/constants'
import { getMethodService } from '../../services'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { HandleLogout, decodeData, replaceZeotoQqual } from '../../utils/Helper'
import TableLoading from '../../utils/TableLoading';
const Cookies = require('js-cookie');

const FootballBetListParlayDetail = () => {
  const [isLoading,setIsLoading] = useState(false);
  const params = useParams();
  const [data,setData] = useState<stateObjType>({});
  const navigate = useNavigate();

  const fetchBetListsDetail = (id:string | number | undefined) => {
    const getAuth = Cookies.get('n_a_u');
    if(getAuth){
        let decode = decodeData(getAuth);
        return getMethodService(`${BET_HISTORY_API}/${id}`,{},false,decode?.token);
    };
    
  };
  const betListDetailQuery = useQuery({ queryKey: ['betlistdetail', params.id], queryFn: () => fetchBetListsDetail(params.id), keepPreviousData : true});

  useEffect(()=>{
    setIsLoading(betListDetailQuery?.isLoading);
    if(betListDetailQuery?.data == 401){
        HandleLogout(navigate);
    };

    if(betListDetailQuery?.data?.status === "success"){
        setData(betListDetailQuery?.data?.data);
    };
},[betListDetailQuery]);

  return (
    <>
        <Template title='Football'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <table className="float-start mb-4 mb-sm-0">
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>User</th>
                                    <td>: {data?.user?.usercode}</td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Slip ID</th>
                                    <td>: {data?.id}</td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Amount</th>
                                    <td>:
                                          &nbsp;
                                          {data?.stake <= 0 ?
                                               <span className="text-danger">{data?.stake?.toLocaleString('en-US')}</span>:<span>{data?.stake?.toLocaleString('en-US')}</span>
                                          }
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Turn Over</th>
                                    <td>:
                                          &nbsp;
                                          {data?.total_bet_result?.payout_amount <= 0 ?
                                               <span className="text-danger">{data?.total_bet_result?.payout_amount?.toLocaleString('en-US')}</span>:<span>{data?.total_bet_result?.payout_amount?.toLocaleString('en-US')}</span>
                                          }
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Profit</th>
                                    <td>: &nbsp;
                                          {data?.total_bet_result?.profit <= 0 ?
                                               <span className="text-danger">{data?.total_bet_result?.profit?.toLocaleString('en-US')}</span>:<span>{data?.total_bet_result?.profit?.toLocaleString('en-US')}</span>
                                          }
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Bet Time</th>
                                    <td>: {data?.created_at}</td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Result</th>
                                    <td>: <span className={`badge bg${data?.total_bet_result?.bet_result === "win"? '-success': (data?.total_bet_result?.bet_result === "lose"?'-danger':'-warning')}`}>{data?.total_bet_result?.bet_result}</span></td>
                                </tr>
                            </table>
                        </div>
                        {/* <div className="col-sm-6 text-sm-end">
                            <table className="float-sm-end">
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Tax :</th>
                                    <td style={{width: '100px'}}> {data?.total_bet_result?.tax_percent??0} %</td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Super Commission :</th>
                                    <td>{data.commission?.super_commission_amount?? 0} ({data.commission?.super_commission_percentage}%)</td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Senior Commission :</th>
                                    <td>{data.commission?.senior_commission_amount?? 0} ({data.commission?.senior_commission_percentage}%)</td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Master Commission :</th>
                                    <td>{data.commission?.master_commission_amount?? 0} ({data.commission?.master_commission_percentage}%)</td>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'bolder'}}>Agent Commission :</th>
                                    <td>{data.commission?.agent_commission_amount?? 0} ({data.commission?.agent_commission_percentage}%)</td>
                                </tr>
                            </table>
                        </div> */}
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white border table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                            <th className="text-center text-light p-2">No</th>
                                            <th className="text-center text-light p-2">လိဂ်</th>
                                            <th className="text-center text-light p-2">အိမ်ကွင်း</th>
                                            <th className="text-center text-light p-2">ဂိုးရလဒ်</th>
                                            <th className="text-center text-light p-2">အဝေးကွင်း</th>
                                            <th className="text-center text-light p-2">ဂိုးပေါင်း</th>
                                            <th className="text-center text-light p-2">Status</th>
                                            <th className="text-center text-light p-2">Bet Team</th>
                                            <th className="text-center text-light p-2">Odds Market</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading &&
                                                <TableLoading col={9} />
                                            }
                                            {
                                                data?.bet_fixtures?.length > 0 ?
                                                data?.bet_fixtures?.map((fixture:stateObjType,i:number) => 
                                                <tr key={i} style={{verticalAlign: 'middle'}}>
                                                    <td className="text-center text-dark p-2">{i + 1}</td>
                                                    <td className="text-center bg-primary text-white p-2">
                                                        {
                                                            fixture?.football_fixture?.league_data?.name
                                                        }
                                                    </td>
                                                    <td className="text-center text-dark p-2">
                                                        {
                                                            fixture?.football_fixture?.home_team_data?.name
                                                        }
                                                        <span className="badge bg-dark ms-1">
                                                            {
                                                                fixture?.football_fixture?.odds_team=="home"?replaceZeotoQqual(fixture?.football_fixture?.body): ""
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className="text-center text-dark p-2">
                                                        {
                                                            fixture?.football_fixture?.goals?
                                                            `${fixture.football_fixture.goals?.home}
                                                                vs
                                                            ${fixture?.football_fixture?.goals?.away}
                                                            `:
                                                            '-'
                                                        }
                                                    </td>
                                                    <td className="text-center text-dark p-2">
                                                        {fixture?.football_fixture?.away_team_data?.name}
                                                        <span className="badge bg-warning ms-1">
                                                            {
                                                                fixture?.football_fixture?.odds_team=="away"?replaceZeotoQqual(fixture?.football_fixture?.body): ""
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className="text-center text-dark p-2">
                                                        {
                                                            fixture?.football_fixture?.goals?
                                                            `${
                                                                (+fixture?.football_fixture?.goals?.home) +
                                                                (+fixture?.football_fixture.goals?.away)
                                                            }
                                                            `:
                                                            0
                                                        }
                                                    </td>
                                                    <td className="text-center text-dark p-2">
                                                        <span className={`badge bg${fixture?.bet_result?.status === "unsettled"?'-warning': (fixture?.bet_result?.status === "win"?'-success':'-danger')}`}>
                                                            {fixture?.bet_result?.status}
                                                        </span>
                                                    </td>
                                                    <td className="text-center text-dark p-2">
                                                        <span className={`badge bg-${fixture?.bet_team === "home"? 'dark':'info'}`}>
                                                            {
                                                                fixture?.bet_team
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className="text-center text-dark p-2">
                                                        {
                                                            fixture?.market === 'first-half'?
                                                            <span className='badge bg-success'>1 <sup>st</sup> Half</span>:
                                                            <span className='badge bg-dark'>Full Time</span>
                                                        }
                                                    </td>
                                                </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan={9} className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            }
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
        <ToastContainer />
    </>
  )
}

export default FootballBetListParlayDetail
