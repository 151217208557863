import { createSlice } from "@reduxjs/toolkit";

const refreshStatusService = createSlice({
    name: 'status',
    initialState: {
        serviceStatus: true,
    },
    reducers: {
        serviceStatusHandler: (state,action) => {
            state.serviceStatus = action.payload
        }
    }
});

export const {serviceStatusHandler} = refreshStatusService.actions;
export default refreshStatusService.reducer;