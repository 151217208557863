import { Modal, ModalBody } from "reactstrap";
import "react-quill/dist/quill.snow.css";

const MMThreedResultFinished = (props) => {
  return (
    <Modal isOpen={true} style={{ maxWidth: "400px" }}>
      <ModalBody className="pt-5">
        <p className="text-center fs-3" style={{ lineHeight: "40px" }}>
          ထီပေါက်သူ ({Math.round(props.progress / 500)}) ယောက်အတွက် အလျော်အစား ပြုလုပ်ပြီးပါပြီခင်ဗျာ။
        </p>
        <div className="d-flex justify-content-center">
        <button
          className="btn btn-primary py-1 mt-1"
          onClick={() => props.setFinish(false)}
        >
          ဟုတ်ကဲ့
        </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MMThreedResultFinished;
