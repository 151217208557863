import React, { useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import { CONFIG_SETTING_API, CONFIG_SETTING_EDIT_API } from "../../services/constants";
import { getMethodService, putMethodService } from "../../services";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import Template from "../../utils/Template";
import { HandleLogout } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";

const RuleAndRegulations = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [text,setText] = useState("");
  const navigate = useNavigate();

    const fetchRulesText = () => {
        getMethodService(CONFIG_SETTING_API)
        .then((response) => {
            setIsLoading(false);
            if(response == 401){
                HandleLogout(navigate);
            };
            if(response?.status === 'success'){
                setText(response?.data?.rule_and_regulation);
            };
        })
        .catch((err) => console.error(err));
    };

    useEffect(()=>{
        fetchRulesText();
    },[])

    const editText = () => {
        setIsLoading(true);
        putMethodService(CONFIG_SETTING_EDIT_API, {rule_and_regulation: text},true)
        .then((response) => {
            if(response == 401){
                HandleLogout(navigate);
            };
            if (response?.status === "success") {
                fetchRulesText();
            };
        })
        .catch((err) => {
            setIsLoading(false);
            console.error(err);
        });
    };
    const inputHandler = (text:any) => {
        setText(text);
    };

    const autofocusNoSpellcheckerOptions = useMemo(() => {
        return {
          autofocus: true,
          spellChecker: false,
        };
      }, []);
  return (
    <>
      <Template title='Setting'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <h4 className="card-title">Rules and Regulations</h4>
                        </div>
                        <div className="col-sm-9">
                            <div className="text-end">
                                <button className="btn btn-primary" disabled={isLoading} onClick={editText}>
                                    {
                                        isLoading? 'Loading...': 'Submit'
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <SimpleMDE
                        // id="editor_container"
                        onChange={inputHandler}
                        value={text}
                        options={autofocusNoSpellcheckerOptions}
                    />
                </div>
            </div>
      </Template>
      <ToastContainer />
    </>
  )
}

export default RuleAndRegulations
