import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { HandleLogout, decodeData } from '../utils/Helper';
import { useDispatch, useSelector } from 'react-redux';
import { userIdHandler } from '../store/slices/userIdSlice';
import { dateHandler } from '../store/slices/dateSlice';
import { postMethodService } from '../services';
import { LOGOUT_API, LOGOUT_NODE_API } from '../services/constants';
import Swal from 'sweetalert2';
const sidebarLogo = require('../assets/images/logo/dashboard_light_logo.png')
const Cookies = require('js-cookie');

const Sidebar = () => {
  const url = window.location.pathname;
  const params = useParams();
  const [userInfo,setUserInfo] = useState<any>({})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [goLinkUrl,setGoLinkUrl] = useState("#");
  const {setting} = useSelector((state:any) => state.settingSlice);

  const start_date_v2 = params?.start_date && `${params.start_date}`;
  const end_date_v2 = params?.end_date && `${moment(params.end_date).add(0,'days').format('YYYY-MM-DD')} 10:28`;
  const activeLink = `/stock-manage/member/betting-list/${params.id}/${params.status}/${start_date_v2}/${end_date_v2}`;
  const replaceActiveLink = activeLink.split(' ').join('%20');

  const reportActivLink = `/report/win-lose-detail/${params.id}/${params.status}/${params.start_date}/${params.end_date}`;
  const replaceReportActiveLink = reportActivLink.split(' ').join('%20');

  const reportThreedActivLink = `/report/3d-win-lose-detail/${params.id}/${params.status}/${params.start_date}/${params.end_date}`;
  const replaceReportThreedActiveLink = reportThreedActivLink.split(' ').join('%20');
  const reportAuserActivLink = `/report/win-lose-detail-a-user/${params.id}/${params.status}/${params.start_date}/${params.end_date}`;
  const replaceReportAuserActiveLink = reportAuserActivLink.split(' ').join('%20');

    useEffect(()=>{
        const getAuth = Cookies.get('a_u');
        let decode = getAuth && decodeData(getAuth);
        if(decode){
            setUserInfo(decode)
        };
    },[]);

  const bodyClass = document.querySelector('.navbar-vertical-aside-closed-mode ');
  const logoutHandler = () => {
        const laravelToken = Cookies.get("a_u");
        const nodeToken = Cookies.get("n_a_u");
        let decodeLaravelToken = laravelToken && decodeData(laravelToken);
        let decodeNodeToken = nodeToken && decodeData(nodeToken);
        if(decodeLaravelToken && decodeNodeToken){
            try {
                postMethodService(LOGOUT_NODE_API,{}, false,decodeNodeToken?.token)
                .then((nodeRes) => {
                    if(nodeRes == 401){
                        HandleLogout(navigate);
                    };
                    if(nodeRes?.status === "success"){
                        postMethodService(LOGOUT_API,{}, true)
                        .then((laravelRes) => {
                            if(laravelRes == 401){
                                HandleLogout(navigate);
                            };
                            if(laravelRes?.status || laravelRes?.status === "success"){
                                const delay = setTimeout(()=>{
                                    Cookies.remove("a_u")
                                    Cookies.remove("n_a_u")
                                    navigate('/login');
                                    window.location.reload();
                                },1000);
                    
                                return ()=> {
                                    clearTimeout(delay);
                                };
                            };
                        })
                    };
                })
            }
            catch(err){
                console.log(err);
            };
        }else{
            console.log("Laravel Token is - ",decodeLaravelToken);
            console.log("Node Token is - ",decodeNodeToken);
            
        };
  };

    // const showClosReportHandler = () => {
    //     if((setting?.is_enable_report && !blackListRoles?.includes(userInfo?.role_name))){
    //         return;
    //     }else{
    //         Swal.fire({
    //             title: "Closed!",
    //             text: "ယာယီပိတ်ထားပါသည်။",
    //             icon: "error"
    //         });
    //     };
    // };

    const changePageHandler = (value:any) => {
        setGoLinkUrl(value);
        // if((blackListRoles?.includes(userInfo?.role_name) && blackListPages?.includes(value))){
        //     return  Swal.fire({
        //                 title: "Closed!",
        //                 text: "ယာယီပိတ်ထားပါသည်။",
        //                 icon: "error"
        //             });  
        // }else{
        //     setGoLinkUrl(value);
        // }
        // console.log(blackListRoles?.includes(userInfo?.role_name));
    };


    useEffect(()=>{
        navigate(goLinkUrl)
    },[goLinkUrl]);

  return (
    <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered" style={{backgroundColor: '#25282a'}}>
        <div className="navbar-vertical-container">
            <div className="navbar-vertical-footer-offset">
                 {/* Logo */}
                 <Link className="navbar-brand" to="#" aria-label="Front" onClick={() => changePageHandler("/")}>
                    <img className="" src={sidebarLogo} alt="Logo" style={{width: '100%', marginTop: '15px'}}/>
                 </Link>
                 {/* End Logo */}

                 {/* Navbar Vertical Toggle */}
                 <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler">
                    <i className="bi-arrow-bar-left navbar-toggler-short-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse"></i>
                    <i className="bi-arrow-bar-right navbar-toggler-full-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Expand"></i>
                </button>
                {/* End Navbar Vertical Toggle */}
                <div className="navbar-vertical-content">
                    <div id="navbarVerticalMenu" className="nav nav-pills nav-vertical card-navbar-nav">
                        {
                            (userInfo?.role_name !== 'admin' || (userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0) || userInfo?.permissions?.includes('View Report')) && 
                            <div className="nav-item">
                                <Link className={`nav-link ${url === '/' && 'active'}`} to="#" onClick={() => changePageHandler("/")} data-placement="left">
                                    <i className="bi-house-door nav-icon"></i>
                                    <span className="nav-link-title">Dashboard</span>
                                </Link>
                            </div>
                        }
                        <div className="nav-item">
                            <Link className="nav-link dropdown-toggle collapsed" to="#navbarVerticalMenuDashboards_account" role="button"
                                data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_account"
                                aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_account">
                                <i className="bi bi-people-fill nav-icon"></i>
                                <span className="nav-link-title">Account</span>
                            </Link>
                            <div id="navbarVerticalMenuDashboards_account" className={`nav-collapse collapse ${(url === '/account/change-password' || url === '/account/profile') && 'show'}`}
                                data-bs-parent="#navbarVerticalMenuAccount">
                                {
                                    userInfo?.role_name !== 'admin' &&
                                    <Link className={`nav-link ${url === '/account/profile' && 'active'}`} to="#" onClick={() => changePageHandler("/account/profile")}>Profile</Link>
                                }
                                <Link className={`nav-link ${url === '/account/change-password' && 'active'}`} to="#" onClick={() => changePageHandler("/account/change-password")}>Change password</Link>
                            </div>
                        </div>
                        {
                            (userInfo?.role_name !== 'admin' || (userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0) || (userInfo?.permissions?.includes('View Report'))) &&
                            <div className="nav-item">
                                <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_stock" role="button"
                                    data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_stock"
                                    aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_stock">
                                    <i className="bi bi-graph-up-arrow nav-icon"></i>
                                    <span className="nav-link-title">Stock Management</span>
                                </Link>
                                <div id="navbarVerticalMenuDashboards_stock" className={`nav-collapse collapse
                                ${((url === '/stock-manage/hdp-ou') ||
                                    (url === `/stock-manage-hdp-ou/hdp/${params.id}` && !bodyClass) ||
                                    (url === `/stock-manage-hdp-ou/ou/${params.id}`&& !bodyClass) ||
                                    (url === '/stock-manage/parlay') ||
                                    (url === '/stock-manage/member-outstanding') ||
                                    (url === `/stock-manage/member-outstanding/${params.id}` && !bodyClass) ||
                                    (url === `/stock-manage/mm3d-stock`) ||
                                    (url === `/stock-manage/mm3d-stock/${params.id}`) ||
                                    (url === `/stock-manage/mm3d-outstanding`) ||
                                    (url === `/stock-manage/mm3d-outstanding/${params.user_id}/${params.role_id}`) ||
                                    (url === replaceActiveLink && !bodyClass)
                                    ) && 'show'} 
                                `}
                                    data-bs-parent="#navbarVerticalMenuAccount">

                                        <Link className={`nav-link ${(url === '/stock-manage/hdp-ou' || url === `/stock-manage-hdp-ou/hdp/${params.id}` || url === `/stock-manage-hdp-ou/ou/${params.id}`) && 'active'}`} to="#" onClick={() => changePageHandler("/stock-manage/hdp-ou")}>HDP & OU</Link>
                                        <Link className={`nav-link ${url === '/stock-manage/parlay' && 'active'}`} to="/stock-manage/parlay">Parlay</Link>
                                        <Link className={`nav-link ${((url === '/stock-manage/member-outstanding') || (url === `/stock-manage/member-outstanding/${params.id}`) || (url === replaceActiveLink)) && 'active'}`} to={`#`} onClick={() => changePageHandler("/stock-manage/member-outstanding")}>Member Outstanding</Link>
                                        {
                                            userInfo?.type === 'main' && <Link className={`nav-link ${(url === '/stock-manage/mm3d-stock' || url === `/stock-manage/mm3d-stock/${params.id}`) && 'active'}`} to="#" onClick={() => changePageHandler("/stock-manage/mm3d-stock")}>MM 3D Stock</Link>
                                        }
                                        {
                                            userInfo?.type === 'main' && <Link className={`nav-link ${(url === '/stock-manage/mm3d-outstanding' || url === `/stock-manage/mm3d-outstanding/${params.user_id}/${params.role_id}`) && 'active'}`} to="#" onClick={() => changePageHandler("/stock-manage/mm3d-outstanding")}>MM 3D Outstanding</Link>
                                        } 
                                </div>
                            </div>
                        }
                        {
                            (userInfo?.role_name !== 'admin' || (userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0) || userInfo?.permissions?.includes('View Report')) &&
                            <div className="nav-item">
                                <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_report" role="button"
                                    data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_report"
                                    aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_report">
                                    <i className="bi bi-journal-text nav-icon"></i>
                                    <span className="nav-link-title">Report</span>
                                </Link>
                                <div id="navbarVerticalMenuDashboards_report" className={`
                                    nav-collapse collapse ${
                                        (   url === '/report/win-lose-detail' ||  url === '/report/3d-win-lose-detail' ||
                                            (url === replaceReportActiveLink && !bodyClass) ||
                                            (url === replaceReportThreedActiveLink && !bodyClass) ||
                                            (url === `/report/win-lose-detail-a-user`) ||
                                            (url === `/report/3d-win-lose-detail`) ||
                                            (url === `/report/3d-win-lose-detail/${params.id}/${params.status}/${params.start_date}/${params.end_date}`) ||
                                            url === '/report/member-list' ||
                                            (url === `/report/member-list/${params.id}` && !bodyClass) ||
                                            (url === `/report/member-percent/${params.id}`) ||
                                            url === `/report/transaction-history`
                                        ) && 'show'}
                                `}
                                    data-bs-parent="#navbarVerticalMenuAccount">
                                            <Link className={`nav-link ${(url === '/report/win-lose-detail' || url === replaceReportActiveLink) && 'active'}`} to='/report/win-lose-detail'  onClick={() =>{dispatch(userIdHandler([])); dispatch(dateHandler({}))}}>Win / Lose ( Detail )</Link>
                                            <Link className={`nav-link ${(url === '/report/3d-win-lose-detail' || url === replaceReportThreedActiveLink) && 'active'}`} to={"#"} onClick={() => changePageHandler("/report/3d-win-lose-detail")}>3D Win / Lose ( Detail )</Link>
                                            <Link className={`nav-link ${((url === `/report/member-percent/${params.id}`) || (url === '/report/member-list') || (url === `/report/member-list/${params.id}`)) && 'active'}`} to="#" onClick={() => changePageHandler("/report/member-list")}>Members List</Link>
                                            <Link className={`nav-link ${url === '/report/transaction-history' && 'active'}`} to="#" onClick={() => changePageHandler("/report/transaction-history")}>Deposit & Withdraw History</Link>   
                                </div>
                            </div>
                        }
                        {
                            (userInfo?.type === 'main' && userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0) &&
                            <div className="nav-item">
                                <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_football" role="button"
                                    data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_football"
                                    aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_football">
                                    <i className="bi bi-dribbble nav-icon"></i>
                                    <span className="nav-link-title">Football</span>
                                </a>

                                <div id="navbarVerticalMenuDashboards_football" className={`nav-collapse collapse
                                    ${(url === '/football/bet-list/parlay' || 
                                    url === `/football/bet-list/parlay/${params.id}` ||
                                    url === '/football/bet-list/body' ||
                                    url === '/football/create-odds' ||
                                    url === '/football/created-odds' ||
                                    url === '/football/create-result' ||
                                    // url === '/football/create-result/first-half' ||
                                    // url === '/football/create-result/full-time' ||
                                    url === '/football/setting' ||
                                    url === '/football/team-list' ||
                                    url === '/football/wallet-transaction'
                                    ) && 'show'}
                                `}
                                    data-bs-parent="#navbarVerticalMenuAccount">

                                    <div className="nav-item">
                                        <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_football_4" role="button"
                                            data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_football_4"
                                            aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_football_4">
                                            <span className="nav-link-title">Bet List</span>
                                        </a>
                                        <div id="navbarVerticalMenuDashboards_football_4" className={`nav-collapse collapse 
                                            ${(url === '/football/bet-list/parlay' || 
                                            url === `/football/bet-list/parlay/${params.id}` || 
                                            url === '/football/bet-list/body'
                                            ) && 'show'}
                                        `}
                                            data-bs-parent="#navbarVerticalMenu4">
                                                <Link className={`nav-link ${url === '/football/bet-list/body' && 'active'}`} to="#" onClick={() => changePageHandler("/football/bet-list/body")}>Body</Link>
                                                <Link className={`nav-link ${(url === '/football/bet-list/parlay' || url === `/football/bet-list/parlay/${params.id}`) && 'active'}`} to="#" onClick={() => changePageHandler("/football/bet-list/parlay")}>Parlay</Link>
                                        </div>
                                    </div>
                                    
                                    <Link className={`nav-link ${url === '/football/create-odds' && 'active'}`} to="#" onClick={() => changePageHandler("/football/create-odds")}>Create Odds Match</Link>
                                    <Link className={`nav-link ${url === '/football/created-odds' && 'active'}`} to="#" onClick={() => changePageHandler("/football/created-odds")}>Created Odds Match</Link>
                                    <Link className={`nav-link ${url === '/football/create-result' && 'active'}`} to="#" onClick={() => changePageHandler("/football/create-result")}>Create Result</Link>

                                    {/* <div className="nav-item">
                                        <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_football_create_result" role="button"
                                            data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_football_create_result"
                                            aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_football_create_result">
                                            <span className="nav-link-title">Create Result</span>
                                        </a>
                                        <div id="navbarVerticalMenuDashboards_football_create_result" className={`nav-collapse collapse 
                                            ${(
                                                url === '/football/create-result/first-half' ||
                                                url === '/football/create-result/full-time'
                                            ) && 'show'}
                                        `}
                                            data-bs-parent="#navbarVerticalMenu4">
                                                <Link className={`nav-link ${url === '/football/create-result/first-half' && 'active'}`} to="#" onClick={() => changePageHandler("/football/create-result/first-half")}>1<span style={{fontSize: '10px', marginRight: '5px'}}>st</span> Half</Link>
                                                <Link className={`nav-link ${url === '/football/create-result/full-time' && 'active'}`} to="#" onClick={() => changePageHandler("/football/create-result/full-time")}>Full Time</Link>
                                        </div>

                                    </div> */}
                                    <Link className={`nav-link ${url === '/football/team-list' && 'active'}`} to="#" onClick={() => changePageHandler("/football/team-list")}>Team List</Link>
                                    <Link className={`nav-link ${url === '/football/setting' && 'active'}`} to="#" onClick={() => changePageHandler("/football/setting")}>Settings</Link>
                                    <Link className={`nav-link ${url === '/football/wallet-transaction' && 'active'}`} to="#" onClick={() => changePageHandler("/football/wallet-transaction")}>Wallet Transaction</Link>
                                </div>
                            </div>
                        }
                        {
                            (userInfo?.type === 'sub' && userInfo?.role_name === 'admin' && userInfo?.permissions?.includes('Confirm Result')) &&
                            <>
                                <Link className={`nav-link ${url === '/football/create-result' && 'active'}`} to="#" onClick={() => changePageHandler("/football/create-result")}>Create Result</Link>
                                <div className="nav-item">
                                    <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_feedback" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_feedback"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_feedback">
                                        <i className="bi bi-file-text-fill nav-icon"></i>
                                        <span className="nav-link-title">Complain & Feedback</span>
                                    </a>

                                    <div id="navbarVerticalMenuDashboards_feedback" className={`nav-collapse collapse
                                        ${(url === '/feedback/request' ||
                                        url === `/feedback/request/${params.id}` ||
                                        url === '/feedback/recommend' ||
                                        url === `/feedback/recommend/${params.id}`
                                        ) && 'show'}
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${(url === '/feedback/request' || (url === `/feedback/request/${params.id}`)) && 'active'}`} to="#" onClick={() => changePageHandler("/feedback/request")}>Complain</Link>
                                        <Link className={`nav-link ${(url === '/feedback/recommend' || (url === `/feedback/recommend/${params.id}`)) && 'active'}`} to="#" onClick={() => changePageHandler("/feedback/recommend")}>Feedback</Link>
                                    </div>
                                </div>
                            </>
                            // <div className="nav-item">
                            //     <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_football" role="button"
                            //         data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_football"
                            //         aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_football">
                            //         <i className="bi bi-dribbble nav-icon"></i>
                            //         <span className="nav-link-title">Football</span>
                            //     </a>

                            //     <div id="navbarVerticalMenuDashboards_football" className={`nav-collapse collapse
                            //         ${(
                            //         url === '/football/create-result/first-half' ||
                            //         url === '/football/create-result/full-time'
                            //         ) && 'show'}
                            //     `}
                            //         data-bs-parent="#navbarVerticalMenuAccount">
                            //         <Link className={`nav-link ${url === '/football/create-result/first-half' && 'active'}`} to="#" onClick={() => changePageHandler("/football/create-result/first-half")}>1<span style={{fontSize: '10px', marginRight: '5px'}}>st</span> Half</Link>
                            //         <Link className={`nav-link ${url === '/football/create-result/full-time' && 'active'}`} to="#" onClick={() => changePageHandler("/football/create-result/full-time")}>Full Time</Link>
                            //     </div>
                            // </div>
                        }
                        
                        {
                            (userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0 && userInfo?.type === 'main') &&
                            <div className="nav-item">
                            <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_3d" role="button"
                                data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_3d"
                                aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_3d">
                                <i className="bi bi-3-square nav-icon"></i>
                                <span className="nav-link-title">3D</span>
                            </a>
                            <div id="navbarVerticalMenuDashboards_3d" className={`nav-collapse collapse
                                ${(url === '/mm3d/settings' ||
                                url === '/mm3d/winners' || url === '/mm3d/reports' || url === '/mm3d/results' || url === '/mm3d/bet-lists' || url === '/mm3d/transactions'
                                ) && 'show'}
                            `}
                                data-bs-parent="#navbarVerticalMenuAccount">
                                <Link className={`nav-link ${url === '/mm3d/settings' && 'active'}`} to="/mm3d/settings">Settings</Link>
                                {
                                    userInfo?.role_name === 'admin' &&
                                    <Link className={`nav-link ${url === '/mm3d/bet-lists' && 'active'}`} to="#" onClick={() => changePageHandler("/mm3d/bet-lists")}>Bet Lists</Link>
                                }
                                <Link className={`nav-link ${url === '/mm3d/winners' && 'active'}`} to="#" onClick={() => changePageHandler("/mm3d/winners")}>Winners</Link>
                                <Link className={`nav-link ${url === '/mm3d/results' && 'active'}`} to="#" onClick={() => changePageHandler("/mm3d/results")}>Results</Link>
                                <Link className={`nav-link ${url === '/mm3d/transactions' && 'active'}`} to="#" onClick={() => changePageHandler("/mm3d/transactions")}>Wallet Transactions</Link>
                            </div>
                        </div>
                        }
                        {
                            (userInfo?.type === 'sub' && userInfo?.role_name === 'admin' && userInfo?.permissions?.includes('Create Match')) &&
                            <>
                                <div className="nav-item">
                                    <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_football" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_football"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_football">
                                        <i className="bi bi-dribbble nav-icon"></i>
                                        <span className="nav-link-title">Football</span>
                                    </a>

                                    <div id="navbarVerticalMenuDashboards_football" className={`nav-collapse collapse
                                        ${(
                                        url === '/football/create-odds' ||
                                        url === '/football/created-odds'
                                        ) && 'show'}
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${url === '/football/create-odds' && 'active'}`} to="#" onClick={() => changePageHandler("/football/create-odds")}>Create Odds Match</Link>
                                        <Link className={`nav-link ${url === '/football/created-odds' && 'active'}`} to="#" onClick={() => changePageHandler("/football/created-odds")}>Created Odds Match</Link>
                                    </div>
                                </div>
                                <div className="nav-item">
                                    <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_feedback" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_feedback"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_feedback">
                                        <i className="bi bi-file-text-fill nav-icon"></i>
                                        <span className="nav-link-title">Complain & Feedback</span>
                                    </a>

                                    <div id="navbarVerticalMenuDashboards_feedback" className={`nav-collapse collapse
                                        ${(url === '/feedback/request' ||
                                        url === `/feedback/request/${params.id}` ||
                                        url === '/feedback/recommend' ||
                                        url === `/feedback/recommend/${params.id}`
                                        ) && 'show'}
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${(url === '/feedback/request' || (url === `/feedback/request/${params.id}`)) && 'active'}`} to="#" onClick={() => changePageHandler("/feedback/request")}>Complain</Link>
                                        <Link className={`nav-link ${(url === '/feedback/recommend' || (url === `/feedback/recommend/${params.id}`)) && 'active'}`} to="#" onClick={() => changePageHandler("/feedback/recommend")}>Feedback</Link>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            ((userInfo?.role_name !== 'admin' || (userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0)) && userInfo?.type === 'main') &&
                            <div className="nav-item">
                                <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_member_manage" role="button"
                                    data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_member_manage"
                                    aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_member_manage">
                                    <i className="bi bi-people-fill nav-icon"></i>
                                    <span className="nav-link-title">Member Management</span>
                                </Link>
                                <div id="navbarVerticalMenuDashboards_member_manage" className={`nav-collapse collapse
                                ${( (url === '/member/roles/2') ||
                                    (url === '/member/roles/3') ||
                                    (url === '/member/roles/4') ||
                                    (url === '/member/roles/5') ||
                                    (url === '/member/roles/6') ||
                                    (url === '/member/sub-user') ||
                                    (url === `/report/member-management/detail/${params.id}`) 
                                ) && 'show'} 
                                `}
                                    data-bs-parent="#navbarVerticalMenuAccount">
                                    
                                    {
                                        userInfo?.role_name === 'admin' &&
                                        <Link className={`nav-link ${url === '/member/roles/2' && 'active'}`} to="#" onClick={() => changePageHandler("/member/roles/2")}>Super</Link>
                                    }
                                    {
                                        userInfo?.role_name === 'super' &&
                                        <Link className={`nav-link ${url === '/member/roles/3' && 'active'}`} to="#" onClick={() => changePageHandler("/member/roles/3")}>Senior</Link>
                                    }
                                    {
                                        userInfo?.role_name === 'senior' &&
                                        <Link className={`nav-link ${url === '/member/roles/4' && 'active'}`} to="#" onClick={() => changePageHandler("/member/roles/4")}>Master</Link>
                                    }
                                    {
                                        (userInfo?.role_name === 'senior' || userInfo?.role_name === 'master') &&
                                        <Link className={`nav-link ${url === '/member/roles/5' && 'active'}`} to="#" onClick={() => changePageHandler("/member/roles/5")}>Agent</Link>
                                    }
                                    {
                                        (userInfo?.role_name === 'senior' || userInfo?.role_name === 'master' || userInfo?.role_name === 'agent') &&
                                        <Link className={`nav-link ${url === '/member/roles/6' && 'active'}`} to="#" onClick={() => changePageHandler("/member/roles/6")}>User</Link>
                                    }
                                    {
                                        userInfo?.type === 'main' && <Link className={`nav-link ${url === '/member/sub-user' && 'active'}`} to="#" onClick={() => changePageHandler("/member/sub-user")}>Sub User</Link>
                                    }
                                </div> 
                            </div>
                        }
                        {
                            (userInfo?.role_name !== 'admin' || (userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0 && userInfo?.type === 'main')) &&
                            <div className="nav-item">
                                <Link className="nav-link dropdown-toggle" to="#navbarVerticalMenuDashboards_deposit_withdraw" role="button"
                                    data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_deposit_withdraw"
                                    aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_deposit_withdraw">
                                    <i className="bi bi-journal-text nav-icon"></i>
                                    <span className="nav-link-title">Payment</span>
                                </Link>
                                <div id="navbarVerticalMenuDashboards_deposit_withdraw" className={`nav-collapse collapse ${url === '/payment/deposit-withdraw' && 'show'}`}
                                
                                    data-bs-parent="#navbarVerticalMenuAccount">
                                    
                                    <Link className={`nav-link ${url === '/payment/deposit-withdraw' && 'active'}`} to="#" onClick={() => changePageHandler("/payment/deposit-withdraw")}>Deposit & Withdraw</Link>
                                </div>
                            </div>
}
                        {/* <div className="nav-item">
                            <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_2d" role="button"
                                data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_2d"
                                aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_3d">
                                <i className="bi bi-2-square nav-icon"></i>
                                <span className="nav-link-title">MM 2D</span>
                            </a>

                            <div id="navbarVerticalMenuDashboards_2d" className={`nav-collapse collapse
                                ${(url === '/mm2d/settings' ||
                                url === '/mm2d/bet-lists' || url === '/mm2d/reports' || url === '/mm2d/winners' || url === '/mm2d/results'
                                ) && 'show'}
                            `}
                                data-bs-parent="#navbarVerticalMenuAccount">
                                {
                                    userInfo?.role_name === 'admin' &&
                                    <Link className={`nav-link ${url === '/mm2d/settings' && 'active'}`} to="/mm2d/settings">Settings</Link>
                                }
                                <Link className={`nav-link ${url === '/mm2d/bet-lists' && 'active'}`} to="/mm2d/bet-lists">Bet Lists</Link>
                                <Link className={`nav-link ${url === '/mm2d/winners' && 'active'}`} to="/mm2d/winners">Winners</Link>
                                <Link className={`nav-link ${url === '/mm2d/results' && 'active'}`} to="/mm2d/results">Results</Link>
                                <Link className={`nav-link ${url === '/mm2d/reports' && 'active'}`} to="/mm2d/reports">Reports</Link>
                            </div>
                        </div> */}
                        {
                            (userInfo?.type === 'main' && userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0) &&
                            <>
                                <div className="nav-item">
                                    <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_social" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_social"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_social">
                                        <i className="bi bi-newspaper nav-icon"></i>
                                        <span className="nav-link-title">Community</span>
                                    </a>

                                    <div id="navbarVerticalMenuDashboards_social" className={`nav-collapse collapse
                                        ${(url === '/community/news' ||
                                        url === '/community/create-news' ||
                                        url === `/community/edit-news/${params.id}`
                                        ) && 'show'}
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${(url === '/community/news' || (url === '/community/create-news') || (url === `/community/edit-news/${params.id}`)) && 'active'}`} to="#" onClick={() => changePageHandler("/community/news")}>News</Link>
                                    </div>
                                </div>
                                <div className="nav-item">
                                    <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_feedback" role="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_feedback"
                                        aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_feedback">
                                        <i className="bi bi-file-text-fill nav-icon"></i>
                                        <span className="nav-link-title">Complain & Feedback</span>
                                    </a>

                                    <div id="navbarVerticalMenuDashboards_feedback" className={`nav-collapse collapse
                                        ${(url === '/feedback/request' ||
                                          url === `/feedback/request/${params.id}` ||
                                          url === '/feedback/recommend' ||
                                          url === `/feedback/recommend/${params.id}`
                                        ) && 'show'}
                                    `}
                                        data-bs-parent="#navbarVerticalMenuAccount">
                                        <Link className={`nav-link ${(url === '/feedback/request' || (url === `/feedback/request/${params.id}`)) && 'active'}`} to="#" onClick={() => changePageHandler("/feedback/request")}>Complain</Link>
                                        <Link className={`nav-link ${(url === '/feedback/recommend' || (url === `/feedback/recommend/${params.id}`)) && 'active'}`} to="#" onClick={() => changePageHandler("/feedback/recommend")}>Feedback</Link>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            (userInfo?.type === 'main' && userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0) &&
                            <div className="nav-item">
                                <a className="nav-link dropdown-toggle" href="#navbarVerticalMenuDashboards_setting" role="button"
                                    data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuDashboards_setting"
                                    aria-expanded="false" aria-controls="navbarVerticalMenuDashboards_setting">
                                    <i className="bi bi-gear-fill nav-icon"></i>
                                    <span className="nav-link-title">Settings</span>
                                </a>

                                <div id="navbarVerticalMenuDashboards_setting" className={`nav-collapse collapse
                                    ${(url === '/setting/app-setting' ||
                                    url === '/setting/rules-regulations'
                                    ) && 'show'}
                                `}
                                    data-bs-parent="#navbarVerticalMenuAccount">
                                    <Link className={`nav-link ${url === '/setting/app-setting' && 'active'}`} to="#" onClick={() => changePageHandler("/setting/app-setting")}>App Settings</Link>
                                    <Link className={`nav-link ${url === '/setting/rules-regulations' && 'active'}`} to="#" onClick={() => changePageHandler("/setting/rules-regulations")}>Rules And Regulations</Link>
                                </div>
                            </div>
                        }
                        
                        <span className="mt-4 dropdown-header">Logout</span>
                        <small className="bi-three-dots nav-subtitle-replacer"></small>
                        <div className="nav-item">
                            <Link className='nav-link' to="" style={{cursor: 'pointer'}} data-placement="left" onClick={logoutHandler}>
                                <i className="bi bi-box-arrow-right nav-icon"></i>
                                <span className="nav-link-title">Logout</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aside>
      
  )
}

export default Sidebar
