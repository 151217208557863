import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { getMethodService } from '../services';
import { HandleLogout, amountCommasSparated, decodeData } from '../utils/Helper';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MEMBER_LIST_NODE_API, USER_DETAIL_NODE_API } from '../services/constants';
import Template from '../utils/Template';
import ScreenLoader from '../utils/ScreenLoader';
import MemberPercentDetail from '../utils/MemberPercentDetail';
const Cookies = require('js-cookie');

const ReportMemberPercentage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [userDetailData,setUserDetailData] = useState<any>({});
    const navigate = useNavigate();
    const {id} = useParams();
    
    const fetchUserPercent = (userId:any) => {
        if(userId){
            const getAuth = Cookies.get('n_a_u');
            if(getAuth){
                let decode = decodeData(getAuth);
                getMethodService(`${USER_DETAIL_NODE_API(userId)}?is_show_wallet_detail=${true}`,{},false,decode?.token)
                .then((res) => {
                    setIsLoading(false);
                    if (res == 401) {
                        HandleLogout(navigate);
                    }
                    if (res?.status === "success") {
                       setUserDetailData(res?.data);
                    }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsLoading(false);
                    });
            };
        }
    };

    useEffect(()=>{
        if(id){
            fetchUserPercent(id)
        }
    },[id]);
    
  return (
    <>
       <Template title={`Member - ${userDetailData?.usercode ??'??'} ( ${userDetailData?.role ??'??'} )`}>
            {/* <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6">
                            <h4 className="card-title">
                                {
                                    `Member - ${userDetailData?.usercode ??'??'} ( ${userDetailData?.role ??'??'} )`
                                }
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">Cash Balance</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.wallet?.cash_balance ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">Account Balance</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.wallet?.account_balance ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">Member Balance</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.wallet?.member_balance ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">Min Bet Limit</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.bet_setting?.min_bet_limit ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">Max Single Bet Limit</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.bet_setting?.max_single_bet_limit ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">Match Limit</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.bet_setting?.match_limit ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">Min Perlay Limit Per Combination</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.bet_setting?.min_myanmar_perlay_limit_per_combination ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">Max Perlay Limit Per Combination</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.bet_setting?.max_myanmar_perlay_limit_per_combination ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">3D Limit</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.bet_setting?.three_d_limit ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">3D Min Per Bet</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.bet_setting?.three_d_min_per_bet ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="row mb-2">
                                <label className="col-12 col-form-label form-label">3D Max Per Bet</label>
                                <div className="col-12">
                                <span className="form-control">
                                    {amountCommasSparated(userDetailData?.bet_setting?.three_d_max_per_bet ?? '??')}
                                </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
            <div className='row'>
                <div className='col-lg-4'>
                    <div className='card mb-2 mb-lg-0 rounded-1'>
                        <div className="card-header bg-dark">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-6">
                                    <h4 className="card-title mb-0 text-white">
                                        Balance
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <MemberPercentDetail title="Cash Balance" value={amountCommasSparated(userDetailData?.wallet?.cash_balance ?? '??')}/>
                            <MemberPercentDetail title="Account Balance" value={amountCommasSparated(userDetailData?.wallet?.account_balance ?? '??')}/>
                            <MemberPercentDetail title="Member Balance" value={amountCommasSparated(userDetailData?.wallet?.member_balance ?? '??')}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className='card mb-2 mb-lg-0 rounded-1'>
                        <div className="card-header bg-dark">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-6">
                                    <h4 className="card-title mb-0 text-white">
                                        Football Setting
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <MemberPercentDetail title="Min Bet Limit" value={amountCommasSparated(userDetailData?.bet_setting?.min_bet_limit ?? '??')}/>
                            <MemberPercentDetail title="Max Single Bet Limit" value={amountCommasSparated(userDetailData?.bet_setting?.max_single_bet_limit ?? '??')}/>
                            <MemberPercentDetail title="Match Limit" value={amountCommasSparated(userDetailData?.bet_setting?.match_limit ?? '??')}/>
                            <MemberPercentDetail title="Min Perlay Limit Per Combination" value={amountCommasSparated(userDetailData?.bet_setting?.min_myanmar_perlay_limit_per_combination ?? '??')}/>
                            <MemberPercentDetail title="Max Perlay Limit Per Combination" value={amountCommasSparated(userDetailData?.bet_setting?.max_myanmar_perlay_limit_per_combination ?? '??')}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className='card mb-2 mb-lg-0 rounded-1'>
                        <div className="card-header bg-dark">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-6">
                                    <h4 className="card-title mb-0 text-white">
                                        3D Setting
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <MemberPercentDetail title="3D Limit" value={amountCommasSparated(userDetailData?.bet_setting?.three_d_limit ?? '??')}/>
                            <MemberPercentDetail title="3D Min Per Bet" value={amountCommasSparated(userDetailData?.bet_setting?.three_d_min_per_bet ?? '??')}/>
                            <MemberPercentDetail title="3D Max Per Bet" value={amountCommasSparated(userDetailData?.bet_setting?.three_d_max_per_bet ?? '??')}/>
                        </div>
                    </div>
                </div>
            </div>

       </Template>
        <ScreenLoader isLoading={isLoading} />
    </>
  )
}

export default ReportMemberPercentage
