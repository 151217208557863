import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../assets/styles/Maintenance.module.css';

const Maintenance = ({settingJson}) => {
  const [value,setValue] = useState("--");
  const navigate = useNavigate();

  useEffect(()=>{
    if(settingJson?.is_enable_dashboard){
        navigate('/');
    }else{
        setValue(settingJson?.disable_remark);
    };
  },[settingJson]);

  return (
    <div className={classes['maintain-container']}>
        <div className={classes['error-image']}>
            <h1>👨‍🔧</h1>
        </div>
        <div className={classes['error-msg-container']}>
            <h1>{value ? 'We\'re working on this site now!' :'Connection lost'}</h1>
            <p className={classes.maintenance_text}>{value ?? 'ဆာဗာနှင့်ချိတ်ဆက်၍မရပါ ပြန်လည်ကြိုးစားပါ။'}</p>
            <p>We apologize for any inconvenience.</p>
        </div>
    </div>
  )
}

export default Maintenance
