import React, { useEffect, useState } from 'react'

const CreatedApiSource = ({id,getUpdateData,source,source_type}:any) => {
  return (
    <select value={source} style={{
        border: 'none',
        borderRadius: '5px',
        outline: 'none'
    }} className="bg-info text-white py-1 ps-1 w-100" onChange={e => {
        getUpdateData(id,e.target.value,source_type);
    }}>
        <option value="a" className='text-center'>A</option>
        <option value="b" className='text-center'>B</option>
        <option value="c" className='text-center'>C</option>
        <option value="d" className='text-center'>D</option>
        <option value="e" className='text-center'>E</option>
    </select>
  )
}

export default CreatedApiSource
