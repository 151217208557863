import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import NormalDateRangeFormat from '../utils/NormalDateRangeFormat';
import { useSelector } from 'react-redux';
import { TRANSFER_RECORD_NODE_API } from '../services/constants';
import { getMethodService } from '../services';
import { useQuery } from '@tanstack/react-query';
import { HandleLogout, decodeData } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import TableScroll from '../utils/TableScroll';
import TableLoading from '../utils/TableLoading';
import Pagi from '../utils/Pagi';
import { stateObjType } from '../types';
const Cookies = require('js-cookie');

const ReportTransactionHistory = () => {
    const abortController = new AbortController();
    const [isLoading,setIsLoading] = useState(false);

    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const [search, setSearch] = useState("");
    const [transactions,setTransactions] = useState([]);

    const [totalPage,setTotalPage] = useState(1);
    const [page,setPage] = useState(1);
    const [from,setFrom] = useState(1);
    const [upOrDownline,setUpOrDownline] = useState('downline');
    const [type,setType] = useState("");
    const navigate = useNavigate();
    const [refreshKey, setRefreshKey] = useState(0);
    const [userInfo,setUserInfo] = useState<any>({})
    useEffect(()=>{
        const getAuth = Cookies.get('a_u');
        let decode = getAuth && decodeData(getAuth);
        if(decode){
            setUserInfo(decode)
        };
    },[]);

    function formatDate(date:any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    };

    const fetchTranHistData = (
        search:string | number | undefined,start_date: string | undefined, end_date: string | undefined, line:string | number | undefined, type:string | number | undefined, page: string | number | undefined
    ) => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            return getMethodService(`${TRANSFER_RECORD_NODE_API}?${upOrDownline? `line_type=${line}&`: ''}${search? `search=${search}&`: ''}${startDate? `start_date=${start_date}&`: ''}${endDate? `end_date=${end_date}&`: ''}${type? `transaction_type_id[]=${type}&`: ''}sortColumn=id&sortDirection=desc&perPage=20&page=${page}`,{},false,decode?.token,abortController);
        };
    };
    const userReportQuery = useQuery({ queryKey: ['transHistData',page,refreshKey], queryFn: () => fetchTranHistData(search,startDate,endDate,upOrDownline, type, page),keepPreviousData : true, refetchOnWindowFocus: false});
    useEffect(()=>{
        if(userReportQuery){
            setIsLoading(userReportQuery?.isLoading);
            if(userReportQuery?.data == 401){
                HandleLogout(navigate);
            };
            if(userReportQuery?.data?.status === "success"){
                if(userReportQuery?.data?.meta){
                    let totalPages = Number(Math.ceil((userReportQuery?.data?.meta?.total) / (userReportQuery?.data?.meta?.perPage)));
                    let fromPages = Number(((userReportQuery?.data?.meta?.perPage)) * Number((userReportQuery?.data?.meta?.page)) -  Number(userReportQuery?.data?.meta?.perPage));
                    setFrom(fromPages + 1);
                    setTotalPage(totalPages);
                };
                setTransactions(userReportQuery?.data?.data);
            };
        };
    },[userReportQuery]);

    const handleUplineDownline = (value:any) =>{
        // setIsLoading(true);
        setUpOrDownline(value);
    }

    const submitHandler = () => {
        setIsLoading(true);
        setRefreshKey(prevKey => prevKey + 1);
    };
    const handleTransactionTypeChange = (e:any) => {
        setType(e.target.value);
    };
    useEffect(()=>{
        return () => {
            abortController.abort();
        };
    },[]);
  return (
    <> 
       <Template title="Report">
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row justify-content-between">
                        <div className="col-lg-5 d-flex justify-content-between align-items-center">
                            <h4 className="card-title mb-3 mb-lg-0">
                                Transaction History
                            </h4>
                        </div>
                        <div className="col-lg-7">
                            <NormalDateRangeFormat startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                        </div>
                    </div>
                    <div className="row justify-content-between align-items-sm-center">
                        <div className="col-lg-3 mb-3 mb-lg-0">
                            <div>
                                <ul className="nav nav-segment nav-pills" role="tablist">
                                    <li className="nav-item">
                                        <a className={`nav-link ${upOrDownline === 'downline' && 'bg-primary text-white'}`} id="nav-one-eg1-tab" href="#nav-one-eg1" data-bs-toggle="pill" data-bs-target="#nav-one-eg1" role="tab" aria-controls="nav-one-eg1" aria-selected="true" onClick={e => handleUplineDownline('downline')}>Downline</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${upOrDownline === 'upline' && 'bg-primary text-white'}`} id="nav-two-eg1-tab" href="#nav-two-eg1" data-bs-toggle="pill" data-bs-target="#nav-two-eg1" role="tab" aria-controls="nav-two-eg1" aria-selected="false" onClick={e => handleUplineDownline('upline')}>Upline</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-3 mb-lg-0">
                            <select
                                className="mt-2 form-select"
                                onChange={(e) => {
                                    handleTransactionTypeChange(
                                        e
                                    );
                                }}
                                value={type}
                            >
                                <option
                                    value=""
                                    selected
                                >
                                    All
                                </option>
                                <option value="1">Deposit</option>
                                <option value="2">Withdraw</option>
                            </select>
                        </div>
                        <div className="col-lg-3 mb-3 mb-lg-0">
                            <input type="text" className="p-2 mt-2 form-control w-100" placeholder="Search..." onChange={
                                e => setSearch(e.target.value)
                            }/>
                        </div>
                        <div className="col-lg-3 mb-3 mb-lg-0">
                            <button
                                className="btn btn-primary w-100"
                                onClick={submitHandler}
                                disabled={isLoading}
                            >
                                {isLoading ? "Loading ..." : "Submit"}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                 <div>
                                    <table className="table border table-bordered table-striped text-white">
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                <th className="text-center text-light p-2"  >No</th>
                                                <th className="text-center text-light p-2"  >Name</th>
                                                <th className="text-center text-light p-2"  >Pay Date</th>
                                                <th className="text-center text-light p-2"  >Type</th>
                                                <th className="text-center text-light p-2"  >Pre Balance</th>
                                                <th className="text-center text-light p-2"  >Amount</th>
                                                <th className="text-center text-light p-2"  >Balance</th>
                                                <th className="text-center text-light p-2"  >Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && (
                                                <TableLoading col={8} />
                                            )}
                                            {transactions.length > 0 ? (
                                                transactions.map((transaction:stateObjType, index) => (
                                                    <tr style={{verticalAlign: 'middle'}} key={index}>
                                                        <td className="p-2 text-center text-dark">{index + from}</td>
                                                        <td className="p-2 text-center text-dark">
                                                            {
                                                                userInfo.id != transaction.receiver.id ? transaction.receiver.usercode : transaction.sender.usercode
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">{transaction?.created_at}</td>
                                                        <td className="p-2 text-center text-dark">
                                                            <span className={`badge bg-${transaction.transaction_type.name === 'Deposit'? 'success': 'danger'}`}>
                                                                {
                                                                    transaction.transaction_type
                                                                        .name
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className="p-2 text-end text-dark">
                                                            <b>
                                                                {
                                                                    userInfo.id == transaction.sender.id ?
                                                                    (
                                                                        transaction.sender_before <= 0?
                                                                        <span className="text-danger">-{transaction.sender_before}</span>:
                                                                        <span>{transaction.sender_before?.toLocaleString('en-Us')}</span>
                                                                    ) :
                                                                    (
                                                                        transaction.receiver_before <= 0?
                                                                        <span className="text-danger">-{transaction.receiver_before}</span>:
                                                                        <span>{transaction.receiver_before?.toLocaleString('en-Us')}</span>
                                                                    )
                                                                }
                                                            </b>
                                                        </td>
                                                        <td className="p-2 text-end text-dark">
                                                            {
                                                                transaction.transaction_type.name === "Withdraw"?
                                                                <b className="text-danger">
                                                                    -{transaction.transfer_amount?.toLocaleString('en-Us')}
                                                                </b>:
                                                                <b>
                                                                    {transaction.transfer_amount?.toLocaleString('en-Us')}
                                                                </b>
                                                            }
                                                        </td>
                                                        <td className="p-2 text-end text-dark">
                                                            <b>
                                                                {
                                                                    userInfo.id == transaction.sender.id ?
                                                                    (
                                                                        transaction.sender_after <= 0?
                                                                        <span className="text-danger">-{transaction.sender_after}</span>:
                                                                        <span>{transaction.sender_after?.toLocaleString('en-Us')}</span>
                                                                    ) :
                                                                    (
                                                                        transaction.receiver_after <= 0?
                                                                        <span className="text-danger">-{transaction.receiver_after}</span>:
                                                                        <span>{transaction.receiver_after?.toLocaleString('en-Us')}</span>
                                                                    )
                                                                }
                                                            </b>
                                                        </td>

                                                        <td className='text-center'>
                                                             { transaction?.remark??'--' }
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={8} className="text-center">
                                                        No Data
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                 </div>
                            </TableScroll>
                            {
                                totalPage > 1 && <Pagi setPage={setPage} setIsLoading={setIsLoading} totalPage={totalPage}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
       </Template>
    </>
  )
}

export default ReportTransactionHistory
