import React, { useEffect, useState } from "react";
import { BanUserTypes } from "../../types";
const {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
} = require("reactstrap");



function BanUserModal(props:BanUserTypes) {
    const [banTill,setBanTill] = useState("");
    const [remark,setRemark] = useState("");
    const handleSubmit = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        props.onSubmit({ban_till:banTill,remark: remark});
    }


  return (
    <Modal isOpen={true} toggle={props.onClose}>
      <ModalHeader toggle={props.onClose}>Ban User</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="ban_till">Ban Type</Label>
            <Input type="select" name="ban_till" id="ban_till" onChange={(e: React.ChangeEvent<HTMLSelectElement>)=>setBanTill(e.target.value)}>
              <option value="">Select ban type</option>
              <option value="permanent">Ban permanently</option>
              <option value="7">Ban 7 days</option>
              <option value="14">Ban 14 days</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="remark">Remark</Label>
            <Input type="textarea" name="remark" id="remark" onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setRemark(e.target.value)}/>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={props.onClose}>
          No
        </button>
        {
          props.isLoading ?
          <button className="btn btn-primary" disabled={true}>
            Loading...
          </button>:
          <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
            Save
          </button>
        }
      </ModalFooter>
    </Modal>
  );
}

export default BanUserModal;
