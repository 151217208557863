import React, { useEffect, useState } from 'react';

const useDebounce = (value:string,delay:number) => {
  const [debounceValue,setDebounceValue] = useState('');
  useEffect(()=>{
    const valueHandler = setTimeout(() => {
        setDebounceValue(value);
    },delay);
    return () => clearTimeout(valueHandler);
  },[value,delay]);
  return debounceValue;
}

export default useDebounce
