import React, { useEffect, useState } from 'react'
import Template from '../../utils/Template'
import { getMethodService, putMethodService } from '../../services';
import { FIXTURES_API, UPDATE_FIXTURE } from '../../services/constants';
import { useQuery } from '@tanstack/react-query';
import { HandleLogout, capitalizedText, replaceZeotoQqual } from '../../utils/Helper';
import { useNavigate } from 'react-router-dom';
import TableScroll from '../../utils/TableScroll';
import TableLoading from '../../utils/TableLoading';
import { stateObjType } from '../../types';
import FixtureToggle from '../../utils/FixtureToggle';
import { ToastContainer } from 'react-toastify';
import EditFixtureModal from '../../utils/Modal/EditFixtureModal';
import CreatedApiSource from '../../utils/CreatedApiSource';

const FootballCreatedOdds = () => {
  const [mmFootballCategory, setMmFootballCategory] = useState(0);
  const [isLoading,setIsLoading] = useState(true);
  const [fixtures, setFixtures] = useState([]);
  const [resultFixtures, setResultFixtures] = useState([]);
  const [findFixtures, setFindFixtures] = useState([]);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0);
  const [type,setType] = useState('');
  const [showLoading,setShowLoading] = useState(true);
  const [search,setSearch] = useState("");

  const actualDate = new Date();
  const footballDate = new Date();
  var checkTime = new Date();
  checkTime.setHours(10); checkTime.setMinutes(29);
  if(actualDate.getTime() < checkTime.getTime()){
      footballDate.setDate(footballDate.getDate() - 1);
  }
  const year = footballDate.getFullYear();
  const month = String(footballDate.getMonth() + 1).padStart(2, "0");
  const day = footballDate.getDate();
  const currentDate = `${year}-${month}-${day.toString().padStart(2, "0")} 10:29`;

  const fetchAlreadyOdds = () => getMethodService(`${FIXTURES_API}?source=created&status=active&limit=1000&sortColumn=fixture_timestamp&sortDirection=asc`);
  const alreadyOddsQuery = useQuery({ queryKey: ['alreadyOdds',refreshKey,type], queryFn: fetchAlreadyOdds, refetchOnWindowFocus: false});

  useEffect(()=>{
    if(alreadyOddsQuery){
        if(alreadyOddsQuery?.data == 401){
            HandleLogout(navigate);
            setIsLoading(false);
        };
        if(alreadyOddsQuery?.data?.status === "success"){
            setIsLoading(false);
            setFixtures(alreadyOddsQuery?.data?.data);
        };
    }
 },[alreadyOddsQuery]);

 useEffect(() => {
    if (mmFootballCategory == 0) {
        setResultFixtures(fixtures);
    } else {
        let result = fixtures.filter((fixture:any) => fixture?.mm_football_category.id == mmFootballCategory);
        setResultFixtures(result);
    }
}, [mmFootballCategory, fixtures]);

const fixtureToggleHandler = (id:any,status:any,data:any) => {
    if(data){
        const prevData = {
            is_published:status,
        };
        putMethodService(UPDATE_FIXTURE(id), prevData, true)
        .then((response) => {
            if(response == 401){
                HandleLogout(navigate);
            };
            if (response.status === "success") {
                setShowLoading(false);
                setRefreshKey(prevKey => prevKey + 1);
            }
        })
        .catch((err) => {
            setIsLoading(false);
            console.error(err);
        });
    };
};

const hideCreateModal = () => {
    setIsShowEditModal(false);
};

const handleEdit = (data:any) => {
    setData(data);
    setIsShowEditModal(true);
};

const submitUpdate = (data:any, id:any) => {
    if(data){
        putMethodService(UPDATE_FIXTURE(id), data, true)
        .then((response) => {
            if(response == 401){
                HandleLogout(navigate);
            }
            if (response.status === "success") {
                setIsShowEditModal(false);
                setShowLoading(true);
                setRefreshKey(prevKey => prevKey + 1);
            };
        })
        .catch((err) => {
            setIsLoading(false);
            console.error(err);
        });
    };
};

const getUpdateData = (id:any,type:any, source_type:any) => {
    putMethodService(UPDATE_FIXTURE(id), {[source_type]:type}, true)
    .then((response) => {
        if(response == 401){
            HandleLogout(navigate);
        }
        if (response.status === "success") {
            setIsShowEditModal(false);
            setShowLoading(true);
            setRefreshKey(prevKey => prevKey + 1);
        };
    })
    .catch((err) => {
        setIsLoading(false);
        console.error(err);
    });
};

 useEffect(()=>{
    const capitalized = capitalizedText(search);
    let find = resultFixtures?.filter((result:any) => 
      result?.league_data?.name?.includes(capitalized) ||
      result?.home_team_data?.name?.includes(capitalized) ||
      result?.home_team_data?.name_mm?.includes(capitalized) ||
      result?.away_team_data?.name?.includes(capitalized) ||
      result?.away_team_data?.name_mm?.includes(capitalized)
    );
    setFindFixtures(find);
  },[search,resultFixtures]);

  console.log(findFixtures)

  return (
    <>
        <Template title='Football'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <h4 className="card-title mb-2">
                                Created Odds - {resultFixtures?.length ?? 0}
                            </h4>
                        </div>
                        <div className='col-sm-3'>
                            <input type="text" className="form-control mb-2 mb-md-0" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)}/>
                        </div>
                        <div className="col-sm-6">
                            <div className="text-end">
                                <ul
                                    className="nav nav-segment nav-pills"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item"
                                        onClick={() => {
                                            setMmFootballCategory(0);
                                        }}
                                    >
                                        <a
                                            className={`nav-link  ${
                                                mmFootballCategory == 0
                                                    ? "active bg-primary text-white"
                                                    : ""
                                            }`}
                                            id="nav-one-eg1-tab"
                                            href="#nav-one-eg1"
                                            data-bs-toggle="pill"
                                            data-bs-target="#nav-one-eg1"
                                            role="tab"
                                            aria-controls="nav-one-eg1"
                                            aria-selected="true"
                                            style={{fontSize: '12px'}}
                                        >
                                            အားလုံး
                                        </a>
                                    </li>

                                    <li
                                        className="nav-item"
                                        onClick={() => {
                                            setMmFootballCategory(1);
                                        }}
                                    >
                                        <a
                                            className={`nav-link  ${
                                                mmFootballCategory == 1
                                                    ? "active bg-primary text-white"
                                                    : ""
                                            }`}
                                            id="nav-one-eg1-tab"
                                            href="#nav-one-eg1"
                                            data-bs-toggle="pill"
                                            data-bs-target="#nav-one-eg1"
                                            role="tab"
                                            aria-controls="nav-one-eg1"
                                            aria-selected="true"
                                            style={{fontSize: '12px'}}
                                        >
                                            ပွဲသေး
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item"
                                        onClick={() => {
                                            setMmFootballCategory(2);
                                        }}
                                    >
                                        <a
                                            className={`nav-link  ${
                                                mmFootballCategory == 2
                                                    ? "active bg-primary text-white"
                                                    : ""
                                            }`}
                                            id="nav-two-eg1-tab"
                                            href="#nav-two-eg1"
                                            data-bs-toggle="pill"
                                            data-bs-target="#nav-two-eg1"
                                            role="tab"
                                            aria-controls="nav-two-eg1"
                                            aria-selected="false"
                                            style={{fontSize: '12px'}}
                                        >
                                            ပွဲကြီး
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item"
                                        onClick={() => {
                                            setMmFootballCategory(3);
                                        }}
                                    >
                                        <a
                                            className={`nav-link  ${
                                                mmFootballCategory == 3
                                                    ? "active bg-primary text-white"
                                                    : ""
                                            }`}
                                            id="nav-three-eg1-tab"
                                            href="#nav-three-eg1"
                                            data-bs-toggle="pill"
                                            data-bs-target="#nav-three-eg1"
                                            role="tab"
                                            aria-controls="nav-three-eg1"
                                            aria-selected="false"
                                        >
                                            မောင်း
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white border table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                <th className="text-center text-light p-2">No</th>
                                                <th className="text-center text-light p-2">လိဂ်</th>
                                                <th className="text-center text-light p-2">အိမ်ကွင်း</th> {/*( ပပိုင်း / ပွဲပြီး ) */}
                                                <th className="text-center text-light p-2">ဂိုးပေါင်း</th>
                                                <th className="text-center text-light p-2">အဝေးကွင်း</th>
                                                <th className="text-center text-light p-2">ပွဲချိန်</th>
                                                <th className="text-center text-light p-2">ဖွင့် / ပိတ်</th>
                                                <th className="text-center text-light p-2">Type</th>
                                                <th className="text-center text-light p-2">Action</th>
                                                <th className="text-center text-light p-2">Source</th>
                                                {/* <th className="text-center text-light p-2">Source 1<sup>st</sup> Time</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading &&
                                                <TableLoading col={10} />
                                            }
                                            {
                                                findFixtures?.length > 0 ?
                                                findFixtures?.map((fixture:stateObjType, index:number) => 
                                                    <tr style={{verticalAlign: 'middle'}} key={index}>
                                                        <td className="text-center text-dark p-2">{index + 1}</td>
                                                        <td className="p-2 text-center text-white bg-primary">
                                                            {
                                                                fixture?.league_data?.name
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            {
                                                                fixture?.home_team_data?.name
                                                            }
                                                            {
                                                                fixture?.odds_team=="home"? 
                                                                    <>
                                                                        {
                                                                            fixture?.first_half_body &&
                                                                            <span className="badge bg-dark ms-1">{replaceZeotoQqual(fixture?.first_half_body)}</span>
                                                                        }
                                                                        <span className="badge bg-dark ms-1">{replaceZeotoQqual(fixture?.body)}</span>
                                                                    </>
                                                                    : ""
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">{ fixture?.first_half_total && replaceZeotoQqual(fixture?.first_half_total)} { fixture?.first_half_total && '/'}  {replaceZeotoQqual(fixture?.total)}</td>
                                                        <td className="p-2 text-center text-dark">
                                                            {
                                                                fixture?.away_team_data?.name
                                                            }
                                                            {
                                                                fixture?.odds_team=="away"? 
                                                                    <>
                                                                        {
                                                                            fixture?.first_half_body &&
                                                                            <span className="badge bg-dark ms-1">{replaceZeotoQqual(fixture?.first_half_body)}</span>
                                                                        }
                                                                        <span className="badge bg-dark ms-1">{replaceZeotoQqual(fixture?.body)}</span>
                                                                    </>
                                                                    : ""
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            {fixture?.fixture_start_time}
                                                        </td>
                                                        <td className="p-2 text-dark">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <FixtureToggle id={fixture?.id} status={fixture?.is_published} event={fixtureToggleHandler}  data={fixture}/>
                                                            </div>
                                                            {/* <span className={`badge bg-${fixture.status === 'active'? 'success':'danger'}`}>{fixture.is_published?"active":"disable"}</span> */}
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            {
                                                                fixture?.mm_football_category?.name
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <a
                                                                onClick={(e) => {
                                                                    handleEdit(fixture);
                                                                }}
                                                                className="btn btn-success mr-3 py-1"
                                                                style={{fontSize:'12px'}}
                                                            >
                                                                {" "}
                                                                Edit
                                                            </a>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <CreatedApiSource id={fixture?.id} getUpdateData={getUpdateData} source={fixture?.api_source} source_type="api_source"/>
                                                        </td>
                                                        {/* <td className="p-2 text-center text-dark">
                                                            <CreatedApiSource id={fixture?.id} getUpdateData={getUpdateData} source={fixture?.first_half_api_source} source_type="first_half_api_source"/>
                                                        </td> */}
                                                    </tr>
                                                ):
                                                search?.length > 0 ?
                                                    <tr>
                                                        <td colSpan={10} className="text-center">
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                                :
                                                resultFixtures?.length > 0 ? 
                                                resultFixtures.map((fixture:stateObjType, index:number) => 
                                                    <tr style={{verticalAlign: 'middle'}} key={index}>
                                                        <td className="text-center text-dark p-2">{index + 1}</td>
                                                        <td className="p-2 text-center text-white bg-primary">
                                                            {
                                                                fixture?.league_data?.name
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            {
                                                                fixture?.home_team_data?.name
                                                            }
                                                            {
                                                                fixture?.odds_team=="home"? 
                                                                    <>
                                                                        {
                                                                            fixture?.first_half_body &&
                                                                            <span className="badge bg-dark ms-1">{replaceZeotoQqual(fixture?.first_half_body)}</span>
                                                                        }
                                                                        <span className="badge bg-dark ms-1">{replaceZeotoQqual(fixture?.body)}</span>
                                                                    </>
                                                                    : ""
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">{ fixture?.first_half_total && replaceZeotoQqual(fixture?.first_half_total)} { fixture?.first_half_total && '/'}  {replaceZeotoQqual(fixture?.total)}</td>
                                                        <td className="p-2 text-center text-dark">
                                                            {
                                                                fixture?.away_team_data?.name
                                                            }
                                                            {
                                                                fixture?.odds_team=="away"? 
                                                                    <>
                                                                        {
                                                                            fixture?.first_half_body &&
                                                                            <span className="badge bg-dark ms-1">{replaceZeotoQqual(fixture?.first_half_body)}</span>
                                                                        }
                                                                        <span className="badge bg-dark ms-1">{replaceZeotoQqual(fixture?.body)}</span>
                                                                    </>
                                                                    : ""
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            {fixture?.fixture_start_time}
                                                        </td>
                                                        <td className="p-2 text-dark">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <FixtureToggle id={fixture?.id} status={fixture?.is_published} event={fixtureToggleHandler}  data={fixture}/>
                                                            </div>
                                                            {/* <span className={`badge bg-${fixture.status === 'active'? 'success':'danger'}`}>{fixture.is_published?"active":"disable"}</span> */}
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            {
                                                                fixture?.mm_football_category?.name
                                                            }
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <a
                                                                onClick={(e) => {
                                                                    handleEdit(fixture);
                                                                }}
                                                                className="btn btn-success mr-3 py-1"
                                                                style={{fontSize:'12px'}}
                                                            >
                                                                {" "}
                                                                Edit
                                                            </a>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <CreatedApiSource id={fixture?.id} getUpdateData={getUpdateData} source={fixture?.api_source} source_type="api_source"/>
                                                        </td>
                                                        {/* <td className="p-2 text-center text-dark">
                                                            <CreatedApiSource id={fixture?.id} getUpdateData={getUpdateData} source={fixture?.first_half_api_source} source_type="first_half_api_source"/>
                                                        </td> */}
                                                    </tr>
                                                )
                                                :
                                                    <tr>
                                                        <td colSpan={10} className="text-center">
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
        
        {isShowEditModal && (
            <EditFixtureModal data={data} onSubmit={submitUpdate} onClose={hideCreateModal} loading={isLoading}/>
        )}
        
        <ToastContainer />
    </>
  )
}

export default FootballCreatedOdds
