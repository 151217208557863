import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Template from '../../utils/Template'
import TableScroll from '../../utils/TableScroll'
import { stateObjType } from '../../types'
import { FEEDBACK_API, FEEDBACK_DETAIL_API } from '../../services/constants'
import { deleteMethodService, getMethodService, postMethodService, putMethodService } from '../../services'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import Pagi from '../../utils/Pagi'
import { HandleLogout, decodeData } from '../../utils/Helper'
import TableLoading from '../../utils/TableLoading'
import moment from 'moment'
const Cookies = require('js-cookie');

const Request = () => {
  const abortController = new AbortController();
  const [isLoading,setIsLoading] = useState(false);
  const [feedbacks,setFeedbacks] = useState<stateObjType>([]);
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState<any>(1);
  const [page, setPage] = useState<any>(1);
  const [from, setFrom] = useState<any>(1);
  const [refreshKey,setRefreshKey] = useState(0);
  const [gameType,setGameType] = useState('');

  const fetchFeedbacks = () => {
    const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            return getMethodService(`${FEEDBACK_API}?type=complaint&game=${gameType}&perPage=20&page=${page}`,{},false,decode?.token,abortController);
        };
  };
  const feedbackQuery = useQuery({ queryKey: ['complaint',gameType,page,refreshKey], queryFn: fetchFeedbacks});
  
    useEffect(()=>{
        if(feedbackQuery){
            if(feedbackQuery.data === "Unauthenticated"){
                return HandleLogout(navigate);
            };
            setIsLoading(feedbackQuery.isLoading);
            if(feedbackQuery?.data){
                let totalPages = Number(Math.ceil((feedbackQuery?.data?.meta?.total) / (feedbackQuery?.data?.meta?.perPage)));
                let fromPages = Number(((feedbackQuery?.data?.meta?.perPage)) * Number((feedbackQuery?.data?.meta?.page)) -  Number(feedbackQuery?.data?.meta?.perPage));
                setFrom(fromPages + 1);
                setTotalPage(totalPages);
                setFeedbacks(feedbackQuery?.data?.data);
            };
        };
    },[feedbackQuery]);

  return (
    <>
      <Template title='Complain & Feedback'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-6">
                            <h4 className="card-title mb-3 mb-sm-0">Complain</h4>
                        </div>
                        <div className='col-sm-6'>
                            <div className='d-flex justify-content-end'>
                                <div>
                                    <ul className="nav nav-segment nav-pills" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link px-5 ${gameType ==='' && 'bg-primary text-white'}`} id="nav-one-eg1-tab" href="#nav-one-eg1" data-bs-toggle="pill" data-bs-target="#nav-one-eg1" role="tab" aria-controls="nav-one-eg1" aria-selected="true" onClick={() => setGameType('')}>All</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${gameType ==='football' && 'bg-primary text-white'}`} id="nav-one-eg1-tab" href="#nav-one-eg1" data-bs-toggle="pill" data-bs-target="#nav-one-eg1" role="tab" aria-controls="nav-one-eg1" aria-selected="true" onClick={() => setGameType('football')}>Football</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link px-5 ${gameType ==='3D' && 'bg-primary text-white'}`} id="nav-two-eg1-tab" href="#nav-two-eg1" data-bs-toggle="pill" data-bs-target="#nav-two-eg1" role="tab" aria-controls="nav-two-eg1" aria-selected="false" onClick={() => setGameType('3D')}>3D</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                               <div>
                                  <table className="table text-white border table-bordered table-striped">
                                      <thead>
                                          <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                              <th className="p-2 text-center text-light">No</th>
                                              <th className="p-2 text-center text-light">Usercode</th>
                                              <th className="p-2 text-center text-light">Type</th>
                                              <th className="p-2 text-center text-light">Slip ID</th>
                                              <th className="p-2 text-center text-light">Phone</th>
                                              <th className="p-2 text-center text-light">Description</th>
                                              <th className="p-2 text-center text-light">Image</th>
                                              <th className="p-2 text-center text-light">Status</th>
                                              <th className="p-2 text-center text-light">Date</th>
                                              <th className="p-2 text-center text-light">Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              isLoading &&
                                              <TableLoading col={10} />
                                          }
                                          {
                                            feedbacks?.length > 0?
                                            feedbacks?.map((feedback:any, i:number) => 
                                                <tr key={feedback?.id} style={{verticalAlign: 'middle'}}>
                                                    <td className="p-2 text-center text-dark">{from + i}</td>
                                                    <td className="p-2 text-center text-dark">{feedback?.user_data?.usercode || '--'}</td>
                                                    <td className="p-2 text-center text-dark">{
                                                        feedback?.game?
                                                        <span className='badge text-white' style={{backgroundColor: feedback?.game === "3D" ? '#ff8800': '#377dff'}} >{feedback?.game}</span>:
                                                        '--'
                                                    }</td>
                                                    <td className="p-2 text-center text-dark">
                                                        {
                                                            feedback?.betslip_id ?
                                                            <span className='badge bg-dark' style={{cursor: 'pointer'}} onClick={() => window.open(`/stock-manage/parlay-detail/${feedback?.betslip_id}`, '_blank')}>{feedback?.betslip_id}</span>: '--'
                                                        }
                                                        
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {feedback?.phone || '--'}
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {
                                                            feedback?.content?.length > 50 ?
                                                            feedback?.content?.slice(0, 50) + '. . .':
                                                            feedback?.content
                                                        }
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {
                                                           feedback?.attach_files?.length > 0 ?
                                                           <img src={feedback?.attach_files[0]} style={{width: '35px', height: '35px', borderRadius: '3px'}} />:
                                                            '--'
                                                        }
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        <span style={{color: '#FFF', backgroundColor:  (feedback?.status === "completed") ? '#00c9a7': (feedback?.status === "rejected" ? '#ed4c78':'#ff8800')}} className='badge'>{feedback?.status}</span>
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        {moment(feedback?.createdAt)?.format('YYYY-MM-DD, hh:mm:ss A')}
                                                    </td>
                                                    <td className="p-2 text-center">
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <button className="py-1 ms-2 btn btn-success" style={{fontSize: '13px'}} onClick={() => navigate(`${feedback?.id}`)}>View</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td colSpan={10} className='text-center'>No found data</td>
                                            </tr>
                                          }
                                      </tbody>
                                  </table>
                               </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
                {totalPage > 1 && (
                    <div className="card-footer">
                        <div className="row justify-content-end">
                            <div>
                                <Pagi
                                    setIsLoading={setIsLoading}
                                    setPage={setPage}
                                    totalPage={totalPage}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
      </Template>
      <ToastContainer />
      
    </>
  )
}

export default Request