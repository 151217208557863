import { configureStore } from '@reduxjs/toolkit'
import unitControlSlite from './slices/unitControlSlite'
import userInfoSlice from './slices/userInfoSlice'
import userIdSlice from './slices/userIdSlice'
import dateSlice from './slices/dateSlice';
import { setupListeners } from '@reduxjs/toolkit/query'
import settingSlice from './slices/settingSlice';
import refreshStatusService from './slices/refreshStatusService';

export const store = configureStore({
    reducer: {
        unitController: unitControlSlite,
        userInfo: userInfoSlice,
        userId: userIdSlice,
        dateSlice: dateSlice,
        settingSlice: settingSlice,
        refreshStatusService: refreshStatusService,
    },
})

setupListeners(store.dispatch)
