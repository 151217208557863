let node_domain = process.env.REACT_APP_NODE_DOMAIN;
let domain = process.env.REACT_APP_DOMAIN;
const post_domain = process.env.REACT_APP_POST_DOMAIN;
// let node_domain = "http://192.168.1.4:4444/";

export const PROXY_API = `${domain}api/betsapi/proxy`;

export const LOGIN_API = `${domain}api/login`;
export const LOGOUT_API = `${domain}api/logout`;
export const ADMIN_PASSWORD_CHANGE_API = `${domain}api/change-password`;
export const HDP_AND_OU_API = `${domain}api/stock-management/hdp-and-ou`;
export const STOCK_MANAGEMENT_HOME_AWAY_DETAIL_API = (id) =>
  `${domain}api/stock-management/${id}/home-away`;
export const STOCK_MANAGEMENT_OVER_UNDER_DETAIL_API = (id) =>
  `${domain}api/stock-management/${id}/over-under`;
export const BET_HISTORY_API = `${node_domain}napi/football/bettings`;
export const BET_HISTORY_DETAILS_API = (id) =>
  `${domain}api/football/bettings/${id}`;
export const MEMBER_OUTSTANDING_API = `${domain}api/reports/outstanding`;
export const MEMBER_OUTSTANDING_BY_USER_ID_API = (id) =>
  `${domain}api/reports/outstanding/${id}`;
export const TRANSFER_RECORD_API = `${domain}api/transfer-records`;
export const DEPOSIT_WITYDRAW_API = `${domain}api/users/wallets/deposit-withdraw`;
export const AUTH_USER_API = `${domain}api/users/auth`;
export const AGENT_LIST_API = (agent_id) =>
  `${domain}api/users/agents/roles/` + agent_id;
export const USER_PASSWORD_CHANGE_API = `${domain}api/password/update`;
export const USER_BANN_API = (id) => `${domain}api/users/detail/${id}/ban`;
export const USER_UNBANN_API = (id) => `${domain}api/users/detail/${id}/unban`;
export const CREATE_AGENT_API = `${domain}api/users/agents/store`;
export const COMMESON_SETTING_API = `${domain}api/auth/commission_and_settings`;
export const COMMESON_SETTING_DETAIL_API = (id) =>
  `${domain}api/users/detail/${id}`;
export const COMMESON_SETTING_EDIT_API = (id) =>
  `${domain}api/users/detail/${id}/update`;
export const SUB_ACCOUNT_API = `${domain}api/users/sub-accounts`;
export const CREATE_SUB_ACCOUNT_API = `${domain}api/users/sub-acc/store`;
export const PAYMENT_TRANSACTION_API = `${domain}api/users/downlines`;
export const WIN_LOSE_REPORT_API = `${domain}api/reports/win-lose`;
export const DOWNLINE_BETTING_USER_API = `${domain}api/users/downlines-betting-user`;
export const MEMBER_LIST_API = `${domain}api/users/member-list`;
export const MEMBER_LIST_DETAIL_API = (id) =>
  `${domain}api/users/member-list/${id}`;
export const INVOICE_REJECT_API = (id) =>
  `${domain}api/football/bettings/reject/${id}`;
export const ALREADY_FIXTURES_API = `${domain}api/football/already-fixtures`;
export const LEAGUE_API = `${domain}api/leagues`;
export const CONVERT_MM_ODDS_API = (event_id) =>
  `${domain}api/football/events/mm-odds/${event_id}`;
export const SAVE_FIXTURES_API = `${domain}api/football/fixtures`;
export const FIXTURES_API = `${domain}api/football/fixtures`;
export const UPDATE_FIXTURE = (id) => `${domain}api/football/fixtures/${id}`;

export const UPDATE_FIXTURE_SOURCE = (id) => `${domain}/api/football/fixtures/${id}/update-api-source`;

export const GOALS_CONFIRM = (id) => `${domain}api/football/fixtures/confirm-goals/queue/${id}`;
export const FOOTBALL_REJECT_API = (id)=>`${domain}api/football/fixtures/cancel/queue/${id}`;
export const CONFIG_SETTING_API = `${domain}api/config_setting`;
export const CONFIG_SETTING_EDIT_API = `${domain}api/config_setting/update`;
export const TEX_SETTING_API = `${domain}api/tax_setting`;
export const WALLET_TRANSACTION_API = `${domain}api/wallet-transactions`;
export const AUTH_USER_WITH_COMM_AND_SETTINGS = `${domain}api/auth/commission_and_settings`;

// ----------- NODE API ------------
export const LOGIN_NODE_API = `${node_domain}napi/login`;
export const LOGOUT_NODE_API = `${node_domain}napi/logout`;
export const AUTH_USER_NODE_API = `${node_domain}napi/users/auth`;
export const BET_HISTORY_NODE_API = `${node_domain}napi/football/bettings`;
export const BET_HISTORY_DETAILS_NODE_API = (id) =>
  `${node_domain}napi/football/bettings/${id}`;
export const HDP_AND_OU_NODE_API = `${node_domain}napi/stock-management/hdp-and-ou`;
export const WIN_LOSE_REPORT_NODE_API = `${node_domain}napi/reports/win-lose`;
export const DOWNLINE_COUNT_NODE_API = `${node_domain}napi/users/downline-count`;
export const WIN_LOSE_REPORT_NODE_QUEUE_API = `${node_domain}napi/reports/win-lose/queue`;
export const WIN_LOSE_REPORT_FOR_A_USER_API = `${node_domain}napi/reports/win-lose-for-a-user`;
export const DOWNLINE_BETTING_USER_NODE_API = `${node_domain}napi/users/downlines-betting-user`;
export const MEMBER_OUTSTANDING_NODE_API = `${node_domain}napi/reports/outstanding`;
export const MEMBER_OUTSTANDING_BY_USER_ID_NODE_API = (id) =>
  `${node_domain}napi/reports/outstanding/${id}`;
export const STOCK_MANAGEMENT_HOME_AWAY_DETAIL_NODE_API = (id) =>
  `${node_domain}napi/stock-management/${id}/home-away`;
export const STOCK_MANAGEMENT_OVER_UNDER_DETAIL_NODE_API = (id) =>
  `${node_domain}napi/stock-management/${id}/over-under`;
export const MEMBER_LIST_NODE_API = `${node_domain}napi/users/member-list`;
export const TRANSFER_RECORD_NODE_API = `${node_domain}napi/transfer-records`;
export const PAYMENT_TRANSACTION_NODE_API = `${node_domain}napi/users/downlines`;
export const DASHBOARD_BALANCE_NODE_API = `${node_domain}napi/auth/children-balance-count`;
export const USER_BANN_NODE_API = (id) => `${node_domain}napi/users/detail/${id}/ban`;
export const USER_UNBANN_NODE_API = (id) => `${node_domain}napi/users/detail/${id}/unban`;
export const USER_DETAIL_NODE_API = (id) => `${node_domain}napi/users/detail/${id}`;
export const COMMESON_SETTING_DETAIL_NODE_API = (id) =>
`${node_domain}napi/users/detail/${id}`;
// export const COMMESON_SETTING_EDIT_NODE_API = (id) =>
//   `${node_domain}napi/users/detail/${id}/update`;
export const CHECKED_CALL_API = `${node_domain}napi/send-noti`;

export const BET_SETTING_API = `${node_domain}napi/bet-setting`;
export const FIXTURES_NODE_API = `${node_domain}napi/fixtures`;



export const TRANSLATE = `${domain}api/en-to-mm`

export const POSTS = `${post_domain}api/posts`
export const POSTS_DETAIL = id => `${post_domain}api/posts/${id}`
export const CREATE_POSTS = `${post_domain}api/posts/create`
export const FEEDBACK_API = `${post_domain}api/fc`;
export const FEEDBACK_DETAIL_API = id => `${post_domain}api/fc/${id}`;
export const CREATE_REPLY_API = `${post_domain}api/fc/reply`;

// const test_domain = "http://192.168.1.10:4444/";
// ----------- MM 2D API -----------
export const NODE_USER_INFO_API = `${node_domain}napi/getAuth`;
export const TWO_THREE_SETTING_API = `${node_domain}napi/two-three-setting`;
export const TWO_D_TIME_API = `${node_domain}napi/twod-open-close`;
export const TWO_D_NUMS_API = `${node_domain}napi/twod-nums`;
export const TWO_D_LUCKY_NUMS_API = `${node_domain}napi/twod-lucky-nums`;
export const TWO_D_BET_LISTS_ALL_API = `${node_domain}napi/twod-lucky-draws`;
export const TWO_D_WINNER_API = `${node_domain}napi/winners`;

// ----------- MM 3D API -----------
export const THREE_D_NUMS_API = `${node_domain}napi/threed-nums`;
export const THREE_D_TIME_API = `${node_domain}napi/threed-open-close`;
export const THREE_D_LUCKY_NUMS_API = `${node_domain}napi/threed-lucky-nums`;
export const THREE_D_LIMIT_RESET_API = `${node_domain}napi/threed-nums/clean`;
export const THREE_D_WINNER_API = `${node_domain}napi/threed-winners`;
export const THREE_D_BET_LISTS_ALL_API = `${node_domain}napi/threed-slip`;
export const THREE_D_SLIP_STATUS_API = `${node_domain}napi/threed-slip-status`;
export const THREE_D_STOCK_API = `${node_domain}napi/threed/stock`;
export const THREE_D_OUTSTANDING_API = `${node_domain}napi/threed/outstanding`;
export const THREE_D_OUTSTANDING_DETAIL_API = `${node_domain}napi/threed/stock-detail`;
export const THREE_D_BET_SLIPS_NODE_API = `${node_domain}napi/win-lose-threed-slip`;
export const THREE_D_WIN_LOSE_REPORT_NODE_API = `${node_domain}napi/reports/three-d/win-lose`;
export const THREE_D_TRANSACTIONS_API = `${node_domain}napi/threed/transition`;

// temp token 

export const TEMP_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjMsInJvbGVfaWQiOjIsInN1cGVyX2lkIjpudWxsLCJzZW5pb3JfaWQiOm51bGwsIm1hc3Rlcl9pZCI6bnVsbCwiYWdlbnRfaWQiOm51bGwsImJhbGFuY2UiOjEwMDgyNywiaWF0IjoxNjk1NzI2NjczfQ.IMDpSwVAsjMRDZKIotciAYhlDFWoLikZorVivrz1838'

export const TEAMS_API = `${domain}api/names`;

