import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from '../auths/Login';
import Dashboard from '../components/Dashboard';
import ChangePassword from '../components/ChangePassword';
import StockOverUnder from '../components/StockOverUnder';
import StockParlay from '../components/StockParlay';
import StockDetailHomeAway from '../components/StockDetailHomeAway';
import StockDetailOverUnder from '../components/StockDetailOverUnder';
import StockParlayDetail from '../components/StockParlayDetail';
import StockMemberOutstanding from '../components/StockMemberOutstanding';
import StockMemberOutstandingDetail from '../components/StockMemberOutstandingDetail';
import StockMemberListDetail from '../components/StockMemberListDetail';
import PaymentTransaction from '../components/PaymentTransaction';
import MemberList from '../components/MemberList';
import SubUser from '../components/SubUser';
import Protect from './Protect';
import WinLoseDetail from '../components/WinLoseDetail';
import WinLoseDetailPro from '../components/WinLoseDetailPro';
import WinLoseDetailPerUser from '../components/WinLoseDetailPerUser';
import ReportMember from '../components/ReportMember';
import ReportMemberDetail from '../components/ReportMemberDetail';
import ReportTransactionHistory from '../components/ReportTransactionHistory';
import ChangePasswordAuth from '../auths/ChangePasswordAuth';
import FootballBetListParlay from '../components/football/FootballBetListParlay';
import FootballBetListParlayDetail from '../components/football/FootballBetListParlayDetail';
import FootballBetListBody from '../components/football/FootballBetListBody';
import FootballCreateOdds from '../components/football/FootballCreateOdds';
import FootballCreatedOdds from '../components/football/FootballCreatedOdds';
import FootballResultFullTime from '../components/football/FootballResultFullTime';
import FootballSetting from '../components/football/FootballSetting';
import WalletTransaction from '../components/football/WalletTransaction';
import RuleAndRegulations from '../components/AppSettings/RuleAndRegulations';
import AppSetting from '../components/AppSettings/AppSetting';
import { decodeData } from '../utils/Helper';
import Profile from '../components/Profile';
import MMTwodBetsLists from '../components/MMTwodBetsLists';
import MMTwodSettings from '../components/MMTwodSettings';
import MMThreedSettings from '../components/MMThreedSettings';
import MMTwodBetsListsAll from '../components/MMTwodBetsListsAll';
import ReportMmTwod from '../components/ReportMMTwod';
import MMTwodWinner from '../components/MMTwodWinner';
import MMTwodResults from '../components/MMTwodResults';
import MMThreedResults from '../components/MMThreedResults';
import MMThreedWinner from '../components/MMThreedWinner';
import MMThreedBetListsAll from '../components/MMThreedBetListsAll';
import MMThreedStock from '../components/MMThreedStock';
import MMThreedStockDetail from '../components/MMThreedStockDetail';
import MMThreedOutstanding from '../components/MMThreedOutstanding';
import MMThreedOutstandingDetail from '../components/MMThreedOutstandingDetail';
import MMThreeDWinLoseDetail from '../components/MMThreeDWinLoseDetail';
import ThreedStockMemberListDetail from '../components/MMThreeDStockMemberListDetail';
import MMThreedTransactions from '../components/MMThreedTransactions';
import TeamList from '../components/football/TeamList';
import NotFound from '../utils/NotFound';
import Maintenance from '../utils/Maintenance';
import CreateNews from '../components/social/CreateNews';
import News from '../components/social/News';
import EditNews from '../components/social/EditNews';
import ReportMemberPercentage from '../components/ReportMemberPercentage';
import BlackListRoute from '../auths/BlackListRoute';
import { getMethodCheckAppStatusService } from '../services';
import BlackListWinLoseReportRoute from '../auths/BlackListWinLoseReportRoute';
import { useDispatch, useSelector } from 'react-redux';
import { settingHandler } from '../store/slices/settingSlice';
import Request from '../components/feedback/Request';
import RequestDetail from '../components/feedback/RequestDetail';
import Recommend from '../components/feedback/Recommend';
import RecommentDetail from '../components/feedback/RecommendDetail';
import BlackListOutstandingRoute from '../auths/BlackListOutstandingRoute';
import FootballResultFirstHalf from '../components/football/FootballResultFirstHalf';
import Testing from '../components/Testing';
const Cookies = require('js-cookie');

const Routers = () => {
  const [userInfo,setUserInfo] = useState<any>({});
  const navigate = useNavigate();
  const [settingJson,setSettingJson] = useState({});
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {serviceStatus} = useSelector((state:any) => state.refreshStatusService);

  const fetchAppStatus = async () => {
    let res = await getMethodCheckAppStatusService();
    if(res){
      setSettingJson(res);
      dispatch(settingHandler(res));
      if(!res?.is_enable_dashboard){
        return navigate("/maintenance");
      };
    };
  };

  useEffect(() => {
    fetchAppStatus();
  }, [pathname,serviceStatus]);


  useEffect(()=>{
      const getAuth = Cookies.get('a_u');
      let decode = getAuth && decodeData(getAuth);
      if(decode){
          setUserInfo(decode)
      };
  },[]);
  
  return (
      <Routes>
        <Route path='/app-testing' element={<Testing />} />
        <Route path="/login" element={<Login />} /> 
        <Route path='/maintenance' element={<Maintenance settingJson={settingJson}/>} />
        <Route path="/change-password" element={<ChangePasswordAuth />} /> 
        {
          ((userInfo?.role_name !== 'admin') || (userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0) || userInfo?.permissions?.includes('View Report')) && <Route path="/" element={
            <Protect>
              <BlackListRoute route="/">
                <Dashboard />
              </BlackListRoute>
            </Protect>} /> 
        }
        <Route path="/account/change-password" element={
          <Protect>
            <BlackListRoute route="/account/change-password">
              <ChangePassword />
            </BlackListRoute>
          </Protect>} /> 
       
        {
          userInfo?.role_name !== 'admin' && <Route path="/account/profile" element={
          <Protect>
            <BlackListRoute route="/account/profile">
              <Profile />
            </BlackListRoute>
          </Protect>} /> 
        }

        {
          ( userInfo?.role_name !== 'admin' || (userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0) )&&
          <>
            
            <Route path="/payment/deposit-withdraw" element={
              <Protect>
                <BlackListRoute route="/payment/deposit-withdraw">
                  <PaymentTransaction />
                </BlackListRoute>
              </Protect>} /> 
        
            <Route path="/member/roles/:id" element={
              <Protect>
                 <BlackListRoute route="/member/roles/:id">
                  <MemberList />
                 </BlackListRoute>
              </Protect>} /> 
            <Route path="/member/sub-user" element={
              <Protect>
                  <BlackListRoute route="/member/sub-user">
                    <SubUser />
                  </BlackListRoute>
              </Protect>} />
            <Route path="/stock-manage/hdp-ou" element={
              <Protect>
                <BlackListRoute route="/stock-manage/hdp-ou">
                  <StockOverUnder />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/stock-manage/parlay" element={
              <Protect>
                <BlackListRoute route="/stock-manage/parlay">
                  <StockParlay />
                </BlackListRoute>
              </Protect>} /> 
            
            {
              userInfo?.type === 'main' && <Route path="/stock-manage/mm3d-stock" element={
                <Protect>
                  <BlackListRoute route="/stock-manage/mm3d-stock">
                    <MMThreedStock />
                  </BlackListRoute>
                </Protect>} />
            } 
            {
              userInfo?.type === 'main' && <Route path="/stock-manage/mm3d-stock/:id" element={
                <Protect>
                    <BlackListRoute route="/stock-manage/mm3d-stock/:id">
                      <MMThreedStockDetail />
                    </BlackListRoute>
                </Protect>} /> 
            } 
            {
              userInfo?.type === 'main' && <Route path="/stock-manage/mm3d-outstanding" element={
                <Protect>
                    <BlackListRoute route="/stock-manage/mm3d-outstanding">
                      <MMThreedOutstanding />
                    </BlackListRoute>
                </Protect>} /> 
            } 
            {
              userInfo?.type === 'main' && <Route path="/stock-manage/mm3d-outstanding/:user_id/:role_id" element={
                <Protect>
                    <BlackListRoute route="/stock-manage/mm3d-outstanding/:user_id/:role_id">
                      <MMThreedOutstandingDetail />
                    </BlackListRoute>
                </Protect>} /> 
            }    
            <Route path="/stock-manage-hdp-ou/hdp/:id" element={
              <Protect>
                  <BlackListRoute route="/stock-manage-hdp-ou/hdp/:id">
                    <StockDetailHomeAway />
                  </BlackListRoute>
              </Protect>} /> 
            <Route path="/stock-manage-hdp-ou/ou/:id" element={
              <Protect>
                <BlackListRoute route="/stock-manage-hdp-ou/ou/:id">
                  <StockDetailOverUnder />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/stock-manage/parlay-detail/:id" element={
              <Protect>
                <BlackListRoute route="/stock-manage/parlay-detail/:id">
                  <StockParlayDetail />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/report/win-lose-detail" element={
              <Protect>
                    <BlackListWinLoseReportRoute settingJson={settingJson}>
                        <WinLoseDetail />
                    </BlackListWinLoseReportRoute>
              </Protect>} /> 
            <Route path="/report/win-lose-detail-a-user" element={
              <Protect>
                <BlackListWinLoseReportRoute settingJson={settingJson}>
                  <WinLoseDetailPerUser />
                </BlackListWinLoseReportRoute>
              </Protect>} /> 
            <Route path="/report/win-lose-detail-pro" element={
              <Protect>
                  <BlackListWinLoseReportRoute settingJson={settingJson}>
                    <WinLoseDetailPro />
                  </BlackListWinLoseReportRoute>
              </Protect>} /> 
            <Route path="/report/win-lose-detail/:id/:status/:start_date/:end_date" element={
              <Protect>
                  <BlackListWinLoseReportRoute settingJson={settingJson}>
                    <StockMemberListDetail />
                  </BlackListWinLoseReportRoute>
              </Protect>} />
            <Route path="/stock-manage/member-outstanding" element={
              <Protect>
                <BlackListOutstandingRoute settingJson={settingJson}>
                  <StockMemberOutstanding />
                </BlackListOutstandingRoute>
              </Protect>} /> 
            <Route path="/stock-manage/member-outstanding/:id" element={
              <Protect>
                <BlackListOutstandingRoute settingJson={settingJson}>
                  <StockMemberOutstandingDetail />
                </BlackListOutstandingRoute>
              </Protect>} /> 
            <Route path="/stock-manage/member/betting-list/:id/:status/:start_date/:end_date" element={
              <Protect>
                <BlackListRoute route="/stock-manage/member/betting-list/:id/:status/:start_date/:end_date">
                  <StockMemberListDetail />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/report/3d-win-lose-detail" element={
              <Protect>
                  <BlackListRoute route="/report/3d-win-lose-detail">
                    <MMThreeDWinLoseDetail />
                  </BlackListRoute>
              </Protect>} />
            <Route path="/report/3d-win-lose-detail/:id/:status/:start_date/:end_date" element={
              <Protect>
                <BlackListRoute route="/report/3d-win-lose-detail/:id/:status/:start_date/:end_date">
                  <ThreedStockMemberListDetail />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/report/member-list" element={
              <Protect>
                <BlackListRoute route="/report/member-list">
                  <ReportMember />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/report/member-list/:id" element={
              <Protect>
                <BlackListRoute route="/report/member-list/:id">
                  <ReportMemberDetail />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/report/member-percent/:id" element={
              <Protect>
                <BlackListRoute route="/report/member-percent/:id">
                  <ReportMemberPercentage />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/report/member-management/detail/:id" element={
              <Protect>
                <BlackListRoute route="/report/member-management/detail/:id">
                  <ReportMemberPercentage />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/report/transaction-history" element={
              <Protect>
                <BlackListRoute route="/report/transaction-history">
                  <ReportTransactionHistory />
                </BlackListRoute>
              </Protect>} />      
          </>
        }

        {
          (userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0 && userInfo?.type === 'main')&&
          <>
            <Route path="/mm3d/settings" element={
              <Protect>
                <BlackListRoute route="/mm3d/settings">
                  <MMThreedSettings />
                </BlackListRoute>
              </Protect>} />
            <Route path="/mm3d/winners" element={
              <Protect>
                <BlackListRoute route="/mm3d/winners">
                  <MMThreedWinner />
                </BlackListRoute>
              </Protect>} />
            <Route path="/mm3d/results" element={
              <Protect>
                <BlackListRoute route="/mm3d/results">
                  <MMThreedResults />
                </BlackListRoute>
              </Protect>} />
            <Route path="/mm3d/transactions" element={
              <Protect>
                <BlackListRoute route="/mm3d/transactions">
                  <MMThreedTransactions />
                </BlackListRoute>
              </Protect>} />
          </>
        }

        {
          (userInfo?.type === 'main' && userInfo?.role_name === 'admin' && userInfo?.permissions?.length === 0) &&
          <>
              <Route path="/football/bet-list/body" element={
                <Protect>
                  <BlackListRoute route="/football/bet-list/body">
                    <FootballBetListBody />
                  </BlackListRoute>
                </Protect>} /> 
              <Route path="/football/bet-list/parlay" element={
                <Protect>
                  <BlackListRoute route="/football/bet-list/parlay">
                    <FootballBetListParlay />
                  </BlackListRoute>
                </Protect>} /> 
              <Route path="/football/bet-list/parlay/:id" element={
                <Protect>
                  <BlackListRoute route="/football/bet-list/parlay/:id">
                    <FootballBetListParlayDetail />
                  </BlackListRoute>
                </Protect>} /> 
              <Route path="/football/create-odds" element={
                <Protect>
                   <BlackListRoute route="/football/create-odds">
                    <FootballCreateOdds />
                   </BlackListRoute>
                </Protect>} /> 
              <Route path="/football/created-odds" element={
                <Protect>
                  <BlackListRoute route="/football/created-odds">
                    <FootballCreatedOdds />
                  </BlackListRoute>
                </Protect>} /> 
              {/* <Route path="/football/create-result/first-half" element={
                <Protect>
                  <BlackListRoute route="/football/create-result/first-half">
                    <FootballResultFirstHalf />
                  </BlackListRoute>
                </Protect>} /> 
              <Route path="/football/create-result/full-time" element={
                <Protect>
                  <BlackListRoute route="/football/create-result/full-time">
                    <FootballResultFullTime />
                  </BlackListRoute>
                </Protect>} />  */}
              <Route path="/football/create-result" element={
                <Protect>
                  <BlackListRoute route="/football/create-result">
                    <FootballResultFullTime />
                  </BlackListRoute>
                </Protect>} />
              <Route path="/football/team-list" element={
                <Protect>
                  <BlackListRoute route="/football/team-list">
                    <TeamList />
                  </BlackListRoute>
                </Protect>} /> 
              <Route path="/football/setting" element={
                <Protect>
                  <BlackListRoute route="/football/setting">
                    <FootballSetting />
                  </BlackListRoute>
                </Protect>} />
              <Route path="/football/wallet-transaction" element={
                <Protect>
                  <BlackListRoute route="/football/wallet-transaction">
                    <WalletTransaction />
                  </BlackListRoute>
                </Protect>} />
                <Route path="/setting/rules-regulations" element={
                  <Protect>
                     <BlackListRoute route="/setting/rules-regulations">
                      <RuleAndRegulations />
                     </BlackListRoute>
                  </Protect>} />
                <Route path="/setting/app-setting" element={
                  <Protect>
                    <BlackListRoute route="/setting/app-setting">
                      <AppSetting />
                    </BlackListRoute>
                  </Protect>} />
                <Route path="/community/news" element={
                  <Protect>
                    <BlackListRoute route="/community/news">
                      <News />
                    </BlackListRoute>
                  </Protect>} />
                <Route path="/community/edit-news/:id" element={
                  <Protect>
                    <BlackListRoute route="/community/edit-news/:id">
                      <EditNews />
                    </BlackListRoute>
                  </Protect>} />
                <Route path="/community/create-news" element={
                  <Protect>
                    <BlackListRoute route="/community/create-news">
                      <CreateNews />
                    </BlackListRoute>
                  </Protect>} />
                  
                <Route path="/feedback/request" element={
                  <Protect>
                    <BlackListRoute route="/feedback/request">
                      <Request />
                    </BlackListRoute>
                  </Protect>} />
                <Route path="/feedback/request/:id" element={
                  <Protect>
                    <BlackListRoute route="/feedback/request/:id">
                      <RequestDetail />
                    </BlackListRoute>
                  </Protect>} />

                <Route path="/feedback/recommend" element={
                  <Protect>
                    <BlackListRoute route="/feedback/recommend">
                      <Recommend />
                    </BlackListRoute>
                  </Protect>} />
                <Route path="/feedback/recommend/:id" element={
                  <Protect>
                    <BlackListRoute route="/feedback/recommend/:id">
                      <RecommentDetail />
                    </BlackListRoute>
                  </Protect>} />

                <Route path="/mm3d/bet-lists" element={
                  <Protect>
                    <BlackListRoute route="/mm3d/bet-lists">
                      <MMThreedBetListsAll />
                    </BlackListRoute>
                  </Protect>} />
          </>
        }
        {
          (userInfo?.type === 'sub' && userInfo?.role_name === 'admin' && userInfo?.permissions?.includes('Confirm Result')) && 
          <>
            <Route path="/" element={
              <Protect>
                <BlackListRoute route="/">
                  <FootballResultFullTime />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/football/create-result" element={
              <Protect>
                <BlackListRoute route="/football/create-result">
                  <FootballResultFullTime />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/feedback/request" element={
                <Protect>
                  <BlackListRoute route="/feedback/request">
                    <Request />
                  </BlackListRoute>
                </Protect>} />
            <Route path="/feedback/request/:id" element={
              <Protect>
                <BlackListRoute route="/feedback/request/:id">
                  <RequestDetail />
                </BlackListRoute>
              </Protect>} />
            <Route path="/feedback/recommend" element={
                <Protect>
                  <BlackListRoute route="/feedback/recommend">
                    <Recommend />
                  </BlackListRoute>
                </Protect>} />
            <Route path="/feedback/recommend/:id" element={
              <Protect>
                <BlackListRoute route="/feedback/recommend/:id">
                  <RecommentDetail />
                </BlackListRoute>
              </Protect>} />
            {/* <Route path="/football/create-result/first-half" element={
              <Protect>
                <BlackListRoute route="/football/create-result/first-half">
                  <FootballResultFirstHalf />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/football/create-result/full-time" element={
              <Protect>
                <BlackListRoute route="/football/create-result/full-time">
                  <FootballResultFullTime />
                </BlackListRoute>
              </Protect>} />  */}
          </>
        }
        {
          (userInfo?.type === 'sub' && userInfo?.role_name === 'admin' && userInfo?.permissions?.includes('Create Match')) &&
          <>
            <Route path="/" element={
              <Protect>
                <BlackListRoute route="/">
                  <FootballCreateOdds />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/football/create-odds" element={
              <Protect>
                <BlackListRoute route="/football/create-odds">
                  <FootballCreateOdds />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/football/created-odds" element={
              <Protect>
                <BlackListRoute route="/football/created-odds">
                  <FootballCreatedOdds />
                </BlackListRoute>
              </Protect>} /> 
            <Route path="/feedback/request" element={
                <Protect>
                  <BlackListRoute route="/feedback/request">
                    <Request />
                  </BlackListRoute>
                </Protect>} />
            <Route path="/feedback/request/:id" element={
              <Protect>
                <BlackListRoute route="/feedback/request/:id">
                  <RequestDetail />
                </BlackListRoute>
              </Protect>} />
            <Route path="/feedback/recommend" element={
                <Protect>
                  <BlackListRoute route="/feedback/recommend">
                    <Recommend />
                  </BlackListRoute>
                </Protect>} />
            <Route path="/feedback/recommend/:id" element={
              <Protect>
                <BlackListRoute route="/feedback/recommend/:id">
                  <RecommentDetail />
                </BlackListRoute>
              </Protect>} />
          </>
        }
        {
          (userInfo?.type === 'sub' && userInfo?.role_name === 'admin' && userInfo?.permissions?.includes('View Report')) &&
          <>
              <Route path="/" element={
                <Protect>
                   <BlackListRoute route="/">
                    <StockOverUnder />
                   </BlackListRoute>
                </Protect>} /> 
              <Route path="/stock-manage/hdp-ou" element={
                <Protect>
                  <BlackListRoute route="/stock-manage/hdp-ou">
                    <StockOverUnder />
                  </BlackListRoute>
                </Protect>} />
              <Route path="/stock-manage/parlay" element={
                <Protect>
                  <BlackListRoute route="/stock-manage/parlay">
                    <StockParlay />
                  </BlackListRoute>
                </Protect>} /> 
              <Route path="/stock-manage-hdp-ou/hdp/:id" element={
                <Protect>
                  <BlackListRoute route="/stock-manage-hdp-ou/hdp/:id">
                    <StockDetailHomeAway />
                  </BlackListRoute>
                </Protect>} /> 
              <Route path="/stock-manage-hdp-ou/ou/:id" element={
                <Protect>
                  <BlackListRoute route="/stock-manage-hdp-ou/ou/:id">
                    <StockDetailOverUnder />
                  </BlackListRoute>
                </Protect>} />
              <Route path="/stock-manage/parlay-detail/:id" element={
                <Protect>
                   <BlackListRoute route="/stock-manage/parlay-detail/:id">
                    <StockParlayDetail />
                   </BlackListRoute>
                </Protect>} /> 
              <Route path="/stock-manage/member/betting-list/:id/:status/:start_date/:end_date" element={
                <Protect>
                    <BlackListRoute route="/stock-manage/member/betting-list/:id/:status/:start_date/:end_date">
                      <StockMemberListDetail />
                    </BlackListRoute>
                </Protect>} />
              <Route path="/report/win-lose-detail" element={
                <Protect>
                  <BlackListWinLoseReportRoute settingJson={settingJson}>
                    <WinLoseDetail />
                  </BlackListWinLoseReportRoute>
                </Protect>} /> 
              <Route path="/report/win-lose-detail-pro" element={
                <Protect>
                  <BlackListWinLoseReportRoute settingJson={settingJson}>
                    <WinLoseDetailPro />
                  </BlackListWinLoseReportRoute>
                </Protect>} />
              <Route path="/report/win-lose-detail-a-user" element={
                <Protect>
                  <BlackListWinLoseReportRoute settingJson={settingJson}>
                      <WinLoseDetailPerUser />
                  </BlackListWinLoseReportRoute>
                </Protect>} /> 
                <Route path="/report/win-lose-detail/:id/:status/:start_date/:end_date" element={
                  <Protect>
                    <BlackListWinLoseReportRoute settingJson={settingJson}>
                      <StockMemberListDetail />
                    </BlackListWinLoseReportRoute>
                  </Protect>} />
                <Route path="/stock-manage/member-outstanding" element={
                  <Protect>
                      <BlackListOutstandingRoute settingJson={settingJson}>
                        <StockMemberOutstanding />
                      </BlackListOutstandingRoute>
                  </Protect>} /> 
                <Route path="/stock-manage/member-outstanding/:id" element={
                  <Protect>
                      <BlackListOutstandingRoute settingJson={settingJson}>
                        <StockMemberOutstandingDetail />
                      </BlackListOutstandingRoute>
                  </Protect>} /> 
                <Route path="/stock-manage/mm3d-stock" element={
                  <Protect>
                    <BlackListRoute route="/stock-manage/mm3d-stock">
                      <MMThreedStock />
                    </BlackListRoute>
                  </Protect>} /> 
                <Route path="/stock-manage/mm3d-stock/:id" element={
                  <Protect>
                    <BlackListRoute route="/stock-manage/mm3d-stock/:id">
                      <MMThreedStockDetail />
                    </BlackListRoute>
                  </Protect>} /> 
                <Route path="/stock-manage/mm3d-outstanding" element={
                  <Protect>
                    <BlackListRoute route="/stock-manage/mm3d-outstanding">
                      <MMThreedOutstanding />
                    </BlackListRoute>
                  </Protect>} /> 
                <Route path="/stock-manage/mm3d-outstanding/:user_id/:role_id" element={
                  <Protect>
                    <BlackListRoute route="/stock-manage/mm3d-outstanding/:user_id/:role_id">
                      <MMThreedOutstandingDetail />
                    </BlackListRoute>
                  </Protect>} /> 
                <Route path="/report/3d-win-lose-detail" element={
                  <Protect>
                      <BlackListRoute route="/report/3d-win-lose-detail">
                        <MMThreeDWinLoseDetail />
                      </BlackListRoute>
                  </Protect>} />
                <Route path="/report/3d-win-lose-detail/:id/:status/:start_date/:end_date" element={
                  <Protect>
                    <BlackListRoute route="/report/3d-win-lose-detail/:id/:status/:start_date/:end_date">
                      <ThreedStockMemberListDetail />
                    </BlackListRoute>
                  </Protect>} /> 
                <Route path="/report/member-list" element={
                  <Protect>
                    <BlackListRoute route="/report/member-list">
                      <ReportMember />
                    </BlackListRoute>
                  </Protect>} /> 
                <Route path="/report/member-list/:id" element={
                  <Protect>
                      <BlackListRoute route="/report/member-list/:id">
                        <ReportMemberDetail />
                      </BlackListRoute>
                  </Protect>} /> 
                <Route path="/report/member-percent/:id" element={
                  <Protect>
                    <BlackListRoute route="/report/member-percent/:id">
                      <ReportMemberPercentage />
                    </BlackListRoute>
                  </Protect>} /> 
                <Route path="/report/member-management/detail/:id" element={
                  <Protect>
                    <BlackListRoute route="/report/member-management/detail/:id">
                      <ReportMemberPercentage />
                    </BlackListRoute>
                  </Protect>} /> 
                <Route path="/report/transaction-history" element={
                  <Protect>
                    <BlackListRoute route="/report/transaction-history">
                      <ReportTransactionHistory />
                    </BlackListRoute>
                  </Protect>} />   
                <Route path="/feedback/request" element={
                  <Protect>
                    <BlackListRoute route="/feedback/request">
                      <Request />
                    </BlackListRoute>
                  </Protect>} />
                <Route path="/feedback/request/:id" element={
                  <Protect>
                    <BlackListRoute route="/feedback/request/:id">
                      <RequestDetail />
                    </BlackListRoute>
                  </Protect>} />
                <Route path="/feedback/recommend" element={
                  <Protect>
                    <BlackListRoute route="/feedback/recommend">
                      <Recommend />
                    </BlackListRoute>
                  </Protect>} />
                <Route path="/feedback/recommend/:id" element={
                  <Protect>
                    <BlackListRoute route="/feedback/recommend/:id">
                      <RecommentDetail />
                    </BlackListRoute>
                  </Protect>} />
          </>
        }
        <Route path="*" element={<NotFound />} />
      </Routes>
  )
}

export default Routers
