import React, { useEffect, useState } from "react";
import { ChangePasswordTypes } from "../../types";
const {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    Input,
  } = require("reactstrap");

function ChangePasswordModal(props: ChangePasswordTypes) {
    const [newPass,setNewPass] = useState("");
    const [conPass,setConPass] = useState("");
    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        props.onSubmit({new_password:newPass,confirm_password: conPass});
    }


  return (
    <Modal isOpen={true} toggle={props.onClose}>
      <ModalHeader toggle={props.onClose}>Change Password</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="amount">New Password</Label>
            <Input type="text" name="amount" id="amount" onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setNewPass(e.target.value)}/>
          </FormGroup>
          <FormGroup>
            <Label for="amount">Confirm Password</Label>
            <Input type="text" name="amount" id="amount" onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setConPass(e.target.value)}/>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={props.onClose}>
          No
        </button>
        {
          props.isLoading ?
          <button className="btn btn-primary" disabled={true}>
            Loading...
          </button>:
          <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
            Save
          </button>
        }
      </ModalFooter>
    </Modal>
  );
}

export default ChangePasswordModal;
