import { numInputPreventWheel } from "./Helper"

export default function EditToast({ editValue, setEditValue, state, saveState, cancelState, isLoading, placeholder }) {
    const editValueHandler = e => {
        const value = e.target.value;
        const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
        if (positiveNumberPattern.test(value)) {
            const first_num = value.slice(0,1)
            if (first_num > 0 || editValue > 0) {
                setEditValue(value);
            }
        }
    }
    return (
        state && <div className="rounded-2 bg-light shadow-lg position-absolute p-1 d-flex" style={{ width: '240px', bottom: '0', left:'0', zIndex: '100' }}>
            <input onWheel={numInputPreventWheel} type="text" className='form-control border border-success px-2' autoFocus value={editValue} onChange={editValueHandler} placeholder={placeholder} onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            saveState(e)
                        }
                    }}/>
            <button
                className={`btn btn-success p-1 px-2 rounded-1 mx-1`}
                onClick={saveState}
                disabled={isLoading}
                type="submit"
            >
                {isLoading ? "Loading ..." : "Save"}
            </button>
            <button
                className={`btn btn-danger p-1 px-2 rounded-1`}
                onClick={cancelState}
                disabled={isLoading}
            >
                {isLoading ? "Loading ..." : "Cancel"}
            </button>
        </div>
    )
}
