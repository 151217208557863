import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import TableScroll from '../utils/TableScroll'
import { DOWNLINE_COUNT_NODE_API, MEMBER_OUTSTANDING_NODE_API } from '../services/constants'
import { getMethodService } from '../services'
import { HandleLogout, amountCommasSparated, decodeData } from '../utils/Helper'
import TableLoading from '../utils/TableLoading'
import { stateObjType } from '../types'
import { useQuery } from '@tanstack/react-query'
import { userIdHandler } from '../store/slices/userIdSlice'
import { useDispatch, useSelector } from 'react-redux'
import ScreenLoader from '../utils/ScreenLoader'
const Cookies = require('js-cookie');
let totalFetch = 0;
let totalFetchPage = 0;

const StockMemberOutstandingDetail = () => {
    const abortController = new AbortController();
    const [outstandingsDetail, setOutstandingsDetail] = useState<any>([]);
    const [checkedAdminId,setCheckedAdminId] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    var checkTime = new Date();
    var actualDate = new Date();
    var footballDate = new Date();
    checkTime.setHours(10); checkTime.setMinutes(29);
    const params = useParams();

    if(actualDate.getTime() < checkTime.getTime()){
        footballDate.setDate(footballDate.getDate() - 1);
    }

    const year = footballDate.getFullYear();
    const month = String(footballDate.getMonth() + 1).padStart(2, "0");
    const day = footballDate.getDate();


    const tomorrowDate = new Date(footballDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const tomorrowYear = tomorrowDate.getFullYear();
    const tomorrowMonth = String(tomorrowDate.getMonth() + 1).padStart(2, "0");
    const tomorrowDay = String(tomorrowDate.getDate()).padStart(2, "0");

    const today = `${year}-${month}-${day.toString().padStart(2, "0")}`;
    const tomorrow = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`;
    const [downlineCount,setDownlineCount] = useState<any>(null);
    const [isDownlineLoading, setIsDownlineLoading] = useState(false);
    const [role,setRole] = useState("");
    const { temp_user_id } = useSelector((state: any) => state.userId);
    const dispatch = useDispatch();

    const fetchDownlinesCount = () => {
        const getAuth = Cookies.get("n_a_u");
        setIsDownlineLoading(true);
        let decode = decodeData(getAuth);
        getMethodService(`${DOWNLINE_COUNT_NODE_API}?user_id=${params.id}`,{},false,decode?.token)
          .then((res) => {
            setIsDownlineLoading(false);
            if (res == 401) {
              HandleLogout(navigate);
            }
            if (res?.status === "success") {
              setDownlineCount(res?.data);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsDownlineLoading(false);
          });
    };

    useEffect(() => {
        const getAuth = Cookies.get("a_u");
        let decode = getAuth && decodeData(getAuth);
        if (decode) {
          setRole(decode?.role_name)
          if (temp_user_id?.length > 0) {
            setCheckedAdminId(temp_user_id[temp_user_id.length - 1]);
          } else {
            dispatch(userIdHandler([decode?.user_id]));
            setCheckedAdminId(decode?.user_id);
          }
        }
      }, []);

    const fetchOutstandingData = async (page: number, limit: number) => {
        const getAuth = Cookies.get("n_a_u");
        if (getAuth) {
            // if (userId) {
            let decode = decodeData(getAuth);
            setIsLoading(true);
            return getMethodService(`${MEMBER_OUTSTANDING_NODE_API}?page=${page}&limit=${limit}&user_id=${params?.id}&start_date=${today} 10:29&end_date=${tomorrow} 10:28&sort_column=usercode`,{},false,decode?.token)
                .then((res) => {
                if (res == 401) {
                    HandleLogout(navigate);
                }
                if (res?.status === "success") {
                    totalFetch = +totalFetch+1;
                    let result = res?.data;
                    if (totalFetchPage == totalFetch) {
                        setIsLoading(false)
                    }

                    setOutstandingsDetail((prevReports:any) => {
                    const updatedReports = [...prevReports, ...result];

                    // Sort the updatedReports array by usercode
                    updatedReports.sort((a, b) =>
                        a.usercode.localeCompare(b.usercode)
                    );

                    return updatedReports;
                    });
                } else {
                    console.log("res",res)
                    console.log("report api  error response..");
                    fetchOutstandingData(page, limit);
                }

                // setIsLoading(false);
                })
                .catch((err) => {
                    console.log("report api requet fail ..", err);
                    fetchOutstandingData(page, limit);
                // setIsLoading(false);
                });
            // }
        };
    };

    const fetAllOutstandingData = async() => {
        setOutstandingsDetail([]);
        if (downlineCount?.count) {
          const concurrencyLimit = 1;
          let promises: any = [];
          let limit = 100000;
          let page =1;
          if(checkedAdminId == 1 || role === 'admin'){
             limit =  5;
             page = Math.ceil(downlineCount?.count / limit);
          };
          for (let i = 1; i <= page; i++) {
            if(window.location.pathname !== `/stock-manage/member-outstanding/${params?.id}`){
              break;
            }
            promises.push(fetchOutstandingData(i, limit));
            if (promises.length >= concurrencyLimit) {
              await Promise.all(promises);
              promises.length = 0; // Clear the array for the next batch
            }
          }
          setIsLoading(false);
        };
    };

    useEffect(() => {
        const abortController = new AbortController();
        fetAllOutstandingData();
        return () => {
            abortController.abort();
        };
    }, [ downlineCount]);


    useEffect(()=>{
        return () => {
            abortController.abort();
        }
    },[]);

    useEffect(() => {
        fetchDownlinesCount();
    }, [params?.id]);
    
    // const fetchMemberOutstandDetail = (id:string | number | undefined) => {
    //     const getAuth = Cookies.get('n_a_u');
    //     if(getAuth){
    //         let decode = decodeData(getAuth);
    //         return getMethodService(`${MEMBER_OUTSTANDING_NODE_API}?page=${page}&limit=${limit}&user_id=${id}&start_date=${today} 10:29&end_date=${tomorrow} 10:28&sort_column=usercode`,{},false,decode?.token);
    //     };
    // }
    // const memberOutstandDetailQuery = useQuery({ queryKey: ['memberOutstandDetail',params.id], queryFn: ()=>fetchMemberOutstandDetail(params.id),refetchOnWindowFocus: false});

    // useEffect(()=>{
    //     if(memberOutstandDetailQuery){
    //         setIsLoading(memberOutstandDetailQuery.isLoading);
    //         if(memberOutstandDetailQuery?.data == 401){
    //             HandleLogout(navigate);
    //         };
    //         if(memberOutstandDetailQuery?.data?.status === "success"){
    //             setOutstandingsDetail(memberOutstandDetailQuery?.data?.data);
    //         }
    //     };
    // },[memberOutstandDetailQuery]);

  return (
    <>
       <Template title='Stock Management'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-2">
                            <button onClick={() => navigate(-1)} className="btn btn-sm btn-dark" >
                                {isLoading ? "Loading ..." : "Back"}
                            </button>
                        </div>
                        <div className="col-sm-5">
                            <h4 className="card-title">
                                Member Oustanding Detail
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8">
                            <TableScroll>
                                <div>
                                <table className="table text-white w-100 table-bordered table-striped"  >
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a'}}>
                                                <th className="p-2 text-center text-light"  >No</th>
                                                <th className="p-2 text-center text-light"  >Account</th>
                                                <th className="p-2 text-center text-light"  >Total Outstanding</th>
                                                <th className="p-2 text-center text-light"  >Body Outstanding</th>
                                                <th className="p-2 text-center text-light"  >Parlay Outstanding</th>
                                                <th className="p-2 text-center text-light"  >3D Outstanding</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                outstandingsDetail.length > 0 ?
                                                outstandingsDetail.map((outstandingDetail:stateObjType, i:number) =>(
                                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="p-2 text-center text-dark">{i + 1}</td>
                                                        <td className="p-2 text-center text-dark">{outstandingDetail.usercode}</td>
                                                        <td className="p-2 text-end text-dark">
                                                        {outstandingDetail.role_id == 6 ? (
                                                            <Link
                                                                to={`/stock-manage/member/betting-list/${outstandingDetail.user_id}/pending/${today}/${tomorrow}`}
                                                            >
                                                                <b>
                                                                    {
                                                                        amountCommasSparated(outstandingDetail.oustanding)
                                                                    }
                                                                </b>
                                                            </Link>
                                                        ) : (
                                                            <Link
                                                                to={`/stock-manage/member-outstanding/${outstandingDetail.user_id}`}
                                                            >
                                                                <b>
                                                                    {
                                                                        amountCommasSparated(outstandingDetail.oustanding)
                                                                    }
                                                                </b>
                                                            </Link>
                                                        )}
                                                        </td>
                                                        <td className='p-2 text-end text-dark'><b>{amountCommasSparated(outstandingDetail.body_oustanding)}</b></td>
                                                        <td className='p-2 text-end text-dark'><b>{amountCommasSparated(outstandingDetail.perlay_oustanding)}</b></td>
                                                        <td className='p-2 text-end text-dark'><b>{amountCommasSparated(outstandingDetail.threed_oustanding)}</b></td>
                                                    </tr>
                                                )):
                                                <tr>
                                                    <td colSpan={6} className='text-center'>No found data</td>
                                                </tr>
                                            }
                                        </tbody>
                                        {
                                            outstandingsDetail?.length > 0 &&
                                            <tfoot>
                                                <tr style={{backgroundColor: '#25282a'}}>
                                                    <th className="p-2 text-end text-light" colSpan={2}>Total :</th>
                                                    <td style={{fontSize: '13px'}} className="text-end text-white p-2"><b>{outstandingsDetail.reduce((a:any,v:any) =>  a = a + v?.oustanding , 0 ).toLocaleString()}</b></td>
                                                    <th className="p-2 text-center text-light" colSpan={3}></th>
                                                </tr>
                                            </tfoot>
                                        }
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </Template> 
        <ScreenLoader isLoading={isLoading || isDownlineLoading} />
    </>
  )
}

export default StockMemberOutstandingDetail
