import { Modal, ModalBody } from "reactstrap";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";

const MMThreedResultProgress = (props) => {

  const [elapsedTime, setElapsedTime] = useState(0);
  const totalDuration = props.progress - 250

  useEffect(() => {
    if (elapsedTime < totalDuration) {

      const intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 100);
      }, 100);

      if (elapsedTime >= totalDuration) {
        clearInterval(intervalId);
      }
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [elapsedTime, totalDuration]);

  return (
    <Modal isOpen={true} style={{ maxWidth: "400px" }}>
      <ModalBody className="pb-4">
        <p className="text-center fs-2">ထီပေါက်သူ - ({props?.closeTime/500}) ယောက်</p>
        <p className="text-center fs-3" style={{ lineHeight: "30px" }}>
            အလျော်အစားပြုလုပ်နေသည် . . . 
        </p>
        <div
          class="progress"
          role="progressbar"
          aria-label="Animated striped example"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            style={{width: `${Math.round((elapsedTime / totalDuration) * 100)}%`}}
          ></div>
        </div>
          <p>
          {
            Math.round((elapsedTime / totalDuration) * 100) >= 100 ? 100 : Math.round((elapsedTime / totalDuration) * 100)
          }%
          </p>
      </ModalBody>
    </Modal>
  );
};

export default MMThreedResultProgress;
