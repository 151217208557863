import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { HandleLogout, copyToClipboard, generatePassword } from "../utils/Helper";
import { ToastContainer } from "react-toastify";
import { postMethodService,getMethodService } from "../services";
import { COMMESON_SETTING_API, CREATE_AGENT_API } from "../services/constants";
import SweetAlert from "sweetalert2";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function CreateAgent(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const params = useParams();
    const [contact, setContact] = useState("");
    const [usercode, setUserCode] = useState("");
    const [password, setPassword] = useState("");
    const [minBetLimit, setMinBetLimit] = useState("");
    const [maxSingleBetLimit, setMaxSingleBetLimit] = useState("");
    const [matchLimit, setMatchLimit] = useState("");
    const [minMyanmarPerlayLimitPerCombination, setMinMyanmarPerlayLimitPerCombination] = useState("");
    const [maxMyanmarPerlayLimitPerCombination, setMaxMyanmarPerlayLimitPerCombination] = useState("");
    const [commissionSM, setCommissionSM] = useState("");
    const [commissionLG, setCommissionLG] = useState("");
    const [perlay2, setPerlay2] = useState("");
    const [perlay38, setPerlay38] = useState("");
    const [perlay911, setPerlay911] = useState("");
    const [twoDCommission, setTwoCommission] = useState("");
    const [threeDCommission, setThreeDCommission] = useState("");

    const [twoDMinPerBet, setTwoDMinPerBet] = useState("");
    const [twoDMaxPerBet, setTwoDMaxPerBet] = useState("");
    const [twoDLimit, setTwoDLimit] = useState("");

    const [threeDMinPerBet, setThreeDMinPerBet] = useState("");
    const [threeDMaxPerBet, setThreeDMaxPerBet] = useState("");
    const [threeDLimit, setThreeDLimit] = useState("");

    const { user_info } = useSelector((state) => state.userInfo);
    const [auth, setAuth] = useState(null);
    const navigate = useNavigate();

    const toggleHandler = () => {
        setIsShowModal(!isShowModal);
    };

    const fetchCommSetting = () => {
        getMethodService(COMMESON_SETTING_API)
        .then((response) => {
            console.log(response)
            setIsLoading(false);
            if(response == 401){
                HandleLogout(navigate);
            };
            if(response.status){
                setAuth(response.data);
                if(user_info?.role != "admin"){
                    setMinBetLimit(response.data?.bet_setting?.min_bet_limit)
                    setMaxSingleBetLimit(response.data?.bet_setting?.max_single_bet_limit)
                    setMatchLimit(response.data?.bet_setting?.match_limit)
                    setMaxMyanmarPerlayLimitPerCombination(response.data?.bet_setting?.max_myanmar_perlay_limit_per_combination)
                    setMinMyanmarPerlayLimitPerCombination(response.data?.bet_setting?.min_myanmar_perlay_limit_per_combination)

                    setTwoDMinPerBet(response.data.bet_setting?.two_d_min_per_bet)
                    setTwoDMaxPerBet(response.data.bet_setting?.two_d_max_per_bet)
                    setTwoDLimit(response.data.bet_setting?.two_d_limit)

                    setThreeDMinPerBet(response.data.bet_setting?.three_d_min_per_bet)
                    setThreeDMaxPerBet(response.data.bet_setting?.three_d_max_per_bet)
                    setThreeDLimit(response.data.bet_setting?.three_d_limit)

                    setCommissionSM(filterCommissionV2(response.data,'commissionSM')?.percent)
                    setCommissionLG(filterCommissionV2(response.data,'commissionLG')?.percent)
                    setPerlay2(filterCommissionV2(response.data,'perlay2')?.percent)
                    setPerlay38(filterCommissionV2(response.data,'perlay38')?.percent)
                    setPerlay911(filterCommissionV2(response.data,'perlay911')?.percent)
                    setTwoCommission(filterCommissionV2(response.data,'twoDCommission')?.percent)
                    setThreeDCommission(filterCommissionV2(response.data,'threeDCommission')?.percent)
                };
            };
        });
    };


    useEffect(() => {
        if(user_info?.role != "admin"){
            fetchCommSetting();
        };
    }, []);

    useEffect(()=>{
        let generatePass = generatePassword();
        setPassword(generatePass);
    },[isShowModal])

    const saveHandler = () => {
        setIsLoading(true);
        const data = {
            role_id: params.id,
            usercode: usercode,
            contact: contact,
            password: password,
            min_bet_limit: minBetLimit,
            max_single_bet_limit: maxSingleBetLimit,
            match_limit: matchLimit,
            min_myanmar_perlay_limit_per_combination: minMyanmarPerlayLimitPerCombination,
            max_myanmar_perlay_limit_per_combination: maxMyanmarPerlayLimitPerCombination,

            two_d_min_per_bet : twoDMinPerBet,
            two_d_max_per_bet : twoDMaxPerBet,
            two_d_limit : twoDLimit,

            three_d_min_per_bet : threeDMinPerBet,
            three_d_max_per_bet : threeDMaxPerBet,
            three_d_limit : threeDLimit,

            commissions : {
                commissionSM: commissionSM,
                commissionLG: commissionLG,
                perlay2: perlay2,
                perlay38: perlay38,
                perlay911: perlay911,
                twoDCommission: twoDCommission,
                threeDCommission: threeDCommission,
            }
        };
        postMethodService(CREATE_AGENT_API, data)
        .then(res => {
            setIsLoading(false);
            if(res == 401){
                HandleLogout(navigate);
            };
            if(res?.status === 'success'){
                SweetAlert.fire({
                    icon: "success",
                    confirmButtonText: "Copy",
                    html: `<div style="background-color: #f3f3f3;padding: 10px 0;border-radius: 5px;">usercode: ${res.data.usercode} </br> password: ${res.data.password}</div>`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        copyToClipboard(
                            `usercode: ${res.data.usercode}\npassword: ${res.data.password}`
                        );
                        props.setRefreshKey(prevKey => prevKey + 1);
                    }
                });
                setIsLoading(false);
                setIsShowModal(false);
            };
          })
    };
    const filterCommissionV2 = (current_user,key) =>{
        let category_id = null;
        let game_type_id = null;
        let min_count = null;
        let max_count = null;
        switch (key) {
            case 'commissionSM':
                category_id = 1;
                game_type_id = 1;
                min_count = 1;
                max_count = 1;
                break;
            case 'commissionLG':
                category_id = 2;
                game_type_id = 1;
                min_count = 1;
                max_count = 1;
                break;
            case 'perlay2':
                category_id = 3;
                game_type_id = 1;
                min_count = 2;
                max_count = 2;
                break;
            case 'perlay38':
                category_id = 3;
                game_type_id = 1;
                min_count = 3;
                max_count = 8;
                break;
            case 'perlay911':
                category_id = 3;
                game_type_id = 1;
                min_count = 9;
                max_count = 11;
                break;
            case 'twoDCommission':
                category_id = null;
                game_type_id = 2;
                min_count = 1;
                max_count = 1;
                break;
            case 'threeDCommission':
                category_id = null;
                game_type_id = 3;
                min_count = 1;
                max_count = 1;
                break;
        }
        return current_user ? current_user.agent_commissions.find((element)=>{ return (element.game_type_id == game_type_id && element.mm_football_category_id == category_id && element.min_perlay_count == min_count && element.max_perlay_count == max_count)}) : null
    }

    const getPercentageOptions = (key, limit = 20, increment = 0.5) => {
        const options = [];
        let defaultValue = null;
        switch (key) {
            case 'commissionSM':
                defaultValue = commissionSM;
                break;
            case 'commissionLG':
                defaultValue = commissionLG;
                break;
            case 'perlay2':
                defaultValue = perlay2;
                break;
            case 'perlay38':
                defaultValue = perlay38;
                break;
            case 'perlay911':
                defaultValue = perlay911;
                break;
            case 'twoDCommission':
                defaultValue = twoDCommission;
                break;
            case 'threeDCommission':
                defaultValue = threeDCommission;
                break;
        }
        for (let i = 0; i <= limit * (1/increment); i++) {
            let num = (i * increment).toFixed(2);
            options.push(<option key={i} value={num} selected={defaultValue == num ? true : false}>{num}%</option>);
        }
        return options;
    }

    const filterCommissions = (key) =>{
        let category_id = null;
        let game_type_id = null;
        let min_count = null;
        let max_count = null;
        switch (key) {
            case 'commissionSM':
                category_id = 1;
                game_type_id = 1;
                min_count = 1;
                max_count = 1;
                break;
            case 'commissionLG':
                category_id = 2;
                game_type_id = 1;
                min_count = 1;
                max_count = 1;
                break;
            case 'perlay2':
                category_id = 3;
                game_type_id = 1;
                min_count = 2;
                max_count = 2;
                break;
            case 'perlay38':
                category_id = 3;
                game_type_id = 1;
                min_count = 3;
                max_count = 8;
                break;
            case 'perlay911':
                category_id = 3;
                game_type_id = 1;
                min_count = 9;
                max_count = 11;
                break;
            case 'twoDCommission':
                category_id = null;
                game_type_id = 2;
                min_count = 1;
                max_count = 1;
                break;
            case 'threeDCommission':
                category_id = null;
                game_type_id = 3;
                min_count = 1;
                max_count = 1;
                break;
        }
        return auth ? auth.agent_commissions.find((element)=>{ return (element.game_type_id == game_type_id && element.mm_football_category_id == category_id && element.min_perlay_count == min_count && element.max_perlay_count == max_count)}) : null
    }

    const handleMinInput = (value) =>
    {
        const limit = auth ? auth.bet_setting?.min_bet_limit : null;
        if(limit){
            value <= limit ? setMinBetLimit(value) : setMinBetLimit(limit);
        }else{
            setMinBetLimit(value);
        }
    }

    const handleMaxInput = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.max_single_bet_limit : null;
        if(limit){
            value <= limit ? setMaxSingleBetLimit(value) : setMaxSingleBetLimit(limit);
        }else{
            setMaxSingleBetLimit(value);
        }
    }

    const handleMatchLimitInput = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.match_limit : null;
        if(limit){
            value <= limit ? setMatchLimit(value) : setMatchLimit(limit);
        }else{
            setMatchLimit(value);
        }
    }

    const handleMinMyanmarPerlayLimitInput = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.min_myanmar_perlay_limit_per_combination : null;
        if(limit){
            value <= limit ? setMinMyanmarPerlayLimitPerCombination(value) : setMinMyanmarPerlayLimitPerCombination(limit);
        }else{
            setMinMyanmarPerlayLimitPerCombination(value);
        }
    }

    const handleMaxMyanmarPerlayLimitInput = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.max_myanmar_perlay_limit_per_combination : null;
        if(limit){
            value <= limit ? setMaxMyanmarPerlayLimitPerCombination(value) : setMaxMyanmarPerlayLimitPerCombination(limit);
        }else{
            setMaxMyanmarPerlayLimitPerCombination(value);
        }
    }

    const handleTwoMinPerBet = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.two_d_min_per_bet : null;
        if(limit){
            value <= limit ? setTwoDMinPerBet(value) : setTwoDMinPerBet(limit);
        }else{
            setTwoDMinPerBet(value);
        }
    }

    const handleTwoMaxPerBet = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.two_d_max_per_bet : null;
        if(limit){
            value <= limit ? setTwoDMaxPerBet(value) : setTwoDMaxPerBet(limit);
        }else{
            setTwoDMaxPerBet(value);
        }
    }

    const handleTwoLimit = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.two_d_limit : null;
        if(limit){
            value <= limit ? setTwoDLimit(value) : setTwoDLimit(limit);
        }else{
            setTwoDLimit(value);
        }
    }

    const handleThreeMinPerBet = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.three_d_min_per_bet : null;
        if(limit){
            value <= limit ? setThreeDMinPerBet(value) : setThreeDMinPerBet(limit);
        }else{
            setThreeDMinPerBet(value);
        }
    }

    const handleThreeMaxPerBet = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.three_d_max_per_bet : null;
        if(limit){
            value <= limit ? setThreeDMaxPerBet(value) : setThreeDMaxPerBet(limit);
        }else{
            setThreeDMaxPerBet(value);
        }
    }

    const handleThreeLimit = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.three_d_limit : null;
        if(limit){
            value <= limit ? setThreeDLimit(value) : setThreeDLimit(limit);
        }else{
            setThreeDLimit(value);
        }
    }



    return (
        <>
            <Modal isOpen={isShowModal} toggle={toggleHandler} size={"xl"}>
                <ModalHeader toggle={toggleHandler}>
                    <h3>Create {props.roleName}</h3>
                 </ModalHeader>
                <ModalBody>
                    <div id="addUserStepFormContent">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4 className="text-center mt-4">User Info</h4>
                                    <div className=" row">
                                        <label
                                            htmlFor="text"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Usercode *
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group mb-1">
                                                {(auth && auth.role_id != 1) && <span className="input-group-text text-dark" id="basic-addon1"><b>{auth ? auth.usercode : null}</b></span>}
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    name="usercode"
                                                    id="usercode"
                                                    placeholder="usercode"
                                                    autocomplete="off"
                                                    value={usercode}
                                                    onChange={(e) =>
                                                        setUserCode(e.target.value.replace(/[^a-zA-Z0-9]/g, '').trim().toLowerCase())
                                                    }
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className=" row">
                                        <label
                                            htmlFor="password"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Password *
                                        </label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                name="password"
                                                id="password"
                                                placeholder="Login password"
                                                aria-label="Password"
                                                autocomplete="off"
                                                value={password}
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className=" row">
                                        <label
                                            htmlFor="password"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Contact
                                        </label>
                                        <div className="col-sm-6">
                                            <textarea name="contact" id="contact" className="form-control form-control-sm" onChange={e => setContact(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <h4 className="text-center mt-4">2D Settings</h4>
                                    <div className=" row">
                                        <label
                                            htmlFor="twoDMinPerBet"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            2d Min per bet * <span className="text-danger">{auth ? `(${auth?.bet_setting?.two_d_min_per_bet ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="twoDMinPerBet"
                                                    value={twoDMinPerBet}
                                                    onChange={(e) =>
                                                        handleTwoMinPerBet(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" row">
                                        <label
                                            htmlFor="twoDMaxPerBet"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            2d Max per bet * <span className="text-danger">{auth ? `(${auth?.bet_setting?.two_d_max_per_bet ?? 0})`  : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="twoDMaxPerBet"
                                                    value={twoDMaxPerBet}
                                                    onChange={(e) =>
                                                        handleTwoMaxPerBet(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" row">
                                        <label
                                            htmlFor="twoDLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            2D Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.two_d_limit ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="twoDLimit"
                                                    value={twoDLimit}
                                                    onChange={(e) =>
                                                        handleTwoLimit(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        params.id != 6
                                        ?
                                    <div className=" row">
                                        <label
                                            htmlFor="commissionSM"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Commission (2D) * <span className="text-danger">{filterCommissions('twoDCommission') ? `(${filterCommissions('twoDCommission').percent} %)` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <select name="twoDCommission" id="" className="form-control form-control-sm" onChange={ e => setTwoCommission(e.target.value)}>
                                                    {getPercentageOptions('twoDCommission',filterCommissions('twoDCommission') ? filterCommissions('twoDCommission').percent : 18 , 0.5)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    :<></>
                                    }

                                    <h4 className="text-center mt-4">3D Settings</h4>
                                    <div className=" row">
                                        <label
                                            htmlFor="threeDMinPerBet"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            3d Min per bet * <span className="text-danger">{auth ? `(${auth?.bet_setting?.three_d_min_per_bet ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="threeDMinPerBet"
                                                    value={threeDMinPerBet}
                                                    onChange={(e) =>
                                                        handleThreeMinPerBet(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" row">
                                        <label
                                            htmlFor="threeDMaxPerBet"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            3d Max per bet * <span className="text-danger">{auth ? `(${auth?.bet_setting?.three_d_max_per_bet ?? 0})`  : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="threeDMaxPerBet"
                                                    value={threeDMaxPerBet}
                                                    onChange={(e) =>
                                                        handleThreeMaxPerBet(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" row">
                                        <label
                                            htmlFor="threeDLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            3D Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.three_d_limit ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="threeDLimit"
                                                    value={threeDLimit}
                                                    onChange={(e) =>
                                                        handleThreeLimit(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        params.id != 6
                                        ?
                                    <div className=" row">
                                        <label
                                            htmlFor="commissionSM"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Commission (3D) * <span className="text-danger">{filterCommissions('threeDCommission') ? `(${filterCommissions('threeDCommission').percent} %)` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <select name="threeDCommission" id="" className="form-control form-control-sm" onChange={ e => setThreeDCommission(e.target.value)}>
                                                    {getPercentageOptions('threeDCommission',filterCommissions('threeDCommission') ? filterCommissions('threeDCommission').percent : 30 , 0.5)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    :<></>}

                                </div>

                                        <div className="col-sm-6">
                                            <h4 className="text-center mt-4">Football Settings</h4>
                                    <div className=" row">
                                        <label
                                            htmlFor="minBetLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Min Bet Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.min_bet_limit ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="minBetLimit"
                                                    value={minBetLimit}
                                                    onChange={(e) =>
                                                        handleMinInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" row">
                                        <label
                                            htmlFor="maxSingleBetLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Max Single Bet Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.max_single_bet_limit ?? 0})`  : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="maxSingleBetLimit"
                                                    value={maxSingleBetLimit}
                                                    onChange={(e) =>
                                                        handleMaxInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" row">
                                        <label
                                            htmlFor="matchLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Match Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.match_limit ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="matchLimit"
                                                    value={matchLimit}
                                                    onChange={(e) =>
                                                        handleMatchLimitInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" row">
                                        <label
                                            htmlFor="minMyanmarPerlayLimitPerCombination"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Min Myanmar Perlay Limit Per Combination * <span className="text-danger">{auth ? `(${auth?.bet_setting?.min_myanmar_perlay_limit_per_combination ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="minMyanmarPerlayLimitPerCombination"
                                                    id="minMyanmarPerlayLimitPerCombination"
                                                    value={minMyanmarPerlayLimitPerCombination}
                                                    onChange={(e) =>
                                                        handleMinMyanmarPerlayLimitInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" row">
                                        <label
                                            htmlFor="maxMyanmarPerlayLimitPerCombination"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Max Myanmar Perlay Limit Per Combination * <span className="text-danger">{auth ? `(${auth?.bet_setting?.max_myanmar_perlay_limit_per_combination ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="maxMyanmarPerlayLimitPerCombination"
                                                    value={maxMyanmarPerlayLimitPerCombination}
                                                    onChange={(e) =>
                                                        handleMaxMyanmarPerlayLimitInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        params.id != 6
                                        ?
                                        <Fragment>
                                            <div className=" row">
                                                <label
                                                    htmlFor="commissionSM"
                                                    className="col-sm-6 col-form-label form-label"
                                                >
                                                    Commission (ပွဲသေး) * <span className="text-danger">{filterCommissions('commissionSM') ? `(${filterCommissions('commissionSM')?.percent} %)` : null}</span>
                                                </label>
                                                <div className="col-sm-6">
                                                    <div className="input-group input-group-sm-vertical">
                                                        <select name="commissionSM" id="" className="form-control form-control-sm" onChange={ e => setCommissionSM(e.target.value)}>
                                                            {getPercentageOptions('commissionSM',filterCommissions('commissionSM') ? filterCommissions('commissionSM')?.percent : 2 , 0.1)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" row">
                                            <label
                                                htmlFor="commissionLG"
                                                className="col-sm-6 col-form-label form-label"
                                            >
                                                Commission (ပွဲကြီး) * <span className="text-danger">{filterCommissions('commissionLG') ? `(${filterCommissions('commissionLG')?.percent} %)` : null}</span>
                                            </label>
                                            <div className="col-sm-6">
                                                <div className="input-group input-group-sm-vertical">
                                                    <select name="commission" id="" className="form-control form-control-sm" onChange={ e => setCommissionLG(e.target.value)}>
                                                    {getPercentageOptions('commissionLG',filterCommissions('commissionLG') ? filterCommissions('commissionLG')?.percent : 2, 0.1)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" row">
                                            <label
                                                htmlFor="perlay2"
                                                className="col-sm-6 col-form-label form-label"
                                            >
                                                Commission (Perlay (2)) * <span className="text-danger">{filterCommissions('perlay2') ? `(${filterCommissions('perlay2').percent} %)` : null}</span>
                                            </label>
                                            <div className="col-sm-6">
                                                <div className="input-group input-group-sm-vertical">
                                                    <select name="perlay2" id="" className="form-control form-control-sm" onChange={ e => setPerlay2(e.target.value)}>
                                                    {getPercentageOptions('perlay2',filterCommissions('perlay2') ? filterCommissions('perlay2').percent : 20)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" row">
                                            <label
                                                htmlFor="perlay38"
                                                className="col-sm-6 col-form-label form-label"
                                            >
                                                Commission (Perlay (3-8)) * <span className="text-danger">{filterCommissions('perlay38')? `(${filterCommissions('perlay38').percent} %)`:null}</span>
                                            </label>
                                            <div className="col-sm-6">
                                                <div className="input-group input-group-sm-vertical">
                                                    <select name="perlay38" id="" className="form-control form-control-sm" onChange={ e => setPerlay38(e.target.value)}>
                                                        {getPercentageOptions('perlay38',filterCommissions('perlay38') ? filterCommissions('perlay38').percent : 20)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" row">
                                            <label
                                                htmlFor="perlay911"
                                                className="col-sm-6 col-form-label form-label"
                                            >
                                                Commission (Perlay (9-11)) * <span className="text-danger">{filterCommissions('perlay911')? `(${filterCommissions('perlay911').percent} %)`: null}</span>
                                            </label>
                                            <div className="col-sm-6">
                                                <div className="input-group input-group-sm-vertical">
                                                    <select name="perlay911" id="" className="form-control form-control-sm" onChange={ e => setPerlay911(e.target.value)}>
                                                        {getPercentageOptions('perlay911',filterCommissions('perlay911') ? filterCommissions('perlay911').percent : 20)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        </Fragment>
                                        :<></>}

                                        </div>

                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {isLoading ? (
                        <button type="button" className="btn btn-primary">
                            <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-hs-step-form-next-options='{
                                "targetSelector": "#addUserStepBillingAddress"
                              }'
                            onClick={saveHandler}
                        >
                            Save <i className="bi-chevron-right"></i>
                        </button>
                    )}
                </ModalFooter>
            </Modal>

            <button
                className="btn btn-primary"
                onClick={() => setIsShowModal(true)}
            >
                <i className="bi-person-plus-fill me-1"></i> Create
            </button>

            <ToastContainer />
        </>
    );
}

export default CreateAgent;