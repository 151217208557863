import React from 'react'

const MemberPercentDetail = ({title,value}:any) => {
  return (
        <div className='row pb-2 align-items-center mb-2' style={{borderBottom: '1px solid #c5c5c5'}}>
            <div className='col-6'>
                <h4 className='mb-0' style={{fontSize: '14px', color: '#000',fontWeight: 'bolder'}}>{title}</h4>
            </div>
            <div className='col-6'>
                <p className='mb-0 text-end' style={{fontSize: '14px', color: '#4f5a65'}}>{value}</p>
            </div>
        </div>
  )
}

export default MemberPercentDetail
