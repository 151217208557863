import React, { useEffect, useMemo, useState } from 'react'
import Template from '../../utils/Template'
import { useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import classes from '../../assets/styles/CreateNews.module.css';
import { fileUploadService } from '../../services';
import { HandleLogout, decodeData, errorToaster } from '../../utils/Helper';
import { CREATE_POSTS } from '../../services/constants';
import { ToastContainer } from 'react-toastify';
const preImage = require('../../assets/images/other/preImage.png');
const Cookies = require('js-cookie');

const CreateNews = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [title, setTitle] = useState<any>(null);
  const [previewImgFile, setPreviewImgFile] = useState<any>(null);
  const [imgFile,setImgFile] = useState<any>("");
  const [text,setText] = useState("");
  const navigate = useNavigate();
    function handleImageChange(e:any) {
        setImgFile(e?.target?.files[0]);
        setPreviewImgFile(URL?.createObjectURL(e?.target?.files[0]));
    };

    const inputHandler = (text:any) => {
        setText(text);
    };

    const submitHandler = () => {
        setIsLoading(true);
        if(!imgFile){
            setIsLoading(false);
            return errorToaster("Post image is required!");
        };
        // if(!title){
        //     setIsLoading(false);
        //     return errorToaster("Post title is required!");
        // };
        if(!text){
            setIsLoading(false);
            return errorToaster("Post description is required!");
        };
        let formData = {
            title,
            detail: text,
            image: imgFile
        };
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            fileUploadService(CREATE_POSTS,formData,true,decode?.token)
            .then(res => {
                setIsLoading(false);
                if(res == 401){
                    HandleLogout(navigate);
                };
                if(res?.status || res?.status === 'success'){
                    const delay = setTimeout(()=>{
                        setTitle("");
                        setImgFile("");
                        setPreviewImgFile("");
                        setText("");
                        navigate('/community/news');
                    },1000);
        
                    return ()=> {
                        clearTimeout(delay);
                    };
                };
            });
        };
    };

    const autofocusNoSpellcheckerOptions = useMemo(() => {
        return {
          autofocus: true,
          spellChecker: false,
        };
    }, []);


  return (
    <>
      <Template title='Social'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <h4 className="card-title">Create News</h4>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <div className="d-flex align-items-center">
                            <label
                            htmlFor="avatarUploader"
                            className='position-relative'
                            >
                                <img
                                    id="avatarProjectSettingsImg"
                                    className={classes.uploadImg}
                                    src={previewImgFile ?? preImage}
                                    alt="Image Description"
                                />
                                <input
                                    type="file"
                                    id="avatarUploader"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className={classes.uploadFile}
                                />
                                <span className={classes.uploadIcon}>
                                    <i className="bi-pencil-fill shadow-sm" />
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="newsTitleLabel" className="form-label">
                            News Title{" "}
                        </label>
                        <div className="input-group input-group-merge">
                            <div className="input-group-prepend input-group-text">
                            <i className="bi bi-card-heading" />
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                name="projectName"
                                id="newsTitleLabel"
                                placeholder="Enter news title"
                                aria-label="Enter news title"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="newsTitleLabel" className="form-label">
                            News Detail{" "}
                        </label>
                        <SimpleMDE
                            // id="editor_container"
                            onChange={inputHandler}
                            value={text}
                            options={autofocusNoSpellcheckerOptions}
                        />
                    </div>
                    <div className='d-flex justify-content-end align-items-center'>
                        <button
                            className="btn btn-primary w-100 mt-4"
                            onClick={submitHandler}
                            disabled={isLoading}
                        >
                            {isLoading ? "Loading ..." : "Submit"}
                        </button>
                    </div>

                </div>
            </div>
      </Template>
      <ToastContainer />
    </>
  )
}

export default CreateNews
