import { createSlice } from "@reduxjs/toolkit";

const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        setting: {},
    },
    reducers: {
        settingHandler: (state,action) => {
            state.setting = action.payload
        }
    }
});

export const {settingHandler} = settingSlice.actions;
export default settingSlice.reducer;