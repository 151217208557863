import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from "react";
import { ISOdateFormatter } from "../Helper";
import moment from "moment";

const MMThreedSettingModal = (props) => {
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit();
  }
  const startHandler = (e) => {
    props.setStart(e.target.value);
    
  }
  const endHandler = (e) => {
    props.setEnd(e.target.value)
  }
  const closeHandler = () => {
    props.onClose()
    props.setStart('')
    props.setEnd('')
  }

  useEffect(() => {
    let date = moment(props.start, "YYYY-MM-DD HH:mm:ss").format('DD');
    props.setLotteryTime(+date > 19 ? '၁ ရက်နေ့' : '၁၆ ရက်နေ့')
  }, [props.start])

  useEffect(() => {
    const today = new Date().getDate()
    const thisMonth = new Date().getMonth();
    const thisYear = new Date().getFullYear();

    // get last day of this month 
    // const current = new Date();
    // current.setMonth(thisMonth+1, 0)
    // const lastDay = current.getDate()
    
    if (today > 19) {
      setMinDate(`${thisYear}-${thisMonth+1 >= 10 ? '' : '0'}${thisMonth+1}-20 00:01`)
      setMaxDate(`${thisYear}-${thisMonth+2 >= 10 ? '' : '0'}${thisMonth+2}-04 00:01`)
      // console.log(`${thisYear}-${thisMonth+2 >= 10 ? '' : '0'}${thisMonth+2}-04 00:01`)
    } else {
      setMinDate(`${thisYear}-${thisMonth+1 >= 10 ? '' : '0'}${thisMonth+1}-05 00:01`)
      setMaxDate(`${thisYear}-${thisMonth+1 >= 10 ? '' : '0'}${thisMonth+1}-19 00:01`)
    }

    console.log(minDate, maxDate)
  }, [])

  return (
    <Modal isOpen={true} toggle={closeHandler}>
      <ModalHeader toggle={closeHandler}>{props.name}</ModalHeader>
      <ModalBody className="pb-3">
        <Form>
          <FormGroup>
            <label htmlFor="start">Opening Time</label>
            {/* <Input type="datetime-local" className="mb-3" id="start" min={minDate} max={maxDate} value={props.start} onChange={startHandler} /> */}
            <Input type="datetime-local" className="mb-3" id="start" value={props.start} onChange={startHandler} />
            <label htmlFor="end">Closing Time</label>
            {/* <Input type="datetime-local" className="mb-3" id="end" min={minDate} max={maxDate} value={props.end} onChange={endHandler} /> */}
            <Input type="datetime-local" className="mb-3" id="end" value={props.end} onChange={endHandler} />
            <label htmlFor="end">Status</label>
            <select className="form-select mb-3" value={props.threedTimeStatus} onChange={(e) => props.setThreedTimeStatus(e.target.value)}>
                <option value="open">Open</option>
                <option value="close">Close</option>
            </select>
            <label>Lottery Time</label>
            <Input type="text" disabled value={props.lotteryTime} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className="py-3">
        <button className="btn py-2 btn-danger" onClick={closeHandler}>
          No
        </button>
        <button className="btn py-2 btn-primary" onClick={handleSubmit} disabled={props.isLoading}>
          {
            props.isLoading ? 'Uploading...' : 'Save'
          }
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default MMThreedSettingModal
