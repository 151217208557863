import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Template from '../utils/Template';
import { getMethodService } from '../services';
import { STOCK_MANAGEMENT_OVER_UNDER_DETAIL_NODE_API } from '../services/constants';
import { HandleLogout, amountCommasSparated, decodeData } from '../utils/Helper';
import { stateObjType } from '../types';
import TableLoading from '../utils/TableLoading';
import { useQuery } from '@tanstack/react-query';
import TableScroll from '../utils/TableScroll';
const Cookies = require('js-cookie');

const StockDetailOverUnder = () => {
  const [isLoading,setIsLoading] = useState(true);
  const [data, setData] = useState<stateObjType>({});
  const params = useParams();
  const navigate = useNavigate();

  const fetchStockOUDetail = (id:string | number | undefined) => {
    const getAuth = Cookies.get('n_a_u');
    if(getAuth){
        let decode = decodeData(getAuth);
        return getMethodService(`${STOCK_MANAGEMENT_OVER_UNDER_DETAIL_NODE_API(id)}`,{},false,decode?.token);  
    };
  }
  
  
  const stockOuQuery = useQuery({ queryKey: ['ouDetail',params.id], queryFn: () => fetchStockOUDetail(params.id), refetchOnWindowFocus: false});
    useEffect(() => {
        if(stockOuQuery){
            setIsLoading(stockOuQuery.isLoading);
            if(stockOuQuery?.data == 401){
                HandleLogout(navigate);
            };
            setData(stockOuQuery?.data?.data);
        }
    }, [stockOuQuery]);

  const calculateTotal = (data: number | any) =>{
    return data?.reduce((a:number | any,b: number | any) => {
        return a + b?.amount
    },0);
  };

  return (
    <Template title='Stock Management'>
        <div className="card mb-3 mb-lg-5">
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col-sm-5">
                        <h4 className="card-title">
                            OU Detail - {params?.id}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12">
                        <h6>
                            {
                                data?.odds_team == 'home' ?
                                (
                                    <span className="text-danger">{
                                        data?.home_team_data?.name
                                    }</span>
                                    
                                ) :
                                data?.home_team_data?.name
                            }
                            {` `} vs {` `}
                            {
                                data?.odds_team == 'away' ?
                                (
                                    <span className="text-danger">{
                                        data?.away_team_data?.name
                                    }</span>
                                ) :
                                data?.away_team_data?.name
                            }
                            {
                                data?.goals &&` (${data?.goals?.home} vs ${data?.goals?.away})`
                            }
                        </h6>
                    </div>
                    <div className="col-sm-6">
                            <div>
                                <h6 className="text-center">
                                   Over
                                </h6>
                            <TableScroll>
                                <div>
                                    <table className="w-100 table table-bordered table-striped text-white" style={{fontSize:'10px'}}>
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a'}}>
                                                <th className="text-center text-light p-2" style={{fontSize:'10px'}}>User</th>
                                                <th className="text-center text-light p-2" style={{fontSize:'10px'}}>Date</th>
                                                <th className="text-center text-light p-2" style={{fontSize:'10px'}}>Detail</th>
                                                <th className="text-center text-light p-2" style={{fontSize:'10px'}}>Amt</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {isLoading && (
                                            <TableLoading col={4} />
                                        )}
                                        {
                                            data?.over_bettings?.length > 0 &&
                                            data?.over_bettings?.map((betting:stateObjType,i:number)=>(
                                                <tr style={{verticalAlign: 'middle'}} key={i}>
                                                    <td className="text-center text-dark p-2">{betting?.users?.usercode}</td>
                                                    <td className="text-center text-dark p-2">
                                                        <p className="mb-0">{betting?.created_at}</p>
                                                    </td>
                                                    <td className="text-center text-dark p-2">
                                                        {`${betting?.bet_football_fixtures[0]?.bet_symbol1} ( ${betting?.bet_football_fixtures[0]?.bet_symbol2 } ${betting?.bet_football_fixtures[0]?.bet_symbol3} )`}
                                                    </td>
                                                    <td className="text-end text-dark p-2">
                                                        <b>{amountCommasSparated(betting?.amount ?? 0)}</b>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                        <tfoot>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                <th colSpan={3} className='text-end text-white' style={{fontSize: '13px'}}>
                                                    Total :
                                                </th>
                                                <td className="text-end text-white p-2" style={{fontSize: '13px'}}>
                                                    {
                                                        data?.over_bettings?.length > 0 ?
                                                        amountCommasSparated(calculateTotal(data?.over_bettings)):
                                                        0
                                                    }
                                                </td>
                                            </tr>
                                    </tfoot>
                                    </table>
                                </div>
                            </TableScroll>
                            </div>
                    </div>
                    <div className="col-sm-6">
                        <div>
                            <h6 className="text-center">
                                Under
                            </h6>
                            <TableScroll>
                                <div>
                                    <table className="w-100 table table-bordered table-striped text-white" style={{fontSize:'10px'}}>
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a'}}>
                                                <th className="text-center text-light p-2" style={{fontSize:'10px'}}>User</th>
                                                <th className="text-center text-light p-2" style={{fontSize:'10px'}}>Date</th>
                                                <th className="text-center text-light p-2" style={{fontSize:'10px'}}>Detail</th>
                                                <th className="text-center text-light p-2" style={{fontSize:'10px'}}>Amt</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {isLoading && (
                                            <TableLoading col={4} />
                                        )}
                                        {
                                            data?.under_bettings?.length > 0 &&
                                            data?.under_bettings?.map((betting:stateObjType,i:number)=>(
                                                <tr style={{verticalAlign: 'middle'}} key={i}>
                                                    <td className="text-center text-dark p-2">{betting?.users?.usercode}</td>
                                                    <td className="text-center text-dark p-2">
                                                        <p className="mb-0">{betting?.created_at}</p>
                                                    </td>
                                                    <td className="text-center text-dark p-2">
                                                        {`${betting?.bet_football_fixtures[0]?.bet_symbol1} ( ${betting?.bet_football_fixtures[0]?.bet_symbol2 } ${betting?.bet_football_fixtures[0]?.bet_symbol3} )`}
                                                    </td>
                                                    <td className="text-end text-dark p-2">
                                                        <b>{amountCommasSparated(betting?.amount)}</b>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                        <tfoot>
                                                <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                    <th colSpan={3} className='text-end text-white' style={{fontSize: '13px'}}>
                                                        Total :
                                                    </th>
                                                    <td className="text-end text-white p-2" style={{fontSize: '13px'}}>
                                                        {
                                                            data?.under_bettings?.length > 0 ?
                                                            amountCommasSparated(calculateTotal(data?.under_bettings)):
                                                            0
                                                        }
                                                    </td>
                                                </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Template>
  )
}

export default StockDetailOverUnder 