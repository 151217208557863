import React from 'react';
import { Navigate } from 'react-router-dom';
import { decodeData } from '../utils/Helper';
const Cookies = require('js-cookie');

const Protect = ({children}: any) => {
  const token = Cookies.get('a_u');
  if(token){
    let decode = decodeData(token);
    if(decode.is_password_changed){
      return children;
    }else{
      return <Navigate to='/change-password' />
    };
  }else{
    return <Navigate to='/login' />
  };
};

export default Protect
