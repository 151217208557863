import React, { useEffect, useMemo, useState } from 'react'
import Template from '../../utils/Template';
import classes from '../../assets/styles/RequestDetail.module.css';
import { ToastContainer } from 'react-toastify';
import Fancybox from '../../utils/FancyBox';
import { HandleLogout, changeTimeAgo, decodeData } from '../../utils/Helper';
import { getMethodService, postMethodService, putMethodService } from '../../services';
import { CREATE_REPLY_API, FEEDBACK_DETAIL_API } from '../../services/constants';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
const Cookies = require('js-cookie');
const userImg = require('../../assets/images/icons/userImg.png');

const RequestDetail = () => {
  const abortController = new AbortController();
  const [isLoading,setIsLoading] = useState(false);
  const [refreshKey,setRefreshKey] = useState(0);
  const {id} = useParams();
  const navigate = useNavigate();
  const [data,setData] = useState<any>({});
  const [message,setMessage] = useState("ပြင်ပေးထားပါပြီခင်ဗျ");

  const fetchFeedbacks = () => {
    const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            return getMethodService(`${FEEDBACK_DETAIL_API(id)}`,{},false,decode?.token,abortController);
        };
  };
  const feedbackQuery = useQuery({ queryKey: ['complaint_detail',refreshKey], queryFn: fetchFeedbacks});

    useEffect(()=>{
        if(feedbackQuery){
            if(feedbackQuery.data === "Unauthenticated"){
                return HandleLogout(navigate);
            };
            // setIsLoading(feedbackQuery.isLoading);
            if(feedbackQuery?.data){
                setData(feedbackQuery?.data?.data);
            };
        };
    },[feedbackQuery]);

    const statusUpdateHandler = (id:number,status:string) => {
        setIsLoading(true);
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            putMethodService(FEEDBACK_DETAIL_API(id),{status},true,decode?.token)
            .then(res => {
                setIsLoading(false);
                if(res == 401){
                    HandleLogout(navigate);
                };
                if(res.status === "success"){
                    setRefreshKey(prevKey => prevKey + 1);
                    if((status === 'completed') || (status === 'rejected')){
                        setTimeout(()=> navigate('/feedback/request'), 500)
                    };
                };
            });
        };
    };

    const messageHandler = (e:any) => {
        e.preventDefault();
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            let data = {
                fc_id: id,
                message
            }
            postMethodService(CREATE_REPLY_API,data,true,decode?.token)
            .then(res => {
                if(res == 401){
                    HandleLogout(navigate);
                };
                if(res.status === "success"){
                    setRefreshKey(prevKey => prevKey + 1);
                    setMessage("");
                };
            });
        };
    };
    
  return (
    <>
      <Template title='Complain & Feedback'>
            <div className="card mb-3 mb-lg-4" style={{backgroundColor: '#ebedff'}}>
                <div className="card-header bg-dark">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <h4 className="card-title text-white">Complain</h4>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="d-flex align-items-start">
                        <label htmlFor="" className="form-label text-dark mb-0 me-2 fw-bolder" style={{width: '120px'}}>
                            Usercode
                        </label>: &nbsp;
                        <p className={classes.histPara} >
                            {data?.user_data?.usercode || '--'}
                        </p>
                    </div>
                    <div className="d-flex align-items-start">
                        <label htmlFor="" className="form-label text-dark mb-0 me-2 fw-bolder" style={{width: '120px'}}>
                            Type
                        </label>: &nbsp;
                        <p className={classes.histPara} >
                            {data?.game}
                        </p>
                    </div>
                    {
                        data?.game !== '3D' &&
                        <div className="d-flex align-items-start">
                            <label htmlFor="" className="form-label text-dark mb-0 me-2 fw-bolder" style={{width: '120px'}}>
                                Slip ID
                            </label>: &nbsp;
                            <p style={{cursor: 'pointer', textDecoration: 'underline'}} className={`${classes.histPara} text-primary`} onClick={() => window.open(`/stock-manage/parlay-detail/${data?.betslip_id}`, '_blank')}>
                                {data?.betslip_id}
                            </p>
                        </div>
                    }
                    <div className="d-flex align-items-start">
                        <label htmlFor="" className="form-label text-dark mb-0 me-2 fw-bolder" style={{width: '120px'}}>
                            Phone
                        </label>: &nbsp;
                        <p className={classes.histPara} >
                            {data?.phone || '--'}
                        </p>
                    </div>
                    <div className="d-flex align-items-start">
                        <label htmlFor="" className="form-label text-dark mb-0 me-2 fw-bolder" style={{width: '120px'}}>
                            Status
                        </label>: &nbsp;
                        <p className={classes.histPara} >
                            <span className='badge text-white' style={{ backgroundColor:  (data?.status === "completed") ? '#00c9a7': (data?.status === "rejected" ? '#ed4c78':'#ff8800')}}>{data?.status}</span>
                        </p>
                    </div>
                    <div className="d-flex align-items-start mb-3">
                        <label htmlFor="" className="form-label text-dark mb-0 me-2 fw-bolder" style={{width: '120px'}}>
                            Description
                        </label>: &nbsp;
                        <p className={classes.histPara} >
                            {data?.content}
                        </p>
                    </div>
                    {
                        data?.attach_files?.length > 0 &&
                        <div className="d-flex align-items-start">
                            <label htmlFor="" className="form-label text-dark mb-0 me-2 fw-bolder" style={{width: '120px'}}>
                                Photo
                            </label>: &nbsp;
                            <div style={{width: '100%'}}>
                                <Fancybox
                                    options={{
                                        Carousel: {
                                            infinite: false,
                                        },
                                    }}
                                    style={{display: 'flex'}}
                                >
                                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                        {
                                            data?.attach_files?.map((src:any,i:number) => 
                                            <a key={i} className={classes.previewDetailImage} data-fancybox="gallery" href={src}>
                                                <img alt='photo' src={src}/>
                                            </a>
                                            )
                                        }
                                    </div>
                                </Fancybox>
                            </div>
                        </div>
                    }
                        <div className="d-flex align-items-start mt-4">
                            <label htmlFor="" className="form-label text-dark mb-0 me-2 fw-bolder" style={{width: '120px'}}>
                                Action
                            </label>: &nbsp;
                            <div className='w-75'>
                                <div className="input-group input-group-md-vertical">
                                    <label className={`form-control`} style={{backgroundColor: '#ff8800'}} htmlFor="pending">
                                        <span className="form-check">
                                            <input type="radio" checked={data?.status === 'pending'} value="pending" className="form-check-input bg-warning border-white" disabled={isLoading} id="pending" onChange={(e) => statusUpdateHandler(data?.id,e.target.value)}/>
                                            <span className="form-check-label text-white">Pending</span>
                                        </span>
                                    </label>
                                    <label className={`form-control bg-info`} htmlFor="in-progress">
                                        <span className="form-check">
                                            <input type="radio" checked={data?.status === 'in-progress'} value="in-progress" className="form-check-input bg-info border-white" disabled={isLoading} id="in-progress" onChange={(e) => statusUpdateHandler(data?.id,e.target.value)}/>
                                            <span className="form-check-label text-white">In Progress</span>
                                        </span>
                                    </label>
                                    <label className={`form-control bg-success`} htmlFor="completed">
                                        <span className="form-check">
                                            <input type="radio" checked={data?.status === 'completed'} value="completed" className="form-check-input bg-success border-white" disabled={isLoading} id="completed" onChange={(e) => statusUpdateHandler(data?.id,e.target.value)}/>
                                            <span className="form-check-label text-white">Complete</span>
                                        </span>
                                    </label>
                                    <label className={`form-control bg-danger`} htmlFor="rejected">
                                        <span className="form-check">
                                            <input type="radio" checked={data?.status === 'rejected'} value="rejected" className="form-check-input bg-danger border-white" disabled={isLoading} id="rejected" onChange={(e) => statusUpdateHandler(data?.id,e.target.value)}/>
                                            <span className="form-check-label text-white">Reject</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div className="card" style={{backgroundColor: '#ebedff'}}>
                <div className="card-header bg-dark"> 
                    <div className="row align-items-center justify-content-between">
                        <div className="col-3">
                            <h4 className="card-title text-white">Reply Message</h4>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className={classes.commentContainer}>
                        <div>
                            {
                                data?.fc_replies && (data?.fc_replies?.length > 0 &&
                                data?.fc_replies?.map((mes:any) => 
                                    mes?.user_data?.role_name === "admin"? 
                                    <div className={classes.commentNote} key={mes?.id}>
                                        <div className='d-flex justify-content-end'>
                                            <div className={classes.messageSize}>
                                                <div className={classes.messageContainer}>
                                                    <p className={classes.adminComment}>
                                                        {mes?.content}
                                                    </p>
                                                </div>
                                                <small className={classes.adminMessageTime}>{changeTimeAgo(mes?.createdAt)}</small>
                                            </div>
                                        </div>
                                    </div>:
                                    <div className={classes.commentNote} key={mes?.id}>
                                        <div className='d-flex'>
                                            <img src={userImg} className={classes.userImg} alt="admin" />
                                            <div className={classes.messageContainer}>
                                                <div>
                                                    <p className={classes.userName}>{mes?.user_data?.usercode}</p>
                                                    <small className={classes.messageTime}>{changeTimeAgo(mes?.createdAt)}</small>
                                                </div>
                                                <p className={classes.userComment}>{mes?.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <form onSubmit={messageHandler}>
                        <div className="form-group mb-3">
                            <textarea value={message} id="message" placeholder='Write a comment...' rows={3} className="form-control" style={{resize: 'none'}} onChange={e => setMessage(e.target.value)}></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary btn-block w-100">Comment</button>
                    </form>
                </div>
            </div>
      </Template>
      <ToastContainer />
    </>
  )
}

export default RequestDetail
