import React, { useEffect, useState } from 'react'
import classes from '../assets/styles/AutoBetToggle.module.css'
const FixtureToggle = ({id,status,event,data}) => {
    const [checkStatus,setCheckStatus] = useState(false);
    useEffect(()=>{
        setCheckStatus(status === 1? true: false)
    },[status]);
    const submitHandler = () => {
        event && event(id,!checkStatus,data);
        setCheckStatus(!checkStatus);
    };
    return (
        <div className={classes['switch-container']}>
            <input type="checkbox" className={classes.checkbox} id={id} checked={checkStatus} onChange={submitHandler}/>
            <label className={classes.switch} htmlFor={id}>
                <span className={classes.slider}></span>
            </label>
        </div>
      )
    }

export default FixtureToggle