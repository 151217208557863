import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangeType } from '../types';
import { useDispatch } from 'react-redux';
import { dateHandler } from '../store/slices/dateSlice';

const DateRangeFormat = ({startDate,endDate,setStartDate, setEndDate}: DateRangeType) => {
    const [dateType,setDateType] = useState<any>(localStorage.getItem('r_type'));
    const dispatch = useDispatch();

    function currentDate() {
      const currentTime = moment();
      const targetTime = moment().set({ hour: 10, minute: 29, second: 0, millisecond: 0 });
      if (currentTime.isBefore(targetTime)) {
        let start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        let end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
        localStorage.setItem('r_type',"1");
      }else{
        let start_date = moment().format('YYYY-MM-DD');
        let end_date = moment().format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
        localStorage.setItem('r_type',"1");
      };
    };

    function yesterdayDate() {
        const yesterdayTime = moment();
        const targetTime = moment().set({ hour: 10, minute: 29, second: 0, millisecond: 0 });
        if(yesterdayTime.isBefore(targetTime)){
          let start_date = moment().subtract(2, 'days').format('YYYY-MM-DD');
          let end_date = moment().subtract(2, 'days').format('YYYY-MM-DD');
          setStartDate(start_date);
          setEndDate(end_date);
          localStorage.setItem('r_type',"2");
        }else{
          let start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
          let end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
          setStartDate(start_date);
          setEndDate(end_date);
          localStorage.setItem('r_type',"2");
        };
    };

    function thisWeek() {
        let start_date = moment().startOf('isoWeek').format('YYYY-MM-DD');
        let end_date = moment().endOf('isoWeek').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
        localStorage.setItem('r_type',"3");
    };

    function lastWeek() {
        let start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
        let end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
        localStorage.setItem('r_type',"4");
    };

    function thisMonth() {
        let start_date = moment().startOf('month').format('YYYY-MM-DD');
        let end_date = moment().endOf('month').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
        localStorage.setItem('r_type',"5");
    };
    function lastMonth() {
      let start_date = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      let end_date = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(start_date);
      setEndDate(end_date);
      localStorage.setItem('r_type',"6");
    };

    useEffect(()=>{
      console.log(dateType)
       switch(dateType){
          case '1':
             return currentDate();
          case '2':
            return yesterdayDate();
          case '3':
            return thisWeek();
          case '4':
            return lastWeek();
          case '5':
            return thisMonth();
          case '6':
            return lastMonth();
          default:
            return currentDate();
       }
    },[dateType]);

  return (
      <div className="row">
         <div className="mb-3 col-lg-4 mb-lg-0">
              <label className="form-label mb-1">Start date</label>
              <input type="date" className='form-control' onClick={() => dispatch(dateHandler({}))} value={startDate} onChange={e => setStartDate(e.target.value)}/>
         </div>
         <div className="mb-3 col-lg-4 mb-lg-0">
              <label className="form-label mb-1">End date</label>
              <input type="date" className='form-control' onClick={() => dispatch(dateHandler({}))} value={endDate} onChange={e => setEndDate(e.target.value)}/>
         </div>
         <div className="mb-3 col-lg-4 mb-lg-0">
            <label className="form-label mb-1">Find</label>
            <select value={dateType} className="form-select form-select-lg w-100" onClick={() => dispatch(dateHandler({}))} onChange={e => setDateType(e.target.value)}>
                  <option value="1">Today</option>
                  <option value="2">Yesterday</option>
                  <option value="3">This Week</option>
                  <option value="4">Last Week</option>
                  <option value="5">This Month</option>
                  <option value="6">Last Month</option>
              </select>
         </div>
      </div>
  )
}

export default DateRangeFormat;
