import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import 'react-quill/dist/quill.snow.css';
import { stateObjType } from "../../types";
import { errorToaster } from "../Helper";

const MMThreedResultModal = (props: stateObjType) => {

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (props.luckyNum.length === 3) {
      props.onSubmit();
    } else {
      errorToaster('Lucky Number must be 3 digits!')
    }
  }

  const closeHandler = () => {
    props.onClose()
    props.setLuckyNum('')
    props.setLotteryTime('')
  }

  const luckyNumHandler = (e:any) => {
    const value = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    if (positiveNumberPattern.test(value)) {
            value < 1000 && props.setLuckyNum(value.slice(0,3))
    }
  }

  return (
    <Modal isOpen={true} toggle={closeHandler}>
      <ModalHeader toggle={closeHandler}>{props.name}</ModalHeader>
      <ModalBody className="pb-3">
        <Form>
          <FormGroup>
            <label htmlFor="start">Lucky Number</label>
            <Input type="text" className="mb-3" id="start" value={props.luckyNum} onChange={luckyNumHandler} placeholder="Lucky Number" min={props.luckyNum} max={props.luckyNum}/>
            <label htmlFor="status">Lottery Time</label>
            <select id="status" className="form-select mb-3" value={props.lotteryTime} onChange={(e) => props.setLotteryTime(e.target.value)}>
                <option value="">Select Lottery Time</option>
                <option value="One">၁၆ ရက်နေ့</option>
                <option value="Two">၁ ရက်နေ့</option>
            </select>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className="py-3">
        <button className="btn py-2 btn-danger" onClick={closeHandler}>
          No
        </button>
        <button className="btn py-2 btn-primary" onClick={handleSubmit} disabled={props.isLoading}>
          {
            props.isLoading ? 'Uploading...' : 'Save'
          }
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default MMThreedResultModal
