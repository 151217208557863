import React, { useEffect, useState } from 'react'
import Template from '../../utils/Template'
import { ToastContainer } from 'react-toastify'
import DateRangeFormat from '../../utils/DateRangeFormat'
import moment from 'moment'
import TableScroll from '../../utils/TableScroll'
import { getMethodService, postMethodService } from '../../services'
import { BET_HISTORY_NODE_API, INVOICE_REJECT_API } from '../../services/constants'
import { useQuery } from '@tanstack/react-query'
import { HandleLogout, amountCommasSparated, decodeData, errorToaster } from '../../utils/Helper'
import { Link, useNavigate } from 'react-router-dom'
import Pagi from '../../utils/Pagi'
import TableLoading from '../../utils/TableLoading'
import { stateObjType } from '../../types'
import SweetAlert from "sweetalert2";
const Cookies = require('js-cookie');

const FootballBetListParlay = () => {
    const abortController = new AbortController();
    const [isLoading,setIsLoading] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);
    const footballDate: any = new Date();
    const actualDate = new Date();
    var checkTime = new Date();
    checkTime.setHours(10); checkTime.setMinutes(29);
    if(actualDate.getTime() < checkTime.getTime()){
        footballDate.setDate(footballDate.getDate() - 1);
    };
    const [startDate, setStartDate] = useState(formatDate(footballDate));
    const [endDate, setEndDate] = useState(formatDate(footballDate));
    const start_date = startDate && `${startDate} 10:29`;
    const end_date = endDate && `${moment(endDate).add(1,'days').format('YYYY-MM-DD')} 10:28`;
    const [data, setData] = useState([]);
    const [search,setSearch] = useState("");
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const navigate = useNavigate();

    function formatDate(date:string) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
    };

    const fetchBetLists = (search:string | number | undefined,sd: string | undefined, ed: string | undefined,page: number | undefined) => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            // if(search){
                return getMethodService(`${BET_HISTORY_NODE_API}?bet_type=perlay&search=${search}&start_date=${sd}&end_date=${ed}&sortDirection=desc&sortColumn=id&page=${page}&limit=10`,{},false,decode?.token,abortController);
            // };
        };
    }
    const betListQuery = useQuery({ queryKey: ['betlistParlay',page,refreshKey], queryFn: () => fetchBetLists(search,start_date,end_date,page), refetchOnWindowFocus: false});
    useEffect(()=>{
        setIsLoading(betListQuery?.isLoading);
        if(betListQuery?.data == 401){
            HandleLogout(navigate);
        };
        if(betListQuery?.data?.status === "success"){
            if(betListQuery?.data?.meta){
                if(betListQuery?.data?.meta.current_page > 0){
                   if(betListQuery?.data?.meta.per_page && betListQuery?.data?.meta.current_page){
                      setFrom((betListQuery?.data?.meta.per_page * (betListQuery?.data?.meta.current_page - 1)) + 1 );
                   };
                };
                setTotalPage(betListQuery?.data?.meta.last_page);
            };
            setData(betListQuery?.data?.data);
        };
    },[betListQuery]);

    const submitHandler = () => {
        // if(search){
            setRefreshKey(prevKey => prevKey + 1);
        // }else{
        //     return errorToaster("Search field is required!");
        // };
    };
    const rejectSlipHandler = (id:number) => {
        SweetAlert.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Reject",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                postMethodService(INVOICE_REJECT_API(id), {}, true)
                    .then((res) => {
                        if(res == 401){
                            HandleLogout(navigate);
                        };
                        if(res.status === "success"){
                            setRefreshKey(prevKey => prevKey + 1);
                        };
                    });
            };
        });
    };

    useEffect(()=>{
        return () => {
            abortController.abort();
        }
    },[]);
  return (
    <>
        <Template title='Football'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <h4 className="card-title mb-2">
                                Bet list - Parlay
                            </h4>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-3 mb-3 mb-lg-0">
                            <label className="form-label mb-1">Search</label>
                            <input type="text" className="p-2 form-control w-100" placeholder="Search..." onChange={
                                e => setSearch(e.target.value)
                            }/>
                        </div>
                        <div className="col-lg-7">
                            <DateRangeFormat
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                        </div>
                        <div className="col-lg-2">
                            <button
                                className="btn btn-primary w-100 mt-4"
                                onClick={submitHandler}
                                disabled={isLoading}
                            >
                                {isLoading ? "Loading ..." : "Submit"}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white border table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                <th className="p-2 text-center text-light">Betslip ID</th>
                                                <th className="p-2 text-center text-light">User</th>
                                                <th className="p-2 text-center text-light">Match</th>
                                                <th className="p-2 text-center text-light">Bet Result</th>
                                                <th className="p-2 text-center text-light">Status</th>
                                                <th className="p-2 text-center text-light">Amount</th>
                                                <th className="p-2 text-center text-light">Payout</th>
                                                <th className="p-2 text-center text-light">Profit</th>
                                                <th className="p-2 text-center text-light">Time</th>
                                                <th className="p-2 text-center text-light">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading &&
                                                <TableLoading col={15} />
                                            }
                                            {
                                                data?.length > 0 ?
                                                data?.map((list: stateObjType, i: number) => 
                                                   <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="text-center text-dark p-2">{i+from}</td>
                                                        <td className="text-center text-dark p-2">
                                                            <Link className="text-primary" to={`/football/bet-list/parlay/${list.id}`}>
                                                                <span className="badge bg-primary">
                                                                    {list.id}
                                                                </span>
                                                            </Link>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">{list?.user?.usercode}</td>
                                                        <td className="p-2 text-center text-dark">{list?.bet_fixtures?.length}</td>
                                                        <td className="p-2 text-center text-dark">
                                                            <span className={`badge bg${list?.total_bet_result?.bet_result === "win"? '-success': (list?.total_bet_result?.bet_result === "lose"?'-danger':'-warning')}`}>
                                                                {list?.total_bet_result?.bet_result}
                                                            </span>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <span className={`badge bg${list?.status === "completed"?'-success':'-warning'}`} >
                                                                {list?.status}
                                                            </span>
                                                        </td>
                                                        <td className="text-end text-dark">
                                                            <b>
                                                                {amountCommasSparated(list?.stake)}
                                                            </b>
                                                        </td>
                                                        <td className="text-end text-dark">
                                                            <b>
                                                                {
                                                                   amountCommasSparated(list?.total_bet_result?.payout_amount?? 0)
                                                                }
                                                            </b>
                                                        </td>
                                                        <td className="text-end text-dark">
                                                            <b>
                                                                {
                                                                    amountCommasSparated(list?.total_bet_result?.profit?? 0)
                                                                }
                                                            </b>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">{list.created_at}</td>
                                                        <td className="p-2 text-center text-dark">
                                                                {list?.total_bet_result?.bet_result !=
                                                                "rejected" ? (
                                                                    <button
                                                                        className="p-1 btn btn-danger"
                                                                        style={{
                                                                            fontSize:
                                                                                "10px",
                                                                        }}
                                                                        onClick={() =>
                                                                            rejectSlipHandler(
                                                                                list?.id
                                                                            )
                                                                        }
                                                                    >
                                                                        Reject
                                                                    </button>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                   </tr>
                                                )
                                                   :
                                                   <tr>
                                                       <td colSpan={15} className="text-center">
                                                           No Data Found
                                                       </td>
                                                   </tr>
                                            }
                                        </tbody>
                                        {
                                            data?.length > 0 &&
                                            <tfoot>
                                                <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                    <th className="p-2 text-center text-light" colSpan={6}></th>
                                                    <th className="p-2 text-center text-light">
                                                        {data?.reduce((a:number,b:any) => a + b?.stake,0)?.toLocaleString('en-US')}
                                                    </th>
                                                    <th className="p-2 text-center text-light" colSpan={4}></th>
                                                </tr>
                                            </tfoot>
                                        }
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                        {totalPage > 1 && (
                            <div className="card-footer">
                                <div className="row justify-content-end">
                                    <div>
                                        <Pagi
                                            setIsLoading={setIsLoading}
                                            setPage={setPage}
                                            totalPage={totalPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Template>
        <ToastContainer />
    </>
  )
}

export default FootballBetListParlay
