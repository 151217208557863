import { useEffect, useState } from 'react';
import Template from '../utils/Template'
import { HandleLogout, amountCommasSparated, decodeData } from '../utils/Helper';
import { getMethodService } from '../services';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { AUTH_USER_NODE_API, DASHBOARD_BALANCE_NODE_API } from '../services/constants';
import { useSelector } from 'react-redux';
import TextLoad from '../utils/TextLoad';
const Cookies = require('js-cookie');

const Dashboard = () => {
    const abortController = new AbortController();
    const [isLoading,setIsLoading] = useState(false);
    const [isBalanceLoading,setIsBalanceLoading] = useState(false);
    const [userInfo,setUserInfo] = useState<any>({});
    const [supersData,setSupersData] = useState<any>(null);
    const [seniorsData,setSeniorsData] = useState<any>(null);
    const [mastersData,setMastersData] = useState<any>(null);
    const [agentsData,setAgentsData] = useState<any>(null);
    const [usersData,setUsersData] = useState<any>(null);
    const [loadType,setLoadType] = useState<any>(null);
    const navigate = useNavigate();
    const [currentRole,setCurrentRole] = useState("");
    const { user_info } = useSelector((state:any) => state.userInfo);
    const [balanceStatus,setBalanceStatus] = useState(false);

    useEffect(()=>{
        localStorage.removeItem('r_type');
    },[]);

    const fetchAllBalance = () => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
           let decode = decodeData(getAuth);
           setCurrentRole(decode?.role_name)
           if(balanceStatus){
                setIsBalanceLoading(true);
                getMethodService(`${AUTH_USER_NODE_API}?is_show_wallet_detail=${balanceStatus}`,{},false,decode?.token,abortController)
                .then((response) => {
                    if(response){
                        setIsBalanceLoading(false);
                        if(response == 401){
                            HandleLogout(navigate);
                        };
                        if(response?.status || response?.status === 'success'){
                            setUserInfo(response?.data);
                        }
                    }
                });
           }
           
        };
    };

    useEffect(() => {
        fetchAllBalance();
    },[balanceStatus])

    const usersDetailHanlder = (type:string) => {
        setIsLoading(true);
        setLoadType(type);
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            getMethodService(`${DASHBOARD_BALANCE_NODE_API}?type=${type}`,{},false,decode?.token,abortController)
            .then((response) => {
                if(response){
                    setIsLoading(false);
                    if(response == 401){
                        HandleLogout(navigate);
                    };
                    if(response?.status || response?.status === "success"){
                        if(response.data?.supers){
                            setSupersData(response.data?.supers);
                        };
                        if(response.data?.seniors){
                            setSeniorsData(response.data?.seniors);
                        };
                        if(response.data?.masters){
                            setMastersData(response.data?.masters);
                        };
                        if(response.data?.agents){
                            setAgentsData(response.data?.agents);
                        };
                        if(response.data?.users){
                            setUsersData(response.data?.users);
                        };
                    };
                }
            });
        };
    };

    useEffect(()=>{
     localStorage.removeItem('r_type');
    return () => {
        abortController.abort();
    }
    },[]);
  return (
       <Template title="Dashboard">
            <div className="card card-body mb-lg-3">
                <div className="row">
                  <div className="col-md-5">
                        <div className="mb-3">
                            <table className="table text-white w-100 table-bordered table-striped" style={{fontSize:'11px'}}>
                                  <tbody>
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">User Name :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px',width: '100px'}}><b className="text-dark">{user_info?.usercode ?? <TextLoad />}</b></td>
                                      </tr>
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Type :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px',width: '100px'}}><b className="text-dark">{user_info?.type ?? <TextLoad />}</b></td>
                                      </tr>
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Cash Balance :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}>
                                            <b className="text-dark">
                                                {
                                                    user_info?.wallet?.amount == 0 ? 0 : (user_info?.wallet?.amount ? amountCommasSparated(user_info?.wallet?.amount) : <TextLoad />)
                                                }
                                            </b>
                                          </td>
                                      </tr>
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Account Balance :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}>
                                            <b className="text-dark">
                                                    {
                                                        isBalanceLoading ?
                                                        <TextLoad />: 
                                                        (userInfo?.wallet ?
                                                        <p className='d-flex justify-content-between mb-0'>
                                                            {
                                                                userInfo?.wallet?.account_balance == 0 ? 0 : amountCommasSparated(userInfo?.wallet?.account_balance)
                                                            }
                                                        </p>:
                                                        <p className='text-end mb-0'>
                                                            <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => setBalanceStatus(true)}></i>
                                                        </p>)
                                                    }
                                                {/* {
                                                    userInfo?.wallet?.account_balance == 0 ? 0 : (userInfo?.wallet?.account_balance ? amountCommasSparated(userInfo?.wallet?.account_balance) : <TextLoad />)
                                                } */}
                                            </b>
                                          </td>
                                      </tr>
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Member Balance :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}>
                                            <b className="text-dark">
                                                {
                                                    isBalanceLoading ?
                                                    <TextLoad />: 
                                                    userInfo?.wallet ?
                                                    <p className='d-flex justify-content-between mb-0'>
                                                        {
                                                            userInfo?.wallet?.member_balance == 0 ? 0 : amountCommasSparated(userInfo?.wallet?.member_balance)
                                                        }
                                                    </p>:
                                                    <p className='text-end mb-0'>
                                                        <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => setBalanceStatus(true)}></i>
                                                    </p>
                                                }
                                                {/* {
                                                    userInfo?.wallet?.member_balance == 0 ? 0 : (userInfo?.wallet?.member_balance ? amountCommasSparated(userInfo?.wallet?.member_balance) : <TextLoad />)
                                                } */}
                                            </b>
                                          </td>
                                      </tr>
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total Outstanding :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}>
                                            <b className="text-dark">
                                                {
                                                    isBalanceLoading ?
                                                    <TextLoad />: 
                                                    userInfo?.wallet ?
                                                    <p className='d-flex justify-content-between mb-0'>
                                                        {
                                                            userInfo?.wallet?.outstanding_balance == 0 ? 0 : amountCommasSparated(userInfo?.wallet?.outstanding_balance)
                                                        }
                                                    </p>:
                                                    <p className='text-end mb-0'>
                                                        <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => setBalanceStatus(true)}></i>
                                                    </p>
                                                }
                                                {/* {
                                                    userInfo?.wallet?.outstanding_balance == 0 ? 0 : (userInfo?.wallet?.outstanding_balance ? amountCommasSparated(userInfo?.wallet?.outstanding_balance) : <TextLoad />)
                                                } */}
                                            </b>
                                        </td>
                                      </tr>
                                      {/* <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Body Outstanding :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}>
                                            <b className="text-dark">
                                                {
                                                    outstandingData?.body_oustanding == 0 ? 0 : (outstandingData?.body_oustanding ? amountCommasSparated(outstandingData?.body_oustanding) : <TextLoad />)
                                                }
                                            </b>
                                        </td>
                                      </tr>
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Perlay Outstanding :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}>
                                            <b className="text-dark">
                                                {
                                                    outstandingData?.perlay_oustanding == 0 ? 0 : (outstandingData?.perlay_oustanding ? amountCommasSparated(outstandingData?.perlay_oustanding) : <TextLoad />)
                                                }
                                            </b>
                                        </td>
                                      </tr>
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">3D Outstanding :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}>
                                            <b className="text-dark">
                                                {
                                                    outstandingData?.threed_oustanding == 0 ? 0 : (outstandingData?.threed_oustanding ? amountCommasSparated(outstandingData?.threed_oustanding) : <TextLoad />)
                                                }
                                            </b>
                                        </td>
                                      </tr> */}
                                  </tbody>
                            </table>
                        </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                        <div className="mb-3">
                            <table className="table text-white w-100 table-bordered table-striped" style={{fontSize:'11px'}}>
                                  <tbody>
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Currency :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px',borderColor: 'red',width: '100px'}}>
                                            <b className="text-dark">MMK</b>
                                          </td>
                                      </tr>
                                        {
                                            (currentRole === 'admin') && 
                                            <tr style={{borderWidth: '3px'}}>
                                                <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total Super Balance :</b></td>
                                                <td className="text-start" style={{fontSize: '13px',padding: '2px 5px',borderColor: 'red',width: '100px'}}><b className="text-dark">
                                                    {
                                                        (loadType==="supers" && isLoading) ?
                                                        <TextLoad />: 
                                                        !supersData ?
                                                        <p className='text-end mb-0'>
                                                            <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("supers")}></i>
                                                        </p>
                                                        :
                                                        <p className='d-flex justify-content-between mb-0'>
                                                            {
                                                                supersData?.total_balance == 0 ? 0 : amountCommasSparated(supersData?.total_balance)
                                                            }
                                                            <i className="bi bi-arrow-clockwise" style={{fontSize: '13px', cursor: 'pointer', marginLeft: '5px'}} onClick={() => usersDetailHanlder("supers")}></i>
                                                        </p>
                                                    }
                                                    </b>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            (currentRole === 'admin' || currentRole === "super") && 
                                            <tr style={{borderWidth: '3px'}}>
                                                <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total Senior Balance :</b></td>
                                                <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">
                                                    {
                                                        (loadType==="seniors" &&isLoading) ?
                                                        <TextLoad />: 
                                                        !seniorsData ?
                                                        <p className='text-end mb-0'>
                                                            <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("seniors")}></i>
                                                        </p>
                                                        :
                                                        <p className='d-flex justify-content-between mb-0'>
                                                            {
                                                                seniorsData?.total_balance == 0 ? 0 : amountCommasSparated(seniorsData?.total_balance)
                                                            }
                                                            <i className="bi bi-arrow-clockwise" style={{fontSize: '13px', cursor: 'pointer', marginLeft: '5px'}} onClick={() => usersDetailHanlder("seniors")}></i>
                                                        </p>
                                                    }
                                                </b></td>
                                            </tr>
                                        }
                                        {
                                            (currentRole === 'admin' || currentRole === "super" || currentRole === "senior") && 
                                            <tr style={{borderWidth: '3px'}}>
                                                <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total Master Balance :</b></td>
                                                <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">
                                                        {
                                                            (loadType==="masters" &&isLoading) ?
                                                            <TextLoad />: 
                                                            !mastersData ?
                                                            <p className='text-end mb-0'>
                                                                <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("masters")}></i>
                                                            </p>
                                                            :
                                                            <p className='d-flex justify-content-between mb-0'>
                                                                {
                                                                    mastersData?.total_balance == 0 ? 0 : amountCommasSparated(mastersData?.total_balance)
                                                                }
                                                                <i className="bi bi-arrow-clockwise" style={{fontSize: '13px', cursor: 'pointer', marginLeft: '5px'}} onClick={() => usersDetailHanlder("masters")}></i>
                                                            </p>
                                                        }
                                                    </b></td>
                                            </tr>
                                        }
                                        {
                                            (currentRole === 'admin' || currentRole === "super" || currentRole === "senior" || currentRole === "master") && 
                                            <tr style={{borderWidth: '3px'}}>
                                                <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total Agent Balance :</b></td>
                                                <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">
                                                    {
                                                        (loadType==="agents" &&isLoading) ?
                                                        <TextLoad />: 
                                                        !agentsData ?
                                                        <p className='text-end mb-0'>
                                                            <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("agents")}></i>
                                                        </p>
                                                        :
                                                        <p className='d-flex justify-content-between mb-0'>
                                                            {
                                                                agentsData?.total_balance == 0 ? 0 : amountCommasSparated(agentsData?.total_balance)
                                                            }
                                                            <i className="bi bi-arrow-clockwise" style={{fontSize: '13px', cursor: 'pointer', marginLeft: '5px'}} onClick={() => usersDetailHanlder("agents")}></i>
                                                        </p>
                                                    }
                                                    </b></td>
                                            </tr>
                                        }
                                        <tr style={{borderWidth: '3px'}}>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total User Balance :</b></td>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">
                                                    {
                                                        (loadType==="users" &&isLoading) ?
                                                        <TextLoad />: 
                                                        !usersData ?
                                                        <p className='text-end mb-0'>
                                                            <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("users")}></i>
                                                        </p>
                                                        :
                                                        <p className='d-flex justify-content-between mb-0'>
                                                            {
                                                                usersData?.total_balance == 0 ? 0 : amountCommasSparated(usersData?.total_balance)
                                                            }
                                                            <i className="bi bi-arrow-clockwise" style={{fontSize: '13px', cursor: 'pointer', marginLeft: '5px'}} onClick={() => usersDetailHanlder("users")}></i>
                                                        </p>
                                                    }
                                                </b></td>
                                        </tr>
                                  </tbody>
                            </table>
                        </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                        <div className="mb-3">
                            <table className="table text-white w-100 table-bordered table-striped" style={{fontSize:'11px'}}>
                                  <tbody>
                                      {
                                        (currentRole === 'admin') && 
                                        <tr style={{borderWidth: '3px'}}>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total Super Count :</b></td>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px',borderColor: 'red',width: '100px'}}><b className="text-dark">
                                                {
                                                    (loadType==="supers" &&isLoading) ?
                                                    <TextLoad />: 
                                                    !supersData ?
                                                    <p className='text-end mb-0'>
                                                        <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("supers")}></i>
                                                    </p>
                                                    :
                                                    supersData?.count == 0 ? 0 : amountCommasSparated(supersData?.count)
                                                }
                                            </b></td>
                                        </tr>
                                      }
                                      {
                                        (currentRole === 'admin' || currentRole === "super") &&
                                        <tr style={{borderWidth: '3px'}}>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total Senior Count :</b></td>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">
                                                {
                                                    (loadType==="seniors" &&isLoading) ?
                                                    <TextLoad />: 
                                                    !seniorsData ?
                                                    <p className='text-end mb-0'>
                                                        <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("seniors")}></i>
                                                    </p>
                                                    :
                                                    seniorsData?.count == 0 ? 0 : amountCommasSparated(seniorsData?.count)
                                                }
                                            </b></td>
                                        </tr>
                                      }
                                      {
                                        (currentRole === 'admin' || currentRole === "super" || currentRole === "senior") &&
                                        <tr style={{borderWidth: '3px'}}>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total Master Count :</b></td>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">
                                                {
                                                    (loadType==="masters" &&isLoading) ?
                                                    <TextLoad />: 
                                                    !mastersData ?
                                                    <p className='text-end mb-0'>
                                                        <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("masters")}></i>
                                                    </p>
                                                    :
                                                    mastersData?.count == 0 ? 0 : amountCommasSparated(mastersData?.count)
                                                }
                                            </b></td>
                                        </tr>
                                      }
                                      
                                      {
                                        (currentRole === 'admin' || currentRole === "super" || currentRole === "senior" || currentRole === "master") && 
                                        <tr style={{borderWidth: '3px'}}>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total Agent Count :</b></td>
                                            <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">
                                                    {
                                                        (loadType==="agents" &&isLoading) ?
                                                        <TextLoad />: 
                                                        !agentsData ?
                                                        <p className='text-end mb-0'>
                                                            <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("agents")}></i>
                                                        </p>
                                                        :
                                                        agentsData?.count == 0 ? 0 : amountCommasSparated(agentsData?.count)
                                                    }
                                                </b></td>
                                        </tr>
                                      }
                                      
                                      <tr style={{borderWidth: '3px'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">Total User Count :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-dark">
                                                {
                                                    (loadType==="users" &&isLoading) ?
                                                    <TextLoad />: 
                                                    !usersData ?
                                                    <p className='text-end mb-0'>
                                                        <i className="bi bi-eye-slash" style={{fontSize: '13px', cursor: 'pointer'}} onClick={() => usersDetailHanlder("users")}></i>
                                                    </p>
                                                    :
                                                    usersData?.count == 0 ? 0 : amountCommasSparated(usersData?.count)
                                                }
                                            </b></td>
                                      </tr>
                                      {/* <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-white">Total member :</b></td>
                                          <td className="text-start" style={{fontSize: '13px',padding: '2px 5px'}}><b className="text-white">{
                                              (data?.supers?.count ?? 0) +
                                              (data?.seniors?.count ?? 0) +
                                              (data?.masters?.count ?? 0) +
                                              (data?.agents?.count ?? 0) +
                                              (data?.users?.count ?? 0)
                                          }</b></td>
                                      </tr> */}
                                  </tbody>
                            </table>
                        </div>
                  </div>
                </div>
            </div>
       </Template>
  )
}

export default Dashboard
