import { Navigate, useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import moment from "moment";
import html2canvas from 'html2canvas';
const downloadjs = require('downloadjs');
const SweetAlert = require("sweetalert2");
const CryptoJS = require("crypto-js");
const Cookies = require('js-cookie');

export async function copyToClipboard(copyMe) {
  await navigator.clipboard.writeText(copyMe);
  SweetAlert.fire({
    icon: "success",
    width: 300,
    title: "Success",
    text: "Copy to Clipboard",
  });
}

export const errorToaster = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Slide,
    hideProgressBar: true,
    theme: "colored",
  });
};

export const successToaster = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Slide,
    hideProgressBar: true,
    theme: "colored",
  });
};

export const HandleLogout = (navigate) => {
  const getToken = Cookies.get("a_u");
  const getNodeToken = Cookies.get("n_a_u");
  if (getToken && getNodeToken) {
    Cookies.remove("a_u");
    Cookies.remove("n_a_u");
    navigate("/login");
    window.location.reload();
  } else {
    navigate("/login");
    window.location.reload();
  };
};

export const amountCommasSparated = (num) => {
  if(num != "??" || num != "--"){
    if (num || num == 0) {
      let str = num.toString().split(".");
      if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, "$1 ");
      }
      return str.join(".");
    }
  }else{
    return "??"
  }
};

export const popupCenter = ({ url, title, w, h }) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
  );

  if (window.focus) newWindow?.focus();
};

export const timeFormatMM = (utcDateString) => {
  // UTC date string

  // create new Date object with UTC date string
  const utcDate = new Date(utcDateString);

  // add 6 hours and 30 minutes to UTC date
  const mmtDate = new Date(utcDate.getTime() + 6.5 * 60 * 60 * 1000);

  // format MMT date and time string
  const mmtDateTimeString = mmtDate.toLocaleString("en-US", {
    timeZone: "Asia/Yangon",
  });

  return mmtDateTimeString;
};

export const parlayDetailTime = utcDateString => {
    const utcDate = new Date(utcDateString);
    const mmtDate = new Date(utcDate.getTime());
    const mmtDateTimeString = mmtDate.toLocaleString("en-US", {
        timeZone: "Asia/Yangon",
    });

    return mmtDateTimeString;
}

export const utcToMMT = (dateTime) => {  //format date time
  const date = new Date(dateTime);
  const formatHour =
    date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours();
  const formatMinute =
    date.getUTCMinutes() < 10
      ? `0${date.getUTCMinutes()}`
      : date.getUTCMinutes();
  const formatSecond =
    date.getUTCSeconds() < 10
      ? `0${date.getUTCSeconds()}`
      : date.getUTCSeconds();

  const formatYear = date.getUTCFullYear();
  const formatMonth =
    date.getUTCMonth() + 1 < 10
      ? `0${date.getUTCMonth() + 1}`
      : date.getUTCMonth() + 1;
  const formatDay =
    date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();

  const timeFormat = `${formatHour}:${formatMinute}:${formatSecond}`;
  const formatDate = `${formatYear}-${formatMonth}-${formatDay}`;
  return `${formatDate} ${timeFormat}`;
};

export const encodeData = (data) => {
  const deText = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return deText;
};

export const decodeData = (data) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (err) {
    const getFromStore = Cookies.get("a_u");
    if (getFromStore) {
      Cookies.remove("a_u");
      return <Navigate to="/login" />;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

export const replaceZeotoQqual = (str) => {
  if (str.includes("+")) {
    let splitVal = str.split("+");
    if (splitVal[0] === "0") {
      splitVal[0] = "=";
    } else {
      splitVal[0] = `${splitVal[0]}`;
    }
    if (splitVal[1] === "0") {
      splitVal[1] = "=";
    } else {
      splitVal[1] = `+${splitVal[1]}`;
    }
    return splitVal;
  }
  if (str.includes("=")) {
    let splitVal = str.split("=");
    if (splitVal[0] === "0") {
      splitVal[0] = "";
    } else {
      splitVal[0] = `${splitVal[0]}=`;
    }
    if (splitVal[1] === "0") {
      splitVal[1] = "";
    } else {
      splitVal[1] = `${splitVal[1]}`;
    }
    return splitVal;
  }
  if (str.includes("-")) {
    let splitVal = str.split("-");
    if (splitVal[0] === "0") {
      splitVal[0] = "=";
    } else {
      splitVal[0] = `${splitVal[0]}`;
    }
    if (splitVal[1] === "0") {
      splitVal[1] = "=";
    } else {
      splitVal[1] = `-${splitVal[1]}`;
    }
    return splitVal;
  }

  switch (str) {
    case "0":
      return "D";
    case "1":
      return "1=";
    case "2":
      return "2=";
    case "3":
      return "3=";
    case "4":
      return "4=";
    case "5":
      return "5=";
    default:
      return str;
  }
};

// const bytes  = CryptoJS.AES.decrypt("Og8R+JlKD31utkk1+k0ToA==", "87654321");
// const originalText = bytes.toString(CryptoJS.enc.Utf8);
// console.log(originalText)

export const TimeConvert = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

export const dateFormatter = (date) => {
  // Input date string '2023-06-18T01:13'
  console.log("now date", date);
  const parsedDate = new Date(date);

  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const hours = String(parsedDate.getHours()).padStart(2, "0");
  const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}`;

  console.log(formattedDateString);

  return formattedDateString;
};

export const ISOdateFormatter = (date) => {
  // Input date string in the ISO 8601 format '2023-09-02T05:16:00.000Z'
  const inputDate = new Date(date);

  // Format the date in the desired format 'YYYY-MM-DD HH:MM:SS'
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getUTCDate()).padStart(2, "0");
  const hours = String(inputDate.getUTCHours()).padStart(2, "0");
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, "0");

  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  // Output: '2023-09-02 05:16:00'
  return formattedDateString;
};

export const numInputPreventWheel = (e) => {
  e.target.blur();
  e.stopPropagation();
  setTimeout(() => {
    e.target.focus();
  }, 0);
};
export const exportExcelFormat = (exalData, fileName) => {

   const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
   const fileExtension = ".xlsx"

   const ws = XLSX.utils.json_to_sheet(exalData);
   const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
   const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
   const data = new Blob([excelBuffer], {type: fileType});
   FileSaver.saveAs(data, fileName + fileExtension)
};

export const convertRole = id => {
  let roleName;
  switch (id) {
    case 1:
      roleName = 'admin'
      break;
    case 2:
      roleName = 'super'
      break;
    case 3:
      roleName = 'senior'
      break;
    case 4:
      roleName = 'master'
      break;
    case 5:
      roleName = 'agent'
      break;
    default:
      roleName = 'member'
      break;
  }
  return roleName;
}
export const capitalizedText = (text) => {
  let words = text.split(' ');
  for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  
  let capitalized = words.join(' ');
  return capitalized;
}

export const changeTimeAgo = dateTime => {
  const formattedTime = moment(dateTime);
  const currentTime = moment();
  
  const diffMinutes = currentTime.diff(formattedTime, 'minutes');
  const diffHours = currentTime.diff(formattedTime, 'hours');
  const diffDays = currentTime.diff(formattedTime, 'days');
  const diffWeeks = currentTime.diff(formattedTime, 'weeks');
  const diffMonths = currentTime.diff(formattedTime, 'months');
  const diffYears = currentTime.diff(formattedTime, 'years');

  if (diffYears > 0) {
      return diffYears + "y ago";
  } else if (diffMonths > 0) {
      return diffMonths + "M ago";
  } else if (diffWeeks > 0) {
      return diffWeeks + "w ago";
  } else if (diffDays > 0) {
      return diffDays + "d ago";
  } else if (diffHours > 0) {
      return diffHours + "h ago";
  } else {
      return diffMinutes + "m ago";
  }
};

export const htmlToPng = async (saveDom,id) => {
  if(saveDom){
    html2canvas(saveDom, {
      width: saveDom.offsetWidth,
      height: saveDom.offsetHeight,
      windowWidth: saveDom.offsetWidth,
      windowHeight: saveDom.offsetHeight,
      scrollX: 0,
      scrollY: 0,
    }).then(function(canvas) {
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, `${id}.png`, 'image/png');
    });
  };
};

export const generatePassword = () => {
  const minChars = 8;
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let generatedPassword = '';

  for (let i = 0; i < minChars; i++) {
    generatedPassword += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return generatedPassword;
};
