import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { putMethodService,getMethodService } from "../services";
import { COMMESON_SETTING_API, COMMESON_SETTING_EDIT_API ,COMMESON_SETTING_DETAIL_NODE_API} from "../services/constants";

import SweetAlert from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { HandleLogout, decodeData } from "../utils/Helper";
const Cookies = require('js-cookie');

function EditAgent(props) {
    const [isLoading, setIsLoading] = useState(false);

    const [roleId, setRoleId] = useState(props.roleId);
    const [contact, setContact] = useState("");
    const [usercode, setUserCode] = useState("");
    const [password, setPassword] = useState("");
    const [minBetLimit, setMinBetLimit] = useState("");
    const [maxSingleBetLimit, setMaxSingleBetLimit] = useState("");
    const [matchLimit, setMatchLimit] = useState("");
    const [minMyanmarPerlayLimitPerCombination, setMinMyanmarPerlayLimitPerCombination] = useState("");
    const [maxMyanmarPerlayLimitPerCombination, setMaxMyanmarPerlayLimitPerCombination] = useState("");
    const [commissionSM, setCommissionSM] = useState("");
    const [commissionLG, setCommissionLG] = useState("");
    const [perlay2, setPerlay2] = useState("");
    const [perlay38, setPerlay38] = useState("");
    const [perlay911, setPerlay911] = useState("");
    const [twoDCommission, setTwoCommission] = useState("");
    const [threeDCommission, setThreeDCommission] = useState("");

    const [twoDMinPerBet, setTwoDMinPerBet] = useState("");
    const [twoDMaxPerBet, setTwoDMaxPerBet] = useState("");
    const [twoDLimit, setTwoDLimit] = useState("");

    const [threeDMinPerBet, setThreeDMinPerBet] = useState("");
    const [threeDMaxPerBet, setThreeDMaxPerBet] = useState("");
    const [threeDLimit, setThreeDLimit] = useState("");
    const [auth, setAuth] = useState(null);
    const params = useParams();
    const navigate = useNavigate();

    console.log("Updated by hp")

    const fetchCommSetting = () => {
        getMethodService(COMMESON_SETTING_API)
        .then((response) => {
            if(response == 401){
                HandleLogout(navigate);
            };
            if(response?.status){
                setAuth(response.data);
            };
        });
    }

    const fetchDetail = () => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            getMethodService(`${COMMESON_SETTING_DETAIL_NODE_API(props.auth)}?enabled_settings=1`,{},false,decode?.token)
            .then((response) => {
                if(response == 401){
                    HandleLogout(navigate);
                };
                if(response?.status === 'success'){
                    setContact(response?.data.contact);
                    setUserCode(response?.data.usercode);
                    setMinBetLimit(response?.data.bet_setting.min_bet_limit)
                    setMaxSingleBetLimit(response?.data.bet_setting.max_single_bet_limit)
                    setMatchLimit(response?.data.bet_setting.match_limit)

                    setTwoDMinPerBet(response?.data.bet_setting.two_d_min_per_bet)
                    setTwoDMaxPerBet(response?.data.bet_setting.two_d_max_per_bet)
                    setTwoDLimit(response?.data.bet_setting.two_d_limit)

                    setThreeDMinPerBet(response?.data.bet_setting.three_d_min_per_bet)
                    setThreeDMaxPerBet(response?.data.bet_setting.three_d_max_per_bet)
                    setThreeDLimit(response?.data.bet_setting.three_d_limit)

                    setMaxMyanmarPerlayLimitPerCombination(response?.data.bet_setting.max_myanmar_perlay_limit_per_combination)
                    setMinMyanmarPerlayLimitPerCombination(response?.data.bet_setting.min_myanmar_perlay_limit_per_combination)
                    setCommissionSM(filterCommissions(response?.data,'commissionSM').percent)
                    setCommissionLG(filterCommissions(response?.data,'commissionLG').percent)
                    setPerlay2(filterCommissions(response?.data,'perlay2').percent)
                    setPerlay38(filterCommissions(response?.data,'perlay38').percent)
                    setPerlay911(filterCommissions(response?.data,'perlay911').percent)

                    setTwoCommission(filterCommissions(response?.data,'twoDCommission').percent)
                    setThreeDCommission(filterCommissions(response?.data,'threeDCommission').percent)
                };
            })
            .catch((err) => console.error(err));
        };
    };

      useEffect(()=>{
        fetchDetail();
        fetchCommSetting();
      },[props.auth]);

    const saveHandler = () => {
        // setIsLoading(true);
        const data = {
            role_id: params.id,
            usercode: usercode,
            contact: contact,
            min_bet_limit: minBetLimit,
            max_single_bet_limit: maxSingleBetLimit,
            match_limit: matchLimit,
            min_myanmar_perlay_limit_per_combination: minMyanmarPerlayLimitPerCombination,
            max_myanmar_perlay_limit_per_combination: maxMyanmarPerlayLimitPerCombination,

            two_d_min_per_bet : twoDMinPerBet,
            two_d_max_per_bet : twoDMaxPerBet,
            two_d_limit : twoDLimit,

            three_d_min_per_bet : threeDMinPerBet,
            three_d_max_per_bet : threeDMaxPerBet,
            three_d_limit : threeDLimit,

            commissions : {
                commissionSM: commissionSM,
                commissionLG: commissionLG,
                perlay2: perlay2,
                perlay38: perlay38,
                perlay911: perlay911,
                twoDCommission: twoDCommission,
                threeDCommission: threeDCommission,
            }
        };
        putMethodService(`${COMMESON_SETTING_EDIT_API(props.auth)}`, data,false)
        .then((response) => {
            if(response == 401){
                HandleLogout(navigate);
            }
            if (response?.status === "success") {
                props.setRefreshKey(prevKey => prevKey + 1);
                fetchDetail();
                props.onClose();
                SweetAlert.fire({
                    title: `<strong>Good job!</strong>`,
                    html: `<i>Update Successful!</i>`,
                    icon: 'success'
                })
                setIsLoading(false);
            }
        })
        .catch((err) => {
            setIsLoading(false);
            console.error(err);
        });
    };

    const getPercentageOptions = (key, limit = 20, increment = 0.5) => {
        const options = [];
        let defaultValue = null;
        switch (key) {
            case 'commissionSM':
                defaultValue = commissionSM;
                break;
            case 'commissionLG':
                defaultValue = commissionLG;
                break;
            case 'perlay2':
                defaultValue = perlay2;
                break;
            case 'perlay38':
                defaultValue = perlay38;
                break;
            case 'perlay911':
                defaultValue = perlay911;
                break;
            case 'twoDCommission':
                defaultValue = twoDCommission;
                break;
            case 'threeDCommission':
                defaultValue = threeDCommission;
                break;
        }
        for (let i = 0; i <= limit * (1/increment); i++) {
            let num = (i * increment).toFixed(2);
            options.push(<option key={i} value={num} selected={Number(defaultValue)?.toFixed(2) == num ? true : false}>{num}%</option>);
        }
        return options;
    }

    const filterCommissions = (current_user,key) =>{
        let category_id = null;
        let game_type_id = null;
        let min_count = null;
        let max_count = null;
        switch (key) {
            case 'commissionSM':
                category_id = 1;
                game_type_id = 1;
                min_count = 1;
                max_count = 1;
                break;
            case 'commissionLG':
                category_id = 2;
                game_type_id = 1;
                min_count = 1;
                max_count = 1;
                break;
            case 'perlay2':
                category_id = 3;
                game_type_id = 1;
                min_count = 2;
                max_count = 2;
                break;
            case 'perlay38':
                category_id = 3;
                game_type_id = 1;
                min_count = 3;
                max_count = 8;
                break;
            case 'perlay911':
                category_id = 3;
                game_type_id = 1;
                min_count = 9;
                max_count = 11;
                break;
            case 'twoDCommission':
                category_id = null;
                game_type_id = 2;
                min_count = 1;
                max_count = 1;
                break;
            case 'threeDCommission':
                category_id = null;
                game_type_id = 3;
                min_count = 1;
                max_count = 1;
                break;
        };
        return current_user ? current_user?.agent_commissions.find((element)=>{ return (element.game_type_id == game_type_id && element.mm_football_category_id == category_id && element.min_perlay_count == min_count && element.max_perlay_count == max_count)}) : null
    }

    const handleMinInput = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.min_bet_limit : null;
        if(limit){
            value <= limit ? setMinBetLimit(value) : setMinBetLimit(limit);
        }else{
            setMinBetLimit(value);
        }
    }

    const handleMaxInput = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.max_single_bet_limit : null;
        if(limit){
            value <= limit ? setMaxSingleBetLimit(value) : setMaxSingleBetLimit(limit);
        }else{
            setMaxSingleBetLimit(value);
        }
    }

    const handleMatchLimitInput = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.match_limit : null;
        if(limit){
            value <= limit ? setMatchLimit(value) : setMatchLimit(limit);
        }else{
            setMatchLimit(value);
        }
    }

    const handleMinMyanmarPerlayLimitInput = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.min_myanmar_perlay_limit_per_combination : null;
        if(limit){
            value <= limit ? setMinMyanmarPerlayLimitPerCombination(value) : setMinMyanmarPerlayLimitPerCombination(limit);
        }else{
            setMinMyanmarPerlayLimitPerCombination(value);
        }
    }

    const handleMaxMyanmarPerlayLimitInput = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.max_myanmar_perlay_limit_per_combination : null;
        if(limit){
            value <= limit ? setMaxMyanmarPerlayLimitPerCombination(value) : setMaxMyanmarPerlayLimitPerCombination(limit);
        }else{
            setMaxMyanmarPerlayLimitPerCombination(value);
        }
    }

    const handleTwoMinPerBet = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.two_d_min_per_bet : null;
        if(limit){
            value <= limit ? setTwoDMinPerBet(value) : setTwoDMinPerBet(limit);
        }else{
            setTwoDMinPerBet(value);
        }
    }

    const handleTwoMaxPerBet = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.two_d_max_per_bet : null;
        if(limit){
            value <= limit ? setTwoDMaxPerBet(value) : setTwoDMaxPerBet(limit);
        }else{
            setTwoDMaxPerBet(value);
        }
    }

    const handleTwoLimit = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.two_d_limit : null;
        if(limit){
            value <= limit ? setTwoDLimit(value) : setTwoDLimit(limit);
        }else{
            setTwoDLimit(value);
        }
    }

    const handleThreeMinPerBet = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.three_d_min_per_bet : null;
        if(limit){
            value <= limit ? setThreeDMinPerBet(value) : setThreeDMinPerBet(limit);
        }else{
            setThreeDMinPerBet(value);
        }
    }

    const handleThreeMaxPerBet = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.three_d_max_per_bet : null;
        if(limit){
            value <= limit ? setThreeDMaxPerBet(value) : setThreeDMaxPerBet(limit);
        }else{
            setThreeDMaxPerBet(value);
        }
    }

    const handleThreeLimit = (value) =>
    {
        const limit = auth ? auth?.bet_setting?.three_d_limit : null;
        if(limit){
            value <= limit ? setThreeDLimit(value) : setThreeDLimit(limit);
        }else{
            setThreeDLimit(value);
        }
    };

    return (
        <>
            <Modal isOpen={true} toggle={props.onClose} size={"xl"}>
                <ModalHeader toggle={props.onClose}>
                    <h3>Edit {`- ${props.role} (${props.auth})`}</h3>
                 </ModalHeader>
                <ModalBody>
                    <div id="addUserStepFormContent">
                        <div className="card-body">
                            <div className="row">
                                <div className={"col-sm-6"}>
                                    <h4 className="text-center mt-4">User Info</h4>

                                    <div className="mb-4 row">
                                        <label
                                            for="text"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Username *
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group mb-1">
                                                {(auth && auth.role_id != 1) && <span className="input-group-text text-dark" id="basic-addon1">{auth ? auth.usercode : null}</span>}
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    name="usercode"
                                                    id="usercode"
                                                    placeholder="username"
                                                    autocomplete="off"
                                                    value={usercode}
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mb-4 row">
                                        <label
                                            for="password"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Contact
                                        </label>
                                        <div className="col-sm-6">
                                            <textarea name="contact" id="contact" className="form-control form-control-sm" onChange={e => setContact(e.target.value)} value={contact}></textarea>
                                        </div>
                                    </div>

                                    <h4 className="text-center mt-4">2D Settings</h4>
                                    <div className="mb-4 row">
                                        <label
                                            for="twoDMinPerBet"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            2D Min Per Bet * <span className="text-danger">{auth ? `(${auth?.bet_setting?.two_d_min_per_bet ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="twoDMinPerBet"
                                                    value={twoDMinPerBet}
                                                    onChange={(e) =>
                                                        handleTwoMinPerBet(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 row">
                                        <label
                                            for="twoDMaxPerBet"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            2D Max Per Bet * <span className="text-danger">{auth ? `(${auth?.bet_setting?.two_d_max_per_bet ?? 0})`  : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="twoDMaxPerBet"
                                                    value={twoDMaxPerBet}
                                                    onChange={(e) =>
                                                        handleTwoMaxPerBet(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 row">
                                        <label
                                            for="twoDLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            2D Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.two_d_limit ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="twoDLimit"
                                                    value={twoDLimit}
                                                    onChange={(e) =>
                                                        handleTwoLimit(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        roleId != 6
                                        ?
                                    <div className="mb-4 row">
                                        <label
                                            for="twoDCommission"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Commission (2D) * <span className="text-danger">{filterCommissions(auth,'twoDCommission')? `(${filterCommissions(auth,'twoDCommission').percent}%)`: null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <select name="twoDCommission" id="" className="form-control form-control-sm" onChange={ e => setTwoCommission(e.target.value)}>
                                                    {getPercentageOptions('twoDCommission',filterCommissions(auth,'twoDCommission') ? filterCommissions(auth,'twoDCommission').percent : 18)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                    }

                                    <h4 className="text-center mt-4">3D Settings</h4>
                                    <div className="mb-4 row">
                                        <label
                                            for="threeDMinPerBet"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            3D Min Per Bet * <span className="text-danger">{auth ? `(${auth?.bet_setting?.three_d_min_per_bet ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="threeDMinPerBet"
                                                    value={threeDMinPerBet}
                                                    onChange={(e) =>
                                                        handleThreeMinPerBet(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 row">
                                        <label
                                            for="threeDMaxPerBet"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            3D Max Per Bet * <span className="text-danger">{auth ? `(${auth?.bet_setting?.three_d_max_per_bet ?? 0})`  : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="threeDMaxPerBet"
                                                    value={threeDMaxPerBet}
                                                    onChange={(e) =>
                                                        handleThreeMaxPerBet(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 row">
                                        <label
                                            for="threeDLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            3D Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.three_d_limit ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="threeDLimit"
                                                    value={threeDLimit}
                                                    onChange={(e) =>
                                                        handleThreeLimit(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        roleId != 6
                                        ?
                                    <div className="mb-4 row">
                                        <label
                                            for="threeDCommission"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Commission (3D) * <span className="text-danger">{filterCommissions(auth,'threeDCommission')? `(${filterCommissions(auth,'threeDCommission').percent}%)`: null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <select name="threeDCommission" id="" className="form-control form-control-sm" onChange={ e => setThreeDCommission(e.target.value)}>
                                                    {getPercentageOptions('threeDCommission',filterCommissions(auth,'threeDCommission') ? filterCommissions(auth,'threeDCommission').percent : 30)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                    }

                                </div>
                                <div className="col-sm-6">
                                    <h4 className="text-center mt-4">Football Settings</h4>
                                    <div className="mb-4 row">
                                        <label
                                            for="minBetLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Min Bet Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.min_bet_limit ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="minBetLimit"
                                                    value={minBetLimit}
                                                    onChange={(e) =>
                                                        handleMinInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 row">
                                        <label
                                            for="maxSingleBetLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Max Single Bet Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.max_single_bet_limit ?? 0})`  : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="maxSingleBetLimit"
                                                    value={maxSingleBetLimit}
                                                    onChange={(e) =>
                                                        handleMaxInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 row">
                                        <label
                                            for="matchLimit"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Match Limit * <span className="text-danger">{auth ? `(${auth?.bet_setting?.match_limit ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="matchLimit"
                                                    value={matchLimit}
                                                    onChange={(e) =>
                                                        handleMatchLimitInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 row">
                                        <label
                                            for="minMyanmarPerlayLimitPerCombination"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Min Myanmar Perlay Limit Per Combination * <span className="text-danger">{auth ? `(${auth?.bet_setting?.min_myanmar_perlay_limit_per_combination ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="minMyanmarPerlayLimitPerCombination"
                                                    value={minMyanmarPerlayLimitPerCombination}
                                                    id="minMyanmarPerlayLimitPerCombination"
                                                    onChange={(e) =>
                                                        handleMinMyanmarPerlayLimitInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4 row">
                                        <label
                                            for="maxMyanmarPerlayLimitPerCombination"
                                            className="col-sm-6 col-form-label form-label"
                                        >
                                            Max Myanmar Perlay Limit Per Combination * <span className="text-danger">{auth ? `(${auth?.bet_setting?.max_myanmar_perlay_limit_per_combination ?? 0})` : null}</span>
                                        </label>
                                        <div className="col-sm-6">
                                            <div className="input-group input-group-sm-vertical">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="name"
                                                    id="maxMyanmarPerlayLimitPerCombination"
                                                    value={maxMyanmarPerlayLimitPerCombination}
                                                    onChange={(e) =>
                                                        handleMaxMyanmarPerlayLimitInput(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        roleId != 6
                                        ?
                                        <Fragment>
                                            <div className="mb-4 row">
                                                <label
                                                    for="commissionSM"
                                                    className="col-sm-6 col-form-label form-label"
                                                >
                                                    Commission (ပွဲသေး) * <span className="text-danger">{filterCommissions(auth,'commissionSM') ? `(${filterCommissions(auth,'commissionSM').percent} %)` : null}</span>
                                                </label>
                                                <div className="col-sm-6">
                                                    <div className="input-group input-group-sm-vertical">
                                                        <select  name="commissionSM" id="" className="form-control form-control-sm" onChange={ e => setCommissionSM(e.target.value)}>
                                                            {getPercentageOptions('commissionSM',filterCommissions(auth,'commissionSM') ? filterCommissions(auth,'commissionSM').percent : 2, 0.1)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-4 row">
                                                <label
                                                    for="commissionLG"
                                                    className="col-sm-6 col-form-label form-label"
                                                >
                                                    Commission (ပွဲကြီး) * <span className="text-danger">{filterCommissions(auth,'commissionLG') ? `(${filterCommissions(auth,'commissionLG').percent} %)` : null}</span>
                                                </label>
                                                <div className="col-sm-6">
                                                    <div className="input-group input-group-sm-vertical">
                                                        <select name="commission" id="" className="form-control form-control-sm" onChange={ e => setCommissionLG(e.target.value)}>
                                                            {getPercentageOptions('commissionLG',filterCommissions(auth,'commissionLG') ? filterCommissions(auth,'commissionLG').percent : 2, 0.1)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-4 row">
                                                <label
                                                    for="perlay2"
                                                    className="col-sm-6 col-form-label form-label"
                                                >
                                                    Commission (Perlay (2)) * <span className="text-danger">{filterCommissions(auth,'perlay2') ? `(${filterCommissions(auth,'perlay2').percent} %)` : null}</span>
                                                </label>
                                                <div className="col-sm-6">
                                                    <div className="input-group input-group-sm-vertical">
                                                        <select name="perlay2" id="" className="form-control form-control-sm" onChange={ e => setPerlay2(e.target.value)}>
                                                            {getPercentageOptions('perlay2',filterCommissions(auth,'perlay2') ? filterCommissions(auth,'perlay2').percent : 20)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-4 row">
                                                <label
                                                    for="perlay38"
                                                    className="col-sm-6 col-form-label form-label"
                                                >
                                                    Commission (Perlay (3-8)) * <span className="text-danger">{filterCommissions(auth,'perlay38')? `(${filterCommissions(auth,'perlay38').percent} %)` :null}</span>
                                                </label>
                                                <div className="col-sm-6">
                                                    <div className="input-group input-group-sm-vertical">
                                                        <select name="perlay38" id="" className="form-control form-control-sm" onChange={ e => setPerlay38(e.target.value)}>
                                                            {getPercentageOptions('perlay38',filterCommissions(auth,'perlay38') ? filterCommissions(auth,'perlay38').percent : 20)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-4 row">
                                                <label
                                                    for="perlay911"
                                                    className="col-sm-6 col-form-label form-label"
                                                >
                                                    Commission (Perlay (9-11)) * <span className="text-danger">{filterCommissions(auth,'perlay911')? `(${filterCommissions(auth,'perlay911').percent}%)`: null}</span>
                                                </label>
                                                <div className="col-sm-6">
                                                    <div className="input-group input-group-sm-vertical">
                                                        <select name="perlay911" id="" className="form-control form-control-sm" onChange={ e => setPerlay911(e.target.value)}>
                                                            {getPercentageOptions('perlay911',filterCommissions(auth,'perlay911') ? filterCommissions(auth,'perlay911').percent : 20)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                     : (<></>)
                                    }   
                                        
                                </div>
                                        
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {!isLoading ? (
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-hs-step-form-next-options='{
                                "targetSelector": "#addUserStepBillingAddress"
                              }'
                            onClick={saveHandler}
                        >
                            Save <i className="bi-chevron-right"></i>
                        </button>
                    ) : (
                        <button type="button" className="btn btn-primary">
                            <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </button>
                    )}
                </ModalFooter>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default EditAgent;
