import React, { useEffect, useState } from 'react'
import { getMethodService } from '../services';
import { HandleLogout, decodeData, htmlToPng, parlayDetailTime } from '../utils/Helper';
import { useNavigate, useParams } from 'react-router-dom';
import { BET_HISTORY_DETAILS_NODE_API, TEX_SETTING_API } from '../services/constants';
import { stateObjType } from '../types';
import { useQuery } from '@tanstack/react-query';
const Cookies = require('js-cookie');

const StockParlayDetail = () => {
  const [isLoading,setIsLoading] = useState(true);
  const [data,setData] = useState<stateObjType>({});
  const [sortedData,setSortedData] = useState([]);
  const [taxData,setTaxData] = useState([]);
  const navigate = useNavigate();
  const [percentage,setPercentage] = useState(0);
  const params = useParams();
  
  const fetchStockParlayDetail = (id:string | number | undefined) => {
    const getAuth = Cookies.get('n_a_u');
    if(getAuth){
       let decode = decodeData(getAuth);
       return getMethodService(`${BET_HISTORY_DETAILS_NODE_API(id)}`,{},false,decode?.token);
    };
  }
  const stockParlayDetailQuery = useQuery({ queryKey: ['parlayDetail', params.id], queryFn: () => fetchStockParlayDetail(params.id), refetchOnWindowFocus: false});

  useEffect(()=>{
     if(stockParlayDetailQuery){
        setIsLoading(stockParlayDetailQuery?.isLoading);
        if(stockParlayDetailQuery?.data == 401){
            HandleLogout(navigate);
        };
        if(stockParlayDetailQuery?.data?.status === "success"){
            setData(stockParlayDetailQuery?.data?.data);
        }
     }
  },[stockParlayDetailQuery]);

  const fetchTax = () => {
    getMethodService(TEX_SETTING_API)
    .then((response) => {
        if(response == 401){
            HandleLogout(navigate);
        };
        if(response.status === 'success'){
            setTaxData(response?.data)
        };
    })
    .catch((err) => console.error(err));
  };

  useEffect(()=>{
    fetchTax();
  },[]);

  useEffect(()=>{
    if(taxData?.length > 0){
        let filterTax:any = taxData?.filter((tax:any) => tax?.mm_football_category_id === data?.bet_fixtures[0].football_fixture?.mm_football_category?.id);
        let findParlayTex = filterTax?.find((tax:any) => tax?.min_perlay_count <= data?.bet_fixtures?.length && tax?.max_perlay_count >= data?.bet_fixtures?.length);
        if(findParlayTex){
            setPercentage(findParlayTex?.tax_percent);
        };
    };
  },[taxData])

  const saveImageHandler = (id:any) => {
    let saveDom = document.getElementById("saveToImg");
    return htmlToPng(saveDom,id);
  };

  useEffect(()=>{
    if(data?.bet_fixtures?.length > 0){
        let sorted = data?.bet_fixtures?.sort((a:any, b:any) => Number(a?.football_fixture?.fixture_timestamp) - Number(b?.football_fixture?.fixture_timestamp));
        setSortedData(sorted);
    };
  },[data]);

  return (
    <>
        <div className=''>
            <div className="page-header p-4 mb-3">
                <div className="row align-items-center">
                    <div className="col">
                        <div className='d-flex align-items-center'>
                            <h1 className="page-header-title mb-0">Parlay Detail</h1>
                            <button title='Download Slip' className='btn btn-success py-1 px-2 ms-2' onClick={() => saveImageHandler(data?.id)}><i className="bi bi-download" style={{fontSize: '16px'}}></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div id='saveToImg' className='p-4' style={{display: 'inline-block'}}>
                <div className="mb-1 text-dark" style={{fontSize: '12px'}}><b><div className="d-inline-block" style={{width: '100px'}}>Slip ID</div>: {data?.id}</b></div>
                <div className="mb-1 text-dark" style={{fontSize: '12px'}}><b><div className="d-inline-block" style={{width: '100px'}}>Date / Time</div>: {data?.created_at}</b></div>
                {/* <div className="mb-1 text-dark" style={{fontSize: '12px'}}><b><div className="d-inline-block" style={{width: '100px'}}>Usercode</div>: <span className='text-primary'>{data?.user?.usercode}</span></b></div> */}
                <div className="my-3">
                <ul className='p-0 m-0 parlay-betSlip-border' style={{listStyleType: 'none',fontSize: '12px'}}>
                    {
                        sortedData?.map((bet:stateObjType,index:number) => 
                            <li key={index} className="p-2 position-relative zi-3 d-flex" style={{border: '1px solid rgb(87 87 87)'}}>
                                <div style={{
                                    width: '22px',
                                    height: '22px',
                                    // border: '1px solid #575757',
                                    backgroundColor: '#575757',
                                    color: '#FFF',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {index + 1}
                                </div>
                                <div className='ms-2'>
                                    <p className={"mb-0 py-0 text-dark"}>
                                        <div className="d-inline-block" style={{width: '70px'}}>League</div>: <b>{
                                            bet?.football_fixture?.league_data.name
                                        }</b>
                                    </p>
                                    <p className="mb-0 py-0 text-dark"> <div className="d-inline-block" style={{width: '70px'}}>Teams</div>: <span className={bet?.football_fixture?.odds_team == "home" ? "text-danger" : "text-primary"}>{
                                            bet?.football_fixture?.home_team_data.name
                                        }</span> Vs <span className={bet?.football_fixture?.odds_team == "away" ? "text-danger" : "text-primary"}>
                                            {
                                                bet?.football_fixture?.away_team_data.name
                                            }
                                        </span>
                                    </p>
                                    <p className="mb-0 py-0 text-dark"><div className="d-inline-block" style={{width: '70px'}}>Start Time </div>: {parlayDetailTime(bet?.football_fixture?.fixture_start_time)}</p>
                                    <p className="mb-0 py-0 text-dark"><div className="d-inline-block" style={{width: '70px'}}>Bet Odds</div>: <span style={{fontSize: '11px'}} className='badge bg-dark'>{`${bet?.bet_odd}`}</span> , <span>{bet.bet_team }</span></p>
                                    <p className="mb-0 py-0 text-dark"><div className="d-inline-block" style={{width: '70px'}}>Goal Result</div>: ({bet?.football_fixture?.goals?.home} - {bet?.football_fixture?.goals?.away})</p>
                                    <p className="mb-0 py-0 text-dark"><div className="d-inline-block" style={{width: '70px'}}>Bet Result</div>: <b className={bet?.bet_result?.status == 'win' ? 'text-success' : 'text-danger'} style={{fontWeight: 'bolder',fontFamily: 'Inter,sans-serif', fontStyle:'italic'}}>{bet?.bet_result?.status}</b></p>
                                </div>
                            </li>
                        )
                    }
                    <div className='parlay-betSlip-watermark'></div>
                </ul>

                    {/* <table className="table table-bordered mb-0">
                        <tbody>
                            {
                                sortedData?.map((bet:stateObjType,index:number) =>(
                                    <tr key={index}>
                                        <td className="p-2" style={{border: '1px solid rgb(87 87 87)'}}>{index + 1}</td>
                                        <td className="p-2" style={{border: '1px solid rgb(87 87 87)'}}>
                                            <p className={"mb-0 py-0 text-dark"}>
                                                League: <b>{
                                                    bet?.football_fixture?.league_data.name
                                                }</b>
                                            </p>
                                            <p className="mb-0 py-0 text-dark"> Teams: <span className={bet?.football_fixture?.odds_team == "home" ? "text-danger" : "text-primary"}>{
                                                    bet?.football_fixture?.home_team_data.name
                                            }</span> vs <span className={bet?.football_fixture?.odds_team == "away" ? "text-danger" : "text-primary"}>
                                                {
                                                    bet?.football_fixture?.away_team_data.name
                                                }
                                            </span></p>
                                            <p className="mb-0 py-0 text-dark">{parlayDetailTime(bet?.football_fixture?.fixture_start_time)}</p>
                                            <p className="mb-0 py-0 text-dark">Bet Odds: <span style={{fontSize: '11px'}} className='badge bg-dark'>{`${bet?.bet_symbol1}${bet?.bet_symbol2}${bet?.bet_symbol3}`}</span></p>
                                            <p className="mb-0 py-0 text-dark">Bet Odds / Type: <span style={{fontSize: '11px'}} className='badge bg-dark'>{`${bet?.bet_odd}`}</span> , <span>{bet.bet_team }</span></p>
                                            <p className="mb-0 py-0 text-dark">Goal Result: ({bet?.football_fixture?.goals?.home} - {bet?.football_fixture?.goals?.away})</p>
                                            <p className="mb-0 py-0 text-dark">Bet Result: <span className={bet?.bet_result?.status == 'win' ? 'text-success' : 'text-danger'}>{bet?.bet_result?.status}</span></p>
                                            <p className="mb-0 py-0 text-dark">Bet Type: {bet.bet_type} | Odds : 2</p>
                                            {
                                                bet.bet_team == "home" &&
                                                <p className="mb-0 py-0 text-dark">Bet Team: <b><span className={bet?.football_fixture?.odds_team == "home" ? "text-danger" : "text-primary"}>{
                                                    bet?.football_fixture?.home_team_data.name
                                                }</span> &nbsp;&nbsp;</b></p>
                                            }
                                            {
                                                bet.bet_team == "away" &&
                                                <p className="mb-0 py-0 text-dark">Bet Team: <b><span className={bet?.football_fixture?.odds_team == "away" ? "text-danger" : "text-primary"}>{
                                                    bet?.football_fixture?.away_team_data.name
                                                }</span> &nbsp;&nbsp;</b></p>
                                            }
                                            {
                                                (bet.bet_team != "home" && bet.bet_team != "away") &&
                                                <p className="mb-0 py-0 text-dark">Bet Team: <b>{bet.bet_team} &nbsp;&nbsp;</b></p>
                                            }
                                            <p className="mb-0 py-0 text-dark"><b className="text-success">(ACCEPTED)</b></p>
                                            <p className="mb-0 py-0 text-dark">{bet.bet_odd} @ 2</p>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table> */}
                </div>
                <div className="mb-1 text-dark" style={{fontSize: '12px'}}><b><div className="d-inline-block" style={{width: '100px'}}>Bet Amount</div>: {(data?.stake)?.toLocaleString('en-US')}</b></div>
                {/* <div className="mb-1 text-dark" style={{fontSize: '12px'}}><b><div className="d-inline-block" style={{width: '100px'}}>Odds</div>: {sortedData? (Math.pow(2,sortedData?.length))?.toLocaleString('en-US'): ''}</b></div> */}
                <div className="mb-1 text-dark" style={{fontSize: '12px'}}><b><div className="d-inline-block" style={{width: '100px'}}>Est./Payout</div>: {sortedData? (Math.pow(2,sortedData?.length) * data?.stake - ( (Math.pow(2,sortedData?.length) * data?.stake * percentage)/ 100 ))?.toLocaleString('en-US'): ''}</b></div>
                <div className="mb-1 text-dark" style={{fontSize: '12px'}}><b><div className="d-inline-block" style={{width: '100px'}}>Payout Amount</div>: {data?.total_bet_result?.payout_amount?.toLocaleString('en-US')}</b></div>
                <div className="mb-0 py-0 text-dark"><div className="d-inline-block"  style={{width: '100px'}}>W / L Result</div>: <b className={data?.total_bet_result?.bet_result == 'win' ? 'text-success' : 'text-danger'} style={{fontWeight: 'bolder',fontFamily: 'Inter,sans-serif', fontStyle:'italic'}}>{data?.total_bet_result?.bet_result}</b></div>
                {/* <div className="mb-1 text-dark" style={{fontSize: '12px'}}><b><div className="d-inline-block" style={{width: '100px'}}>Result</div>: {data?.total_bet_result?.bet_result}</b></div> */}
            </div>
        </div>
    </>
  )
}

export default StockParlayDetail
