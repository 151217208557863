import { SYSTEM_ERROR } from "../config";
import axios from "axios";
import { decodeData, errorToaster, successToaster } from "../utils/Helper";
const Cookies = require('js-cookie');

export const getMethodService = (api, data,isShow_toast = false, digit_token = '',abortController) => {
    const getAuth = Cookies.get('a_u');
    let decode = getAuth && decodeData(getAuth);
    return new Promise((resolve, reject) => {
        try {
            axios({
                method: "get",
                url: api,
                headers: {
                    // Authorization: decode?.token,
                    Authorization: digit_token ? `Bearer ${digit_token}` : decode?.token,
                },
                params: data ?? null,
                timeout: 1000 * 60 * 3,
                signal: abortController && abortController.signal,
            })
                .then((res) => {
                    if(res){
                        if(isShow_toast){
                            successToaster(res?.data?.message);
                        };
                        resolve(res.data);
                    }
                    
                })
                .catch((error) => {
                    if(error?.response?.data?.message === 'disable'){
                        errorToaster("ယာယီပိတ်ထားပါသည်။");
                        resolve(error?.response?.status);
                        return reject(error);
                    }
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.status);
                    reject(error);
                });
        } catch (error) {
            reject("Something wring!");
        };
    });
};

export const postMethodService =  (api,data,isShow_toast = false, digit_token = '') => {
    const getAuth = Cookies.get('a_u');
    let decode = getAuth && decodeData(getAuth);
    return new Promise(async (resolve, reject) => {
        try {
            axios
                .post(api, data, {
                    headers: {
                        // Authorization: decode?.token,
                        Authorization: digit_token ? `Bearer ${digit_token}` : decode?.token,
                        Accept: "application/json",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    if(isShow_toast){
                        successToaster(res?.data?.message);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.status);
                    reject(error);
                });

        }
        catch(err){
            reject("Something wring!")
        };
    });
};

export const deleteMethodService = (api,isShow_toast = false, digit_token = '') => {
    const getAuth = Cookies.get('a_u');
    let decode = getAuth && decodeData(getAuth);
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(api, {
                    headers: {
                        Authorization: digit_token ? `Bearer ${digit_token}` : decode?.token,
                        Accept: "application/json",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    if(isShow_toast){
                        successToaster(res?.data?.message);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.status);
                    reject(error);
                });
        } catch (error) {
            reject("Something wring!")
        }
    });
};

//for image upload
export const fileUploadService = (api,data,isShow_toast = false, digit_token = '') => {
    const getAuth = Cookies.get('a_u');
    let decode = getAuth && decodeData(getAuth);
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(api, data, {
                    headers: {
                        Authorization: digit_token ? `Bearer ${digit_token}` : decode?.token,
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    if(isShow_toast){
                        successToaster(res?.data?.message);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.status);
                    reject(error);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const fileUpdateService = (api,data,isShow_toast = false, digit_token = '') => {
    const getAuth = Cookies.get('a_u');
    let decode = getAuth && decodeData(getAuth);
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(api, data, {
                    headers: {
                        Authorization: digit_token ? `Bearer ${digit_token}` : decode?.token,
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    if(isShow_toast){
                        successToaster(res?.data?.message);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.status);
                    reject(error);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};



export const putMethodService = (api, data, isShow_toast = false, digit_token = '') => {
    const getAuth = Cookies.get('a_u');
    let decode = getAuth && decodeData(getAuth);
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(api, data, {
                    headers: {
                        // Authorization: decode?.token,
                        Authorization: digit_token ? `Bearer ${digit_token}` : decode?.token,
                        Accept: "application/json",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    if(isShow_toast){
                        successToaster(res?.data?.message);
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    resolve(error?.response?.status);
                    reject(error);
                });
        } catch (error) {
            reject("Something wring!")
        }
    });
};















export const putApiDataService = (api, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(api, data, {
                    headers: {
                        Authorization: `${localStorage.getItem("token")}`,
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    successToaster(res.data.message);
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    if (
                        error.response.status === 401 ||
                        error.response.data.message === "Unauthenticated."
                    ) {
                        // HandleLogout();
                        return;
                    }
                    reject(error);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const patchApiDataService = (api, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .patch(api, data, {
                    headers: {
                        Authorization: `${localStorage.getItem("token")}`,
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 1000 * 60 * 3,
                })
                .then((res) => {
                    successToaster(res.data.message);
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    if (
                        error.response.status === 401 ||
                        error.response.data.message === "Unauthenticated."
                    ) {
                        // HandleLogout();
                        return;
                    }
                    reject(error);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const deleteApiDataService = (api, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(api, {
                    headers: {
                        Authorization: `${localStorage.getItem("token")}`,
                        Accept: "application/json",
                    },
                    timeout: 1000 * 60 * 3,
                    data: data,
                })
                .then((res) => {
                    successToaster(res.data.message);
                    resolve(res.data);
                })
                .catch((error) => {
                    errorToaster(error?.response?.data?.message);
                    if (
                        error.response.status === 401 ||
                        error.response.data.message === "Unauthenticated."
                    ) {
                        // HandleLogout();
                        return;
                    }
                    reject(error);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getMethodCheckAppStatusService = async () => {

    try{
        let fetcher = await fetch("https://sports899.s3.ap-southeast-1.amazonaws.com/sports899status.json" ,{
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Cache-Control": "no-cache" 
            },
        });
        let res = await fetcher.json();
        return res;
    }
    catch(err){
        console.log("check app status",err.message);
    }
    
};