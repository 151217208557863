import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { BET_HISTORY_NODE_API } from '../services/constants';
import { getMethodService } from '../services';
import { HandleLogout, amountCommasSparated, decodeData, errorToaster, popupCenter, successToaster } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import TableScroll from '../utils/TableScroll';
import TableLoading from '../utils/TableLoading';
import { stateObjType } from '../types';
import Pagi from '../utils/Pagi';
import { useQuery } from '@tanstack/react-query';
import useDebounce from '../hooks/useDebounce';
const Cookies = require('js-cookie');

const StockParlay = () => {
    const abortController = new AbortController();
    const [isLoading,setIsLoading] = useState<boolean>(false);
    const [data,setData] = useState([]);
    const [page, setPage] = useState<number>(1);
    const [totalPage,setTotalPage] = useState<number>(1);
    const [from,setFrom] = useState(1);
    const [search,setSearch] = useState("");
    const navigate = useNavigate();
    const actualDate = new Date();
    const footballDate = new Date();

    useEffect(()=>{
        localStorage.removeItem('r_type');
    },[]);

    if(actualDate.getHours() < 10){
        if(actualDate.getMinutes() < 29)
        {
            footballDate.setDate(footballDate.getDate() - 1);
        }
    };

    const year = footballDate.getFullYear();
    const month = String(footballDate.getMonth() + 1).padStart(2, "0");
    const day = footballDate.getDate();
    // const tomorrowDate = new Date(footballDate);
    // tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    // const tomorrowYear = tomorrowDate.getFullYear();
    // const tomorrowMonth = String(tomorrowDate.getMonth() + 1).padStart(2, "0");
    // const tomorrowDay = String(tomorrowDate.getDate()).padStart(2, "0");

    // const tomorrow = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`;
    const today = `${year}-${month}-${day.toString().padStart(2, "0")}`;
    const [startDate, setStartDate] = useState<any>(today);
    const [refreshKey,setRefreshKey] = useState(0);
    function formatDate(date:any,endStatus:any) {
        let d = new Date(date);
        endStatus && d.setDate(d.getDate() + 1);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
    };

    const fetchStockParlay = (search:string | undefined,page: number | undefined) => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            // if(search){
                return getMethodService(`${BET_HISTORY_NODE_API}?bet_type=perlay&sortDirection=desc&sortColumn=id&page=${page}&bet_result=unsettled&search=${search}&start_date=${formatDate(startDate, false)} 10:29&end_date=${formatDate(startDate, true)} 10:28&limit=10`,{},false,decode?.token,abortController);
            // }
        };
    };

    const stockParlayQuery = useQuery({ queryKey: ['stockParlayData', refreshKey,page], queryFn: () => fetchStockParlay(search,page), refetchOnWindowFocus: false});
    useEffect(()=>{
        if(stockParlayQuery){
            setIsLoading(stockParlayQuery?.isLoading);
            if(stockParlayQuery?.data == 401){
                HandleLogout(navigate);
            };
            
            if(stockParlayQuery?.data?.status === "success"){
                if(stockParlayQuery?.data?.meta){
                    if(stockParlayQuery?.data?.meta.current_page > 0){
                       if(stockParlayQuery?.data?.meta.per_page && stockParlayQuery?.data?.meta.current_page){
                          setFrom((stockParlayQuery?.data?.meta.per_page * (stockParlayQuery?.data?.meta.current_page - 1)) + 1 );
                       };
                    };
                    setTotalPage(stockParlayQuery?.data?.meta.last_page);
                };
                setData(stockParlayQuery?.data?.data);
            };
        };
    },[stockParlayQuery]);

    const submitHandler = () => {
        // if(search){
            setRefreshKey(prevKey => prevKey + 1);
        // }else{
        //     return errorToaster("Search field is required!");
        // };
    };

    useEffect(()=>{
        return () => {
            abortController.abort();
        }
    },[]);

  return (
    <>
        <Template title='Stock Management'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-3">
                            <h4 className="card-title">
                                Parlay
                            </h4>
                        </div>
                        <div className="col-lg-3">
                            <label className="form-label mb-1">Search</label>
                            <input type="text" style={{fontSize: '12px'}} className="form-control" placeholder="Search" value={search} onChange={e => {setSearch(e.target.value); setPage(1)}}/>
                        </div>
                        <div className="col-lg-6">
                            <div className='row'>
                                <div className="col-lg-6">
                                    <label className="form-label mb-1">Start date</label>
                                    <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
                                </div>
                                <div className="col-lg-6">
                                    <button
                                        className="btn btn-primary w-100 mt-4"
                                        onClick={submitHandler}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Loading ..." : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table border table-bordered table-striped text-white"  >
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                <th className="text-center text-light p-2"  >No</th>
                                                <th className="text-center text-light p-2"  >Member</th>
                                                <th className="text-center text-light p-2"  >Date</th>
                                                <th className="text-center text-light p-2"  >Event</th>
                                                <th className="text-center text-light p-2"  >Detail</th>
                                                <th className="text-center text-light p-2"  >Amount</th>
                                                <th className="text-center text-light p-2"  >Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {isLoading && (
                                            <TableLoading col={7} />
                                        )}
                                    {
                                        data?.length > 0 ?
                                        data?.map((history:stateObjType,i:number) =>
                                            <tr style={{verticalAlign: 'middle'}} key={i}>
                                                <td className="text-center text-dark p-2">{i+from}</td>
                                                <td className="text-center text-dark p-2">
                                                    {history?.user?.usercode}
                                                </td>
                                                <td className="text-center text-dark p-2">
                                                    <p className="mb-0">{history?.created_at}</p>
                                                </td>
                                                <td className="text-center text-dark p-2">
                                                    <p className="mb-0">
                                                        {history?.bet_fixtures[0]?.football_fixture?.league_data.name}
                                                    </p>
                                                    <p className="mb-0">
                                                        {
                                                            history?.bet_fixtures[0]?.football_fixture?.home_team_data?.name
                                                        }
                                                        {` `} vs {` `}
                                                        {
                                                            history?.bet_fixtures[0]?.football_fixture?.away_team_data?.name
                                                        }
                                                        {
                                                            history?.bet_fixtures[0]?.football_fixture?.goals ? `(${history?.bet_fixtures[0]?.football_fixture?.goals?.home} - ${history?.bet_fixtures[0]?.football_fixture?.goals?.away})` : <></>
                                                        }
                                                    </p>
                                                </td>
                                                <td className="text-center text-dark p-2">
                                                    <div style={{cursor: 'pointer'}} onClick={() =>{
                                                            popupCenter({url: `/stock-manage/parlay-detail/${history?.id}`, title: 'xtf', w: 350, h: 400});
                                                        }}>
                                                        <p className="mb-0 text-decoration-underline">
                                                            <span style={{color: 'green'}}>Parlay </span>
                                                            ({history?.stake} @ {Math.pow(2,history?.bet_fixtures.length) * history?.stake}) @ {history?.bet_fixtures.length}
                                                        </p>
                                                        {/* <p className="mb-0 text-decoration-underline">@ {Math.pow(2,history?.bet_fixtures.length)}</p> */}
                                                    </div>
                                                </td>
                                                <td className="text-end text-dark p-2">
                                                    <b>{amountCommasSparated(history?.stake)}</b>
                                                </td>
                                                <td className="text-end text-dark p-2">
                                                    <b className={history?.total_bet_result?.bet_result == 'win' ? `text-success` : 'text-danger'}>{history?.total_bet_result?.bet_result}</b>
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={7} className="text-center">
                                                No Data Found
                                            </td>
                                        </tr>
                                    }
                                        </tbody>
                                        {
                                            data?.length > 0 &&
                                            <tfoot>
                                                <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                    <th className="text-center text-light p-2" colSpan={5} ></th>
                                                    <th className="text-center text-light p-2"  >
                                                        {data?.reduce((a:number,b:any) => a + b?.stake,0)?.toLocaleString('en-US')}
                                                    </th>
                                                    <th className="text-center text-light p-2"  ></th>
                                                </tr>
                                            </tfoot>
                                        }
                                    </table>
                                </div>
                            </TableScroll>
                            {
                                totalPage > 1 && <Pagi setPage={setPage} setIsLoading={setIsLoading} totalPage={totalPage}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Template>
        <ToastContainer />
    </>
  )
}

export default StockParlay
