import React, { useEffect, useRef, useState } from 'react'
import Template from '../../utils/Template'
import { ToastContainer } from 'react-toastify'
import { deleteMethodService, getMethodService, postMethodService, putMethodService } from '../../services';
import { TEAMS_API } from '../../services/constants';
import SweetAlert from 'sweetalert2';
import { debounce } from "lodash";
import TableScroll from '../../utils/TableScroll';
import TableLoading from '../../utils/TableLoading';
import { HandleLogout } from '../../utils/Helper';
import { useNavigate } from 'react-router-dom';
import Pagi from '../../utils/Pagi';

const TeamList = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [data,setData] = useState([]);
    const [nameEng,setNameEng] = useState("");
    const [nameMM,setNameMM] = useState("");
    const [nameEditEng,setNameEditEng] = useState("");
    const [nameEditMM,setNameEditMM] = useState("");
    const createModalCloseRef = useRef();
    const editModalCloseRef = useRef();
    const [editData,setEditData] = useState(null);
    const [totalPage,setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [from,setFrom] = useState(1);
    const [search,setSearch] = useState("");
    const navigate = useNavigate();

    const fetchTeamList = async () => {
        setIsLoading(true);
        getMethodService(`${TEAMS_API}?sortDirection=desc&sortColumn=id&page=${page}&limit=50`)
            .then((response) => {
                if(response == 401){
                    HandleLogout(navigate);
                };
                setData(response.data);
                if(response.meta){
                    if(response.meta.current_page > 0){
                       if(response.meta.per_page && response.meta.current_page){
                          setFrom((response.meta.per_page * (response.meta.current_page - 1)) + 1 );
                       };
                    };

                    if(response.meta.last_page){
                       setTotalPage(response.meta.last_page);
                    };
                };
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            name_en: nameEng,
            name_mm: nameMM
        }
        postMethodService(TEAMS_API, data,true)
            .then((data) => {
                if(data == 401){
                    HandleLogout(navigate);
                };
                setIsLoading(false);
                console.log("Success:", data);
                createModalCloseRef.current.click();
                setNameEng("");
                setNameMM("");
                fetchTeamList();
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("submit team create Error:", error);
            });
    };

    const deletehandler = (id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
          }).then((result) => {
            if(result.isConfirmed) {
                console.log(id);
                deleteMethodService(`${TEAMS_API}/${id}`, true)
                .then((data) => {
                    setIsLoading(false);
                    fetchTeamList();
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("submit team delete Error:", error);
                });
            };
          });
    };

    useEffect(()=>{
        setNameEditEng(editData?.name_en);
        setNameEditMM(editData?.name_mm);
    },[editData])

    const submitEditHandler = (e) => {
        e.preventDefault();
        if(editData?.id){
           const data = {
            name_en: nameEditEng,
            name_mm: nameEditMM
        }
        putMethodService(`${TEAMS_API}/${editData?.id}`, data,true)
            .then((data) => {
                if(data == 401){
                    HandleLogout(navigate);
                }
                setIsLoading(false);
                editModalCloseRef.current.click();
                setNameEditEng("");
                setNameEditMM("");
                fetchTeamList();
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("submit team edit Error:", error);
            });
        };
    };

    const fetchSearchData = async ()=> {
        setIsLoading(true);
        getMethodService(`${TEAMS_API}?search=${search}&sortDirection=desc&sortColumn=id&limit=100`)
            .then((response) => {
                if(response == 401){
                    HandleLogout(navigate);
                };
                setData(response.data);
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    }

    useEffect(()=>{
        if(search.length > 0 ){
            const searchTeamDebounce = debounce(fetchSearchData, 1500);
            searchTeamDebounce(search); // Initial fetch
            return () => {
                searchTeamDebounce.cancel(); // Cleanup debounced function on unmount
            };
        }else{
            fetchTeamList();
        }
    },[search, page])

    // useEffect(()=>{
    //     fetchTeamList();
    // },[page]);
  return (
    <>
      <Template title="Football">
        <div className="card mb-3 mb-lg-5">
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col-5">
                        <h4 className="card-title">
                            Team list
                        </h4>
                    </div>
                    <div className="col-7">
                        <div style={{textAlign: 'right'}}>
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#teamCreate">
                                Create
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-end mt-3">
                    <div className="col-sm-4">
                        <div className="row align-items-center gx-0 mb-2">
                            <div className="col-3">
                                <span className="text-secondary me-2" style={{fontSize: '12px'}}>
                                    Search:
                                </span>
                            </div>

                            <div className="col-9">
                                <input type="text" style={{fontSize: '12px'}} className="form-control" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className="row">
                    <div className="col-sm-12">
                        <TableScroll>
                            <table className="table text-white border table-bordered table-striped">
                                <thead>
                                    <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                        <th className="p-2 text-center text-light">No</th>
                                        <th className="p-2 text-center text-light">Team Eng</th>
                                        <th className="p-2 text-center text-light">Team MM</th>
                                        <th className="p-2 text-center text-light">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading &&
                                        <TableLoading col={4} />
                                    }
                                    {
                                        data?.length > 0 ?
                                        data?.map((team,i) => 
                                            <tr key={i} style={{ verticalAlign: "middle" }} >
                                                <td className="p-2 text-center text-dark">
                                                    {i + from}
                                                </td>
                                                <td className="text-center text-dark p-2">
                                                    {team.name_en}
                                                </td>
                                                <td className="text-center text-dark p-2">
                                                    {team.name_mm}
                                                </td>
                                                <td className="text-center text-dark p-2">
                                                    <button className='btn btn-success py-1 me-2' data-bs-toggle="modal" data-bs-target="#teamEdit" onClick={() => setEditData(team)}>
                                                        <i className="bi-pencil-square me-2"></i>
                                                        Edit
                                                    </button>
                                                    <button className='btn btn-danger py-1' onClick={() => deletehandler(team.id)}>
                                                        <i className="bi bi-trash me-2"></i>
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ):
                                        <tr>
                                            <td colSpan={4} className="text-center">
                                                No Data Found
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </TableScroll>
                    </div>
                </div>
            </div>
            {totalPage > 1 && (
                <div className="card-footer">
                    <div className="row justify-content-end">
                        <div>
                            <Pagi
                                setIsLoading={setIsLoading}
                                setPage={setPage}
                                totalPage={totalPage}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
      </Template>
      <ToastContainer />

      <div className="modal fade" id="teamCreate" tabIndex="-1" role="dialog" aria-labelledby="teamCreateLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form onSubmit={submitHandler}>
                        <div className="modal-header">
                            <h4 className="modal-title" id="teamCreateLabel">Create team</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="teamEng">Team name Eng</label>
                                <input type="text" id="teamEng" className="form-control" placeholder="Team name" value={nameEng} onChange={e => setNameEng(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="teamMM">Team name MM</label>
                                <input type="text" id="teamMM" className="form-control" placeholder="Team name" value={nameMM} onChange={e => setNameMM(e.target.value)} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={createModalCloseRef} className="btn btn-white" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                            {
                                isLoading?
                                <>
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="visually-hidden">
                                            Loading...
                                        </span>
                                    </div> &nbsp; Loading ...
                                </>
                                :
                                    'Submit'
                                }</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="modal fade" id="teamEdit" tabIndex="-1" role="dialog" aria-labelledby="teamEditLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form onSubmit={submitEditHandler}>
                        <div className="modal-header">
                            <h4 className="modal-title" id="teamEditLabel">Edit team</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="teamEng">Team name Eng</label>
                                <input type="text" id="teamEng" className="form-control" placeholder="Team name" value={nameEditEng} onChange={e => setNameEditEng(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="teamMM">Team name MM</label>
                                <input type="text" id="teamMM" className="form-control" placeholder="Team name" value={nameEditMM} onChange={e => setNameEditMM(e.target.value)} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={editModalCloseRef} className="btn btn-white" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {
                                isLoading?
                                <>
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="visually-hidden">
                                            Loading...
                                        </span>
                                    </div> &nbsp; Loading ...
                                </>
                                :
                                    'Submit'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </>
  )
}

export default TeamList
