import React from 'react';
import classes from '../assets/styles/TextLoad.module.css';

const TextLoad = () => {
  return (
    <div>
        <div className={classes.loader}></div>
    </div>
  )
};

export default TextLoad;