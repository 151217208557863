import { useEffect, useState } from "react";
import Template from "../utils/Template";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import { getMethodService } from "../services";
import {
  AUTH_USER_NODE_API,
  WIN_LOSE_REPORT_NODE_QUEUE_API,
} from "../services/constants";
import {
  HandleLogout,
  amountCommasSparated,
  decodeData,
  exportExcelFormat,
} from "../utils/Helper";
import { Link, useNavigate } from "react-router-dom";
import { stateObjType } from "../types";
import TableLoading from "../utils/TableLoading";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { userIdHandler } from "../store/slices/userIdSlice";
const io = require("socket.io-client");
const Cookies = require('js-cookie');
interface Report {
  user_id: number;
  role_id: number;
  role: object;
  usercode: string;
  contact: string;
  total_bet_amount: number;
  total_valid_amount: number;
  member_win_lose: number;
  member_commission: number;
  member_total: number;
  self_win_lose: number;
  self_commission: number;
  self_total: number;
  upline_win_lose: number;
  upline_commission: number;
  upline_total: number;
}

const WinLoseDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const actualDate = new Date();
  const footballDate = new Date();
  var checkTime = new Date();
  checkTime.setHours(10);
  checkTime.setMinutes(29);

  if (actualDate.getTime() < checkTime.getTime()) {
    footballDate.setDate(footballDate.getDate() - 1);
  }
  const [userId, setUserId] = useState<any>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [startDate, setStartDate] = useState(formatDate(footballDate));
  const [endDate, setEndDate] = useState(formatDate(footballDate));
  const [reports, setReports] = useState<Report[]>([]);
  const navigate = useNavigate();
  const [formatExcel, setFormatExcel] = useState<any>([]);
  const { temp_user_id } = useSelector((state: any) => state.userId);
  const dispatch = useDispatch();
  const [randomString, setRandomString] = useState<any>(null);
  const [socketState, setSocketState] = useState<any>(null);

  function formatDate(date: any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  let generateRandomString = (length: number) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  };

  useEffect(() => {
    const getAuth = Cookies.get("a_u");
    let decode = getAuth && decodeData(getAuth);
    if (decode) {
      if (temp_user_id.length > 0) {
        setUserId(temp_user_id[temp_user_id.length - 1]);
      } else {
        dispatch(userIdHandler([decode?.user_id]));
        setUserId(decode?.user_id);
      }
    }
  }, []);

  useEffect(() => {
    let socket_url = process.env.REACT_APP_SOCKET_URL;
    console.log("socket_url", socket_url);
    let socket_name = "report_of_" + userId + "_" + randomString;
    console.log("socket_name", socket_name);
    const socket = io("https://dev.sports899.org:3333/", {
      transports: ["websocket"],
    }); // Connect to the server

    if (userId && randomString) {
      socket.on(socket_name, (data:any) => {
        let str = `${socket_name} : report of ${data.usercode} => amount: ${data.total_bet_amount} , valid amount: ${data.total_valid_amount}`;
        console.log(str);
        setReports((prevReports) => {
          // Filter out objects where total_bet_amount is 0
          const filteredReports = prevReports.filter(
            (report) => report.total_bet_amount !== 0
          );

          // Add the new data to the filtered array
          const updatedReports = [...filteredReports, data];

          // Sort the array by usercode
          updatedReports.sort((a, b) => a.usercode.localeCompare(b.usercode));

          return updatedReports;
        });
      });
    }

    socket.on("connect", () => {
      console.log("connect");
    });

    return () => {
      socket.disconnect();
    };
  }, [userId, randomString]);

  const userIdAddHandler = (user_id: string) => {
    setUserId(user_id);
    const data = [...temp_user_id];
    data.push(user_id);
    dispatch(userIdHandler(data));
  };

  const userIdRemoveHandler = () => {
    const data = [...temp_user_id];
    data.pop();
    dispatch(userIdHandler(data));
    setUserId(data[data.length - 1]);
  };

  const start_date = startDate && `${startDate} 10:29`;
  const end_date =
    endDate && `${moment(endDate).add(1, "days").format("YYYY-MM-DD")} 10:28`;

  const fetchReportData = () => {
    const getAuth = Cookies.get("a_u");
    if (getAuth) {
      if (userId && randomString) {
        let decode = decodeData(getAuth);
        setIsLoading(true);
        getMethodService(
          `${WIN_LOSE_REPORT_NODE_QUEUE_API}?user_id=${userId}&start_date=${start_date}&end_date=${end_date}&socket_sign=${randomString}`
        )
          .then((res) => {
            if (res == 401) {
              HandleLogout(navigate);
            }
            if (res?.status === "success") {
              let data = res?.data;
              let reports = data.map((item: any) => {
                return {
                  user_id: item.id,
                  role_id: item.role_id,
                  role: {
                    name: null,
                  },
                  usercode: item.usercode,
                  contact: null,
                  total_bet_amount: 0,
                  total_valid_amount: null,
                  member_win_lose: null,
                  member_commission: null,
                  member_total: null,
                  self_win_lose: null,
                  self_commission: null,
                  self_total: null,
                  upline_win_lose: null,
                  upline_commission: null,
                  upline_total: null,
                };
              });
              // console.log(reports);
              setReports(reports);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    }
  };

  const fetchUserData = () => {
    if (userId) {
      const getAuth = Cookies.get('n_a_u');
      if(getAuth){
        let decode = decodeData(getAuth);
        getMethodService(`${AUTH_USER_NODE_API}`,{},false,decode?.token)
        .then((res) => {
          if (res == 401) {
            HandleLogout(navigate);
          };
          if (res?.status === "success") {
            setCurrentUser(res?.data);
          };
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
      };
    }
  };

  useEffect(() => {
    fetchReportData();
    fetchUserData();
  }, [userId]);

  useEffect(()=>{
    let randstr=generateRandomString(5);
    setRandomString(randstr);
  },[])
  

  let show_data: any = {};

  switch (currentUser?.role) {
    case "admin":
      show_data = {
        self: "super",
        upline: "company",
      };
      break;
    case "super":
      show_data = {
        member: "member",
        self: "super",
        upline: "company",
      };
      break;
    case "senior":
      show_data = {
        member: "member",
        self: "senior",
        upline: "company",
      };
      break;
    case "master":
      show_data = {
        member: "member",
        self: "master",
        upline: "company",
      };
      break;
    case "agent":
      show_data = {
        member: "member",
        self: "agent",
        upline: "company",
      };
      break;
  }

  const submitHandler = () => {
    let randstr=generateRandomString(5);
    setRandomString(randstr);
    setIsLoading(true);
    fetchReportData();
    fetchUserData();
  };

  const calculateTotal = (data: any, type: string) => {
    return Number(
      data.reduce((a: any, v: stateObjType) => (a = a + parseInt(v[type])), 0)
    );
  };

  useEffect(() => {
    let temp: any = [];
    if (reports.length > 0) {
      reports.forEach((report: any, i: any) => {
        let obj = {
          No: ++i,
          Account: report?.usercode,
          Contact: report?.contact,
          Amount: report?.total_bet_amount,
          MemberWinLose: Number(Math.round(report?.member_win_lose)),
          MemberComm: Number(Math.round(report?.member_commission)),
          MemberTotal: Number(Math.round(report?.member_total)),
          [show_data?.self + " WinLose"]: Number(
            Math.round(report?.self_win_lose)
          ),
          [show_data?.self + " Comm"]: Number(
            Math.round(report?.self_commission)
          ),
          [show_data?.self + " Total"]: Number(Math.round(report?.self_total)),
          [show_data?.upline + " WinLose"]: Number(
            Math.round(report?.upline_win_lose)
          ),
          [show_data?.upline + " Comm"]: Number(
            Math.round(report?.upline_commission)
          ),
          [show_data?.upline + " Total"]: Number(
            Math.round(report?.upline_total)
          ),
        };
        temp.push(obj);
      });
      const total = {
        No: "",
        Account: "",
        Contact: "Total",
        Amount: calculateTotal(reports, "total_bet_amount"),
        MemberWinLose: calculateTotal(reports, "member_win_lose"),
        MemberComm: calculateTotal(reports, "member_commission"),
        MemberTotal: calculateTotal(reports, "member_total"),
        [show_data?.self + " WinLose"]: calculateTotal(
          reports,
          "self_win_lose"
        ),
        [show_data?.self + " Comm"]: calculateTotal(reports, "self_commission"),
        [show_data?.self + " Total"]: calculateTotal(reports, "self_total"),
        [show_data?.upline + " WinLose"]: calculateTotal(
          reports,
          "upline_win_lose"
        ),
        [show_data?.upline + " Comm"]: calculateTotal(
          reports,
          "upline_commission"
        ),
        [show_data?.upline + " Total"]: calculateTotal(reports, "upline_total"),
      };
      temp.push(total);
      setFormatExcel(temp);
    }
  }, [reports]);
  const exportHandler = () => {
    return exportExcelFormat(formatExcel, "Win-Lose");
  };

  return (
    <>
      <Template title="Report">
        <div className="card mb-3 mb-lg-5">
          <div className="card-header">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-3">
                {temp_user_id?.length > 1 && (
                  <button
                    className="btn btn-sm btn-dark"
                    disabled={isLoading}
                    onClick={() => {
                      // setUserId(
                      //     parentIDList[
                      //         parentIDList.length - 2
                      //     ]
                      // );
                      // setParentIDList(
                      //     parentIDList.slice(0, -1)
                      // );
                      userIdRemoveHandler();
                    }}
                  >
                    {isLoading ? "Loading ..." : "Back"}
                  </button>
                )}
              </div>
              <div className="col-lg-7">
                <DateRangeFormat
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
              <div className="col-lg-2">
                <button
                  className="btn btn-primary w-100 mt-4"
                  onClick={submitHandler}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading ..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                {formatExcel?.length > 0 && (
                  <button
                    className="btn btn-success mb-3"
                    type="button"
                    onClick={exportHandler}
                  >
                    Export Excel
                  </button>
                )}
              </div>
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table className="table border table-bordered table-striped text-white">
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "#25282a",
                            verticalAlign: "middle",
                          }}
                        >
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            No
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Account
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Contact
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Amount
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Valid Amount
                          </th>

                          {show_data.hasOwnProperty("member") && (
                            <th
                              className="text-center text-light p-2"
                              colSpan={3}
                            >
                              Member
                            </th>
                          )}
                          {show_data.hasOwnProperty("self") && (
                            <th
                              className="text-center text-light p-2"
                              colSpan={3}
                            >
                              {show_data?.self}
                            </th>
                          )}
                          {show_data.hasOwnProperty("upline") && (
                            <th
                              className="text-center text-light p-2"
                              colSpan={3}
                            >
                              {show_data.upline}
                            </th>
                          )}
                        </tr>
                        <tr
                          style={{
                            backgroundColor: "#25282a",
                            verticalAlign: "middle",
                          }}
                        >
                          {show_data.hasOwnProperty("member") && (
                            <>
                              <th className="text-center text-light p-2">
                                W/L
                              </th>
                              <th className="text-center text-light p-2">
                                Comm:
                              </th>
                              <th className="p-2 text-center text-light p-2">
                                Total
                              </th>
                            </>
                          )}
                          {show_data.hasOwnProperty("self") && (
                            <>
                              <th className="text-center text-light p-2">
                                W/L
                              </th>
                              <th className="text-center text-light p-2">
                                Comm:
                              </th>
                              <th className="text-center text-light p-2">
                                Total
                              </th>
                            </>
                          )}
                          {show_data.hasOwnProperty("upline") && (
                            <>
                              <th className="text-center text-light p-2">
                                W/L
                              </th>
                              <th className="text-center text-light p-2">
                                Comm:
                              </th>
                              <th className="text-center text-light p-2">
                                Total
                              </th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && <TableLoading col={23} />}
                        {reports?.length > 0 ? (
                          reports?.map((item: stateObjType, i: number) => (
                            <tr style={{ verticalAlign: "middle" }} key={i}>
                              <td className="text-center text-dark p-2">
                                {i + 1}
                              </td>
                              <td
                                className="p-2 text-center text-primary"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                {item?.role?.name != "member" ? (
                                  <span
                                    onClick={() => {
                                      // setParentIDList(
                                      //     (
                                      //         parentIDList:any
                                      //     ) => [
                                      //         ...parentIDList,
                                      //         item?.user_id,
                                      //     ]
                                      // );
                                      // setUserId(
                                      //     item?.user_id
                                      // );
                                      userIdAddHandler(`${item?.user_id}`);
                                    }}
                                  >
                                    {`${item?.usercode}`}

                                    {item?.role?.name != null
                                      ? `(${item?.role?.name})`
                                      : ""}
                                  </span>
                                ) : (
                                  <Link
                                    to={`/report/win-lose-detail/${item.user_id}/all/${startDate}/${endDate}`}
                                  >
                                    {`${item?.usercode} (${item?.role?.name})`}
                                  </Link>
                                )}
                              </td>
                              <td className="p-2 text-center text-dark">
                                {item?.contact}
                              </td>
                              <td className="p-2 text-end text-dark">
                                <b>
                                  {" "}
                                  {amountCommasSparated(
                                    Math.round(item?.total_bet_amount)
                                  )}
                                </b>
                              </td>
                              <td className="p-2 text-end text-dark">
                                <b>
                                  {amountCommasSparated(
                                    Math.round(item?.total_valid_amount)
                                  )}
                                </b>
                              </td>
                              {show_data.hasOwnProperty("member") && (
                                <>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.member_win_lose >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.member_win_lose)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-2 text-end text-dark">
                                    <b>
                                      {amountCommasSparated(
                                        Math.round(item?.member_commission)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-0 text-end text-dark">
                                    <div className="p-2 bg-warning">
                                      <b
                                        className={`${
                                          item?.member_total >= 0
                                            ? "text-dark"
                                            : "text-danger"
                                        }`}
                                      >
                                        {amountCommasSparated(
                                          Math.round(item?.member_total)
                                        )}
                                      </b>
                                    </div>
                                  </td>
                                </>
                              )}
                              {show_data.hasOwnProperty("self") && (
                                <>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.self_win_lose >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.self_win_lose)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-2 text-end text-dark">
                                    <b>
                                      {amountCommasSparated(
                                        Math.round(item?.self_commission)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-0 text-end text-dark">
                                    <div className="p-2 bg-warning">
                                      <b
                                        className={`${
                                          item?.self_total >= 0
                                            ? "text-dark"
                                            : "text-danger"
                                        }`}
                                      >
                                        {amountCommasSparated(
                                          Math.round(item?.self_total)
                                        )}
                                      </b>
                                    </div>
                                  </td>
                                </>
                              )}
                              {show_data.hasOwnProperty("upline") && (
                                <>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.upline_win_lose >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.upline_win_lose)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.upline_commission >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.upline_commission)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-0 text-end text-dark">
                                    <div className="p-2 bg-warning">
                                      <b
                                        className={`${
                                          item?.upline_total >= 0
                                            ? "text-dark"
                                            : "text-danger"
                                        }`}
                                      >
                                        {amountCommasSparated(
                                          Math.round(item?.upline_total)
                                        )}
                                      </b>
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={23} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                      {reports?.length > 0 && (
                        <tfoot>
                          <tr
                            style={{
                              verticalAlign: "middle",
                            }}
                          >
                            <td
                              colSpan={3}
                              className="text-end text-white"
                              style={{ backgroundColor: "#25282a" }}
                            >
                              Total :
                            </td>
                            <td className="p-2 text-end text-dark">
                              <b>
                                {" "}
                                {reports
                                  ?.reduce(
                                    (a: any, v: stateObjType) =>
                                      (a = a + parseInt(v.total_bet_amount)),
                                    0
                                  )
                                  .toLocaleString()}
                              </b>
                            </td>
                            <td className="p-2 text-end text-dark">
                              <b>
                                {reports
                                  ?.reduce(
                                    (a: any, v: stateObjType) =>
                                      (a = a + parseInt(v.total_valid_amount)),
                                    0
                                  )
                                  .toLocaleString()}
                              </b>
                            </td>

                            {show_data.hasOwnProperty("member") && (
                              <>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a = a + parseInt(v.member_win_lose)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a = a + parseInt(v.member_win_lose)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-2 text-end text-dark">
                                  <b>
                                    {reports
                                      ?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a =
                                            a + parseInt(v.member_commission)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-0 text-end text-dark">
                                  <div className="p-2">
                                    <b
                                      className={`${
                                        reports?.reduce(
                                          (a: any, v: stateObjType) =>
                                            (a = a + parseInt(v.member_total)),
                                          0
                                        ) >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {reports
                                        ?.reduce(
                                          (a: any, v: stateObjType) =>
                                            (a = a + parseInt(v.member_total)),
                                          0
                                        )
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                </td>
                              </>
                            )}
                            {show_data.hasOwnProperty("self") && (
                              <>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a = a + parseInt(v.self_win_lose)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a = a + parseInt(v.self_win_lose)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-2 text-end text-dark">
                                  <b>
                                    {reports
                                      ?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a = a + parseInt(v.self_commission)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-0 text-end text-dark">
                                  <div className="p-2">
                                    <b
                                      className={`${
                                        reports?.reduce(
                                          (a: any, v: stateObjType) =>
                                            (a = a + parseInt(v.self_total)),
                                          0
                                        ) >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {reports
                                        ?.reduce(
                                          (a: any, v: stateObjType) =>
                                            (a = a + parseInt(v.self_total)),
                                          0
                                        )
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                </td>
                              </>
                            )}

                            {show_data.hasOwnProperty("upline") && (
                              <>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a = a + parseInt(v.upline_win_lose)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a = a + parseInt(v.upline_win_lose)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a =
                                            a + parseInt(v.upline_commission)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a: any, v: stateObjType) =>
                                          (a =
                                            a + parseInt(v.upline_commission)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-0 text-end text-dark">
                                  <div className="p-2">
                                    <b
                                      className={`${
                                        reports?.reduce(
                                          (a: any, v: stateObjType) =>
                                            (a = a + parseInt(v.upline_total)),
                                          0
                                        ) >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {reports
                                        ?.reduce(
                                          (a: any, v: stateObjType) =>
                                            (a = a + parseInt(v.upline_total)),
                                          0
                                        )
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
      </Template>
    </>
  );
};

export default WinLoseDetail;
