import { useEffect, useState } from "react";
import Template from "../utils/Template";
import { ToastContainer } from "react-toastify";
import TableScroll from "../utils/TableScroll";
import TableLoading from "../utils/TableLoading";
import { Link, useNavigate } from "react-router-dom";
import { HandleLogout, amountCommasSparated, decodeData } from "../utils/Helper";
import { getMethodService } from "../services";
import { THREE_D_STOCK_API } from "../services/constants";
const Cookies = require('js-cookie');

const MMThreedStock = () => {
  const abortController = new AbortController();
  const [isLoading, setIsLoading] = useState(false);
  const [threedNums, setThreedNums] = useState([]);
  const [odds, setOdds] = useState("");
  const [filterType, setFilterType] = useState("amount");
  const [filterCheck, setFilterCheck] = useState(false);
  const [selectNum, setSelectNum] = useState("000");
  const [filterNums, setFilterNums] = useState([]);
  const [viewType, setViewType] = useState("list");
  const [hoverId, setHoverId] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const layout = JSON.parse(localStorage.getItem('layout'));
    if (layout) {
      setFilterCheck(layout.filter_check);
      setViewType(layout.view_type);
      setSelectNum(layout.select_num);
    } else {
      const layout = {
        filter_check: false,
        view_type: 'list',
        select_num: '000'
      }
      localStorage.setItem('layout', JSON.stringify(layout))
    }
  }, [])

  const filterCheckHandler = () => {
    setFilterCheck(!filterCheck)
    const layout = JSON.parse(localStorage.getItem('layout'));
    if (layout.filter_check) {
      layout.select_num = '000'
      setSelectNum('000')
    }
    layout.filter_check = !layout.filter_check;
    localStorage.setItem('layout', JSON.stringify(layout));
  }
  const viewTypeHandler = () => {
    setViewType(viewType === 'list' ? 'box' : 'list')
    const layout = JSON.parse(localStorage.getItem('layout'));
    layout.view_type = layout.view_type === 'list' ? 'box' : 'list';
    localStorage.setItem('layout', JSON.stringify(layout));
  }
  const selectNumHandler = (num) => {
    setSelectNum(num);
    const layout = JSON.parse(localStorage.getItem('layout'));
    layout.select_num = `${num}`;
    localStorage.setItem('layout', JSON.stringify(layout))
  }

  const fetchSotck = async () => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth)
      const res = await getMethodService(
        `${THREE_D_STOCK_API}`,
        [],
        false,
        decode.token,
        abortController
      );
      if (res == 401) {
        HandleLogout(navigate);
      }
      res && setIsLoading(false);
      if (res?.status === "success") {
        setThreedNums(res?.data?.stock?.sort((a, b) => b.amount - a.amount));
        setOdds(res?.data?.odds);
      }
    }
  };

  useEffect(() => {
    fetchSotck();
  }, []);

  const calculateTotal = (data, type) => {
    return data?.reduce((a, b) => {
      if (type === "amount") {
        return a + b?.amount;
      }
      if (type === "estimate_amount") {
        return a + b?.amount * odds;
      }
    }, 0);
  };

  useEffect(() => {
    if (filterType === "amount") {
      const sortedArray = [...filterNums].sort((a, b) => b.amount - a.amount);
      setFilterNums(sortedArray);
    }
    if (filterType === "number") {
      const sortedArray = [...filterNums].sort(
        (a, b) => a.three_digit_id - b.three_digit_id
      );
      setFilterNums(sortedArray);
    }
  }, [filterType, threedNums, selectNum]);

  useEffect(() => {
    if (filterCheck) {
      const filterArray = [];
      for (let i = +selectNum + 1; i < +selectNum + 101; i++) {
        const findNum = threedNums.find((num) => num.three_digit_id === i);
        if (findNum) {
          filterArray.push(findNum);
        } else {
          filterArray.push({ three_digit_id: i, amount: 0 });
        }
      }
      setFilterType("amount");
      setFilterNums(filterArray.sort((a, b) => b.amount - a.amount));
    } else {
      setFilterNums(threedNums);
    }
  }, [filterCheck, selectNum, threedNums]);

  useEffect(()=>{
    return () => {
        abortController.abort();
    }
  },[]);

  return (
    <>
      <Template title="MM 3D Stock">
        <div className="card mb-3 mb-lg-5">
          <span
            className="rounded-5 bg-primary text-center text-white"
            style={{
              position: "absolute",
              top: "-75px",
              right: "0",
              width: "38px",
              height: "38px",
              lineHeight: "40px",
            }}
          >
            <i
              className={`bi bi-${viewType === 'box' ? "grid" : "list-ul"}`}
              style={{ fontSize: "20px", cursor: "pointer" }}
              onClick={() => viewTypeHandler()}
            ></i>
          </span>
          <div className="card-header d-md-flex justify-content-between align-items-center">
            <div>
              <input
                className="me-1"
                type="checkbox"
                id="filterCheck"
                style={{ transform: "scale(1.2)", cursor: "pointer" }}
                checked={filterCheck}
                onClick={() => filterCheckHandler()}
              />
              <label
                className="fs-4"
                style={{ cursor: "pointer" }}
                htmlFor="filterCheck"
              >
                Filter
              </label>
            </div>
            <div className="d-flex">
              {filterCheck && (
                <select
                  value={filterType}
                  className="form-select me-2"
                  onChange={(e) => setFilterType(e.target.value)}
                  style={{ width: "200px" }}
                >
                  <option value="number">Sort By Number</option>
                  <option value="amount">Sort By Amount</option>
                </select>
              )}
              {filterCheck && (
                <select
                  value={selectNum}
                  className="form-select"
                  onChange={(e) => selectNumHandler(e.target.value)}
                >
                  <option value="000">000 မှစ၍</option>
                  <option value="100">100 မှစ၍</option>
                  <option value="200">200 မှစ၍</option>
                  <option value="300">300 မှစ၍</option>
                  <option value="400">400 မှစ၍</option>
                  <option value="500">500 မှစ၍</option>
                  <option value="600">600 မှစ၍</option>
                  <option value="700">700 မှစ၍</option>
                  <option value="800">800 မှစ၍</option>
                  <option value="900">900 မှစ၍</option>
                </select>
              )}
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                {viewType === "list" && (
                  <TableScroll>
                    <div>
                      <table
                        className="table w-100 table-bordered"
                        style={{ border: ".5px solid #ddd" }}
                      >
                        <thead>
                          <tr>
                            <th className=" text-center text-light">#</th>
                            <th
                              className=" text-center text-light"
                              onClick={() => setFilterType("number")}
                            >
                              3D Number
                            </th>
                            <th
                              className=" text-center text-light"
                              onClick={() => setFilterType("amount")}
                            >
                              Amount
                            </th>
                            <th className=" text-center text-light">
                              Estimate Payout
                            </th>
                            <th className=" text-center text-light">
                              Estimate (Win / Lose)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading && <TableLoading col={4} />}
                          {filterNums?.length > 0 &&
                            filterNums?.map((num, i) => (
                              <tr style={{ verticalAlign: "middle" }} key={i}>
                                <td className="text-center bg-white p-2">
                                  {+selectNum + i + 1
                                  }.
                                </td>
                                <td className="text-center bg-white p-2">
                                  <Link
                                    to={
                                      num?.amount === 0
                                        ? `/stock-manage/mm3d-stock`
                                        : `/stock-manage/mm3d-stock/${num?.three_digit_id}`
                                    }
                                    style={{
                                      textDecoration:
                                        num?.amount === 0 ? "" : "underline",
                                      color:
                                        num?.amount === 0 ? "grey" : "blue",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {(num?.three_digit_id - 1).toString()
                                      .length === 1 &&
                                      `00${num?.three_digit_id - 1}`}
                                    {(num?.three_digit_id - 1).toString()
                                      .length === 2 &&
                                      `0${num?.three_digit_id - 1}`}
                                    {(num?.three_digit_id - 1).toString()
                                      .length === 3 && num?.three_digit_id - 1}
                                  </Link>
                                </td>
                                <td className="text-end bg-white">
                                  {amountCommasSparated(num?.amount)}
                                </td>
                                <td className="text-end bg-white p-2">
                                  {amountCommasSparated(num?.amount * odds)}
                                </td>
                                <td className="text-end bg-white p-2">
                                  {calculateTotal(threedNums, "amount") -
                                    num?.amount * odds >
                                  0 ? (
                                    <span className="text-success">
                                      {amountCommasSparated(
                                        calculateTotal(threedNums, "amount") -
                                          num?.amount * odds
                                      )}
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      {amountCommasSparated(
                                        calculateTotal(threedNums, "amount") -
                                          num?.amount * odds
                                      )}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        <thead>
                          <tr
                            className="text-center fw-bold text-white"
                            style={{ verticalAlign: "middle" }}
                          >
                            <td colSpan={2} className="text-end">
                              Total :{" "}
                            </td>
                            <td className="text-end">
                              {amountCommasSparated(
                                calculateTotal(threedNums, "amount")
                              )}
                            </td>
                            <td colSpan={2}></td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </TableScroll>
                )}
                {viewType === "box" && (
                  <TableScroll>
                    <div
                      className="d-flex flex-wrap"
                      style={{ minWidth: "1000px" }}
                    >
                      {filterNums.length > 0 &&
                        filterNums.map((num) => (
                          <Link 
                          to={
                            num?.amount === 0
                              ? `/stock-manage/mm3d-stock`
                              : `/stock-manage/mm3d-stock/${num?.three_digit_id}`
                          }
                            className="p-2"
                            style={{
                              width: "100px",
                              backgroundColor:
                                num?.amount > 0 ? num?.three_digit_id === hoverId ? 'darkgreen' : "forestgreen" : "",
                              color: num?.amount > 0 ? "#fff" : "",
                              border:
                                num?.amount > 0
                                  ? "1px solid #fff"
                                  : "1px solid #eee",
                            }}
                            key={num.three_digit_id}
                            onMouseEnter={e => {setHoverId(num?.three_digit_id)}}
                            onMouseLeave={e => {setHoverId(0)}}
                          >
                            <div
                              style={{
                                color: num?.amount === 0 ? "grey" : "white",
                                cursor: `${num?.three_digit_id === hoverId && num?.amount > 0 ? 'pointer' : 'text'}`,
                                width: '100%',
                                display: 'block',
                                textDecoration:  `${num?.three_digit_id === hoverId && num?.amount > 0 ? 'underline' : 'none'}`
                              }}
                            >
                              {(num?.three_digit_id - 1).toString().length ===
                                1 && `00${num?.three_digit_id - 1}`}
                              {(num?.three_digit_id - 1).toString().length ===
                                2 && `0${num?.three_digit_id - 1}`}
                              {(num?.three_digit_id - 1).toString().length ===
                                3 && num?.three_digit_id - 1}
                            </div>
                            <div>{amountCommasSparated(num.amount)}</div>
                          </Link>
                        ))}
                    </div>
                  </TableScroll>
                )}
              </div>
            </div>
          </div>
        </div>
      </Template>
      <ToastContainer />
    </>
  );
};

export default MMThreedStock;
