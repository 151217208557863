import { useEffect, useState } from "react";
import Template from "../utils/Template";
import TableScroll from "../utils/TableScroll";
import { getMethodService } from "../services";
import {
  THREE_D_WIN_LOSE_REPORT_NODE_API,
  THREE_D_TIME_API,
  AUTH_USER_NODE_API,
} from "../services/constants";
import {
  HandleLogout,
  ISOdateFormatter,
  amountCommasSparated,
  decodeData,
  exportExcelFormat,
} from "../utils/Helper";
import { Link, useNavigate } from "react-router-dom";
import { stateObjType } from "../types";
import TableLoading from "../utils/TableLoading";
import NormalDateRangeFormat from "../utils/NormalDateRangeFormat";
import moment from "moment";
const Cookies = require('js-cookie');

const MMThreeDWinLoseDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState<any>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [parentIDList, setParentIDList] = useState<any>([]);
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();
  const [threedTime, setThreedTime] = useState([]);
  const [formatExcel, setFormatExcel] = useState<any>([]);

  useEffect(()=>{
    localStorage.removeItem('r_type');
  },[]);

  const fetchThreedTime = async () => {
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await getMethodService(
        `${THREE_D_TIME_API}`,
        [],
        false,
        decode.token
      );
      if (res == 401) {
        HandleLogout(navigate);
      }
      if (res?.status === "success") {
        setThreedTime(res?.data);
        setStartDate(
          ISOdateFormatter(res?.data[0]?.opening_time).split(" ")[0]
        );
      }
    }
  };

  useEffect(() => {
    const getAuth = Cookies.get("n_a_u");
    let decode = getAuth && decodeData(getAuth);
    if (decode) {
      // setUserId(decode?.user_id);
      // setParentIDList([decode?.user_id]);
    }
    fetchThreedTime();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [userId])

  const fetchReportData = (abortController:any) => {
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      if (threedTime?.length > 0) {
        let decode = decodeData(getAuth);
        setIsLoading(true);
        getMethodService(
          `${THREE_D_WIN_LOSE_REPORT_NODE_API}?start_date=${startDate} 00:00&end_date=${endDate} 23:59${userId ? `&user_id=${userId}`: ''}`,{},false,decode?.token,abortController
        )
          .then((res) => {
            if (res == 401) {
              HandleLogout(navigate);
            }
            if (res?.status === "success") {
              setReports(res?.data);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    };
  };

  const fetchUserData = () => {
      const getAuth = Cookies.get('n_a_u');
      if(getAuth){
        let decode = decodeData(getAuth);
        getMethodService(`${AUTH_USER_NODE_API}`,{},false,decode?.token)
        .then((res) => {
          if (res == 401) {
            HandleLogout(navigate);
          };
          if (res?.status === "success") {
            setCurrentUser(res?.data);
          };
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
      };
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (threedTime?.length > 0) {
      fetchReportData(abortController);
    }
    return () => {
        abortController.abort();
    };
    // fetchUserData();
  }, [userId, threedTime]);

  let show_data: any = {};

  switch (currentUser?.role) {
    case "admin":
      show_data = {
        self: "super",
        upline: "company",
      };
      break;
    case "super":
      show_data = {
        member: "member",
        self: "super",
        upline: "company",
      };
      break;
    case "senior":
      show_data = {
        member: "member",
        self: "senior",
        upline: "company",
      };
      break;
    case "master":
      show_data = {
        member: "member",
        self: "master",
        upline: "company",
      };
      break;
    case "agent":
      show_data = {
        member: "member",
        self: "agent",
        upline: "company",
      };
      break;
  }

  const submitHandler = () => {
    const abortController = new AbortController();
    setIsLoading(true);
    fetchReportData(abortController);
    fetchUserData();
  };

  const calculateTotal = (data: any, type: string) => {
    return Number(
      data.reduce((a: any, v: stateObjType) => (a = a + parseInt(v[type])), 0)
    );
  };

  useEffect(() => {
    let temp: any = [];
    if (reports.length > 0) {
      reports.forEach((report: any, i) => {
        let obj = {
          No: ++i,
          Account: report?.usercode,
          Contact: report?.contact,
          Amount: report?.total_bet_amount,
          MemberWinLose: Number(Math.round(report?.member_win_lose)),
          MemberComm: Number(Math.round(report?.member_commission)),
          MemberTotal: Number(Math.round(report?.member_total)),
          [show_data?.self + " WinLose"]: Number(
            Math.round(report?.self_win_lose)
          ),
          [show_data?.self + " Comm"]: Number(
            Math.round(report?.self_commission)
          ),
          [show_data?.self + " Total"]: Number(Math.round(report?.self_total)),
          [show_data?.upline + " WinLose"]: Number(
            Math.round(report?.upline_win_lose)
          ),
          [show_data?.upline + " Comm"]: Number(
            Math.round(report?.upline_commission)
          ),
          [show_data?.upline + " Total"]: Number(
            Math.round(report?.upline_total)
          ),
        };
        temp.push(obj);
      });
      const total = {
        No: "",
        Account: "",
        Contact: "Total",
        Amount: calculateTotal(reports, "total_bet_amount"),
        MemberWinLose: calculateTotal(reports, "member_win_lose"),
        MemberComm: calculateTotal(reports, "member_commission"),
        MemberTotal: calculateTotal(reports, "member_total"),
        [show_data?.self + " WinLose"]: calculateTotal(
          reports,
          "self_win_lose"
        ),
        [show_data?.self + " Comm"]: calculateTotal(reports, "self_commission"),
        [show_data?.self + " Total"]: calculateTotal(reports, "self_total"),
        [show_data?.upline + " WinLose"]: calculateTotal(
          reports,
          "upline_win_lose"
        ),
        [show_data?.upline + " Comm"]: calculateTotal(
          reports,
          "upline_commission"
        ),
        [show_data?.upline + " Total"]: calculateTotal(reports, "upline_total"),
      };
      temp.push(total);
      setFormatExcel(temp);
    }
  }, [reports]);
  const exportHandler = () => {
    return exportExcelFormat(formatExcel, "Win-Lose");
  };

  return (
    <>
      <Template title="Report">
        <div className="card mb-3 mb-lg-5">
          <div className="card-header">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-3">
                {parentIDList?.length > 0 && (
                  <button
                    className="btn btn-sm btn-dark"
                    disabled={isLoading}
                    onClick={() => {
                      setUserId(parentIDList[parentIDList.length - 2]);
                      setParentIDList(parentIDList.slice(0, -1));
                    }}
                  >
                    {isLoading ? "Loading ..." : "Back"}
                  </button>
                )}
              </div>
              <div className="col-lg-7">
                <NormalDateRangeFormat
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
              <div className="col-lg-2">
                <button
                  className="btn btn-primary w-100 mt-4"
                  onClick={submitHandler}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading ..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                {formatExcel?.length > 0 && (
                  <button
                    className="btn btn-success mb-3"
                    type="button"
                    onClick={exportHandler}
                  >
                    Export Excel{" "}
                  </button>
                )}
              </div>
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table className="table border table-bordered table-striped text-white">
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "#25282a",
                            verticalAlign: "middle",
                          }}
                        >
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            No
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Account
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Contact
                          </th>
                          <th
                            className="text-center text-light p-2"
                            rowSpan={2}
                          >
                            Amount
                          </th>

                          {show_data.hasOwnProperty("member") && (
                            <th
                              className="text-center text-light p-2"
                              colSpan={3}
                            >
                              Member
                            </th>
                          )}
                          {show_data.hasOwnProperty("self") && (
                            <th
                              className="text-center text-light p-2"
                              colSpan={3}
                            >
                              {show_data?.self}
                            </th>
                          )}
                          {show_data.hasOwnProperty("upline") && (
                            <th
                              className="text-center text-light p-2"
                              colSpan={3}
                            >
                              {show_data.upline}
                            </th>
                          )}
                        </tr>
                        <tr
                          style={{
                            backgroundColor: "#25282a",
                            verticalAlign: "middle",
                          }}
                        >
                          {show_data.hasOwnProperty("member") && (
                            <>
                              <th className="text-center text-light p-2">
                                W/L
                              </th>
                              <th className="text-center text-light p-2">
                                Comm:
                              </th>
                              <th className="p-2 text-center text-light p-2">
                                Total
                              </th>
                            </>
                          )}
                          {show_data.hasOwnProperty("self") && (
                            <>
                              <th className="text-center text-light p-2">
                                W/L
                              </th>
                              <th className="text-center text-light p-2">
                                Comm:
                              </th>
                              <th className="text-center text-light p-2">
                                Total
                              </th>
                            </>
                          )}
                          {show_data.hasOwnProperty("upline") && (
                            <>
                              <th className="text-center text-light p-2">
                                W/L
                              </th>
                              <th className="text-center text-light p-2">
                                Comm:
                              </th>
                              <th className="text-center text-light p-2">
                                Total
                              </th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && <TableLoading col={23} />}
                        {reports?.length > 0 ? (
                          reports?.map((item: stateObjType, i: number) => (
                            <tr style={{ verticalAlign: "middle" }} key={i}>
                              <td className="text-center text-dark p-2">
                                {i + 1}
                              </td>
                              <td
                                className="p-2 text-center text-primary"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                {item?.role.name != "member" ? (
                                  <span
                                    onClick={() => {
                                      setParentIDList((parentIDList: any) => [
                                        ...parentIDList,
                                        item?.user_id,
                                      ]);
                                      setUserId(item?.user_id);
                                    }}
                                  >
                                    {`${item?.usercode} (${item?.role?.name})`}
                                  </span>
                                ) : (
                                  <Link
                                    to={`/report/3d-win-lose-detail/${item.user_id}/all/${startDate}/${endDate}`}
                                  >
                                    {`${item?.usercode} (${item?.role?.name})`}
                                  </Link>
                                )}
                              </td>
                              <td className="p-2 text-center text-dark">
                                {item?.contact}
                              </td>
                              <td className="p-2 text-end text-dark">
                                <b>
                                  {" "}
                                  {amountCommasSparated(
                                    Math.round(item?.total_bet_amount)
                                  )}
                                </b>
                              </td>
                              {show_data.hasOwnProperty("member") && (
                                <>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.member_win_lose >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.member_win_lose)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-2 text-end text-dark">
                                    <b>
                                      {amountCommasSparated(
                                        Math.round(item?.member_commission)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-0 text-end text-dark">
                                    <div className="p-2 bg-warning">
                                      <b
                                        className={`${
                                          item?.member_total >= 0
                                            ? "text-dark"
                                            : "text-danger"
                                        }`}
                                      >
                                        {amountCommasSparated(
                                          Math.round(item?.member_total)
                                        )}
                                      </b>
                                    </div>
                                  </td>
                                </>
                              )}
                              {show_data.hasOwnProperty("self") && (
                                <>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.self_win_lose >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.self_win_lose)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-2 text-end text-dark">
                                    <b>
                                      {amountCommasSparated(
                                        Math.round(item?.self_commission)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-0 text-end text-dark">
                                    <div className="p-2 bg-warning">
                                      <b
                                        className={`${
                                          item?.self_total >= 0
                                            ? "text-dark"
                                            : "text-danger"
                                        }`}
                                      >
                                        {amountCommasSparated(
                                          Math.round(item?.self_total)
                                        )}
                                      </b>
                                    </div>
                                  </td>
                                </>
                              )}
                              {show_data.hasOwnProperty("upline") && (
                                <>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.upline_win_lose >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.upline_win_lose)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-2 text-end text-dark">
                                    <b
                                      className={`${
                                        item?.upline_commission >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {amountCommasSparated(
                                        Math.round(item?.upline_commission)
                                      )}
                                    </b>
                                  </td>
                                  <td className="p-0 text-end text-dark">
                                    <div className="p-2 bg-warning">
                                      <b
                                        className={`${
                                          item?.upline_total >= 0
                                            ? "text-dark"
                                            : "text-danger"
                                        }`}
                                      >
                                        {amountCommasSparated(
                                          Math.round(item?.upline_total)
                                        )}
                                      </b>
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={23} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                      {reports?.length > 0 && (
                        <tfoot>
                          <tr
                            style={{
                              verticalAlign: "middle",
                            }}
                          >
                            <td
                              colSpan={3}
                              className="text-end text-white"
                              style={{ backgroundColor: "#25282a" }}
                            >
                              Total :
                            </td>
                            <td className="p-2 text-end text-dark">
                              <b>
                                {" "}
                                {reports
                                  ?.reduce(
                                    (a, v: stateObjType) =>
                                      (a = a + parseInt(v.total_bet_amount)),
                                    0
                                  )
                                  .toLocaleString()}
                              </b>
                            </td>
                            {show_data.hasOwnProperty("member") && (
                              <>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.member_win_lose)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.member_win_lose)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-2 text-end text-dark">
                                  <b>
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a =
                                            a + parseInt(v.member_commission)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-0 text-end text-dark">
                                  <div className="p-2">
                                    <b
                                      className={`${
                                        reports?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.member_total)),
                                          0
                                        ) >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {reports
                                        ?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.member_total)),
                                          0
                                        )
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                </td>
                              </>
                            )}
                            {show_data.hasOwnProperty("self") && (
                              <>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.self_win_lose)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.self_win_lose)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-2 text-end text-dark">
                                  <b>
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.self_commission)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-0 text-end text-dark">
                                  <div className="p-2">
                                    <b
                                      className={`${
                                        reports?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.self_total)),
                                          0
                                        ) >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {reports
                                        ?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.self_total)),
                                          0
                                        )
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                </td>
                              </>
                            )}

                            {show_data.hasOwnProperty("upline") && (
                              <>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.upline_win_lose)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a = a + parseInt(v.upline_win_lose)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-2 text-end text-dark">
                                  <b
                                    className={`${
                                      reports?.reduce(
                                        (a, v: stateObjType) =>
                                          (a =
                                            a + parseInt(v.upline_commission)),
                                        0
                                      ) >= 0
                                        ? "text-dark"
                                        : "text-danger"
                                    }`}
                                  >
                                    {reports
                                      ?.reduce(
                                        (a, v: stateObjType) =>
                                          (a =
                                            a + parseInt(v.upline_commission)),
                                        0
                                      )
                                      .toLocaleString()}
                                  </b>
                                </td>
                                <td className="p-0 text-end text-dark">
                                  <div className="p-2">
                                    <b
                                      className={`${
                                        reports?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.upline_total)),
                                          0
                                        ) >= 0
                                          ? "text-dark"
                                          : "text-danger"
                                      }`}
                                    >
                                      {reports
                                        ?.reduce(
                                          (a, v: stateObjType) =>
                                            (a = a + parseInt(v.upline_total)),
                                          0
                                        )
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
      </Template>
    </>
  );
};

export default MMThreeDWinLoseDetail;
