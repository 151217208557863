import React from 'react'
import { Link } from 'react-router-dom'
import Template from './Template'

export default function TempoCLose() {
  return (
    <Template title={''}>
            <div className='w-100 d-flex justify-content-center align-items-center flex-column' style={{height: '50vh'}}>
                {/* <p className='text-center' style={{fontSize: '100px'}}>Warning!</p> */}
                <p className='text-center' style={{fontSize: '25px'}}>ယာယီ ပြင်ဆင်မှုများပြုလုပ်နေပါသည်။</p>
                <Link className='btn btn-sm btn-primary' to={'/'}>Go to home</Link>
            </div>
    </Template>
  )
}
