import React from 'react'
import { Link } from 'react-router-dom'
import Template from './Template'

export default function WaitingPage() {
  return (
    <Template title={''}>
            <div className='w-100 d-flex justify-content-center align-items-center flex-column' style={{height: '50vh'}}>
                {/* <p className='text-center' style={{fontSize: '100px'}}>Warning!</p> */}
                <p className='text-center' style={{fontSize: '25px'}}>ခဏစောင့်ပါ</p>
            </div>
    </Template>
  )
}
