import { useEffect, useState } from "react";
import Template from "../utils/Template";
import { ToastContainer } from "react-toastify";
import TableScroll from "../utils/TableScroll";
import TableLoading from "../utils/TableLoading";
import { HandleLogout, ISOdateFormatter, amountCommasSparated, decodeData } from "../utils/Helper";
import { getMethodService } from "../services";
import { THREE_D_BET_LISTS_ALL_API, THREE_D_TIME_API } from "../services/constants";
import Pagi from "../utils/Pagi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const Cookies = require('js-cookie');

const MMThreedBetListsAll = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [betLists, setBetLists] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [lotteryTime, setLotteryTime] = useState(moment().format('DD') > 16 ? 'Two' : 'One');
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [threedTime, setThreedTime] = useState([]);
  const navigate = useNavigate();

  const fetchThreedTime = async () => {
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await getMethodService(
        `${THREE_D_TIME_API}`,
        [],
        false,
        decode.token
      );
      if(res == 401){
          HandleLogout(navigate);
      };
      if (res?.status === "success") {
        setThreedTime(res?.data);
        setStartDate(
          ISOdateFormatter(res?.data[0]?.opening_time).split(" ")[0]
        );
      }
    }
  };

  const fetchBetList = async () => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      if (threedTime?.length > 0) {
        const res = await getMethodService(
          `${THREE_D_BET_LISTS_ALL_API}?limit=50&page=${page}&start_date=${startDate}&end_date=${endDate}&search=${searchKey}`, [], false, decode.token
        );
        res && setIsLoading(false);
        if (res == 401) {
          HandleLogout(navigate);
        }
        if (res?.status === "success") {
          setBetLists(res?.data);
          setTotalPage(res?.meta.pageCount);
          setFrom(page > 1 ? (page - 1) * 50 : 0);
        }
      }
    }
  };

  const calculateTotal = (data, type) => {
    return data?.reduce((a, b) => {
      if (type === "amount") {
        return a + b?.amount;
      }
      if (type === "payout_amount") {
        return a + b?.payout_amount;
      }
    }, 0);
  };

  useEffect(() => {
    fetchThreedTime();
  }, [])

  useEffect(() => {
    if (threedTime?.length > 0) {
      fetchBetList();
    }
  }, [page, threedTime]);

  const submitHandler = () => {
    fetchBetList();
  };

  return (
    <>
      <Template title="MM 3D Bet Lists">
        <div className="card mb-3 mb-lg-5">
          <div className="card-header">
            <div className="d-md-flex justify-content-between">
              <h5 className="card-title mb-3 mb-md-0 d-flex">
                <div className="me-2 mb-3 mb-md-0">
                  Start Date
                  <input
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    type="date"
                    className="form-control"
                    max={moment().format("YYYY-MM-DD")}
                  />
                </div>
                <div className="me-2 mb-3 mb-md-0">
                  End Date
                  <input
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    type="date"
                    className="form-control"
                    max={moment().format("YYYY-MM-DD")}
                  />
                </div>
                <div className="me-2 mb-3 mb-md-0">
                  <h5 className="m-0 p-0">Search</h5>
                  <input
                    type="text"
                    placeholder="Enter User ID"
                    className="form-control"
                    onChange={(e) => setSearchKey(e.target.value)}
                  />
                </div>
                <div className="d-flex py-1">
                  <button
                    className="btn btn-primary mt-auto w-100"
                    style={{ padding: "9px 20px" }}
                    disabled={isLoading}
                    onClick={submitHandler}
                  >
                    Submit
                  </button>
                </div>
              </h5>
              <div>
                <h5 className="m-0 p-0">Lottery Time</h5>
                <div
                  className="p-1 rounded-5 d-flex justify-content-center align-items-center text-center"
                  style={{
                    width: "200px",
                    backgroundColor: "#eee",
                    transition: ".5s",
                  }}
                >
                  <div
                    className={`p-2 rounded-5 me-1 w-100 ${
                      lotteryTime === "One"
                        ? "bg-primary text-white"
                        : "bg-white"
                    }`}
                    onClick={() => setLotteryTime("One")}
                    style={{ cursor: "pointer", transition: ".5s" }}
                  >
                    ၁၆ ရက်နေ့
                  </div>
                  <div
                    className={`p-2 rounded-5 w-100 ${
                      lotteryTime === "Two"
                        ? "bg-primary text-white"
                        : "bg-white"
                    }`}
                    onClick={() => setLotteryTime("Two")}
                    style={{ cursor: "pointer", transition: ".5s" }}
                  >
                    ၁ ရက်နေ့
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table
                      className="table w-100 table-bordered"
                      style={{ border: ".5px solid #ddd" }}
                    >
                      <thead>
                        <tr>
                          <th className=" text-center text-light">No.</th>
                          <th className=" text-center text-light">User ID</th>
                          <th className=" text-center text-light">3D Number</th>
                          <th className=" text-center text-light">
                            Lottery Time
                          </th>
                          <th className=" text-center text-light">
                            Bet Amount
                          </th>
                          <th className=" text-center text-light">
                            Win Amount
                          </th>
                          <th className=" text-center text-light">
                            Bet Status
                          </th>
                          <th className=" text-center text-light">
                            Win / Lose
                          </th>
                          <th className=" text-center text-light">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && <TableLoading col={7} />}
                        {betLists?.length > 0 &&
                          betLists
                            ?.filter((bet) => bet.lottery_time === lotteryTime)
                            ?.sort((a, b) => b.payout_amount - a.payout_amount)
                            .map((bet, i) => (
                              <tr style={{ verticalAlign: "middle" }} key={i}>
                                <td className="text-center bg-white p-2">
                                  {i + from + 1}.
                                </td>
                                <td className="text-center bg-white p-2">
                                  {bet?.users?.usercode}
                                </td>
                                <td className="text-center bg-white p-2">
                                  {bet?.three_digits?.number}
                                </td>
                                <td className="text-center text-white p-2">
                                  <span
                                    className={`p-1 px-2 rounded-1 ${
                                      bet.lottery_time === "Two"
                                        ? "bg-info"
                                        : "bg-secondary"
                                    }`}
                                  >
                                    {bet.lottery_time === "Two"
                                      ? "၁ ရက်နေ့"
                                      : "၁၆ ရက်နေ့"}
                                  </span>
                                </td>
                                <td className="text-end bg-white p-2">
                                  {amountCommasSparated(bet.amount)}
                                </td>
                                <td className="text-end bg-white p-2">
                                  {amountCommasSparated(bet.payout_amount)}
                                </td>
                                <td className="text-center">
                                  <span
                                    className={`p-1 px-3 rounded-1 text-white ${
                                      bet.status === "completed"
                                        ? "bg-success"
                                        : "bg-warning"
                                    }`}
                                  >
                                    {bet.status}
                                  </span>
                                </td>
                                <td className="text-center bg-white p-2">
                                  <span
                                    className={`p-1 px-3 rounded-1 text-white ${
                                      bet.bet_result === "unsettled"
                                        ? "bg-warning"
                                        : bet.bet_result === "win"
                                        ? "bg-success"
                                        : "bg-danger"
                                    }`}
                                  >
                                    {bet.bet_result === "unsettled"
                                      ? "Pending"
                                      : bet.bet_result === "win"
                                      ? "Win"
                                      : "Lose"}
                                  </span>
                                </td>
                                <td className="text-center bg-white p-2">
                                  {ISOdateFormatter(bet.created_at)}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                      <thead>
                        <tr
                          className="text-center fw-bold text-white"
                          style={{ verticalAlign: "middle" }}
                        >
                          <td colSpan={4} className="text-end">
                            Total :{" "}
                          </td>
                          <td className="text-end">
                            {betLists?.length > 0
                              ? amountCommasSparated(
                                  calculateTotal(
                                    betLists?.filter(
                                      (bet) => bet.lottery_time === lotteryTime
                                    ),
                                    "amount"
                                  )
                                )
                              : 0}
                          </td>
                          <td className="text-end">
                            {betLists?.length > 0
                              ? amountCommasSparated(
                                  calculateTotal(
                                    betLists?.filter(
                                      (bet) => bet.lottery_time === lotteryTime
                                    ),
                                    "payout_amount"
                                  )
                                )
                              : 0}
                          </td>
                          <td colSpan={3}></td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </TableScroll>
              </div>
              <div className="col-sm-12">
                {totalPage > 1 && (
                  <Pagi
                    setPage={setPage}
                    setIsLoading={setIsLoading}
                    totalPage={totalPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Template>
      <ToastContainer />
    </>
  );
};

export default MMThreedBetListsAll;
