import { useEffect, useState } from "react";
import Template from "../utils/Template";
import { ToastContainer } from "react-toastify";
import TableScroll from "../utils/TableScroll";
import EditToast from "../utils/EditToast";
import StatusToggle from "../utils/StatusToggle";
import { getMethodService, putMethodService } from "../services";
import {
  BET_SETTING_API,
  THREE_D_LIMIT_RESET_API,
  THREE_D_NUMS_API,
  THREE_D_TIME_API,
  TWO_THREE_SETTING_API,
} from "../services/constants";
import {
  HandleLogout,
  ISOdateFormatter,
  amountCommasSparated,
  dateFormatter,
  decodeData,
  errorToaster,
  numInputPreventWheel,
} from "../utils/Helper";
import MMThreedSettingModal from "../utils/Modal/MMThreedSettingModal";
import TableLoading from "../utils/TableLoading";
import useDebounce from "../hooks/useDebounce";
import MMThreedResetConfirm from "../utils/Modal/MMThreedResetConfirm";
import { useNavigate } from "react-router-dom";
const Cookies = require('js-cookie');

const MMThreedSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [threedNums, setThreedNums] = useState([]);
  const [selectedNum, setSelectedNum] = useState("");
  const [popupEditModal, setPopupEditModal] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [lotteryTime, setLotteryTime] = useState("");
  const [edit, setEdit] = useState("");
  const [editValue, setEditValue] = useState("");
  const [threedSetting, setThreedSetting] = useState([]);
  const [refs, setRefs] = useState(false);
  const [threedTime, setThreedTime] = useState([]);
  const [threedTimeStatus, setThreedTimeStatus] = useState(null);
  const [allowedAmount, setAllowedAmount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const debounceSearch = useDebounce(searchValue, 1000);
  const [resetPopupModal, setResetPopupModal] = useState(false);
  const [authKey, setAuthKey] = useState("");
  const [editId, setEditId] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [betSetting, setBetSetting] = useState({});
  const navigate = useNavigate();

  const fetchThreeDStatus = async () => {
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      setUserInfo(decode);
      const res = await getMethodService(
        `${TWO_THREE_SETTING_API}?type=Three`,
        [],
        false,
        decode.token
      );
      if(res == 401){
          HandleLogout(navigate);
      };
      if (res?.status === "success") {
        setThreedSetting(...res.data);
      }
    }
  };

  const fetchBetSetting = async () => {
    setIsLoading(true)
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      if (decode.role_name !== 'admin') {
        const res = await getMethodService(
          `${BET_SETTING_API}`,
          [],
          false,
          decode.token
        );
        if(res == 401){
            HandleLogout(navigate);
        };
        res && setIsLoading(false)
        if (res?.status === "success") {
          setBetSetting(res.data);
        }
      }
    }
  }

  const fetchThreedTime = async () => {
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await getMethodService(
        `${THREE_D_TIME_API}`,
        [],
        false,
        decode.token
      );
      if(res == 401){
        HandleLogout(navigate);
      };
      if (res?.status === "success") {
        setThreedTime(res.data);
      }
    }
  };

  const searchThreedNums = async () => {
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await getMethodService(
        `${THREE_D_NUMS_API}?search=${searchValue}`,
        [],
        false,
        decode.token
      );
      if (res) {
        if(res == 401){
            HandleLogout(navigate);
        };
        setIsLoading(false);
      }
      if (res?.status === "success") {
        setThreedNums(res.data);
      }
    }
  };

  const fetchThreedNums = async () => {
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await getMethodService(
        `${THREE_D_NUMS_API}`,
        [],
        false,
        decode.token
      );
      if (res) {
        if(res == 401){
            HandleLogout(navigate);
        };
        setIsLoading(false);
      }
      if (res?.status === "success") {
        setThreedNums(res.data);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (searchValue.length > 0) {
      searchThreedNums();
    } else {
      fetchThreedNums();
    }
  }, [debounceSearch, refs]);

  const threedSettingUpdate = async (data) => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await putMethodService(
        `${TWO_THREE_SETTING_API}/${threedSetting?.id}`,
        data,
        true,
        decode.token
      );
      if (res) {
        if (res == 401) {
          HandleLogout(navigate);
        };
        setIsLoading(false);
        setRefs(!refs);
        setEdit("");
        setEditValue("");
      }
    }
  };

  const threedTimeUpdate = async (id) => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await putMethodService(
        `${THREE_D_TIME_API}/${id}?opening_time=${dateFormatter(
          start
        )}:00&closing_time=${dateFormatter(end)}:00&status=${
          threedTimeStatus === "open" ? 1 : 0
        }`,
        [],
        true,
        decode.token
      );
      if (res) {
        if (res == 401) {
          HandleLogout(navigate);
        };
        setIsLoading(false);
        setRefs(!refs);
        setStart("");
        setEnd("");
        setEdit("");
        setThreedTimeStatus(null);
        setPopupEditModal(false);
      }
    }
  };

  const threedNumsLimitUpdate = async (num_status = "", amount) => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await putMethodService(
        `${THREE_D_NUMS_API}?status=${num_status}&num_id=[${+selectedNum + 1}]${
          amount ? `&allowed_amount=${amount}` : ""
        }`,
        [],
        true,
        decode.token
      );
      if (res) {
        if (res == 401) {
          HandleLogout(navigate);
        }
        setIsLoading(false);
        setSelectedNum("");
        setAllowedAmount("");
        fetchThreedNums();
        setRefs(!refs);
      }
    }
  };

  const resetLimit = async () => {
    setIsLoading(true);
    const getAuth = Cookies.get("n_a_u");
    if (getAuth) {
      let decode = decodeData(getAuth);
      const res = await putMethodService(
        `${THREE_D_LIMIT_RESET_API}`,
        [],
        true,
        decode.token
      );
      if (res) {
        if (res == 401) {
          HandleLogout(navigate);
        }
        setAuthKey("");
        setIsLoading(false);
        setRefs(!refs);
        setResetPopupModal(false);
      }
    }
  };

  const selectedNumHandler = (e) => {
    const value = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    if (positiveNumberPattern.test(value)) {
      value < 1000 && setSelectedNum(value.slice(0, 3));
    }
  };

  const searchValueHandler = (e) => {
    const value = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    if (positiveNumberPattern.test(value)) {
      value < 1000 && setSearchValue(value.slice(0, 3));
    }
  };

  const threedStatusToggler = async () => {
    await threedSettingUpdate({ status: threedSetting.status ? 0 : 1 });
  };

  const toggleEdit = (editState) => {
    setEdit(editState);
    switch (editState) {
      case "odds":
        setEditValue(threedSetting?.odds);
        break;
      case "twit":
        setEditValue(threedSetting?.twit);
        break;
      case "min-bet":
        setEditValue(threedSetting?.min_amount);
        break;
      case "max-bet":
        setEditValue(threedSetting?.max_amount);
        break;
      default:
        break;
    }
  };

  const saveState = async (e) => {
    e.preventDefault();
    if (editValue > 0) {
      switch (edit) {
        case "odds":
          await threedSettingUpdate({ odds: editValue });
          break;
        case "twit":
          await threedSettingUpdate({ twit: editValue });
          break;
        case "min-bet":
          await threedSettingUpdate({ min_amount: editValue });
          break;
        case "max-bet":
          await threedSettingUpdate({ max_amount: editValue });
          break;
        default:
          break;
      }
    } else {
      errorToaster(`${edit} must be greater zero ( 0 )`);
    }
  };

  const cancelState = () => {
    setEdit("");
    setEditValue("");
  };

  const updateLotteryTime = async () => {
    await threedTimeUpdate(editId);
  };

  const threedOpenHandler = async () => {
    if (selectedNum.length === 3) {
      await threedNumsLimitUpdate(1, "");
    } else {
      errorToaster("3D Number must be 3 digits!");
    }
  };

  const threedCloseHandler = async () => {
    if (selectedNum.length === 3) {
      await threedNumsLimitUpdate(0, "");
    } else {
      errorToaster("3D Number must be 3 digits!");
    }
  };

  const threedLimitHandler = async () => {
    if (selectedNum.length === 3) {
      if (allowedAmount.length > 0) {
        await threedNumsLimitUpdate("", allowedAmount);
      } else {
        setAllowedAmount("");
        errorToaster("Allowed amount is required!");
      }
    } else {
      errorToaster("3D Number must be 3 digits!");
    }
  };

  const popupEditModalHandler = (threed_data) => {
    setPopupEditModal(true);
    setStart(ISOdateFormatter(threed_data.opening_time));
    setEnd(ISOdateFormatter(threed_data.closing_time));
    setThreedTimeStatus(threed_data.status ? "open" : "close");
    setEditId(threed_data.id);
  };

  const allowedAmountHandler = (e) => {
    const value = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    if (positiveNumberPattern.test(value)) {
      setAllowedAmount(value);
    }
  };

  useEffect(() => {
    fetchThreeDStatus();
  }, [refs]);
  useEffect(() => {
    fetchThreedTime();
  }, [refs]);
  useEffect(() => {
    fetchThreedNums();
    fetchBetSetting();
  }, []);

  return (
    <>
      <Template title="MM 3D Settings">
       {
        userInfo.role_name !== 'admin' &&
        <div className="card mb-3">
        <div className="card-header">
          <h4 className="card-title mt-2">MM 3D Upline Setting</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <TableScroll>
                <div>
                  {betSetting?.three_d_max_per_bet > 0 ? (
                    <table className="table w-100 table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className=" text-center text-light">Status</th>
                          <th className=" text-center text-light">Min Bet</th>
                          <th className=" text-center text-light">Max Bet</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td className="text-center bg-white p-2">
                            <span
                              className={`p-1 px-2 rounded-1 text-white ${
                                betSetting?.three_d_max_per_bet > 0
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            >
                              {betSetting?.three_d_max_per_bet > 0
                                ? "Opened"
                                : "Closed"}
                            </span>
                          </td>
                          <td className="text-center bg-white position-relative fs-4">
                            <div>
                              {amountCommasSparated(betSetting?.three_d_min_per_bet)}
                            </div>
                          </td>
                          <td className="text-center bg-white position-relative fs-4">
                            <div>
                              {amountCommasSparated(
                                betSetting?.three_d_max_per_bet
                              )}
                            </div>
                          </td>
                        </tr>
                        {isLoading && <TableLoading col={3} />}
                      </tbody>
                    </table>
                  ) : (
                   isLoading ? '' : 
                    <div className="alert alert-warning text-center py-3">
                      လူကြီးမင်းအတွက် Upline မှ MM 3D ပိတ်ထားပါသည်ခင်ဗျာ . . .
                    </div>
                  )}
                </div>
              </TableScroll>
            </div>
          </div>
        </div>
      </div>
       }
        <div className="card mb-3">
          <div className="card-header">
            <h4 className="card-title mt-2">MM 3D Opening / Closing</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table className="table w-100 table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className=" text-center text-light">#</th>
                          <th className=" text-center text-light">
                            Opening Time
                          </th>
                          <th className=" text-center text-light">
                            Closing Time
                          </th>
                          <th className=" text-center text-light">
                            Lottery Time
                          </th>
                          <th className=" text-center text-light">Status</th>
                          <th className=" text-center text-light">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {threedTime.length > 0 &&
                          threedTime.map((threed, i) => (
                            <tr
                              key={threed.id}
                              style={{ verticalAlign: "middle" }}
                            >
                              <td className="text-center bg-white p-2">
                                {i + 1}
                              </td>
                              <td className="text-center bg-white position-relative">
                                {ISOdateFormatter(threed.opening_time)}
                              </td>
                              <td className="text-center bg-white position-relative">
                                {ISOdateFormatter(threed.closing_time)}
                              </td>
                              <td className="text-center bg-white position-relative">
                                <span
                                  className={`p-1 px-2 rounded-1 text-white ${
                                    threed.lottery_time === "Two"
                                      ? "bg-info"
                                      : "bg-secondary"
                                  }`}
                                >
                                  {threed.lottery_time === "Two"
                                    ? "၁ ရက်နေ့"
                                    : "၁၆ ရက်နေ့"}
                                </span>
                              </td>
                              <td className="text-center bg-white position-relative">
                                <span
                                  className={`p-1 px-2 rounded-1 text-white ${
                                    threed.status ? "bg-success" : "bg-danger"
                                  }`}
                                >
                                  {threed.status ? "Opened" : "Closed"}
                                </span>
                              </td>
                              <td className="text-center bg-white position-relative">
                                {userInfo?.role_name === "admin" ? (
                                  <i
                                    className="bi bi-pencil-square fs-2 text-primary"
                                    onClick={() =>
                                      popupEditModalHandler(threed)
                                    }
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                ) : (
                                  "- -"
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-header">
            <h4 className="card-title mt-2">MM 3D System Control</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table className="table w-100 table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className=" text-center text-light">Status</th>
                          <th className=" text-center text-light">Odds</th>
                          <th className=" text-center text-light">Twit</th>
                          <th className=" text-center text-light">Min Bet</th>
                          <th className=" text-center text-light">Max Bet</th>
                          <th className=" text-center text-light">
                            Updated Date & Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td className="text-center bg-white p-2">
                            <StatusToggle
                              status={threedSetting?.status ?? false}
                              event={() =>
                                userInfo?.role_name === "admin" &&
                                threedStatusToggler()
                              }
                            />
                          </td>
                          <td className="text-center bg-white position-relative fs-4">
                            <div
                              onClick={() =>
                                userInfo?.role_name === "admin" &&
                                toggleEdit("odds")
                              }
                              style={{
                                cursor: userInfo.role_name === "admin" ? 'pointer' : 'text',
                                textDecoration: userInfo.role_name === "admin" ? 'underline' : 'none',
                              }}
                            >
                              {amountCommasSparated(threedSetting?.odds)}
                            </div>
                            <EditToast
                              isLoading={isLoading}
                              editValue={editValue}
                              setEditValue={setEditValue}
                              state={edit === "odds"}
                              saveState={saveState}
                              cancelState={cancelState}
                              placeholder={"Odds"}
                            />
                          </td>
                          <td className="text-center bg-white position-relative fs-4">
                            <div
                              onClick={() =>
                                userInfo?.role_name === "admin" &&
                                toggleEdit("twit")
                              }
                              style={{
                                cursor: userInfo.role_name === "admin" ? 'pointer' : 'text',
                                textDecoration: userInfo.role_name === "admin" ? 'underline' : 'none',
                              }}
                            >
                              {amountCommasSparated(threedSetting?.twit)}
                            </div>
                            <EditToast
                              isLoading={isLoading}
                              editValue={editValue}
                              setEditValue={setEditValue}
                              state={edit === "twit"}
                              saveState={saveState}
                              cancelState={cancelState}
                              placeholder={"Twit"}
                            />
                          </td>
                          <td className="text-center bg-white position-relative fs-4">
                            <div
                              onClick={() =>
                                userInfo?.role_name === "admin" &&
                                toggleEdit("min-bet")
                              }
                              style={{
                                cursor: userInfo.role_name === "admin" ? 'pointer' : 'text',
                                textDecoration: userInfo.role_name === "admin" ? 'underline' : 'none',
                              }}
                            >
                              {amountCommasSparated(threedSetting?.min_amount)}
                            </div>
                            <EditToast
                              isLoading={isLoading}
                              editValue={editValue}
                              setEditValue={setEditValue}
                              state={edit === "min-bet"}
                              saveState={saveState}
                              cancelState={cancelState}
                              placeholder={"Min Bet"}
                            />
                          </td>
                          <td className="text-center bg-white position-relative fs-4">
                            <div
                              onClick={() =>
                                userInfo?.role_name === "admin" &&
                                toggleEdit("max-bet")
                              }
                              style={{
                                cursor: userInfo.role_name === "admin" ? 'pointer' : 'text',
                                textDecoration: userInfo.role_name === "admin" ? 'underline' : 'none',
                              }}
                            >
                              {amountCommasSparated(threedSetting?.max_amount)}
                            </div>
                            <EditToast
                              isLoading={isLoading}
                              editValue={editValue}
                              setEditValue={setEditValue}
                              state={edit === "max-bet"}
                              saveState={saveState}
                              cancelState={cancelState}
                              placeholder={"Max Bet"}
                            />
                          </td>
                          <td className="text-center bg-white position-relative">
                            <span>
                              {ISOdateFormatter(threedSetting?.updated_at)}
                            </span>
                          </td>
                        </tr>
                        {isLoading && <TableLoading col={6} />}
                      </tbody>
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-3 mb-lg-5">
          <div className="card-header row">
            <div className="col-12 col-md-8">
              <h4 className="card-title mt-2">
                MM 3D ( Limit, Disable & Enable )
              </h4>
            </div>
            <div className="col-12 col-md-4">
              {" "}
              <input
                type="text"
                min={0}
                max={1000}
                onWheel={numInputPreventWheel}
                className="form-control rounded-0 border border-success"
                placeholder="Search Number"
                value={searchValue}
                onChange={searchValueHandler}
              />
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              {userInfo?.role_name === "admin" && (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                      <input
                        type="text"
                        onWheel={numInputPreventWheel}
                        className="form-control rounded-0 border border-success"
                        placeholder="3D Number"
                        value={selectedNum}
                        onChange={selectedNumHandler}
                        style={{ maxWidth: "160px" }}
                      />
                    </div>
                    <div className="col-12 col-md-8 d-flex">
                      <input
                        type="text"
                        onWheel={numInputPreventWheel}
                        className="form-control rounded-0 border border-success"
                        placeholder="Limit Amount"
                        value={allowedAmount}
                        onChange={allowedAmountHandler}
                      />
                      <button
                        className={`btn btn-outline-success me-2 py-2 rounded-0`}
                        onClick={threedLimitHandler}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading" : "Submit"}
                      </button>
                      <button
                        className={`btn btn-outline-success me-2 py-2 rounded-0`}
                        onClick={threedOpenHandler}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading" : "Open"}
                      </button>
                      <button
                        className={`btn btn-outline-danger me-2 py-2 rounded-0`}
                        onClick={threedCloseHandler}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading" : "Close"}
                      </button>
                      <button
                        className={`btn btn-danger py-2 rounded-0`}
                        onClick={() => setResetPopupModal(true)}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading" : "Reset"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-sm-12 mt-3">
                <TableScroll>
                  <div>
                    <table className="table w-100 table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className=" text-center text-light">#</th>
                          <th className=" text-center text-light">3D Number</th>
                          <th className=" text-center text-light">
                            Allowed Amount
                          </th>
                          <th className=" text-center text-light">Status</th>
                          <th className=" text-center text-light">
                            Date & Time
                          </th>
                          {/* <th className=" text-center text-light">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {threedNums.length > 0 &&
                          threedNums.map((threed, i) => (
                            <tr
                              key={threed.id}
                              style={{ verticalAlign: "middle" }}
                            >
                              <td className="text-center bg-white p-2">
                                {i + 1}
                              </td>
                              <td className="text-center bg-white position-relative">
                                {threed.number}
                              </td>
                              <td className="text-center bg-white position-relative">
                                {threed.allowed_amount}
                              </td>
                              {/* <td className="text-center bg-white position-relative">
                                                            <span className={`p-1 px-2 rounded-1 text-white bg-primary`}>{threed.lottery_time}</span>
                                                        </td> */}
                              <td className="text-center bg-white position-relative">
                                <span
                                  className={`p-1 px-2 rounded-1 text-white ${
                                    threed.status ? "bg-success" : "bg-danger"
                                  }`}
                                >
                                  {threed.status ? "Opened" : "Closed"}
                                </span>
                              </td>
                              <td className="text-center bg-white position-relative">
                                {ISOdateFormatter(threed.updated_at)}
                              </td>
                              {/* <td className="text-center bg-white position-relative">
                                                            <i className='bi bi-pencil-square fs-2 text-primary' onClick={() => popupEditModalHandler(threed)} style={{ cursor: 'pointer' }}></i>
                                                        </td> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
      </Template>
      {popupEditModal && userInfo?.role_name === "admin" && (
        <MMThreedSettingModal
          onClose={() => setPopupEditModal(false)}
          name="Update MM 3D Lottery Time"
          onSubmit={updateLotteryTime}
          isLoading={isLoading}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          threedTimeStatus={threedTimeStatus}
          setThreedTimeStatus={setThreedTimeStatus}
          lotteryTime={lotteryTime}
          setLotteryTime={setLotteryTime}
        />
      )}
      {resetPopupModal && userInfo?.role_name === "admin" && (
        <MMThreedResetConfirm
          onClose={() => setResetPopupModal(false)}
          title="Reset All 3D Limit"
          des="3D Limit Setting မှ ပြင်ထားသော ဂဏန်းများအားလုံးကို မူလအခြေအနေတိုင်း ပြန်ထားမည်။"
          onSubmit={resetLimit}
          isLoading={isLoading}
          authKey={authKey}
          setAuthKey={setAuthKey}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default MMThreedSettings;
