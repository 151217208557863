import React, { useEffect, useState } from 'react';
import Template from '../../utils/Template';
import DateWithStartTime from '../../utils/DateWithStartTime'
import TableScroll from '../../utils/TableScroll'
import TableLoading from '../../utils/TableLoading'
import { stateObjType } from '../../types'
import { HandleLogout, amountCommasSparated } from '../../utils/Helper'
import { WALLET_TRANSACTION_API } from '../../services/constants'
import { getMethodService } from '../../services'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

const WalletTransaction = () => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [refreshKey,setRefreshKey] = useState(0);
    const navigate = useNavigate();

    useEffect(()=>{
        localStorage.removeItem('r_type');
    },[]);

    const fetchWalletTrans = (search:string | undefined | number, startDate:string | undefined, endDate:string | undefined) => getMethodService(`${WALLET_TRANSACTION_API}?user_id=${search}&start_date=${startDate}&end_date=${endDate}&transaction_type_id[]=1&transaction_type_id[]=2&load_user=1`);
    const walletTransQuery = useQuery({ queryKey: ['walletTrans',refreshKey], queryFn: () => {
        if(search){
            return fetchWalletTrans(search,startDate,endDate)
        };
    }});
    

    useEffect(()=>{
        if(walletTransQuery){
            setIsLoading(walletTransQuery?.isLoading);
            if(walletTransQuery?.data == 401){
                HandleLogout(navigate);
            };
            if(walletTransQuery?.data?.status === "success"){
                setData(walletTransQuery?.data?.data)
            };
        };
    },[walletTransQuery])

    const submitHandler = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };

  return (
    <>
        <Template title='Report'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <h4 className="card-title mb-2">
                                Wallet Transaction
                            </h4>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="row align-items-sm-center justify-content-end">
                                <div className="col-lg-3">
                                    <div className="mb-3 mb-lg-0">
                                        <label className="form-label mb-1">
                                            Search
                                        </label>
                                        <input
                                            type="text"
                                            className="border form-control"
                                            style={{ padding: "10px" }}
                                            placeholder="search"
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div>
                                        <DateWithStartTime
                                            startDate={startDate}
                                            endDate={endDate}
                                            setStartDate={setStartDate}
                                            setEndDate={setEndDate}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <button
                                        className="btn btn-primary w-100 mt-4"
                                        onClick={submitHandler}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Loading ..." : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white border table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                <th className="p-2 text-center text-light">User Code</th>
                                                <th className="p-2 text-center text-light">Before</th>
                                                <th className="p-2 text-center text-light">Amount</th>
                                                <th className="p-2 text-center text-light">After</th>
                                                <th className="p-2 text-center text-light">Type</th>
                                                <th className="p-2 text-center text-light">Date</th>
                                                <th className="p-2 text-center text-light">Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading &&
                                                <TableLoading col={8} />
                                            }
                                            {
                                                data?.length > 0 ?
                                                data?.map((el:stateObjType, i:number) =>
                                                  <tr key={i}>
                                                        <td className="p-2 text-center text-dark">{i + 1}</td>
                                                        <td className="p-2 text-center text-dark">{el?.user?.usercode}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(el?.before ?? 0)}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(el?.amount ?? 0)}</td>
                                                        <td className="p-2 text-end text-dark">{amountCommasSparated(el?.after ?? 0)}</td>
                                                        <td className="p-2 text-center text-dark">
                                                            <span className={`badge bg-${el?.type === "increase"?'success':'danger'}`}>
                                                                {el?.type}
                                                            </span>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            {el?.created_at}
                                                        </td>
                                                        <td className="p-2 text-center text-dark">
                                                            <span className="text-danger">{el?.transaction_type?.description}</span>
                                                        </td>
                                                  </tr>
                                                ):
                                                <tr>
                                                    <td colSpan={8} className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    </>
  )
}

export default WalletTransaction
