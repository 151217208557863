import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { useQuery } from '@tanstack/react-query';
import { getMethodService, postMethodService, putMethodService } from '../services';
import { CREATE_SUB_ACCOUNT_API, SUB_ACCOUNT_API, USER_PASSWORD_CHANGE_API } from '../services/constants';
import { HandleLogout, copyToClipboard } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import TableLoading from '../utils/TableLoading';
import { stateObjType } from '../types';
import ChangePasswordModal from '../utils/Modal/ChangePasswordModal';
import SubUserCreateModal from '../utils/Modal/SubUserCreateModal';
import SweetAlert from "sweetalert2";
import Pagi from '../utils/Pagi';

const SubUser = () => {
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [createSubModal, setCreateSubModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showChangePassModal, setShowChangePassModal] = useState(false);
    const [auth, setAuth] = useState(null);
    const [refreshKey,setRefreshKey] = useState(0);
    const navigate = useNavigate();
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);

    const fetchUsers = () => getMethodService(`${SUB_ACCOUNT_API}?page=${page}`);
    const usersQuery = useQuery({ queryKey: ['users',page,refreshKey], queryFn: fetchUsers, refetchOnWindowFocus: false});

    useEffect(()=>{
        setIsLoading(usersQuery?.isLoading);
        if(usersQuery?.data == 401){
            HandleLogout(navigate);
        };

        if(usersQuery?.data?.status === "success"){
            if(usersQuery?.data?.meta){
                setIsLoading(usersQuery?.data?.meta?.current_page === page ? false : true);
                if(usersQuery?.data?.meta.current_page > 0){
                   if(usersQuery?.data?.meta.per_page && usersQuery?.data?.meta.current_page){
                      setFrom((usersQuery?.data?.meta.per_page * (usersQuery?.data?.meta.current_page - 1)) + 1 );
                   };
                };
                setTotalPage(usersQuery?.data?.meta.last_page);
            };
            setUsers(usersQuery?.data?.data);
        };
    },[usersQuery]);
    
    const changePasswordSubmit = (data:React.FormEvent<HTMLFormElement>) => {
        if (auth) {
            setIsLoading(true);
            putMethodService(
                USER_PASSWORD_CHANGE_API,
                { user_id: auth, ...data },true
            )
            .then(res => {
                setIsLoading(false);
                if(res == 401){
                    HandleLogout(navigate);
                };
                if(res?.status){
                    setShowChangePassModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
            });
        }
    };

    const createSubAccHandler = (data:React.FormEvent<HTMLFormElement>) => {
            setIsLoading(true);
            postMethodService(
                CREATE_SUB_ACCOUNT_API,data,true
            )
            .then(res => {
                setIsLoading(false);
                if(res == 401){
                    HandleLogout(navigate);
                };
                console.log(res)
                if(res?.status){
                    SweetAlert.fire({
                        icon: "success",
                        confirmButtonText: "Copy",
                        html: `<div style="background-color: #f3f3f3;padding: 10px 0;border-radius: 5px;">usercode: ${res?.data?.usercode} </br> password: ${res?.data?.password}</div>`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // SweetAlert.fire("Saved!", "", "success");
                            copyToClipboard(
                                `usercode: ${res?.data?.usercode}\npassword: ${res?.data?.password}`
                            );
                        }
                    });
                    setCreateSubModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
            });
    };

  return (
    <>
        <Template title="Member management">
            <div className="card-header">
                <div className="row align-items-center justify-content-between">
                    <div className="col-sm-6">
                        <h4 className="card-title">
                            Sub account
                        </h4>
                    </div>
                    <div className="col-sm-6">
                        {
                            !isLoading && <button className='btn btn-primary float-end'
                            onClick={() => {
                                setCreateSubModal(
                                    true
                                );
                            }}
                        >Create</button>
                        }
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className='col-sm-12'>
                        <table className="table text-white border table-bordered table-striped">
                            <thead>
                                <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                    <th className="p-2 text-center text-light">No</th>
                                    <th className="p-2 text-center text-light">Username</th>
                                    <th className="p-2 text-center text-light">Contact</th>
                                    <th className="p-2 text-center text-light">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isLoading &&
                                    <TableLoading col={4} />
                                }
                                {
                                    users?.length > 0 ? 
                                    users?.map((user:stateObjType, i:number) => 
                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                        <td className="p-2 text-center text-dark">{i + from}</td>
                                        <td className="p-2 text-center text-dark">{user.usercode ?? '--'}</td>
                                        <td className="p-2 text-center text-dark">{user.contact ?? '--'}</td>
                                        <td>
                                            <div className='d-flex align-content-center justify-content-center'>
                                                <button
                                                        className="btn btn-outline-primary btn-sm m-1 py-1"
                                                        onClick={() => {
                                                            setShowChangePassModal(
                                                                true
                                                            );
                                                            setAuth(user.id);
                                                        }}
                                                    >
                                                        <i className="bi bi-unlock-fill"></i>{" "}
                                                        Change Password
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={4} className='text-center'>No found data</td>
                                    </tr>

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {totalPage > 1 && (
                <div className="card-footer">
                    <div className="row justify-content-end">
                        <div>
                            <Pagi
                                setIsLoading={setIsLoading}
                                setPage={setPage}
                                totalPage={totalPage}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Template>
        {showChangePassModal && (
                <ChangePasswordModal
                    onClose={() => setShowChangePassModal(false)}
                    onSubmit={changePasswordSubmit}
                    isLoading={isLoading}
                />
            )}
        {
            createSubModal &&
            <SubUserCreateModal
                onClose={() => setCreateSubModal(false)}
                onSubmit={createSubAccHandler}
                isLoading={isLoading}
            />
        }
        <ToastContainer />
    </>
  )
}

export default SubUser
