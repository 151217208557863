import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import TableScroll from '../utils/TableScroll'
import TableLoading from '../utils/TableLoading'
import { getMethodService } from '../services'
import { HDP_AND_OU_NODE_API } from '../services/constants'
import { Link, useNavigate } from 'react-router-dom'
import { HandleLogout, amountCommasSparated, decodeData } from '../utils/Helper'
import { stateObjType } from '../types'
import { useQuery } from '@tanstack/react-query'
import DateRangeFormat from '../utils/DateRangeFormat'
import moment from 'moment'
const Cookies = require('js-cookie');

const StockOverUnder = () => {
    const abortController = new AbortController();
    const [isLoading,setIsLoading] = useState(false);
    const [fixtures, setFixtures] = useState([]);
    const navigate = useNavigate();
    const actualDate = new Date();
    const footballDate = new Date();

    useEffect(()=>{
        localStorage.removeItem('r_type');
    },[]);

    var checkTime = new Date();
    checkTime.setHours(10); checkTime.setMinutes(29);

    if(actualDate.getTime() < checkTime.getTime()){
        footballDate.setDate(footballDate.getDate() - 1);
    }

    const year = footballDate.getFullYear();
    const month = String(footballDate.getMonth() + 1).padStart(2, "0");
    const day = footballDate.getDate();
    // const tomorrowDate = new Date(footballDate);
    // tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    // const tomorrowYear = tomorrowDate.getFullYear();
    // const tomorrowMonth = String(tomorrowDate.getMonth() + 1).padStart(2, "0");
    // const tomorrowDay = String(tomorrowDate.getDate()).padStart(2, "0");
    // const tomorrow = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay} 10:28`;
    
    const today = `${year}-${month}-${day.toString().padStart(2, "0")}`;
    const [startDate, setStartDate] = useState<any>(today);
    const [refreshKey,setRefreshKey] = useState(0);
    function formatDate(date:any,endStatus:any) {
        let testD = new Date(date);
        endStatus && testD.setDate(testD.getDate() + 1);
        let month = "" + (testD.getMonth() + 1);
        let day = "" + testD.getDate();
        let year = testD.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
    };

    const fetchOverUnder = () => {
        const getAuth = Cookies.get('n_a_u');
        if(getAuth){
            let decode = decodeData(getAuth);
            return getMethodService(`${HDP_AND_OU_NODE_API}?mm_football_categories[]=1&mm_football_categories[]=2&limit=100000&start_date=${formatDate(startDate, false)} 10:29&end_date=${formatDate(startDate, true)} 10:28${startDate === today? '&status=active':''}`,{},false,decode?.token,abortController);
        };
    }
    const overUnderQuery = useQuery({ queryKey: ['stockOverUnder',refreshKey], queryFn: fetchOverUnder, refetchOnWindowFocus: false});

    useEffect(() => {
        if(overUnderQuery){
            setIsLoading(overUnderQuery.isLoading);
            if(overUnderQuery?.data == 401){
                HandleLogout(navigate);
            };
            if(overUnderQuery?.data?.status === "success"){
                setFixtures(overUnderQuery?.data?.data);
            };
        };
    }, [overUnderQuery]);

    const changeObs = (num:number) =>{
        if(num < 0){
            return -(num);
        }
        return num;
    };
    const submitHandler = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };
    useEffect(()=>{
        return () => {
            abortController.abort();
        }
    },[]);
  return (
    <>
        <Template title='Stock Management'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-3">
                            <h4 className="card-title mb-2 mb-lg-0">
                                HDP & OU
                            </h4>
                        </div>
                        <div className="col-lg-5">
                            <div className='row'>
                                <div className="col-lg-6">
                                    <label className="form-label mb-1">Start date</label>
                                    <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
                                </div>
                                <div className="col-lg-6">
                                    <button
                                        className="btn btn-primary w-100 mt-4"
                                        onClick={submitHandler}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Loading ..." : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table w-100 table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th className="p-2 text-center text-light"  >No</th>
                                                <th className="p-2 text-center text-light"  >League</th>
                                                <th className="p-2 text-center text-light"  >Date</th>
                                                <th className="p-2 text-center text-light"  >Match</th>
                                                <th className="p-2 text-center text-light"  >Home</th>
                                                <th className="p-2 text-center text-light"  >Stock</th>
                                                <th className="p-2 text-center text-light"  >Away</th>
                                                <th className="p-2 text-center text-light"  >Over</th>
                                                <th className="p-2 text-center text-light"  >Stock</th>
                                                <th className="p-2 text-center text-light"  >Under</th>
                                                <th className="p-2 text-center text-light"  >Bet Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {isLoading && (
                                            <TableLoading col={11} />
                                        )}
                                        {
                                            fixtures.length > 0 ?
                                                    fixtures.map((fixture: stateObjType,index: number) =>(
                                                        <tr style={{verticalAlign: 'middle'}} key={index}>
                                                            <td className="p-2 text-center text-dark" style={{fontWeight: 'bolder'}}>{index + 1}</td>
                                                            <td className="text-center bg-primary">
                                                                <div className="text-white " style={{fontWeight: 'bolder'}}>
                                                                    {fixture?.league_data?.name}
                                                                </div>
                                                            </td>
                                                            <td className="p-2 text-center text-dark" style={{fontWeight: 'bolder'}}>{fixture?.fixture_start_time}</td>
                                                            <td className="p-2 text-center text-dark" style={{fontWeight: 'bolder'}}>
                                                                {

                                                                }
                                                                {
                                                                    fixture?.odds_team == 'home' ?
                                                                    <span className="text-danger">{
                                                                        fixture?.home_team_data?.name
                                                                    }</span>
                                                                    :
                                                                    (fixture?.home_team_data?.name) 
                                                                }
                                                                {` `} vs {` `}
                                                                {
                                                                    fixture?.odds_team == 'away' ?
                                                                    <span className="text-danger">{
                                                                        fixture?.away_team_data?.name
                                                                    }</span>
                                                                     :
                                                                    (fixture?.away_team_data?.name)
                                                                    
                                                                }
                                                                {` `}
                                                                {fixture?.goals ? `(${fixture?.goals?.home} - ${fixture?.goals?.away})` : ''}
                                                            </td>
                                                            <td className="p-2 text-center text-dark" style={{fontWeight: 'bolder'}}>
                                                                {fixture?.odds_team == 'home' ? <span className="text-danger">{amountCommasSparated(fixture?.home)}</span> : amountCommasSparated(fixture?.home) }
                                                            </td>
                                                            <td className="p-2 text-center" style={{fontWeight: 'bolder'}}>
                                                                <Link to={`/stock-manage-hdp-ou/hdp/${fixture?.id}`} className="text-decoration-underline text-dark">
                                                                    {
                                                                        ((fixture?.odds_team == 'home' && fixture?.home < fixture?.away) ||
                                                                        (fixture?.odds_team == 'away' && fixture?.home > fixture?.away))
                                                                        ? amountCommasSparated(changeObs(fixture?.home - fixture?.away))
                                                                        : <span className="text-danger">{amountCommasSparated(changeObs(fixture?.home - fixture?.away))}</span>
                                                                    }
                                                                </Link>
                                                            </td>
                                                            <td className="p-2 text-center text-dark" style={{fontWeight: 'bolder'}}>
                                                                {fixture?.odds_team == 'away' ? <span className="text-danger">{amountCommasSparated(fixture?.away)}</span> : amountCommasSparated(fixture['away'])}
                                                            </td>
                                                            <td className="p-2 text-center text-dark" style={{fontWeight: 'bolder'}}>
                                                                <span className="text-danger">
                                                                    {amountCommasSparated(fixture?.over)}
                                                                </span>
                                                            </td>
                                                            <td className="p-2 text-center" style={{fontWeight: 'bolder'}}>
                                                                <Link to={`/stock-manage-hdp-ou/ou/${fixture?.id}`} className="text-decoration-underline text-dark">
                                                                    {
                                                                        fixture?.over > fixture?.under ? <span className="text-danger">{amountCommasSparated(changeObs(fixture?.over - fixture?.under))}</span> : amountCommasSparated(changeObs(fixture?.over - fixture?.under))
                                                                    }
                                                                </Link>
                                                            </td>
                                                            <td className="p-2 text-center text-dark" style={{fontWeight: 'bolder'}}>
                                                                {amountCommasSparated(fixture?.under)}
                                                            </td>
                                                            <td className="p-2 text-center text-dark" style={{fontWeight: 'bolder'}}>
                                                                {amountCommasSparated(fixture?.total_bet)}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan={11} className="text-center">
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
        <ToastContainer />
    </>
  )
}

export default StockOverUnder
