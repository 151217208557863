import React, { useEffect, useState } from 'react'
import { decodeData } from '../utils/Helper';
import TempoCLose from '../utils/TempoClose';
import NotFound from '../utils/NotFound';
import WaitingPage from '../utils/WaitingPage';
const Cookies = require('js-cookie');

const BlackListOutstandingRoute = ({children,settingJson}:any) => {
    const [userInfo,setUserInfo] = useState<any>({});
    const blackListRoles:any = ['admin', 'super', 'senior']; //'admin', 'super', 'senior', 'master', agent

    useEffect(()=>{
        const getAuth = Cookies.get('a_u');
        let decode = getAuth && decodeData(getAuth);
        if(decode){
            setUserInfo(decode)
        };
    },[]);
    
    if(userInfo?.user_id){
        if(settingJson?.disable_remark){
            switch(userInfo?.role_name){
                case 'admin':
                    if(!settingJson?.is_enable_outstanding_admin){
                        return <TempoCLose />;
                    }else{
                        return children;
                    };
                case 'super':
                    if(!settingJson?.is_enable_outstanding_super){
                        return <TempoCLose />;
                    }else{
                        return children;
                    };
                case 'senior':
                    if(!settingJson?.is_enable_outstanding_senior){
                        return <TempoCLose />;
                    }else{
                        return children;
                    };
                case 'master':
                    if(!settingJson?.is_enable_outstanding_master){
                        return <TempoCLose />;
                    }else{
                        return children;
                    };
                case 'agent':
                    if(!settingJson?.is_enable_outstanding_agent){
                        return <TempoCLose />;
                    }else{
                        return children;
                    };
                default: 
                    return children;
            };
            // if(!settingJson?.is_enable_report){
            //     if(blackListRoles?.includes(userInfo?.role_name)){
            //         return <TempoCLose />;
            //     }else{
            //         return children;
            //     }
            // }else{
            //     return children;
            // };
        }else{
            return <WaitingPage />
        };
    };
};

export default BlackListOutstandingRoute;