import React, { useEffect, useState } from 'react'
import Template from '../../utils/Template'
import { ToastContainer } from 'react-toastify'
import { getMethodService, putMethodService } from '../../services';
import { CONFIG_SETTING_API, CONFIG_SETTING_EDIT_API, TEX_SETTING_API } from '../../services/constants';
import { HandleLogout } from '../../utils/Helper';
import { useNavigate } from 'react-router-dom';
import TableLoading from '../../utils/TableLoading';
import EasyEdit from 'react-easy-edit';
import '../../assets/styles/EasyEdit.css';

const FootballSetting = () => {
  const [isLoading,setIsLoading] = useState(true);
  const [time,setTime] = useState("");
  const [taxData,setTaxData] = useState([]);
  const navigate = useNavigate();

  const fetchTax = () => {
    getMethodService(TEX_SETTING_API)
    .then((response) => {
        if(response == 401){
            HandleLogout(navigate);
        };
        if(response.status === 'success'){
            setTaxData(response?.data);
        };
    })
    .catch((err) => console.error(err));
  };

  const save = (value,id) => {
    setIsLoading(true);
    putMethodService(`${TEX_SETTING_API}/${id}/update`, {tax_percent: value},true)
       .then((response) => {
            setIsLoading(false);
            if(response == 401){
                HandleLogout(navigate);
            }
            if(response.status === 'success'){
                fetchTax();
            };
       })
       .catch((err) => console.error(err));
 }

  const fetchTime = () => {
    getMethodService(CONFIG_SETTING_API)
    .then((response) => {
        setIsLoading(false);
        if(response == 401){
            HandleLogout(navigate);
        };
        if(response.status === 'success'){
            setTime(response?.data?.close_before);
        };
    })
    .catch((err) => console.error(err));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    putMethodService(CONFIG_SETTING_EDIT_API, {close_before: time},true)
    .then((response) => {
        if(response == 401){
            HandleLogout(navigate);
        }
        if(response.status === 'success'){
            fetchTime();
        };
    })
    .catch((err) => console.error(err));
  };

  useEffect(()=>{
    fetchTime();
    fetchTax();
 },[]);

  return (
    <>
        <Template title='Football'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <h4 className="card-title mb-2">
                                Setting
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="card mb-3 mb-sm-0">
                                <div className="card-header">
                                    <h5 className="card-title">
                                        Close Time
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={submitHandler}>
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <label
                                                    htmlFor="close_before"
                                                    className="col-form-label form-label"
                                                    style={{fontSize: '13px'}}
                                                >
                                                    Close Time (Minute)
                                                </label>
                                                <div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="close_before"
                                                        id="close_before"
                                                        aria-label="close_before"
                                                        onChange={(e) => setTime(e.target.value)}
                                                        value={time}
                                                        style={{fontSize: '13px'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                        {!isLoading ? (
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            style={{fontSize: '12px'}}
                                        >
                                            Save changes
                                        </button>
                                        ) : (
                                            <button type="button" className="btn btn-primary" disabled>
                                                <span
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                    style={{fontSize: '12px'}}
                                                ></span>
                                                Loading...
                                            </button>
                                        )}

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">
                                        Tax
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div>
                                    <table className="table text-white border table-bordered table-striped">
                                            <thead>
                                                <tr style={{backgroundColor: '#25282a',verticalAlign: 'middle'}}>
                                                    <th className="text-center text-light p-2" style={{fontSize:'12px'}}>#</th>
                                                    <th className="text-center text-light p-2" style={{fontSize:'12px'}}>Name</th>
                                                    <th className="text-center text-light p-2" style={{fontSize:'12px'}}>%</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading && (
                                                    <TableLoading col={3}/>
                                                )}
                                                {
                                                    taxData?.length > 0 ? 
                                                    taxData?.map((el,i)=>
                                                        <tr key={i} style={{verticalAlign: 'middle'}}>
                                                            <td className="text-center text-dark p-2">{i + 1}</td>
                                                            <td className="text-center text-dark p-2">
                                                                {el.category?.name} 
                                                                {el?.min_perlay_count === el?.max_perlay_count? (el?.min_perlay_count > 1 && ` ( ${el?.min_perlay_count} ) `): ` ( ${el?.min_perlay_count}-${el?.max_perlay_count} ) `}
                                                                {
                                                                    el.market === "first-half" &&
                                                                    <span className='badge bg-primary ms-2'>ပပိုင်း</span>
                                                                }
                                                            </td>
                                                            <td className='text-center text-dark p-2 text-decoration-underline' style={{width: '150px'}}>
                                                                <EasyEdit
                                                                    type="number"
                                                                    onSave={ value => save( value ,el?.id)}
                                                                    saveButtonLabel={<i className="bi bi-check-lg btn btn-success p-2 py-1" style={{fontSize: '10px'}}></i>}
                                                                    cancelButtonLabel={<i className="bi bi-x-lg btn btn-danger p-2 py-1" style={{fontSize: '10px'}}></i>}
                                                                    id={el.id}
                                                                    attributes={{ id: el.id}}
                                                                    value={el?.tax_percent}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    <tr>
                                                        <td colSpan="3" className="text-center">
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
        <ToastContainer />
    </>
  )
}

export default FootballSetting
