import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangeType } from '../types';

const NormalDateRangeFormat = ({startDate,endDate,setStartDate, setEndDate}:DateRangeType) => {
    const [dateType,setDateType] = useState("");

    // function formatDate(date) {
    //     var d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2)
    //         month = '0' + month;
    //     if (day.length < 2)
    //         day = '0' + day;
    //     return [year, month, day].join('-');
    // }

    function currentDate() {
      let start_date = moment().format('YYYY-MM-DD');
      let end_date = moment().format('YYYY-MM-DD');

        setStartDate(start_date);
        setEndDate(end_date);
        // let curr = new Date;
        // let first = curr.getDate();
        // let last = curr.getDate();

        // let firstday = new Date(curr.setDate(first)).toUTCString();
        // let lastday = new Date(curr.setDate(last)).toUTCString();
        // setStartDate(formatDate(firstday));
        // setEndDate(formatDate(lastday));
        // console.log(`Current Day first ${formatDate(firstday)} second ${formatDate(lastday)}`);
    }

    function yesterdayDate() {
        let start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        let end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
        // let curr = new Date;
        // let first = curr.getDate() - 1;
        // let last = curr.getDate() - 1;

        // let firstday = new Date(curr.setDate(first)).toUTCString();
        // let lastday = new Date(curr.setDate(last)).toUTCString();
        // setStartDate(formatDate(firstday));
        // setEndDate(formatDate(lastday));
        // console.log(`yesterdayDate Day first ${formatDate(firstday)} second ${formatDate(lastday)}`);
    }

    function thisWeek() {
        let start_date = moment().startOf('isoWeek').format('YYYY-MM-DD');
        let end_date = moment().endOf('isoWeek').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
        // let curr = new Date;
        // let first = curr.getDate() - curr.getDay();
        // let last = first + 6;

        // let firstday = new Date(curr.setDate(first)).toUTCString();
        // let lastday = new Date(curr.setDate(last)).toUTCString();
        // setStartDate(formatDate(firstday));
        // setEndDate(formatDate(lastday));
        // console.log(`This Week first ${formatDate(firstday)} second ${formatDate(lastday)}`);
    };

    function lastWeek() {
        let start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
        let end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
        // let curr = new Date;
        // let first = curr.getDate() - curr.getDay() - 7;
        // let last = first + 6;

        // let firstday = new Date(curr.setDate(first)).toUTCString();
        // let lastday = new Date(curr.setDate(last)).toUTCString();
        // setStartDate(formatDate(firstday));
        // setEndDate(formatDate(lastday));
        // console.log(`Last Week first ${formatDate(firstday)} second ${formatDate(lastday)}`);
    };

    function thisMonth() {
        let start_date = moment().startOf('month').format('YYYY-MM-DD');
        let end_date = moment().endOf('month').format('YYYY-MM-DD');
        setStartDate(start_date);
        setEndDate(end_date);
    //   let curr = new Date;
    //   let firstday = new Date(curr.getFullYear(), curr.getMonth(), 1);
    //   let lastday = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
    //   setStartDate(formatDate(firstday));
    //   setEndDate(formatDate(lastday));
    //   console.log(`This month first ${formatDate(firstday)} second ${formatDate(lastday)}`);
    };



    useEffect(()=>{
       switch(dateType){
          case '1':
             return currentDate();
          case '2':
            return yesterdayDate();
          case '3':
            return thisWeek();
          case '4':
            return lastWeek();
          case '5':
            return thisMonth();
          default:
            return currentDate();
       }
    },[dateType])

  return (
      <div className="row">
         <div className="mb-3 col-lg-4 mb-lg-0">
              <label className="form-label mb-1">Start date</label>
              <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
         </div>
         <div className="mb-3 col-lg-4 mb-lg-0">
              <label className="form-label mb-1">End date</label>
              <input type="date" className='form-control' value={endDate} onChange={e => setEndDate(e.target.value)}/>
         </div>
         <div className="mb-3 col-lg-4 mb-lg-0">
            <label className="form-label mb-1">Find</label>
            <select className="form-select form-select-lg w-100" onChange={e => setDateType(e.target.value)}>
                  <option value="1">Today</option>
                  <option value="2">Yesterday</option>
                  <option value="3">This Week</option>
                  <option value="4">Last Week</option>
                  <option value="5">This Month</option>
              </select>
         </div>
      </div>
  )
}

export default NormalDateRangeFormat;
